import React, { useState, useMemo } from 'react';
// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, GetTagProps, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress, Chip } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import gql from 'graphql-tag';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { useQuery } from 'react-apollo';

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         removedAvatar: {
//             maxWidth: '15px',
//             maxHeight: '15px',
//         },
//     }),
// );

interface MatterTypeSelectorProps {
    disabled?: boolean;
    required?: boolean;
    // tslint:disable-next-line: no-any
    value?: MatterTypeTypeAutoCompleteItem | MatterTypeTypeAutoCompleteItem[] | any; // value only available in single select
    error?: boolean;
    onSelection?: (value?: MatterTypeTypeAutoCompleteItem | MatterTypeTypeAutoCompleteItem[], name?: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    style?: React.CSSProperties;
    disablePortal?: boolean;
    variant?: 'standard' | 'filled' | 'outlined';
    size?: 'small' | 'medium';
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    multiple?: boolean;
    isApplyMatterAccess?: boolean;
    disableCloseOnSelect?: boolean;
}

export interface MatterTypeTypeAutoCompleteItem extends IAutoCompleteItem, MatterType {}

interface MatterTypeSelectorState {
    data: MatterTypeTypeAutoCompleteItem[];
}

export const MatterTypeSelector: React.FC<MatterTypeSelectorProps> = (props) => {
    // const classes = useStyles();

    const [state, setState] = useState<MatterTypeSelectorState>({
        data: [],
    });

    const matterTypeQuery = useQuery<MatterTypeData, MatterTypeParams>(MATTER_TYPE_QUERY, {
        variables: {
            isApplyMatterAccess: props.isApplyMatterAccess
        },
        fetchPolicy: 'cache-first'
    });

    useMemo(() => {
        if (!matterTypeQuery.loading && !matterTypeQuery.error && matterTypeQuery.data && matterTypeQuery.data.lookup && matterTypeQuery.data.lookup.matterType) {
            setState((prevState) => {
                const { matterType } = matterTypeQuery.data!.lookup;

                const items: MatterTypeTypeAutoCompleteItem[] = matterType.map((mType: MatterType) => {
                    return {
                        ...mType,
                        label: mType.description,
                        value: mType.description,
                    };
                }).sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    } else if (a.label > b.label) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                return {
                    ...prevState,
                    data: items,
                };
            });
        }
    // tslint:disable-next-line: align
    }, [matterTypeQuery.loading, matterTypeQuery.data, matterTypeQuery.error]);
    
    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (props.onSelection) {
            props.onSelection(value, props.name);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return {
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                    {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>
            ),
        };
    };

    const onRenderTags = (value: MatterTypeTypeAutoCompleteItem[], getTagProps: GetTagProps): React.ReactNode => {
        let renderTagsValue = value.map((option: MatterTypeTypeAutoCompleteItem, index: number) => {
            let label = option.label;
            if (option.label.length > 20) {
                label = `${option.label.substring(0, 20)}...`;
            }

            return (
                <Chip
                    {...getTagProps({ index })}
                    key={index}
                    label={label}
                    // variant="outlined"
                    size="small"
                />
            );
        });

        return renderTagsValue;
    };

    if (props.multiple) {
        return (
            <Autocomplete
                style={props.style}
                className={props.className}
                value={props.value}
                loading={matterTypeQuery.loading}
                disabled={props.disabled}
                onChange={handleChange}
                options={state.data}
                disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                // tslint:disable-next-line: jsx-no-lambda
                getOptionLabel={(option: MatterTypeTypeAutoCompleteItem) => option.label}
                // tslint:disable-next-line: jsx-no-lambda
                onBlur={props.onBlur}
                multiple={true}
                renderTags={onRenderTags}
                disableCloseOnSelect={props.disableCloseOnSelect}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        variant={props.variant}
                        fullWidth={true}
                        margin="none"
                        required={props.required}
                        InputProps={getInputProps(params, matterTypeQuery.loading)}
                        error={props.error}
                        size={props.size}
                        helperText={props.helperText}
                    />
                )}
                // tslint:disable-next-line: jsx-no-lambda
                renderOption={(option, { inputValue }) => {
                    const matches = match(option.label, inputValue);
                    const parts = parse(option.label, matches);

                    return (
                        <div>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    );
                }}
            />
        );
    }

    return (
        <Autocomplete
            style={props.style}
            className={props.className}
            value={props.value}
            loading={matterTypeQuery.loading}
            disabled={props.disabled}
            onChange={handleChange}
            options={state.data}
            disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
            // tslint:disable-next-line: jsx-no-lambda
            getOptionLabel={(option: MatterTypeTypeAutoCompleteItem) => option.label}
            // tslint:disable-next-line: jsx-no-lambda
            onBlur={props.onBlur}
            disableCloseOnSelect={props.disableCloseOnSelect}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    variant={props.variant}
                    fullWidth={true}
                    margin="none"
                    required={props.required}
                    InputProps={getInputProps(params, matterTypeQuery.loading)}
                    error={props.error}
                    size={props.size}
                    helperText={props.helperText}
                />
            )}
            // tslint:disable-next-line: jsx-no-lambda
            renderOption={(option, { inputValue }) => {
                const matches = match(option.label, inputValue);
                const parts = parse(option.label, matches);

                return (
                    <div>
                        {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                );
            }}
        />
    );
};

export interface MatterTypeParams {
    isApplyMatterAccess?: boolean;
}

export interface MatterTypeData {
    lookup: Lookup;
}

export interface Lookup {
    matterType: MatterType[];
}

export interface MatterType {
    id: number;
    guidId: string;
    description: string;
    categoryId: string;
    matterTypeId: string;
    state: string;
}

const MATTER_TYPE_QUERY = gql`
    query MatterType($isApplyMatterAccess: Boolean) {
        lookup {
            matterType(isApplyMatterAccess: $isApplyMatterAccess) {
                id
                guidId
                description
                categoryId
                matterTypeId
                state
            }
        }
    }
`;
