import { makeStyles } from '@material-ui/core';
import { mainTheme } from '../../Theme';

export const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiSvgIcon-root': {
            color: '#5173FF'
        },
        '& .MuiTypography-h6': {
            color: mainTheme.BrandColors.TemplatePrimary
        },

        '& .MuiTable-root': {
            display: 'flex',
            flexFlow: 'column',
            '& .MuiTableCell-root': {
                '& .MuiIconButton-colorInherit': {
                    color: '#5173FF !important'
                },
            },
            '& thead.MuiTableHead-root': {
                display: 'flex',
                flex: 1,
                '& tr.MuiTableRow-head': {
                    display: 'flex',
                    flex: 1,
                    alignItems: 'stretch',
                    '& th.MuiTableCell-root': {
                        padding: '0 10px',
                    }
                },
                '& th.MuiTableCell-paddingNone': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 'auto !important',
                },
                '& th.MuiTableCell-paddingCheckbox': {
                    width: 'auto !important',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: '100px',
                },
                '& th.MuiTableCell-alignLeft': {
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    width: 'auto !important'
                },
            },
            '& tbody.MuiTableBody-root': {
                flex: 1,
                display: 'flex',
                flexFlow: 'column',
                '& tr.MuiTableRow-root': {
                    display: 'flex',
                    flex: 1,
                    alignItems: 'stretch',
                    height: 'auto !important',
                },
                '& .MuiTableCell-root': {
                    width: '100%',
                    padding: '0 10px',
                    '& .Mui-disabled .check-button': {
                        color: 'rgba(0, 0, 0, 0.26) !important'
                    },
                    '& .check-button': {
                        color: '#5173FF'
                    },
                    '& .icon-button-color': {
                        color: '#5173FF'
                    }
                },
                '& tr.MuiTableRow-root[mode="add"]': {
                    '& .MuiTableCell-root': {
                        padding: '10px'
                    }
                },
                '& td.MuiTableCell-paddingNone': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 'auto !important',
                },
                '& .MuiTableCell-alignLeft': {
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                }
            },
        },
        '& .Mui-checked': {
            '& .MuiSvgIcon-root': {
                fill: mainTheme.BrandColors.TemplatePrimary,
            }
        }
    },
    icon: {
        color: mainTheme.BrandColors.TemplatePrimary,
    },
    textField: {
        width: '100%',
    },
    avatar: {
        textTransform: 'uppercase',
    },
    hidden: {
        display: 'none',
    },
    highlight: {
        backgroundColor: 'transparent',
    },
    actions: {
        '& .MuiSvgIcon-root': {
            color: mainTheme.BrandColors.TemplatePrimary
        }
    },
    dialogBox: {
        minWidth: '400px',
    },
    breadcrumbs: {
        
    },
    breadcrumbsTitle: {
        color: mainTheme.BrandColors.TemplatePrimary
    },
    tableHeading: {
        color: mainTheme.BrandColors.TemplatePrimary
    },
    totalAvatar: {
        width: '24px',
        height: '24px',
        margin: '0 8px',
        fontSize: '14px',
    },
    customToolbar: {
        display: 'flex',
        flexFlow: 'column',
    },
    totalWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px 0 24px',
    },
    tableTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    tableAvatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    chip: {
        marginLeft: '8px'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    breadcrumbsWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    requiredText: {
        width: '100%',
        color: 'grey',
        padding: '0 15px',
    },    
    personIcons: {
        color: '#5173FF'
    },
    isActive: {
        color: 'green'
    },
    customRoot: {
        '& .MuiTableBody-root': {
            '& .MuiTableRow-root': {
                '& td:last-child': {
                    '& > div': {
                        display: 'flex',
                        flexFlow: 'row-reverse',
                    }
                }
            }
        }
    },
    checkIcon: {
        color: mainTheme.BrandColors.TemplatePrimary,
        margin: '0 9px',
    },
    chipRoot: {
        margin: '5px 2px',
        height: 'auto'
    },
    chipLabel: {
        whiteSpace: 'pre-wrap',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
    expandedRoot: {
        maxWidth: '1200px !important'
    },
}));