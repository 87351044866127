import * as React from 'react';
import '../../App.css';
// import { InfoPanel } from '../../components/DataDisplay/InfoPanel';
// import { CurrencyDisplay } from '../../components/DataDisplay/CurrencyDisplay';
import { CurrencyDisplayStyle } from '../../components/DataDisplay/CurrencyDisplayStyle';
// import { RvLabel } from '../../components/Label/Label';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { MatterTerminology } from '../../MatterTerminology';
import { RvLoader } from '../../components/Loader';
// import { RvStringDisplay } from '../../components/DataDisplay/StringDisplay';
import glamorous from 'glamorous';
import { Grid } from 'semantic-ui-react';
import { FormattedCurrency } from '../../components/DataDisplay/FormattedCurrency';
// import { HorizontalBar } from 'react-chartjs-2';
import { PageNotFound } from '../../components/PageNotFound';
import moment from 'moment';
import FinancialByDayChart from './FinancialByDayChart';
import EstimateVsBilledFeesChart from './EstimateVsBilledFeesChart';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TranslationTextList } from '../../locales/common';
import { DateTimeDisplayStyle, FormattedDateTime } from '../../components/DataDisplay/FormattedDateTime';
import { CurrenciesData } from '../../firmSettingConfiguration/common/Currencies';
import { formatNumber } from '../../helpers/ChartHelper';

// tslint:disable-next-line:no-any
const FinancialWrapper = glamorous.div<{ theme?: any }>((props) => ({
    padding: '23px 32px 0 32px',
    [`& .${`ValueData`}`]: {
        // width: '110px !important',
        textAlign: 'right',
        display: 'flex !important',
        justifyContent: 'flex-end',
        color: props.theme.BrandColors.TextPrimary,
        opacity: props.theme.Opacity.cat3,
        fontFamily: props.theme.Font.Main,
        fontSize: props.theme.FontSize.PX.size1,
        fontWeight: props.theme.FontWeight.FiveHundred,
        letterSpacing: '1px',
        lineHeight: props.theme.LineHeight.Large,
        [`& .${`rvlabel`}`]: {
            paddingRight: '10px'
        }
    },
    [`& .${`ValueData span`}`]: {
        paddingRight: '10px'
    },
    [`& .${`list`}`]: {
        [`& .${`item`}`]: {
            height: '40px',
            display: 'inline-block',
            width: '100%',
            ':nth-child(even)': {
                backgroundColor: props.theme.NeutralColors.LightGrey1
            },
            [`& .${`grid`}`]: {
                alignItems: 'center',
            }
        },
    },
    [`& .${`title`}`]: {
        color: props.theme.BrandColors.TextPrimary,
        fontSize: props.theme.FontSize.PX.size4 + ' !important',
        lineHeight: props.theme.LineHeight.Regular2,
    },
    [`& .${`rvlabel`}`]: {
        fontWeight: props.theme.FontWeight.FiveHundred,
        letterSpacing: '1px',
        lineHeight: props.theme.LineHeight.Large,
        color: props.theme.BrandColors.TextPrimary,
    }
}));

// tslint:disable-next-line:no-any
// const DebtorDueDiv = glamorous.div<{theme?: any}> ((props) => ({
//     [`& .${`rvlabel`}`] : {
//         color: props.theme.BrandColors.TextPrimary,	
//         fontSize: props.theme.FontSize.PX.size4 + ' !important',	
//         lineHeight: props.theme.LineHeight.Regular2,
//     }
// }));

// const LastGrid = glamorous(Grid) ({
//     [`& .${`LabelData`}`] : {
//         paddingLeft: '25px !important'
//     },
// });

// tslint:disable-next-line:no-any
const Card = glamorous.div<{ theme?: any }>((props) => ({
    display: 'flex',
    flexFlow: 'column wrap',
    minHeight: '85px',
    background: '#fff',
    width: '100%',
    boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.3)',
    borderRadius: '5px',
    marginBottom: '15px',
    padding: '15px 10px',
    fontFamily: 'Nunito',
    [`& .${`label`}`]: {
        fontWeight: '400',
        marginBottom: '15px',
        color: props.theme.BrandColors.TemplatePrimary,
    },

    [`& .${`value`}`]: {
        textAlign: 'center',
        fontWeight: '400',
        fontSize: '2rem',
    },
    [`& .${`value-string`}`]: {
        textAlign: 'center',
        fontWeight: '400',
        fontSize: '1.5rem',
    },
}));

// tslint:disable-next-line:no-any
const FormattedCurrencyWrapper = glamorous.div<{ theme?: any }>((props) => ({
    fontSize: '2rem',
    fontWeight: 400,
    textAlign: 'center'
}));

// tslint:disable-next-line:no-any
// const Divider = glamorous.div<{ theme?: any }>((props) => ({
//     content: ' ',
//     height: '1px',
//     borderBottom: '1px solid ' + props.theme.NeutralColors.LightBlue1,
//     marginTop: '22px'
// }));

interface ExternalProps {
    id: string;
    sourceSystem: string;
    isInternalUser: boolean;
}

export interface MatterFinancialsData {
    estimatedCosts: number;
    billedTimeAndFees: number;
    billedDisbursements: number;
    unbilledTimeAndFees: number;
    unbilledDisbursements: number;
    balanceDue: number;
    trustDeposits: number;
    trustWithdrawals: number;
    trustGeneralBalance: number;
    trustControlledMoneyBalance: number;
    trustInvestmentBalance: number;
    billingMethod: string;
    costAgreementReceived: string;
    agedDebtorCurrent: number;
    agedDebtor30Days: number;
    agedDebtor60Days: number;
    agedDebtor90Days: number;
    lastTrustTransactionDate: Date;
    lastTrustTransactionType: string;
    foreignCurrency: string;
    foreignCurrencyBilledTimeAndFees: number;
    foreignCurrencyBalanceDue: number;
    foreignCurrencyUnbilledTimeAndFees: number;
    foreignCurrencyBilledDisbursements: number;
    foreignCurrencyUnbilledDisbursements: number;
}

export interface MatterData {
    loading?: boolean;
    networkStatus?: number;
    matter: Matter;
}

export interface Matter {
    title: string;
    client: string;
    nameID: Number;
    financials?: MatterFinancialsData;
    id: Number;
}

interface MatterFinancialsInfoProps extends ExternalProps, WithTranslation {
    data: MatterData;
    currencySymbol: string;
    homeCountry: string;
}

// tslint:disable-next-line:no-any
class RvMatterFinancialsInfo extends React.Component<MatterFinancialsInfoProps, {}> {
    constructor(props: MatterFinancialsInfoProps) {
        super(props);

        this.getCurrencySymbol = this.getCurrencySymbol.bind(this);
        this.formatCurrencyAndForeignCurrency = this.formatCurrencyAndForeignCurrency.bind(this);
    }

    render() {
        let displayZero = 0.000000000001;

        // tslint:disable-next-line: no-console
        console.log('symbol', this.props.currencySymbol);

        if (this.props.data.loading) {
            return <RvLoader size="small" />;
        }

        var financials = this.props.data.matter.financials;
        if (!financials) {
            return <PageNotFound />;
        }

        let foreignCurrencySybmol = this.props.currencySymbol;
        let showForeignCurrencyAmount = false;
        let showBilledTimeAndFees = true;
        let showBalanceDue = true;
        let showUnbilledTimeAndFees = true;
        let showBilledDisbursements = true;
        let showUnbilledDisbursements = true;

        let billedTimeAndFeesCurrencySymbol = this.getCurrencySymbol(this.props.currencySymbol);
        let balanceDueCurrencySymbol = this.getCurrencySymbol(this.props.currencySymbol);
        let unbilledTimeAndFeesCurrencySymbol = this.getCurrencySymbol(this.props.currencySymbol);
        let billedDisbursementsCurrencySymbol = this.getCurrencySymbol(this.props.currencySymbol);

        let unbilledDisbursementsCurrencySymbol = this.getCurrencySymbol(this.props.currencySymbol);

        if (this.props.sourceSystem.toLocaleLowerCase() === 'inprotech') {
            // foreignCurrencySybmol = financials.foreignCurrency ? financials.foreignCurrency : this.props.currencySymbol;

            if (financials && financials.foreignCurrency) {
                // var sybmol = CurrenciesData.find(currencyItem => currencyItem.currency === financials?.foreignCurrency);
                var sybmol = CurrenciesData.find((currencyItem) => currencyItem.currency.toLowerCase().includes(financials!.foreignCurrency.toLowerCase()));

                if (sybmol) {
                    foreignCurrencySybmol = sybmol.currency;
                }

                showForeignCurrencyAmount = true;
            }

            if (this.props.isInternalUser === false) {
                // For External User - Prioritise the Foreign Currency display only

                if (showForeignCurrencyAmount) {
                    showBilledTimeAndFees = false;
                    showBalanceDue = false;
                    showUnbilledTimeAndFees = false;
                    showBilledDisbursements = false;
                    showUnbilledDisbursements = false;
                }
            }
        }

        var costAgreementReceivedColor: string | undefined = financials.costAgreementReceived ? undefined : '#C0C0C0';

        var billedTimeAndFees = financials.billedTimeAndFees ? financials.billedTimeAndFees : 0;
        var unbilledTimeAndFees = financials.unbilledTimeAndFees ? financials.unbilledTimeAndFees : 0;
        var estimatedFees = financials.estimatedCosts ? financials.estimatedCosts : 0;

        var estimatedFeesColour: string | undefined = billedTimeAndFees + unbilledTimeAndFees > estimatedFees ? 'red' : undefined;
        // let vm = this;

        const { t } = this.props as {
            t: (key: keyof TranslationTextList) => string;
        };

        return (
            <FinancialWrapper className="financials">
                <Grid centered={true}>
                    <Grid.Row columns={2} md={16}>
                        <Grid.Column computer={16} tablet={16} mobile={16} largeScreen={8} widescreen={8} verticalAlign="middle" centered={true}>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column computer={8} tablet={16} mobile={16} largeScreen={8} widescreen={8}>
                                        <Card>
                                            <div className="label">{MatterTerminology.EstimatedCosts}</div>
                                            <div className="value" style={{ color: estimatedFeesColour }}>
                                                <FormattedCurrency
                                                    // tslint:disable-next-line:max-line-length
                                                    value={financials.estimatedCosts ? financials.estimatedCosts : displayZero}
                                                    displayStyle={CurrencyDisplayStyle.Integer}
                                                    format={this.props.currencySymbol}
                                                    // format={financials.estimatedCosts ? this.props.currencySymbol : foreignCurrencySybmol}
                                                />
                                            </div>
                                        </Card>
                                        <Card>
                                            <div className="label">{MatterTerminology.Billed + ' ' + MatterTerminology.BilledTimeAndFees}</div>
                                            <div className="value">
                                                <FormattedCurrencyWrapper>
                                                    {this.formatCurrencyAndForeignCurrency({
                                                        showLocalCurrency: showBilledTimeAndFees,
                                                        localCurrency: billedTimeAndFeesCurrencySymbol,
                                                        localValue: financials.billedTimeAndFees,
                                                        showForeignCurrency: showForeignCurrencyAmount,
                                                        foreignCurrency: foreignCurrencySybmol,
                                                        foreignValue: financials.foreignCurrencyBilledTimeAndFees
                                                    })}
                                                </FormattedCurrencyWrapper>
                                                {/* {showBilledTimeAndFees ? (
                                                    <FormattedCurrency
                                                        // tslint:disable-next-line:max-line-length
                                                        value={financials.billedTimeAndFees ? financials.billedTimeAndFees : displayZero}
                                                        displayStyle={CurrencyDisplayStyle.Integer}
                                                        format={billedTimeAndFeesCurrencySymbol}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                                {showForeignCurrencyAmount ? (
                                                    <>
                                                        {showBilledTimeAndFees ? <span> / </span> : ''}
                                                        <FormattedCurrency
                                                            // tslint:disable-next-line:max-line-length
                                                            value={financials.foreignCurrencyBilledTimeAndFees ? financials.foreignCurrencyBilledTimeAndFees : displayZero}
                                                            displayStyle={CurrencyDisplayStyle.Integer}
                                                            format={foreignCurrencySybmol}
                                                        />
                                                    </>
                                                ) : (
                                                    ''
                                                )} */}
                                            </div>
                                        </Card>
                                        <Card>
                                            <div className="label">{MatterTerminology.TotalOwing}</div>
                                            <div className="value">
                                                <FormattedCurrencyWrapper>
                                                    {this.formatCurrencyAndForeignCurrency({
                                                        showLocalCurrency: showBalanceDue,
                                                        localCurrency: balanceDueCurrencySymbol,
                                                        localValue: financials.balanceDue,
                                                        showForeignCurrency: showForeignCurrencyAmount,
                                                        foreignCurrency: foreignCurrencySybmol,
                                                        foreignValue: financials.foreignCurrencyBalanceDue
                                                    })}
                                                </FormattedCurrencyWrapper>
                                                {/* {showBalanceDue ? (
                                                    <FormattedCurrency
                                                        // tslint:disable-next-line:max-line-length
                                                        value={financials.balanceDue ? financials.balanceDue : displayZero}
                                                        displayStyle={CurrencyDisplayStyle.Integer}
                                                        format={balanceDueCurrencySymbol}
                                                    />
                                                ) : (
                                                    ''
                                                )}

                                                {showForeignCurrencyAmount ? (
                                                    <>
                                                        {showBalanceDue ? <span> / </span> : ''}
                                                        <FormattedCurrency
                                                            // tslint:disable-next-line:max-line-length
                                                            value={financials.foreignCurrencyBalanceDue ? financials.foreignCurrencyBalanceDue : displayZero}
                                                            displayStyle={CurrencyDisplayStyle.Integer}
                                                            format={foreignCurrencySybmol}
                                                        />
                                                    </>
                                                ) : (
                                                    ''
                                                )} */}
                                            </div>
                                        </Card>
                                        {UserFeature.HasAccess(UserFeatureEnum.hasMatterWIPInformation) && (
                                            <Card>
                                                <div className="label">
                                                    {
                                                        // tslint:disable-next-line:max-line-length
                                                        MatterTerminology.Unbilled + ' ' + MatterTerminology.UnbilledTimeAndFees
                                                    }
                                                </div>
                                                <div className="value">
                                                    <FormattedCurrencyWrapper>
                                                        {this.formatCurrencyAndForeignCurrency({
                                                            showLocalCurrency: showUnbilledTimeAndFees,
                                                            localCurrency: unbilledTimeAndFeesCurrencySymbol,
                                                            localValue: financials.unbilledTimeAndFees,
                                                            showForeignCurrency: showForeignCurrencyAmount,
                                                            foreignCurrency: foreignCurrencySybmol,
                                                            foreignValue: financials.foreignCurrencyUnbilledTimeAndFees
                                                        })}
                                                    </FormattedCurrencyWrapper>
                                                    {/* {showUnbilledTimeAndFees ? (
                                                        <FormattedCurrency
                                                            // tslint:disable-next-line:max-line-length
                                                            value={financials.unbilledTimeAndFees ? financials.unbilledTimeAndFees : displayZero}
                                                            displayStyle={CurrencyDisplayStyle.Integer}
                                                            format={unbilledTimeAndFeesCurrencySymbol}
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                    {showForeignCurrencyAmount ? (
                                                        <>
                                                            {showUnbilledTimeAndFees ? <span> / </span> : ''}
                                                            <FormattedCurrency
                                                                // tslint:disable-next-line:max-line-length
                                                                value={financials.foreignCurrencyUnbilledTimeAndFees ? financials.foreignCurrencyUnbilledTimeAndFees : displayZero}
                                                                displayStyle={CurrencyDisplayStyle.Integer}
                                                                format={foreignCurrencySybmol}
                                                            />
                                                        </>
                                                    ) : (
                                                        ''
                                                    )} */}
                                                </div>
                                            </Card>
                                        )}
                                        {!showForeignCurrencyAmount ? (
                                            <Card>
                                                <div className="label">{MatterTerminology.BillingMethod}</div>
                                                <div className="value-string">{financials.billingMethod}</div>
                                            </Card>
                                        ) : null}

                                        {financials.lastTrustTransactionDate ? (
                                            <Card>
                                                <div className="label">
                                                    {t(`Last ${this.props.homeCountry.toLowerCase() === 'au' ? 'Trust' : 'Client Account'} Transaction Date`)}
                                                </div>
                                                <div className="value">
                                                    <FormattedDateTime value={financials.lastTrustTransactionDate} displayStyle={DateTimeDisplayStyle.ShortDate} />
                                                </div>
                                            </Card>
                                        ) : null}
                                    </Grid.Column>
                                    <Grid.Column computer={8} tablet={16} mobile={16} largeScreen={8} widescreen={8}>
                                        <Card>
                                            <div className="label">{MatterTerminology.CostAgreementReceived}</div>
                                            <div className="value-string" style={{ color: costAgreementReceivedColor }}>
                                                {
                                                    // tslint:disable-next-line:max-line-length
                                                    financials.costAgreementReceived ? moment(financials.costAgreementReceived).format('d MMM YYYY') : null
                                                }
                                            </div>
                                        </Card>
                                        <Card>
                                            <div className="label">{MatterTerminology.Billed + ' ' + MatterTerminology.Disbursements}</div>
                                            <div className="value">
                                                <FormattedCurrencyWrapper>
                                                    {this.formatCurrencyAndForeignCurrency({
                                                        showLocalCurrency: showBilledDisbursements,
                                                        localCurrency: billedDisbursementsCurrencySymbol,
                                                        localValue: financials.billedDisbursements,
                                                        showForeignCurrency: showForeignCurrencyAmount,
                                                        foreignCurrency: foreignCurrencySybmol,
                                                        foreignValue: financials.foreignCurrencyBilledDisbursements
                                                    })}
                                                </FormattedCurrencyWrapper>
                                                {/* {
                                                    showBilledDisbursements ? (
                                                        <FormattedCurrency
                                                            // tslint:disable-next-line:max-line-length
                                                            value={financials.billedDisbursements ? financials.billedDisbursements : displayZero}
                                                            displayStyle={CurrencyDisplayStyle.Integer}
                                                            format={billedDisbursementsCurrencySymbol}
                                                        />
                                                    )
                                                    : ''
                                                }
                                               
                                                {
                                                    showForeignCurrencyAmount ? (           
                                                        <>
                                                            {showBilledDisbursements ? <span> / </span> : ''}
                                                            <FormattedCurrency
                                                                // tslint:disable-next-line:max-line-length
                                                                value={financials.foreignCurrencyBilledDisbursements ? financials.foreignCurrencyBilledDisbursements : displayZero}
                                                                displayStyle={CurrencyDisplayStyle.Integer}
                                                                format={foreignCurrencySybmol}
                                                            />
                                                        </>
                                                    )
                                                    :
                                                    ''
                                                }  */}
                                            </div>
                                        </Card>
                                        <Card>
                                            <div className="label">{t('Trust Balance')}</div>
                                            <div className="value">
                                                <FormattedCurrency
                                                    // tslint:disable-next-line:max-line-length
                                                    value={financials.trustGeneralBalance ? financials.trustGeneralBalance : displayZero}
                                                    displayStyle={CurrencyDisplayStyle.Integer}
                                                    format={this.props.currencySymbol}
                                                />
                                            </div>
                                        </Card>
                                        {UserFeature.HasAccess(UserFeatureEnum.hasMatterWIPInformation) && (
                                            <Card>
                                                <div className="label">{MatterTerminology.Unbilled + ' ' + MatterTerminology.Disbursements}</div>
                                                <div className="value">
                                                    <FormattedCurrencyWrapper>
                                                        {this.formatCurrencyAndForeignCurrency({
                                                            showLocalCurrency: showUnbilledDisbursements,
                                                            localCurrency: unbilledDisbursementsCurrencySymbol,
                                                            localValue: financials.unbilledDisbursements,
                                                            showForeignCurrency: showForeignCurrencyAmount,
                                                            foreignCurrency: foreignCurrencySybmol,
                                                            foreignValue: financials.foreignCurrencyUnbilledDisbursements
                                                        })}
                                                    </FormattedCurrencyWrapper>
                                                    {/* {showUnbilledDisbursements ? (
                                                        <FormattedCurrency
                                                            // tslint:disable-next-line:max-line-length
                                                            value={financials.unbilledDisbursements ? financials.unbilledDisbursements : displayZero}
                                                            displayStyle={CurrencyDisplayStyle.Integer}
                                                            format={unbilledDisbursementsCurrencySymbol}
                                                        />
                                                    ) : (
                                                        ''
                                                    )}

                                                    {showForeignCurrencyAmount ? (
                                                        <>
                                                            {showUnbilledDisbursements ? <span> / </span> : ''}
                                                            <FormattedCurrency
                                                                // tslint:disable-next-line:max-line-length
                                                                value={
                                                                    financials.foreignCurrencyUnbilledDisbursements ? financials.foreignCurrencyUnbilledDisbursements : displayZero
                                                                }
                                                                displayStyle={CurrencyDisplayStyle.Integer}
                                                                format={foreignCurrencySybmol}
                                                            />
                                                        </>
                                                    ) : (
                                                        ''
                                                    )} */}
                                                </div>
                                            </Card>
                                        )}
                                        {!showForeignCurrencyAmount ? (
                                            <Card>
                                                <div className="label">{MatterTerminology.TrustControlledMoneyBalance}</div>
                                                <div className="value">
                                                    <FormattedCurrency
                                                        value={financials.trustControlledMoneyBalance}
                                                        displayStyle={CurrencyDisplayStyle.Integer}
                                                        format={this.props.currencySymbol}
                                                    />
                                                </div>
                                            </Card>
                                        ) : (
                                            ''
                                        )}

                                        {financials.lastTrustTransactionType ? (
                                            <Card>
                                                <div className="label">{`Last ${this.props.homeCountry.toLowerCase() === 'au' ? 'Trust' : 'Client Account'} Transaction Type`}</div>
                                                <div className="value-string">{financials.lastTrustTransactionType}</div>
                                            </Card>
                                        ) : null}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column computer={16} tablet={16} mobile={16} largeScreen={8} widescreen={8} verticalAlign="middle" centered={true}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <EstimateVsBilledFeesChart
                                            labels={['Estimated Fees', 'Billed Time & Fees']}
                                            data={[
                                                // tslint:disable-next-line:max-line-length
                                                financials.estimatedCosts ? financials.estimatedCosts : displayZero,
                                                // tslint:disable-next-line:max-line-length
                                                financials.billedTimeAndFees ? financials.billedTimeAndFees : displayZero,
                                            ]}
                                        />
                                        {/* <HorizontalBar 
                                            data={{
                                                labels: ['Estimated Fees', 'Billed Time & Fees'],
                                                datasets: [
                                                    {
                                                    label: 'Cost',
                                                    backgroundColor: [
                                                        '#0078D4',
                                                        '#FE9D17',
                                                    ],
                                                    borderColor: [
                                                        '#0078D4',
                                                        '#FE9D17',
                                                    ],
       `                                             borderWidth: 1,
                                                    hoverBackgroundColor: [
                                                        '#0078D4',
                                                        '#FE9D17',
                                                    ],
                                                    hoverBorderColor: [
                                                        '#0078D4',
                                                        '#FE9D17',
                                                    ],
                                                    data: [
                                                            // tslint:disable-next-line:max-line-length
                                                            financials.estimatedCosts ? financials.estimatedCosts : displayZero, 
                                                            // tslint:disable-next-line:max-line-length
                                                            financials.billedTimeAndFees ? financials.billedTimeAndFees : displayZero, 
                                                        ]
                                                    }
                                                ]
                                            }} 
                                            options={{
                                                title: {
                                                    display: true,
                                                    text: 'Estimate vs Billed Fees',
                                                    fontSize: 25,
                                                    fontFamily: 'Nunito',
                                                },
                                                legend: {
                                                    display: false,
                                                    position: 'bottom',
                                                },
                                                animation: {
                                                    animateScale: true,
                                                    animateRotate: true
                                                },
                                                scales: {
                                                    xAxes: [{
                                                        ticks: {
                                                            suggestedMax: 1000,
                                                            beginAtZero: true,
                                                        }
                                                    }]
                                                },
                                            }}
                                        /> */}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <FinancialByDayChart
                                            labels={['Current', '30 days', '60 days', '90+ days']}
                                            data={[
                                                financials.agedDebtorCurrent ? financials.agedDebtorCurrent : 0,
                                                financials.agedDebtor30Days ? financials.agedDebtor30Days : 0,
                                                financials.agedDebtor60Days ? financials.agedDebtor60Days : 0,
                                                financials.agedDebtor90Days ? financials.agedDebtor90Days : 0,
                                            ]}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {/* <Grid>
                    <Grid.Column 
                        computer={8} 
                        tablet={16} 
                        mobile={16} 
                        largeScreen={8} 
                        widescreen={8}
                    >
                        <InfoPanel>
                            <RvLabel className="title" label={MatterTerminology.Unbilled}/>
                            <CurrencyDisplay
                                label={MatterTerminology.UnbilledTimeAndFees}
                                value={financials.unbilledTimeAndFees ? 
                                    financials.unbilledTimeAndFees : displayZero}
                                displayStyle={CurrencyDisplayStyle.Decimal}
                                tabbed={true}
                            />
                            <CurrencyDisplay
                                label={MatterTerminology.Disbursements}
                                value={financials.unbilledAnticipatedPayments || financials.unbilledOfficePayments ?
                                    financials.unbilledAnticipatedPayments + financials.unbilledOfficePayments
                                    : displayZero}
                                displayStyle={CurrencyDisplayStyle.Decimal}
                                tabbed={true}
                            />
                        </InfoPanel>   
                        <InfoPanel>                                     
                            <RvLabel className="title" label={MatterTerminology.TrustFund} />
                            <CurrencyDisplay
                                label={MatterTerminology.TrustGeneralBalance}
                                value={financials.trustGeneralBalance ? 
                                    financials.trustGeneralBalance : displayZero}
                                displayStyle={CurrencyDisplayStyle.Decimal}
                                tabbed={true}
                            />
                            {financials.trustControlledMoneyBalance !== 0 ?
                                <CurrencyDisplay
                                    label={MatterTerminology.TrustControlledMoneyBalance}
                                    value={financials.trustControlledMoneyBalance}
                                    displayStyle={CurrencyDisplayStyle.Decimal}
                                    tabbed={true}
                                /> : null}
                            {financials.trustInvestmentBalance !== 0 ?
                                <CurrencyDisplay
                                    label={MatterTerminology.TrustInvestmentsBalance}
                                    value={financials.trustInvestmentBalance}
                                    displayStyle={CurrencyDisplayStyle.Decimal}
                                    tabbed={true}
                                /> : null}
                        </InfoPanel>
                    </Grid.Column>
                        
                    <Grid.Column 
                        computer={8} 
                        tablet={16} 
                        mobile={16} 
                        largeScreen={8} 
                        widescreen={8}
                    >
                        <InfoPanel>
                            <RvLabel className="title" label={MatterTerminology.Billed}/>
                            <CurrencyDisplay
                                label={MatterTerminology.BilledTimeAndFees}
                                value={financials.billedTimeAndFees ? financials.billedTimeAndFees : displayZero}
                                displayStyle={CurrencyDisplayStyle.Decimal}
                                tabbed={true}
                            />
                        </InfoPanel>
                        <InfoPanel>
                            <DebtorDueDiv>
                                <CurrencyDisplay
                                    label={MatterTerminology.BalanceDue}
                                    value={financials.balanceDue ? financials.balanceDue : displayZero}
                                    displayStyle={CurrencyDisplayStyle.Decimal}
                                />
                            </DebtorDueDiv>
                            <CurrencyDisplay
                                label={MatterTerminology.BilledCostAndPayments}
                                value={financials.billedCostsAndPayments ? 
                                    financials.billedCostsAndPayments : displayZero}
                                displayStyle={CurrencyDisplayStyle.Decimal}
                                tabbed={true}
                            />
                        </InfoPanel>
                    </Grid.Column>
                </Grid>
                <Divider/>
                <LastGrid>
                    <Grid.Column
                        computer={8} 
                        tablet={16} 
                        mobile={16} 
                        largeScreen={8} 
                        widescreen={8}
                    >
                        <InfoPanel>
                            <CurrencyDisplay
                                label={MatterTerminology.EstimatedCosts}
                                value={financials.estimatedCosts ? financials.estimatedCosts : displayZero}
                                displayStyle={CurrencyDisplayStyle.Decimal}
                                tabbed={true}
                            />
                            {financials.billingMethod ?
                                <RvStringDisplay
                                    label={MatterTerminology.BillingMethod}
                                    value={financials.billingMethod}
                                /> : null}
                            {financials.costAgreementReceived ?
                                <RvStringDisplay
                                    label={MatterTerminology.CostAgreementReceived}
                                    value={financials.costAgreementReceived}
                                    color={costAgreementReceivedColor}
                                /> : null}

                        </InfoPanel>
                    </Grid.Column>
                </LastGrid> */}
            </FinancialWrapper>
        );
    }

    private getCurrencySymbol(currency: string) {
        return CurrenciesData.find((currencyItem) => currencyItem.currency.toLowerCase().includes(currency.toLowerCase()))?.symbol;
    }

    private formatCurrencyAndForeignCurrency(input: {
        showLocalCurrency: boolean;
        localCurrency: string | undefined;
        localValue: number;
        showForeignCurrency: boolean;
        foreignCurrency: string;
        foreignValue: number;
    }) {
        const { foreignCurrency, foreignValue, localCurrency, localValue, showForeignCurrency, showLocalCurrency } = input;
        
        if (showLocalCurrency && localValue > 0 && showForeignCurrency && foreignValue > 0) {
            return `${localCurrency}${formatNumber(localValue)}/${foreignCurrency}${formatNumber(foreignValue)}`;
        }

        if ((showLocalCurrency && localValue > 0 && showForeignCurrency && foreignValue === 0) || (showLocalCurrency && localValue > 0 && !showForeignCurrency)) {
            return `${localCurrency}${formatNumber(localValue)}`;
        }

        if ((!showLocalCurrency && localValue === 0 && showForeignCurrency && foreignValue > 0) || (!showLocalCurrency && showForeignCurrency && foreignValue > 0)) {
            return `${foreignCurrency}${formatNumber(foreignValue)}`;
        }

        if (!showLocalCurrency && localValue === 0 && !showForeignCurrency && foreignValue === 0) {
            return `${localCurrency}${formatNumber(localValue)}`;
        }

        if ((!showLocalCurrency && localValue > 0 && showForeignCurrency && foreignValue === 0)) {
            return `${localCurrency}${formatNumber(localValue)}`;
        }

        if ((!showLocalCurrency && showForeignCurrency && foreignValue === 0) || (showLocalCurrency && showForeignCurrency && localValue === 0 && foreignValue === 0)) {
            return `${localCurrency}0`;
        }

        return '';
    }
}

export const FinancialsInfoData = gql`query FinancialsInfoData($id: Int!){
    matter(id: $id) {
        title,
        client,
        nameID,
        id,
        financials { 
            estimatedCosts
            billedTimeAndFees
            billedDisbursements
            unbilledTimeAndFees
            unbilledDisbursements
            balanceDue
            trustDeposits
            trustWithdrawals
            trustGeneralBalance
            trustControlledMoneyBalance
            trustInvestmentBalance
            billingMethod
            costAgreementReceived
            agedDebtorCurrent
            agedDebtor30Days
            agedDebtor60Days
            agedDebtor90Days
            lastTrustTransactionDate
          	lastTrustTransactionType
            foreignCurrency
            foreignCurrencyBilledTimeAndFees
            foreignCurrencyBalanceDue
            foreignCurrencyUnbilledTimeAndFees
            foreignCurrencyBilledDisbursements
            foreignCurrencyUnbilledDisbursements
        } 
    }
}`;

// tslint:disable-next-line:no-any
export const FinancialsInfo = withTranslation()(graphql<any, ExternalProps, any>
    (FinancialsInfoData, {
        options: ({ id }) => ({ variables: { id: id } })
    })
    (RvMatterFinancialsInfo));