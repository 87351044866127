import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const useRoleListQuery = (params: RoleListParams, fetchPolicy?: FetchPolicy) => {
    const { data, error, loading } = useQuery<RoleListData, RoleListParams>(RoleListQuery, {
        fetchPolicy: fetchPolicy,
        variables: {
            isExternal: params.isExternal
        }
    });
    return {
        data,
        error,
        loading,
    };
};

export interface RoleListParams {
    isExternal: boolean | null;
}

export interface RoleListData {
    loading?: boolean;
    networkStatus?: number;
    roleList: RoleList;
}

export interface RoleList {
    role: Role[];
}

export interface Role {
    roleIdString: string;
    rolename:     string;
    isExternal:   boolean;
}

const RoleListQuery = gql`
    query roleListQuery( $isExternal: Boolean) {
        roleList(isExternal: $isExternal) {
            role {
                roleIdString,
                rolename,
                isExternal
            }
        }
    }
`;