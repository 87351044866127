import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import { TextField, CircularProgress, Tooltip, Chip } from '@material-ui/core';
import { GetTagProps, RenderInputParams } from '@material-ui/lab';
import { useDebouncedCallback } from 'use-debounce/lib';
import { InfiniteAutocomplete } from '../../components/InfiniteAutocomplete';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { client } from '../..';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: '200px',
        },
        textField: {
            width: '100%',
        },
        iconSelected: {
            width: 17,
            height: 17,
            marginRight: 5,
            marginLeft: -2,
        },
        color: {
            width: 14,
            height: 14,
            flexShrink: 0,
            borderRadius: 3,
            marginRight: 8,
            marginTop: 2,
        },
        text: {
            flexGrow: 1,
        },
        close: {
            opacity: 0.6,
            width: 18,
            height: 18,
        },
        renderWrapper: {
            padding: '15px 0',
        },
        customRenderWrapper: {
            display: 'flex',
            margin: '0 -10px',
            width: '100%',
            '& > div': {
                padding: '0 10px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontWeight: 400,
            },
            '& .icon-wrapper': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .other': {
                flex: 1,
                display: 'flex',
                flexFlow: 'column',
                '& .primary': {
                    fontSize: '1rem',
                    lineHeight: 1.5,
                    letterSpacing: '0.00938em',
                },
                '& .secondary': {
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                },
            },
        },
    }),
);

interface NameSummaryQueryParamsExtend {
    isClientsOnly?: boolean;
    includeStaff?: boolean;
    includeIndividual?: boolean;
    includeOrganisation?: boolean;
    tagsList?: string;
    stateList?: string;
    countryList?: string;
    businessTypeList?: string;
    isMatterOwnersOnly?: boolean;
    isApplyMatterAccess?: boolean;
}

interface MatterClientSelectorProps extends NameSummaryQueryParamsExtend {
    multiple?: boolean;
    label?: string;
    name: string;
    // tslint:disable-next-line: no-any
    value?: NameSummaryAutoCompleteItem | NameSummaryAutoCompleteItem[] | any; // value only available in single select
    onSelection?: (value: NameSummaryAutoCompleteItem | NameSummaryAutoCompleteItem[], name: string) => void;
    required?: boolean;
    id?: string;
    textLimit?: {
        label?: number;
        email?: number;
    };
    error?: boolean;
    helperText?: string;
    disablePortal?: boolean;
    variant?: 'standard' | 'filled' | 'outlined';
    size?: 'small' | 'medium';
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    className?: string;
}

export interface NameSummaryAutoCompleteItem extends IAutoCompleteItem, NameSummary {}

interface MatterClientSelectorState {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    offset: number;
    filter: string;
    // tslint:disable-next-line: no-any
    items: NameSummaryAutoCompleteItem[];
}

export const MatterClientSelector: React.FC<MatterClientSelectorProps> = (props) => {
    const classes = useStyles();
    const loadBlockLimit = 20;

    const [delayedonInputChange] = useDebouncedCallback(
        // function
        (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {
            let isClear = false;
            let offset = 0;
            let filter = '';

            if (reason === 'input') {
                filter = value;
                isClear = true;

                fetchData(filter, offset, isClear);
            }
        },
        // delay in ms
        500,
    );

    const [state, setState] = useState<MatterClientSelectorState>({
        hasNextPage: true,
        isNextPageLoading: false,
        offset: 0,
        filter: '',
        items: [],
    });

    // useEffect(() => {
    //     fetchData(state.filter, 0, state.isNextPageLoading, state.hasNextPage, false);
    // // tslint:disable-next-line: align
    // }, []);

    // tslint:disable-next-line: no-any
    const loadNextPage = (...args: any) => {
        if (state.hasNextPage && !state.isNextPageLoading) {
            fetchData(state.filter, state.offset, false);
        }
    };

    const fetchData = (filter: string, offset: number, isClear: boolean) => {
        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: true,
            };
        });

        const userQueryParams: NameSummaryQueryParams = {
            filter: filter,
            first: loadBlockLimit,
            offset: offset,
            isClientsOnly: props.isClientsOnly,
            isMatterOwnersOnly: props.isMatterOwnersOnly,
            includeIndividual: props.includeIndividual,
            includeOrganisation: props.includeOrganisation,
            includeStaff: props.includeStaff,
            businessTypeList: props.businessTypeList,
            countryList: props.countryList,
            stateList: props.stateList,
            tagsList: props.tagsList,
            isApplyMatterAccess: props.isApplyMatterAccess
        };

        retrieveNameSummaryData(
            userQueryParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveClientList(data, filter, offset, isClear),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isNextPageLoading: false,
                        isLoading: false,
                    };
                });
            },
        );
    };

    const onRetrieveClientList = (data: NameSummaryData, filter: string, offset: number, isClear: boolean) => {
        const nameSummaryData = data.party.nameSummaries.nameSummary;
        const recordCount = data.party.nameSummaries.recordCount;

        // tslint:disable-next-line: no-any
        let itemsList: NameSummaryAutoCompleteItem[] = [];

        let items = state.items;

        if (!isClear) {
            itemsList = [...state.items];
        } else {
            items = [];
        }

        if (items.length === 0) {
            itemsList = nameSummaryData.map((source: NameSummary) => ({
                ...source,
                value: source.guidID,
                label: source.name,
            }));
        } else {
            // tslint:disable-next-line: no-shadowed-variable
            for (const nameSummary of nameSummaryData) {
                // tslint:disable-next-line: no-any
                const isExists = items.filter((item: NameSummaryAutoCompleteItem) => item.value.toLowerCase().includes(nameSummary.guidID)).length;

                if (isExists === 0) {
                    itemsList.push({
                        ...nameSummary,
                        value: nameSummary.guidID,
                        label: nameSummary.name,
                    });
                }
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                filter: filter,
                isNextPageLoading: false,
                items: itemsList,
                offset: offset + loadBlockLimit,
                hasNextPage: nameSummaryData.length !== 0 && offset + loadBlockLimit < recordCount,
                isLoading: false,
            };
        });
    };

    const onSelection = (selection: NameSummaryAutoCompleteItem | NameSummaryAutoCompleteItem[], name: string) => {
        // setState((prevState) => {
        //     return {
        //         ...prevState,
        //         isNextPageLoading: false,
        //         filter: '',
        //         offset: 0
        //     };
        // });

        if (props.onSelection) {
            props.onSelection(selection, name);
        }
    };

    const onClose = (event: React.ChangeEvent<{}>) => {
        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: false,
                hasNextPage: true,
                filter: '',
                offset: 0,
                items: [],
            };
        });
    };

    const onOpen = (event: React.ChangeEvent<{}>) => {
        // tslint:disable-next-line: no-console
        fetchData(state.filter, 0, true);
    };

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
        arrow: {
            '&:before': {
                border: '1px solid #E6E8ED',
            },
            color: theme.palette.common.white,
        },
    }))(Tooltip);

    const shortingTitle = (title: string, limit?: number) => {
        if (limit === undefined || limit === null) {
            return title;
        } else if (title === undefined || title === null) {
            return title;
        } else if (title.length < limit) {
            return title;
        } else {
            return (
                <LightTooltip title={title} aria-label="client" arrow={true} placement="right">
                    <span>{`${title.substring(0, limit)}...`}</span>
                </LightTooltip>
            );
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return {
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                    {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>
            ),
        };
    };

    const onRenderTags = (value: NameSummaryAutoCompleteItem[], getTagProps: GetTagProps): React.ReactNode => {
        let renderTagsValue = value.map((option: NameSummaryAutoCompleteItem, index: number) => {
            let label = option.label;
            if (option.label.length > 20) {
                label = `${option.label.substring(0, 20)}...`;
            }

            return (
                <Chip
                    {...getTagProps({ index })}
                    key={index}
                    label={label}
                    // variant="outlined"
                    size="small"
                />
            );
        });

        return renderTagsValue;
    };

    return (
        <InfiniteAutocomplete
            id={props.id}
            itemSize={70}
            loading={state.isNextPageLoading}
            disablePortal={props.disablePortal}
            name={props.name}
            isMultiple={props.multiple}
            value={props.value}
            hasNextPage={state.hasNextPage}
            isNextPageLoading={state.isNextPageLoading}
            items={state.items}
            loadNextPage={loadNextPage}
            // disableCloseOnSelect={true}
            className={`${classes.textField} ${props.className}`}
            disableListWrap={true}
            onSelection={onSelection}
            onClose={onClose}
            onOpen={onOpen}
            getOptionLabel={(option: NameSummaryAutoCompleteItem) => option.label}
            getOptionSelected={(option, value) => value.value === option.value}
            onInputChange={delayedonInputChange}
            onBlur={props.onBlur}
            renderTags={onRenderTags}
            renderOption={(option, { selected }) => {
                return (
                    <div className={classes.customRenderWrapper}>
                        <div className="icon-wrapper">{option.isStaff ? <PersonIcon fontSize="large" /> : <PersonOutlinedIcon fontSize="large" />}</div>
                        <div className="other">
                            <div className="primary">{shortingTitle(option.label, props.textLimit?.label)}</div>
                            <div className="secondary">{shortingTitle(option.email, props.textLimit?.email)}</div>
                        </div>
                    </div>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={props.variant}
                    label={props.label}
                    required={props.required}
                    fullWidth={true}
                    InputProps={getInputProps(params, state.isNextPageLoading)}
                    error={props.error}
                    helperText={props.helperText}
                    size={props.size}
                />
            )}
        />
    );
};

export function retrieveNameSummaryData(
    query: NameSummaryQueryParams,
    refreshData: boolean,
    onSuccess: (data: NameSummaryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: NAME_SUMMARY_QUERY,
            variables: {
                filter: query.filter,
                first: query.first,
                offset: query.offset,
                isClientsOnly: query.isClientsOnly,
                isMatterOwnersOnly: query.isMatterOwnersOnly,
                includeIndividual: query.includeIndividual,
                includeOrganisation: query.includeOrganisation,
                includeStaff: query.includeStaff,
                businessTypeList: query.businessTypeList,
                countryList: query.countryList,
                stateList: query.stateList,
                tagsList: query.tagsList,
                isApplyMatterAccess: query.isApplyMatterAccess
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface NameSummaryQueryParams {
    offset?: number;
    first?: number;
    filter?: string;
    isClientsOnly?: boolean;
    includeStaff?: boolean;
    includeIndividual?: boolean;
    includeOrganisation?: boolean;
    tagsList?: string;
    stateList?: string;
    countryList?: string;
    businessTypeList?: string;
    isMatterOwnersOnly?: boolean;
    isApplyMatterAccess?: boolean;
}

export interface NameSummaryData {
    loading?: boolean;
    networkStatus?: number;
    party: Party;
}

export interface Party {
    nameSummaries: NameSummaries;
}

export interface NameSummaries {
    recordCount: number;
    nameSummaryActualCount: NameSummaryActualCount;
    nameSummary: NameSummary[];
}

export interface NameSummary {
    guidID: string;
    nameId: number;
    name: string;
    relatedName: null;
    emailAddress: null | string;
    mobile: null | string;
    phone: string;
    city: null | string;
    state: null | string;
    country: string | null;
    address: string;
    entityType: number;
    relationshipManager: null;
    relationshipQualityDescription: null;
    relationshipQualityLevel: null;
    referrerType: null;
    referrerSource: null;
    lastContactDate: Date | null;
    lastReferralDate: Date | null;
    notes: null | string;
    isClient: boolean;
    isOrganisation: boolean;
    isIndividual: boolean;
    isStaff: boolean;
    isReferrer: boolean;
    isUser: boolean;
    currentMatters: number;
    lastMatterDate: Date;
    estimatedFees: number;
    billedFees: number;
    trustBalance: number;
    billedDisbursements: number;
    unbilledFees: number;
    unbilledDisbursements: number;
    completedMatters: number;
    newMattersInTheLast12Months: number;
    firstMatterDate: Date;
    totalBilledFees: number;
    percentageEstimate: number;
    exposure: number;
    hasMarketingConsent: boolean;
    tags: null | string;
    enquiriesReferredInTheLast12Months: number;
    enquiriesReferredInTotal: number;
    lastEnquiryDate: Date | null;
    businessType: null | string;
}

export interface NameSummaryActualCount {
    actualRowCountForSearch: number;
}

const NAME_SUMMARY_QUERY = gql`
    query NameSummaries(
        $offset: Int
        $first: Int
        $filter: String
        $isClientsOnly: Boolean
        $includeStaff: Boolean
        $includeIndividual: Boolean
        $includeOrganisation: Boolean
        $tagsList: String
        $stateList: String
        $countryList: String
        $relationshipManagerList: String
        $businessTypeList: String
        $isMatterOwnersOnly: Boolean
        $isApplyMatterAccess: Boolean
    ) {
        party {
            nameSummaries(
                offset: $offset
                first: $first
                filter: $filter
                isClientsOnly: $isClientsOnly
                includeStaff: $includeStaff
                includeIndividual: $includeIndividual
                includeOrganisation: $includeOrganisation
                tagsList: $tagsList
                stateList: $stateList
                countryList: $countryList
                businessTypeList: $businessTypeList
                isMatterOwnersOnly: $isMatterOwnersOnly
                isApplyMatterAccess: $isApplyMatterAccess
                relationshipManagerList: $relationshipManagerList
            ) {
                recordCount
                nameSummaryActualCount {
                    actualRowCountForSearch
                }
                nameSummary {
                    guidID
                    nameId
                    name
                    relatedName
                    emailAddress
                    mobile
                    phone
                    city
                    state
                    country
                    address
                    entityType
                    relationshipManager
                    relationshipQualityDescription
                    relationshipQualityLevel
                    referrerType
                    referrerSource
                    lastContactDate
                    lastReferralDate
                    notes
                    isClient
                    isOrganisation
                    isIndividual
                    isStaff
                    isReferrer
                    isUser
                    currentMatters
                    lastMatterDate
                    estimatedFees
                    billedFees
                    trustBalance
                    billedDisbursements
                    unbilledFees
                    unbilledDisbursements
                    completedMatters
                    newMattersInTheLast12Months
                    firstMatterDate
                    totalBilledFees
                    percentageEstimate
                    exposure
                    hasMarketingConsent
                    tags
                    enquiriesReferredInTheLast12Months
                    enquiriesReferredInTotal
                    lastEnquiryDate
                    businessType
                }
            }
        }
    }
`;
