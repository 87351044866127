/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles, TextField, CircularProgress } from '@material-ui/core';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { client } from '../..';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: '200px',
        },
        autocompletePopper: {
            '& .MuiListSubheader-root': {
                lineHeight: '30px'
            }
        }
    })
);

interface TaskTypeGroupSelectorProps {
    value: IAutoCompleteItem | null; // value only available in single select
    error?: boolean;
    onSelection?: (value?: TaskTypeGroupAutoCompleteItem, name?: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    style?: React.CSSProperties; 
    disablePortal?: boolean;
    disableCloseOnSelect?: boolean;
    disabled?: boolean;
    required?: boolean;
}

// export interface TaskTypeGroupAutoCompleteItem extends IAutoCompleteItem {
//     taskTypeGroupGuid: string;
//     taskTypeGroupDescription: string;
//     taskTypeGuid: string;
//     taskTypeDescription: string;
//     taskTypeCounts: number;
// }

export interface TaskTypeGroupAutoCompleteItem extends IAutoCompleteItem {
    estimatedEffort: string;
    notes: string;
}
interface TaskTypeGroupSelectorState {
    isLoading: boolean;
    data: IAutoCompleteItem[];
    value: IAutoCompleteItem | null;
    searchValue: string | undefined;
}

export const TaskTypeGroupSelector: React.FC<TaskTypeGroupSelectorProps> = props => {
    const classes = useStyles();

    const [state, setState] = useState<TaskTypeGroupSelectorState>({
        isLoading: false,
        data: [],
        value: props.value,
        searchValue: undefined
    });

    useEffect(() => {   
        fetchData();
    // tslint:disable-next-line: align
    }, []);

    const fetchData = () => {

        setState({
            ...state,
            isLoading: true
        });

        const taskTypeGroupParams: TaskTypeGroupParams = {
            taskGroupTypeId: null
        };

        retrieveTaskTypeGroupData(
            taskTypeGroupParams,
            false, // refresh
            (data) => onRetrieveData(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
                
                setState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false
                    };
                });
            }
        );
    };

    const onRetrieveData = (data: TaskTypeGroupData) => {
        const taskTypeGroupList = data.userTasks.taskTypeGroups;

        // let taskTypeGroupItems: TaskTypeGroupAutoCompleteItem[] = [];

        // taskTypeGroupList.taskTypeGroup.forEach((taskTypeGroupItem: TaskTypeGroup) => {
        //     taskTypeGroupItem.taskType.forEach((taskTypeItem: TaskType) => {
        //         taskTypeGroupItems.push({
        //             taskTypeGroupGuid: taskTypeGroupItem.guidID,
        //             taskTypeGroupDescription: taskTypeGroupItem.description,
        //             taskTypeGuid: taskTypeItem.guidID,
        //             taskTypeDescription: taskTypeItem.description,
        //             taskTypeCounts: taskTypeGroupItem.taskType ? taskTypeGroupItem.taskType.length : 0,
        //             value: taskTypeItem.guidID,
        //             label: taskTypeItem.description
        //         });
        //     });
        // });

        let taskTypeGroupItems: TaskTypeGroupAutoCompleteItem[] = [];

        taskTypeGroupList.taskTypeGroup.forEach((taskTypeGroupItem: TaskTypeGroup) => {
            if (taskTypeGroupItem.taskType) {            
                taskTypeGroupItem.taskType.forEach((taskTypeItem: TaskType) => {
                    taskTypeGroupItems.push({
                        secondaryValue: taskTypeGroupItem.guidID,
                        groupBy: taskTypeGroupItem.description,
                        value: taskTypeItem.guidID,
                        label: taskTypeItem.description,
                        estimatedEffort: taskTypeItem.estimatedEffort,
                        notes: taskTypeItem.notes
                    });
                });
            }
        });

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: false,
                data: taskTypeGroupItems
            };
        });
    };

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: TaskTypeGroupAutoCompleteItem) => {
        if (props.onSelection) {
            let autoCompleteValue: TaskTypeGroupAutoCompleteItem | undefined;
            if (value) {
                autoCompleteValue = { value: value.value, label: value.label, estimatedEffort: value.estimatedEffort, notes: value.notes };
            } else {
                autoCompleteValue = undefined;
            }
            props.onSelection(autoCompleteValue, props.name);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                    {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>
            ),     
        });
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            <Autocomplete
                style={props.style}
                className={props.className}
                value={props.value}
                loading={state.isLoading}
                disabled={props.disabled}
                onChange={handleChange}
                options={state.data}
                groupBy={(option) => option.groupBy ? option.groupBy.toString() : ''}
                disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                // tslint:disable-next-line: jsx-no-lambda
                getOptionLabel={(option: IAutoCompleteItem) => option.label}       
                classes={{
                    popper: classes.autocompletePopper
                }}    
                // tslint:disable-next-line: jsx-no-lambda
                renderInput={params => (
                    <TextField 
                        {...params} 
                        label={props.label}
                        variant="standard" 
                        fullWidth={true} 
                        margin="none" 
                        required={props.required}
                        InputProps={getInputProps(params, state.isLoading)}
                        error={props.error}                            
                    />
                )}
                // tslint:disable-next-line: jsx-no-lambda
                renderOption={(option, { inputValue }) => {
                    const matches = match(option.label, inputValue);
                    const parts = parse(option.label, matches);
            
                    return (
                        <div>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    );
                }}
            />
        </div>
    );
};

export interface TaskTypeGroupParams {
    taskGroupTypeId: string | null;
}

export interface TaskTypeGroupData {
    loading?: boolean;
    networkStatus?: number;
    userTasks: UserTasks;
}

export interface UserTasks {
    taskTypeGroups: TaskTypeGroups;
}

export interface TaskTypeGroups {
    recordCount: number;
    taskTypeGroup: TaskTypeGroup[];
}

export interface TaskTypeGroup {
    guidID: string;
    description: string;
    sequence: number;
    taskType: TaskType[];
}

export interface TaskType {
    guidID: string;
    description: string;
    estimatedEffort: string;
    notes: string;
}

export function retrieveTaskTypeGroupData(
    query: TaskTypeGroupParams,
    refreshData: boolean,
    onSuccess: (data: TaskTypeGroupData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: TaskTypeGroupQuery,
            variables: {
                taskGroupTypeId: query.taskGroupTypeId
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const TaskTypeGroupQuery = gql`
query TaskTypeGroupQuery($taskGroupTypeId: String) {
    userTasks {
        taskTypeGroups(taskGroupTypeId: $taskGroupTypeId) {
            recordCount
            taskTypeGroup {
                description
                guidID
                sequence
                taskType {
                    guidID
                    description
                    estimatedEffort
                    notes
                }
            }
        }
    }
}`;