import React, { useState } from 'react';
import { MatterManagementSummary, 
    fetchCurrencyData, CurrencyOptionData } from './MatterManagementRepository';
import { WriteOffWip } from './dialogs/WriteOffWipDialog';
import { MatterSummaryHeader } from './steps/reviewMatters/ReviewMattersStep';
import _ from 'lodash';
import { WriteOffDebtor } from './dialogs/WriteOffDebtorsDialog';
import { BillTheMatter } from './dialogs/BillTheMatterDialog';
import { TransferTrustToPayInvoice } from './dialogs/TransferTustToPayInvoiceDialog';
import { ArchiveMatter } from './dialogs/ArchiveMatterDialog';
import { CloseMatter } from './dialogs/CloseMatterDialog';
import { RowSelectedEvent } from '@ag-grid-community/core';
import { Guid } from 'guid-typescript';
import { showNotification } from '../App';

export const MatterManagementContext = React.createContext<Partial<MatterManagementTypes>>({});

export interface MatterManagementTypes {
    batchGuidId: string;
    contextState: ContextState;
    matterSummaryHeader: MatterSummaryHeader;
    selectedMatter: MatterManagementSummary;
    matterTasks: MatterTask[];
    currentMatterTask: MatterTask;
    menuDisable: ContextMenuDisable[];
    // processedTaskCount: number;
    currencyData: CurrencyDetails;
    currentSummaryViewSelectionId: number;
    
    setBatchGuidId: React.Dispatch<React.SetStateAction<string>>;
    // setProcessedTaskCount: React.Dispatch<React.SetStateAction<number>>;
    setMatterSummaryHeader: React.Dispatch<React.SetStateAction<MatterSummaryHeader>>;
    setSelectedMatter: React.Dispatch<React.SetStateAction<MatterManagementSummary | undefined>>;
    setCurrentMatterTask: React.Dispatch<React.SetStateAction<MatterTask | undefined>>;
    setMatterTasks: React.Dispatch<React.SetStateAction<MatterTask[]>>;    
    setCurrentSummaryViewSelectionId: React.Dispatch<React.SetStateAction<number>>;

    onMatterSelected(matter: MatterManagementSummary, event: RowSelectedEvent): void;
    onWriteOffWipSave(values: WriteOffWip): void; 
    onWriteOffDebtorsSave(values: WriteOffDebtor): void;
    onBillTheMatterSave(values: BillTheMatter): void; 
    onTransferTustToPlayInvoiceSave(values: TransferTrustToPayInvoice): void; 
    onArchiveMatterSave(values: ArchiveMatter): void; 
    onCloseMatterSave(values: CloseMatter): void; 

    onWriteOffWipOpen(): void; 
    onWriteOffWipClosed(): void; 
    onWriteOffDebtorsOpen(): void;
    onWriteOffDebtorsClosed(): void; 
    onBillTheMatterOpen(): void;
    onBillTheMatterClosed(): void; 
    onTransferTrustToPayInvoiceOpen(): void; 
    onTransferTrustToPayInvoiceClosed(): void; 
    onArchiveMatterOpen(): void; 
    onArchiveMatterClosed(): void; 
    onCloseMatterOpen(): void; 
    onCloseMatterClosed(): void;

    onDebtorsExists(data: MatterManagementSummary): boolean;
    onUnBilledFeesExists(data: MatterManagementSummary): boolean;
    onUnBilledDisbursementsExists(data: MatterManagementSummary): boolean;
    onTrustExists(data: MatterManagementSummary): boolean;

    onHandleTextChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
    onHandleTextChangeNumeric(name: string, value?: number): void;
    onHandleCheckBoxChange(event: React.ChangeEvent<HTMLInputElement>, matterId: number, matterGuidId: string): void;
    getTextBoxDisabled(matterId: number, value?: number | string): boolean;
    getFieldDisabled(matterId: number, field: string): boolean;
    calculateMatterSummaryHeader(): void;
    hasTasks(matterId: number): boolean;

    getIsCloseDisabled(matterId?: number, currentTasks?: MatterTask, selectedMatter?: MatterManagementSummary): boolean;
    getIsCloseDisabledForFeeWriteOffDialog(matterId: number, writeOffFee?: number, writeOffDisb?: number): boolean;
    getIsCloseDisabledForFeeWriteOffDebtorDialog(matterId: number, writeOffDebtors?: number): boolean;

    getWriteOffWipFee(matterId: number): number | undefined | string;
    getWriteOffWipDisb(matterId: number): number | undefined | string;
    getWriteOffWipDebtors(matterId: number): number | undefined | string;
    getBillFees(matterId: number): number | undefined | string | string;
    getBillDisb(matterId: number): number | undefined | string;
    getApplyTrust(matterId: number): number | undefined | string;
    getTransferTrust(matterId: number): number | undefined | string;
    getTransferInvoice(matterId: number): string | undefined;
    getIsClosed(matterId: number): boolean | undefined;
    getIsArchived(matterId: number): boolean | undefined;   
    getTrustMaxValue(matterId: number, trustBalance: number, field: string): number;
    fetchCurrencyDetails(): void;
}

export interface MatterTask {
    matterId?: number;
    matterGuid?: string;
    matterData?: MatterManagementSummary;
    writeOffWipTask?: {
        isSaved: boolean;
        fees?: number;
        disbursements?: number;
        isCloseMatterAfterWriteOff?: boolean;        
        comments?: string
    };
    writeOffDebtorsTask?: {
        isSaved: boolean;
        debtors?: number;
        isCloseMatterAfterWriteOff?: boolean;
        comments?: string;
    };    
    billMatterTask?: {
        isSaved: boolean;
        fees?: number;
        disbursements?: number;
        applyTrust?: number;
        writeOffFees?: number;
        writeOffdisbursements?: number;
        comments?: string;
    };
    transferTrustToPayInvoiceTask?: {
        isSaved: boolean;
        transfer?: number;
        invoiceNumber?: string;
        comments?: string;
    };
    archiveMatterTask?: {
        isSaved: boolean;
        isArchived?: boolean;
        comments?: string;
    };
    closeMatterTask?: {
        isSaved: boolean;
        isCloseMatter?: boolean;
        comments?: string;
    };
}

// Type to be used to disable fields in Task Dialogs
export interface FieldDisable {
    matterId: number;
    isWriteOffFeeDisabled?: boolean;
    isWriteOffDisbursementsDisabled?: boolean;
    isWriteOffCloseDisabled?: boolean;

    isWriteOffDebtorsCloseDisabled?: boolean;

    isBillMatterWriteOffFeeDisabled?: boolean;
    isBillMatterWriteOffDisbDisabled?: boolean;
}

export interface ContextMenuDisable {
    matterId?: number;
    isWriteOffWipTaskDisabled?: boolean;
    isWriteOffDebtorsTaskDisabled?: boolean;     
    isBillMatterTaskDisabled?: boolean; 
    isTransferTrustToPayInvoiceTaskDisabled?: boolean;
    isCloseMatterTaskDisabled?: boolean;
    isArchiveMatterTaskDisabled?: boolean;
} 

export interface SelectedMatterAmounts {
    unBilledFees: number;
    unBilledDisbs: number;
    debtors: number;
    trustBalance: number;
}

export interface CurrencyDetails {
    currencySymbol: string;
    currency: string;
}

interface ContextProps {}

interface ContextState {
    isWriteOffWipOpen: boolean;
    isWriteOffDebtorsOpen: boolean;
    isBillTheMatterOpen: boolean;
    isTransferTrustToPayInvoiceOpen: boolean;
    isArchiveMatterOpen: boolean;
    isCloseMatterOpen: boolean;
}

export const MatterManagementContextProvider: React.FC<ContextProps> = (props) => {    
    // batchGuidId is for the entire process.
    const [batchGuidId, setBatchGuidId] = useState<string>(Guid.create().toString());

    const [currencyData, setCurrencyData] = useState<CurrencyDetails>();

    // To keep count of how many tasks processed
    // const [processedTaskCount, setProcessedTaskCount] = useState(0);

    const [contextState, setContextState] = useState<ContextState>({
        isWriteOffWipOpen: false,
        isWriteOffDebtorsOpen: false,
        isBillTheMatterOpen: false,
        isTransferTrustToPayInvoiceOpen: false,
        isArchiveMatterOpen: false,
        isCloseMatterOpen: false
    });

    const [currentSummaryViewSelectionId, setCurrentSummaryViewSelectionId] = useState<number>();

    // Header Count state
    const [matterSummaryHeader, setMatterSummaryHeader] = useState<MatterSummaryHeader>({
        mattersDisplayed: 0,
        mattersToProcess: 0,
        tasksToProcess: 0
    });
    
    // Selected Matter Row from the View
    const [selectedMatter, setSelectedMatter] = useState<MatterManagementSummary>();

    // Collection of Tasks by Matter
    const [matterTasks, setMatterTasks] = useState<MatterTask[]>([]);

    // Selected Matter's Tasks
    const [currentMatterTask, setCurrentMatterTask] = useState<MatterTask>();

    const [menuDisable, setMenuDisable] = useState<ContextMenuDisable[]>([]);

    const [fieldDisable, setFieldDisable] = useState<FieldDisable[]>([]);

    const fetchCurrencyDetails = () => {
        if (currencyData === undefined) {
            fetchCurrencyData(false,
                              (data: CurrencyOptionData) => {
                                handleCurrencyFetch(data);
                               }, 
                              (reason) => {
                                    showNotification('Failed to Fetch App User Settings', reason, 'error'); 
                               });
        }
    };

    const handleCurrencyFetch = (data: CurrencyOptionData) => {
        if (data && !data.loading && data.options) {
            if (data.options.currencySymbol && data.options.localCurrency) {
                setCurrencyData({
                    currency: data.options.localCurrency.currency,
                    currencySymbol: data.options.currencySymbol.symbol
                });
            }
        }
    };

    const onWriteOffWipOpen = () => {
        setContextState({
            ...contextState,
            isWriteOffWipOpen: true
        });
    };

    const onWriteOffWipClosed = () => {
        setContextState({
            ...contextState,
            isWriteOffWipOpen: false
        });
    };

    const onWriteOffDebtorsOpen = () => {
        setContextState({
            ...contextState,
            isWriteOffDebtorsOpen: true
        });
    };

    const onWriteOffDebtorsClosed = () => {
        setContextState({
            ...contextState,
            isWriteOffDebtorsOpen: false
        });
    };

    const onBillTheMatterOpen = () => {
        setContextState({
            ...contextState,
            isBillTheMatterOpen: true
        });
    };

    const onBillTheMatterClosed = () => {
        setContextState({
            ...contextState,
            isBillTheMatterOpen: false
        });
    };

    const onTransferTrustToPayInvoiceOpen = () => {
        setContextState({
            ...contextState,
            isTransferTrustToPayInvoiceOpen: true
        });
    };

    const onTransferTrustToPayInvoiceClosed = () => {
        setContextState({
            ...contextState,
            isTransferTrustToPayInvoiceOpen: false
        });
    };

    const onArchiveMatterOpen = () => {
        setContextState({
            ...contextState,
            isArchiveMatterOpen: true
        });
    };

    const onArchiveMatterClosed = () => {
        setContextState({
            ...contextState,
            isArchiveMatterOpen: false
        });
    };

    const onCloseMatterOpen = () => {
        setContextState({
            ...contextState,
            isCloseMatterOpen: true
        });
    };

    const onCloseMatterClosed = () => {
        setContextState({
            ...contextState,
            isCloseMatterOpen: false
        });
    };
    
    const onMatterSelected = (matter: MatterManagementSummary, event: RowSelectedEvent) => {
        const matterTask = matterTasks.filter((item: MatterTask) => {
            return item.matterId === matter.id;
        });

        const tasks = matterTask.length === 0 ? undefined : matterTask[0];

        // New row selection, so set it as new
        setCurrentMatterTask({
            matterId: matter.id,
            matterGuid: matter.matterGuidId,
            writeOffDebtorsTask: tasks ? tasks.writeOffDebtorsTask : undefined,
            writeOffWipTask: tasks ? tasks.writeOffWipTask : undefined,
            billMatterTask: tasks ? tasks.billMatterTask : undefined,
            transferTrustToPayInvoiceTask: tasks ? tasks.transferTrustToPayInvoiceTask : undefined,
            archiveMatterTask: tasks ? tasks.archiveMatterTask : undefined,
            closeMatterTask: tasks ? tasks.closeMatterTask : undefined,
            matterData: matter
        });

        setSelectedMatter(matter);
    };

    const onWriteOffWipSave = (values: WriteOffWip) => {
        // tslint:disable-next-line: no-console
        // console.log('onWriteOffWipSave');

        const currentTask = {
            ...currentMatterTask,
            writeOffWipTask: {
                isSaved: true,
                fees: values.fees,
                disbursements: values.disbursements,
                isCloseMatterAfterWriteOff: values.isCloseMatterAfterWriteOff,
                comments: values.comments
            }
        };

        // Update the values of Current Matter Task
        let currentTaskNew = handleRemoveTask(currentTask);
        handleDisableContextMenu(currentTaskNew);
        handleDisableFields(currentTaskNew);
        setCurrentMatterTask(currentTask);
        updateMatterTasks(currentTask);
    };

    const onWriteOffDebtorsSave = (values: WriteOffDebtor) => {
        // tslint:disable-next-line: no-console
        // console.log('onWriteOffDebtorsSave');

        const currentTask: MatterTask = {
            ...currentMatterTask,
            writeOffDebtorsTask: {
                isSaved: true,
                debtors: values.debtors,
                isCloseMatterAfterWriteOff: values.isCloseMatterAfterWriteOff,
                comments: values.comments
            }
        };

        // Update the values of Current Matter Task
        let currentTaskNew = handleRemoveTask(currentTask);
        handleDisableContextMenu(currentTaskNew);
        handleDisableFields(currentTaskNew);
        setCurrentMatterTask(currentTask);
        updateMatterTasks(currentTask);
    };

    const onBillTheMatterSave = (values: BillTheMatter) => {
        // tslint:disable-next-line: no-console
        // console.log('onBillTheMatterSave');

        const currentTask: MatterTask = {
            ...currentMatterTask,
            billMatterTask: {
                isSaved: true,
                fees: values.fees,
                disbursements: values.disbursements,
                applyTrust: values.applyTrust,
                writeOffFees: values.writeOffFees,
                writeOffdisbursements: values.writeOffdisbursements,
                comments: values.comments
            }
        };

        // Update the values of Current Matter Task
        let currentTaskNew = handleRemoveTask(currentTask);
        handleDisableContextMenu(currentTaskNew);
        handleDisableFields(currentTaskNew);
        setCurrentMatterTask(currentTask);
        updateMatterTasks(currentTask);
    };

    const onTransferTustToPlayInvoiceSave = (values: TransferTrustToPayInvoice) => {
        // tslint:disable-next-line: no-console
        // console.log('onTransferTustToPlayInvoiceSave');

        const currentTask: MatterTask = {
            ...currentMatterTask,
            transferTrustToPayInvoiceTask: {
                isSaved: true,
                transfer: values.transfer,
                invoiceNumber: values.invoiceNumber,
                comments: values.comments
            }
        };

        // Update the values of Current Matter Task
        let currentTaskNew = handleRemoveTask(currentTask);
        handleDisableContextMenu(currentTaskNew);
        handleDisableFields(currentTaskNew);
        setCurrentMatterTask(currentTask);
        updateMatterTasks(currentTask);
    };

    const onArchiveMatterSave = (values: ArchiveMatter) => {
        // tslint:disable-next-line: no-console
        // console.log('onArchiveMatterSave');

        const currentTask: MatterTask = {
            ...currentMatterTask,
            archiveMatterTask: {
                isSaved: true,
                isArchived: values.isArchiveMatter,
                comments: values.comments
            }
        };

        // Update the values of Current Matter Task
        let currentTaskNew = handleRemoveTask(currentTask);
        handleDisableContextMenu(currentTaskNew);
        handleDisableFields(currentTaskNew);
        setCurrentMatterTask(currentTask);
        updateMatterTasks(currentTask);
    };

    const onCloseMatterSave = (values: CloseMatter) => {
        // tslint:disable-next-line: no-console
        // console.log('onCloseMatterSave');

        const currentTask: MatterTask = {
            ...currentMatterTask,
            closeMatterTask: {
                isSaved: true,
                isCloseMatter: values.isCloseMatter,
                comments: values.comments
            }
        };

        // Update the values of Current Matter Task
        let currentTaskNew = handleRemoveTask(currentTask);
        handleDisableContextMenu(currentTaskNew);
        handleDisableFields(currentTaskNew);
        setCurrentMatterTask(currentTask);
        updateMatterTasks(currentTask);
    };

    const updateMatterTasks = (currentTask?: MatterTask) => {
        // Check whether tasks for this matter already exists
        let selectedMatterTask = matterTasks.find((item: MatterTask) => {
            return item.matterId === currentTask?.matterId;
        });

        if (selectedMatterTask) {
            // Remove and Add
            const newMatterTasks = _.remove(matterTasks, (item: MatterTask) => {return item.matterId !== currentTask?.matterId; });

            if (currentTask && ( currentTask.writeOffWipTask || currentTask.writeOffDebtorsTask || currentTask.billMatterTask
                || currentTask.transferTrustToPayInvoiceTask || currentTask.archiveMatterTask || currentTask.closeMatterTask) ) {
                    newMatterTasks.push(currentTask);  
            } 

            setMatterTasks(newMatterTasks); 
            onMatterTasksChanged(newMatterTasks);

            // tslint:disable-next-line: no-console
            // console.log(batchGuidId, newMatterTasks);
        } else if (currentTask && ( currentTask.writeOffWipTask || currentTask.writeOffDebtorsTask || currentTask.billMatterTask
            || currentTask.transferTrustToPayInvoiceTask || currentTask.archiveMatterTask || currentTask.closeMatterTask) ) {
                   
            matterTasks.push(currentTask);  
            
            setMatterTasks(matterTasks); 
            onMatterTasksChanged(matterTasks);

            // tslint:disable-next-line: no-console
            // console.log(batchGuidId, matterTasks);
        } else {
            // Remove any MatterTasks without MatterId
            const newMatterTasks = _.remove(matterTasks, (item: MatterTask) => {
                return item.matterId !== undefined;
            });

            setMatterTasks(newMatterTasks); 
            onMatterTasksChanged(newMatterTasks);

            // tslint:disable-next-line: no-console
            // console.log(batchGuidId, newMatterTasks);
        }
    };

    const onMatterTasksChanged = (tasks: MatterTask[]) => {
        let mattersToProcess = 0;
        let tasksToProcess = 0;
        
        tasks.forEach(task => {
            mattersToProcess = mattersToProcess + 1;

            if (task.writeOffWipTask) {
                tasksToProcess = tasksToProcess + 1;
            }
            if (task.writeOffDebtorsTask) {
                tasksToProcess = tasksToProcess + 1;
            }
            if (task.billMatterTask) {
                tasksToProcess = tasksToProcess + 1;
            }
            if (task.transferTrustToPayInvoiceTask) {
                tasksToProcess = tasksToProcess + 1;
            }
            if (task.archiveMatterTask) {
                tasksToProcess = tasksToProcess + 1;
            }
            if (task.closeMatterTask) {
                tasksToProcess = tasksToProcess + 1;
            }            
        });

        setMatterSummaryHeader({
            ...matterSummaryHeader,
            mattersToProcess: mattersToProcess,
            tasksToProcess: tasksToProcess
        });
    };

    const calculateMatterSummaryHeader = () => {
        let mattersToProcess = 0;
        let tasksToProcess = 0;
        
        matterTasks.forEach(task => {
            mattersToProcess = mattersToProcess + 1;

            if (task.writeOffWipTask) {
                tasksToProcess = tasksToProcess + 1;
            }
            if (task.writeOffDebtorsTask) {
                tasksToProcess = tasksToProcess + 1;
            }
            if (task.billMatterTask) {
                tasksToProcess = tasksToProcess + 1;
            }
            if (task.transferTrustToPayInvoiceTask) {
                tasksToProcess = tasksToProcess + 1;
            }
            if (task.archiveMatterTask) {
                tasksToProcess = tasksToProcess + 1;
            }
            if (task.closeMatterTask) {
                tasksToProcess = tasksToProcess + 1;
            }            
        });

        setMatterSummaryHeader({
            ...matterSummaryHeader,
            mattersToProcess: mattersToProcess,
            tasksToProcess: tasksToProcess
        });
    };

    const hasTasks = (matterId: number): boolean => {
        const matterTask = matterTasks.filter((item: MatterTask) => {
            return item.matterId === matterId;
        });

        if (matterTask && matterTask.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    const onUnBilledFeesExists = (data: MatterManagementSummary) => {
        if (data) {
            if (data.unbilledFees) {
                return data.unbilledFees > 0;
            } else {
                return false;
            }
        } else {
            return false;
        }      
    };

    const onUnBilledDisbursementsExists = (data: MatterManagementSummary) => {
        if (data) {
            if (data.unbilledDisbursements) {
                return data.unbilledDisbursements > 0;
            } else {
                return false;
            }
        } else {
            return false;
        }      
    };

    const onDebtorsExists = (data: MatterManagementSummary) => {
        if (data) {
            if (data.debtors) {
                return data.debtors > 0;
            } else {
                return false;
            }
        } else {
            return false;
        }      
    };

    const onTrustExists = (data: MatterManagementSummary) => {
        if (data) {
            if (data.trustBalance) {
                return data.trustBalance > 0;
            } else {
                return false;
            }
        } else {
            return false;
        }      
    };

    const onHandleTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // Update currentMatterTask
        // Update MatterTasks
        let currentTask: MatterTask = { ...currentMatterTask };

        // const writeOffWipTask = currentMatterTask?.writeOffWipTask;
        // const writeOffDebtorTask = currentMatterTask?.writeOffDebtorsTask;
        // const billMatterTask = currentMatterTask?.billMatterTask;
        const transferTrustMatterTask = currentMatterTask?.transferTrustToPayInvoiceTask;

        // if (event.currentTarget.name === 'writeOffWipFee') {
        //     const fees = event.currentTarget.value && event.currentTarget.value.length > 0 ? Number(event.currentTarget.value) : undefined;
            
        //     currentTask = {
        //         ...currentMatterTask,
        //         writeOffWipTask: {
        //             isSaved: true,
        //             fees: fees,
        //             disbursements: writeOffWipTask?.disbursements,
        //             isCloseMatterAfterWriteOff: getIsCloseDisabledForFeeWriteOffDialog(currentMatterTask?.matterId, fees) === true
        //                 ? undefined
        //                 : writeOffWipTask?.isCloseMatterAfterWriteOff,
        //             comments: writeOffWipTask?.comments
        //         }
        //     };   
            
        // } else if (event.currentTarget.name === 'writeOffWipDisb') {
        //     const disb = event.currentTarget.value && event.currentTarget.value.length > 0 ? Number(event.currentTarget.value) : undefined;

        //     currentTask = {
        //         ...currentMatterTask,
        //         writeOffWipTask: {
        //             isSaved: true,
        //             fees: writeOffWipTask?.fees,
        //             disbursements: disb,
        //             isCloseMatterAfterWriteOff: writeOffWipTask?.isCloseMatterAfterWriteOff,
        //             comments: writeOffWipTask?.comments
        //         }
        //     };
        // } else if (event.currentTarget.name === 'writeOffWipDebtors') {
        //     const debtors = event.currentTarget.value && event.currentTarget.value.length > 0 ? Number(event.currentTarget.value) : undefined;
            
        //     currentTask = {
        //         ...currentMatterTask,
        //         writeOffDebtorsTask: {
        //             isSaved: true,
        //             debtors: debtors,
        //             isCloseMatterAfterWriteOff: writeOffDebtorTask?.isCloseMatterAfterWriteOff,
        //             comments: writeOffDebtorTask?.comments
        //         }
        //     };
        // } else if (event.currentTarget.name === 'billFees') {
        //     const billFee = event.currentTarget.value && event.currentTarget.value.length > 0 ? Number(event.currentTarget.value) : undefined;
        
        //     currentTask = {
        //         ...currentMatterTask,
        //         billMatterTask: {
        //             isSaved: true,
        //             fees: billFee,
        //             disbursements: billMatterTask?.disbursements,
        //             applyTrust: billMatterTask?.applyTrust,
        //             writeOffFees: billMatterTask?.writeOffFees,
        //             writeOffdisbursements: billMatterTask?.writeOffdisbursements,
        //             comments: billMatterTask?.comments
        //         }
        //     };
        // } else if (event.currentTarget.name === 'billDisb') {
        //     const billDisb = event.currentTarget.value && event.currentTarget.value.length > 0 ? Number(event.currentTarget.value) : undefined;

        //     currentTask = {
        //         ...currentMatterTask,
        //         billMatterTask: {
        //             isSaved: true,
        //             fees: billMatterTask?.fees,
        //             disbursements: billDisb,
        //             applyTrust: billMatterTask?.applyTrust,
        //             writeOffFees: billMatterTask?.writeOffFees,
        //             writeOffdisbursements: billMatterTask?.writeOffdisbursements,
        //             comments: billMatterTask?.comments
        //         }
        //     };
        // } else if (event.currentTarget.name === 'applyTrust') {
        //     const applyTrust = event.currentTarget.value && event.currentTarget.value.length > 0 ? Number(event.currentTarget.value) : undefined;
            
        //     currentTask = {
        //         ...currentMatterTask,
        //         billMatterTask: {
        //             isSaved: true,
        //             fees: billMatterTask?.fees,
        //             disbursements: billMatterTask?.disbursements,
        //             applyTrust: applyTrust,
        //             writeOffFees: billMatterTask?.writeOffFees,
        //             writeOffdisbursements: billMatterTask?.writeOffdisbursements,
        //             comments: billMatterTask?.comments
        //         }
        //     };
        // } else if (event.currentTarget.name === 'transferTrust') {
        // tslint:disable-next-line: max-line-length
        //     const transferTrust = event.currentTarget.value && event.currentTarget.value.length > 0 ? Number(event.currentTarget.value) : undefined;
            
        //     currentTask = {
        //         ...currentMatterTask,
        //         transferTrustToPayInvoiceTask: {
        //             isSaved: true,
        //             transfer: transferTrust,
        //             invoiceNumber: transferTrustMatterTask?.invoiceNumber,                    
        //             comments: billMatterTask?.comments
        //         }
        //     };
        // } else 
        if (event.currentTarget.name === 'transferInvoice') {
            const invoiceNumber = event.currentTarget.value && event.currentTarget.value.length > 0 ? event.currentTarget.value : undefined;
            
            currentTask = {
                ...currentMatterTask,
                transferTrustToPayInvoiceTask: {
                    isSaved: true,
                    transfer: transferTrustMatterTask?.transfer,
                    invoiceNumber: invoiceNumber,                    
                    comments: transferTrustMatterTask?.comments
                }
            };
        }

        // Update the values of Current Matter Task
        let currentTaskNew = handleRemoveTask(currentTask);
        // handleDisableContextMenu(currentTaskNew);
        currentTaskNew = handleUpdateCloseValuesInTasks(currentTaskNew);
        handleDisableFields(currentTaskNew);
        setCurrentMatterTask(currentTaskNew);
        updateMatterTasks(currentTaskNew);        
    };

    const onHandleTextChangeNumeric = (name: string, value?: number) => {
        // Update currentMatterTask
        // Update MatterTasks
        let currentTask: MatterTask = { ...currentMatterTask };

        const writeOffWipTask = currentMatterTask?.writeOffWipTask;
        const writeOffDebtorTask = currentMatterTask?.writeOffDebtorsTask;
        const billMatterTask = currentMatterTask?.billMatterTask;
        const transferTrustMatterTask = currentMatterTask?.transferTrustToPayInvoiceTask;

        if (name === 'writeOffWipFee') {
            const fees = value && value > 0 ? Number(value) : undefined;

            if (billMatterTask) {
                currentTask = {
                    ...currentMatterTask,
                    billMatterTask: {
                        isSaved: true,
                        fees: billMatterTask?.fees,
                        disbursements: billMatterTask?.disbursements,
                        applyTrust: billMatterTask?.applyTrust,
                        writeOffFees: fees,
                        writeOffdisbursements: billMatterTask?.writeOffdisbursements,
                        comments: billMatterTask?.comments
                    }
                };
            } else {
                currentTask = {
                    ...currentMatterTask,
                    writeOffWipTask: {
                        isSaved: true,
                        fees: fees,
                        disbursements: writeOffWipTask?.disbursements,
                        isCloseMatterAfterWriteOff: getIsCloseDisabledForFeeWriteOffDialog(currentMatterTask?.matterId, fees) === true
                            ? undefined
                            : writeOffWipTask?.isCloseMatterAfterWriteOff,
                        comments: writeOffWipTask?.comments
                    }
                }; 
            }            
        } else if (name === 'writeOffWipDisb') {
            const disb = value && value > 0 ? Number(value) : undefined;

            if (billMatterTask) {
                currentTask = {
                    ...currentMatterTask,
                    billMatterTask: {
                        isSaved: true,
                        fees: billMatterTask?.fees,
                        disbursements: billMatterTask?.disbursements,
                        applyTrust: billMatterTask?.applyTrust,
                        writeOffFees: billMatterTask?.writeOffFees,
                        writeOffdisbursements: disb,
                        comments: billMatterTask?.comments
                    }
                };
            } else {
                currentTask = {
                    ...currentMatterTask,
                    writeOffWipTask: {
                        isSaved: true,
                        fees: writeOffWipTask?.fees,
                        disbursements: disb,
                        isCloseMatterAfterWriteOff: writeOffWipTask?.isCloseMatterAfterWriteOff,
                        comments: writeOffWipTask?.comments
                    }
                };
            }
            
        } else if (name === 'writeOffWipDebtors') {
            const debtors = value && value > 0 ? Number(value) : undefined;
            
            currentTask = {
                ...currentMatterTask,
                writeOffDebtorsTask: {
                    isSaved: true,
                    debtors: debtors,
                    isCloseMatterAfterWriteOff: writeOffDebtorTask?.isCloseMatterAfterWriteOff,
                    comments: writeOffDebtorTask?.comments
                }
            };
        } else if (name === 'billFees') {
            const billFee = value && value > 0 ? Number(value) : undefined;
        
            currentTask = {
                ...currentMatterTask,
                billMatterTask: {
                    isSaved: true,
                    fees: billFee,
                    disbursements: billMatterTask?.disbursements,
                    applyTrust: billMatterTask?.applyTrust,
                    writeOffFees: billMatterTask?.writeOffFees,
                    writeOffdisbursements: billMatterTask?.writeOffdisbursements,
                    comments: billMatterTask?.comments
                }
            };
        } else if (name === 'billDisb') {
            const billDisb = value && value > 0 ? Number(value) : undefined;

            currentTask = {
                ...currentMatterTask,
                billMatterTask: {
                    isSaved: true,
                    fees: billMatterTask?.fees,
                    disbursements: billDisb,
                    applyTrust: billMatterTask?.applyTrust,
                    writeOffFees: billMatterTask?.writeOffFees,
                    writeOffdisbursements: billMatterTask?.writeOffdisbursements,
                    comments: billMatterTask?.comments
                }
            };
        } else if (name === 'applyTrust') {
            const applyTrust = value && value > 0 ? Number(value) : undefined;
            
            currentTask = {
                ...currentMatterTask,
                billMatterTask: {
                    isSaved: true,
                    fees: billMatterTask?.fees,
                    disbursements: billMatterTask?.disbursements,
                    applyTrust: applyTrust,
                    writeOffFees: billMatterTask?.writeOffFees,
                    writeOffdisbursements: billMatterTask?.writeOffdisbursements,
                    comments: billMatterTask?.comments
                }
            };
        } else if (name === 'transferTrust') {
            const transferTrust = value && value > 0 ? Number(value) : undefined;
            
            currentTask = {
                ...currentMatterTask,
                transferTrustToPayInvoiceTask: {
                    isSaved: true,
                    transfer: transferTrust,
                    invoiceNumber: transferTrustMatterTask?.invoiceNumber,                    
                    comments: billMatterTask?.comments
                }
            };
        }

        // Update the values of Current Matter Task
        let currentTaskNew = handleRemoveTask(currentTask);
        // handleDisableContextMenu(currentTaskNew);
        currentTaskNew = handleUpdateCloseValuesInTasks(currentTaskNew);
        handleDisableFields(currentTaskNew);
        setCurrentMatterTask(currentTaskNew);
        updateMatterTasks(currentTaskNew);        
    };

    // If close should be disabled, then remove close values from all the available tasks
    const handleUpdateCloseValuesInTasks = (currentTask?: MatterTask): MatterTask| undefined => {
        if (currentTask) {
            let isCloseDisabled = getIsCloseDisabled(currentTask.matterId, currentTask);

            if (isCloseDisabled) {
                if (currentTask.writeOffWipTask) {
                    currentTask.writeOffWipTask.isCloseMatterAfterWriteOff = undefined;
                }
                if (currentTask.writeOffDebtorsTask) {
                    currentTask.writeOffDebtorsTask.isCloseMatterAfterWriteOff = undefined;
                }
                if (currentTask.closeMatterTask) {
                    currentTask.closeMatterTask = undefined;
                }
            }
        }

        return currentTask;
    };

    const onHandleCheckBoxChange = (event: React.FormEvent<HTMLInputElement>, matterId: number, matterGuidId: string) => {
        // Checkbox event if firing before the row selection. So the current Matter Task is not available
        let currentTask: MatterTask | undefined = { ...currentMatterTask };

        if (currentTask.matterId === undefined || (currentTask.matterId !== undefined && currentTask.matterId !== matterId) ) {
            currentTask = undefined;

            // If there are tasks saved against the same matterId, use them
            const newMatterTasks = _.find(matterTasks, (item: MatterTask) => {return item.matterId === matterId; });
            
            if (newMatterTasks) {
                currentTask = newMatterTasks;
            }
        }

        if (event?.currentTarget.name === 'isCloseMatter') {
            const isClosed = event?.currentTarget.checked;
            const closeMatterTask = currentTask?.closeMatterTask;
            const writeOffWipTask = currentTask?.writeOffWipTask;
            const writeOffWipDebtorTask = currentTask?.writeOffDebtorsTask;
            let isCurrentTaskSaved = false;

            if (closeMatterTask === undefined && writeOffWipTask === undefined && writeOffWipDebtorTask === undefined) {
                currentTask = {
                    ...currentTask,
                    matterId: matterId,
                    matterGuid: matterGuidId,
                    closeMatterTask: {
                        isSaved: true,
                        isCloseMatter: isClosed,
                        comments: undefined
                    }
                };

                isCurrentTaskSaved = true;
            }
        
            if (!isCurrentTaskSaved) {
                if (closeMatterTask && closeMatterTask.isCloseMatter) {
                    currentTask = {
                        ...currentTask,
                        matterId: matterId,
                        matterGuid: matterGuidId,
                        closeMatterTask: {
                            isSaved: true,
                            isCloseMatter: isClosed,
                            comments: closeMatterTask.comments
                        }
                    };
                } else {
                    // Check if we have values in either WIP/ WIP Debtor tasks
                    let isWriteOffFeeClose: boolean | undefined = undefined;
                    if (writeOffWipTask?.isCloseMatterAfterWriteOff) {
                        isWriteOffFeeClose = true;
                    }

                    if (writeOffWipDebtorTask?.isCloseMatterAfterWriteOff) {
                        isWriteOffFeeClose = false;
                    }

                    if (isWriteOffFeeClose === undefined || isWriteOffFeeClose === true) {
                        currentTask = {
                            ...currentTask,
                            matterId: matterId,
                            matterGuid: matterGuidId,
                            writeOffWipTask: {
                                isSaved: true,
                                isCloseMatterAfterWriteOff: isClosed,
                                fees: writeOffWipTask?.fees,
                                disbursements: writeOffWipTask?.disbursements,
                                comments: writeOffWipTask?.comments
                            }
                        };
                    } else {
                        currentTask = {
                            ...currentTask,
                            matterId: matterId,
                            matterGuid: matterGuidId,
                            writeOffDebtorsTask: {
                                isSaved: true,
                                isCloseMatterAfterWriteOff: isClosed,
                                debtors: writeOffWipDebtorTask?.debtors,
                                comments: writeOffWipDebtorTask?.comments
                            }
                        };
                    }
                }
            }                
        } else if (event?.currentTarget.name === 'isArchiveMatter') {
            const isArchive = event?.currentTarget.checked;
            const archiveMatterTask = currentTask?.archiveMatterTask;
            
            currentTask = {
                ...currentTask,
                matterId: matterId,
                matterGuid: matterGuidId,
                archiveMatterTask: {
                    isSaved: true,
                    isArchived: isArchive,
                    comments: archiveMatterTask?.comments
                }
            };
        }

        // Update the values of Current Matter Task
        let currentTaskNew = handleRemoveTask(currentTask);
        // handleDisableContextMenu(currentTaskNew);
        handleDisableFields(currentTaskNew);
        setCurrentMatterTask(currentTaskNew);
        updateMatterTasks(currentTaskNew);        
    };

    const handleRemoveTask = (currentTask?: MatterTask): MatterTask | undefined => {
        let currentTaskNew = currentTask;

        // Check the values and remove the task.
        if (currentTaskNew && currentTaskNew.writeOffWipTask) {
            const writeOffWipTask = currentTaskNew.writeOffWipTask;

            if (writeOffWipTask && 
                (writeOffWipTask.fees || writeOffWipTask.disbursements || 
                    (writeOffWipTask.isCloseMatterAfterWriteOff) ) 
                ) {
                   // Do nothing 
                } else {
                    currentTaskNew.writeOffWipTask = undefined;
                }
        } 
        
        if (currentTaskNew && currentTaskNew.writeOffDebtorsTask) {
            const writeOffDebtorsTask = currentTaskNew.writeOffDebtorsTask;

            if (writeOffDebtorsTask && 
                ( writeOffDebtorsTask.debtors || 
                    ( writeOffDebtorsTask.isCloseMatterAfterWriteOff) ) 
                ) {
                   // Do nothing 
                } else {
                    currentTaskNew.writeOffDebtorsTask = undefined;
                }
        } 

        if (currentTaskNew && currentTaskNew.billMatterTask) {
            const billMatterTask = currentTaskNew.billMatterTask;

            if (billMatterTask && 
                (billMatterTask.fees || billMatterTask.disbursements
                    || billMatterTask.applyTrust || billMatterTask.writeOffFees || billMatterTask.writeOffdisbursements)
                ) {
                   // Do nothing 
                } else {
                    currentTaskNew.billMatterTask = undefined;
                }
        } 

        if (currentTaskNew && currentTaskNew.transferTrustToPayInvoiceTask) {
            const transferTrustToPayInvoiceTask = currentTaskNew.transferTrustToPayInvoiceTask;

            if (transferTrustToPayInvoiceTask && 
                (transferTrustToPayInvoiceTask.transfer || transferTrustToPayInvoiceTask.invoiceNumber) 
                ) {
                   // Do nothing 
                } else {
                    currentTaskNew.transferTrustToPayInvoiceTask = undefined;
                }
        } 

        if (currentTaskNew && currentTaskNew.archiveMatterTask) {
            const archiveMatterTask = currentTaskNew.archiveMatterTask;

            if (archiveMatterTask && (archiveMatterTask.isArchived) ) {
                   // Do nothing 
                } else {
                    currentTaskNew.archiveMatterTask = undefined;
                }
        } 

        if (currentTaskNew && currentTaskNew.closeMatterTask) {
            const closeMatterTask = currentTaskNew.closeMatterTask;

            if (closeMatterTask && (closeMatterTask.isCloseMatter) ) {
                   // Do nothing 
                } else {
                    currentTaskNew.closeMatterTask = undefined;
                }
        }

        return currentTaskNew;
    };

    // isCloseDisabledDueToAmounts = If the matter has pending amounts, the matter cannot be closed and close controls should be disabled
    const handleDisableContextMenu = (currentTask?: MatterTask, isCloseDisabledDueToAmounts?: boolean) => {
        // tslint:disable-next-line: no-console
        // console.log('handleDisableContextMenu');

        if (currentTask) {
            // If closed is ticked, then all other tasks should be closed if they don't have any data
            let isClosed = false;

            _.remove(menuDisable, (menu: ContextMenuDisable) => {
                return menu.matterId === currentTask.matterId;
            });

            if (currentTask.closeMatterTask || currentTask.writeOffWipTask || currentTask.writeOffDebtorsTask) {
                if (currentTask.closeMatterTask?.isCloseMatter) {
                    isClosed = true;
                } else if (currentTask.writeOffWipTask?.isCloseMatterAfterWriteOff) {
                    isClosed = true;
                } else if (currentTask.writeOffDebtorsTask?.isCloseMatterAfterWriteOff) {
                    isClosed = true;
                }

                if (isClosed) {
                    menuDisable.push({
                        matterId: currentTask.matterId,
                        isWriteOffWipTaskDisabled: currentTask.writeOffWipTask === undefined ? true : false,
                        isWriteOffDebtorsTaskDisabled: currentTask.writeOffDebtorsTask === undefined ? true : false,
                        isBillMatterTaskDisabled: currentTask.billMatterTask === undefined ? true : false,
                        isTransferTrustToPayInvoiceTaskDisabled: currentTask.transferTrustToPayInvoiceTask === undefined ? true : false,
                        isCloseMatterTaskDisabled: isCloseDisabledDueToAmounts && isCloseDisabledDueToAmounts === true 
                                                        ? true 
                                                        : currentTask.closeMatterTask === undefined ? true : false
                    });

                    setMenuDisable(menuDisable);
                } else {
                    setMenuDisable(menuDisable);
                }
            } else {
                setMenuDisable(menuDisable);
            }
        }
    };

    // Since we have same fields accross multiple tasks, if a value is applied in 1 task, it shouldn't be applied in another.
    // So, we are disabling those fields here.
    const handleDisableFields = (currentTask?: MatterTask) => {
        if (currentTask && currentTask.matterId) {
            _.remove(fieldDisable, (fields: FieldDisable) => {
                return fields.matterId === currentTask.matterId;
            });

            let fieldDisableState: FieldDisable = {
                matterId: currentTask.matterId
            };

            let isFieldDisableStateChanged = false;

            if (currentTask.writeOffWipTask) {
                if (currentTask.writeOffWipTask.fees) {
                    fieldDisableState.isBillMatterWriteOffFeeDisabled = true;
                    isFieldDisableStateChanged = true;
                }
                if (currentTask.writeOffWipTask.disbursements) {
                    fieldDisableState.isBillMatterWriteOffDisbDisabled = true;
                    isFieldDisableStateChanged = true;
                }
                if (currentTask.writeOffWipTask.fees || currentTask.writeOffWipTask.disbursements
                    || !currentTask.writeOffWipTask.isCloseMatterAfterWriteOff) {
                        if (currentTask.writeOffDebtorsTask?.isCloseMatterAfterWriteOff || currentTask.closeMatterTask?.isCloseMatter) {
                            fieldDisableState.isWriteOffCloseDisabled = true;
                            isFieldDisableStateChanged = true;
                        }
                }
            }

            if (currentTask.writeOffDebtorsTask) {
                if (currentTask.writeOffDebtorsTask.debtors && !currentTask.writeOffDebtorsTask.isCloseMatterAfterWriteOff) {
                    if (currentTask.writeOffWipTask?.isCloseMatterAfterWriteOff || currentTask.closeMatterTask?.isCloseMatter) {
                        fieldDisableState.isWriteOffDebtorsCloseDisabled = true;
                        isFieldDisableStateChanged = true;
                    }
                }
            }
            
            if (currentTask.billMatterTask) {
                if (currentTask.billMatterTask.writeOffFees) {
                    fieldDisableState.isWriteOffFeeDisabled = true;
                    isFieldDisableStateChanged = true;
                }
                if (currentTask.billMatterTask.writeOffdisbursements) {
                    fieldDisableState.isWriteOffDisbursementsDisabled = true;
                    isFieldDisableStateChanged = true;
                }
            }

            if (isFieldDisableStateChanged) {
                fieldDisable.push(fieldDisableState);
                setFieldDisable(fieldDisable);
            }
        }
    };

    // Check whether the Text Box in Grid should be disable. Rules applied by comparing Closed flag and whether the field has any values
    const getTextBoxDisabled = (matterId: number, value?: number| string): boolean => {
        // If value is empty and Closed is ticked, then disable

        const tasks = _.find(matterTasks, (matterTask: MatterTask) => {
            return matterTask.matterId === matterId;
        });

        let isClosed = false;

        if (tasks && (tasks.closeMatterTask || tasks.writeOffWipTask || tasks.writeOffDebtorsTask) ) {
            if (tasks.closeMatterTask?.isCloseMatter) {
                isClosed = true;
            } else if (tasks.writeOffWipTask?.isCloseMatterAfterWriteOff) {
                isClosed = true;
            } else if (tasks.writeOffDebtorsTask?.isCloseMatterAfterWriteOff) {
                isClosed = true;
            }
        }
        
        if (isClosed && value === undefined) {
            return true;
        } else {
            return false;
        }        
    };

    const getFieldDisabled = (matterId: number, field: string): boolean => {
        const fieldDisableState = _.find(fieldDisable, (item: FieldDisable) => {
            return item.matterId === matterId;
        });

        if (fieldDisableState) {
            return fieldDisableState[field] ? fieldDisableState[field] : false;
        } else {
            return false;
        }
    };

    // Check values against Tasks and current dialog values to see whether the close should be enabled or not.
    // tslint:disable-next-line: no-shadowed-variable
    const getIsCloseDisabled = (matterId?: number, currentTasks?: MatterTask, selectedMatter?: MatterManagementSummary): boolean => {
        // tslint:disable-next-line: no-console
        // console.log('getIsCloseDisabled');

        let tasks: MatterTask | undefined;

        if (currentTasks) {
            tasks = currentTasks;
        } else {
            tasks = _.find(matterTasks, (matterTask: MatterTask) => {
                return matterTask.matterId === matterId;
            });
        }        

        if (tasks && tasks.matterData) {

            let selectedMatterAmounts = getCurrentMatterAmounts(tasks.matterData);

            let unBilledFees = selectedMatterAmounts.unBilledFees;
            let unBilledDisbs = selectedMatterAmounts.unBilledDisbs;
            let debtors = selectedMatterAmounts.debtors;
            let trustBalance = selectedMatterAmounts.trustBalance;

            if (tasks.writeOffWipTask) {
                if (tasks.writeOffWipTask.fees) {
                    unBilledFees = unBilledFees - tasks.writeOffWipTask.fees;
                }
                if (tasks.writeOffWipTask.disbursements) {
                    unBilledDisbs = unBilledDisbs - tasks.writeOffWipTask.disbursements;
                }
            }

            if (tasks.billMatterTask) {
                if (tasks.billMatterTask.writeOffFees) {
                    unBilledFees = unBilledFees - tasks.billMatterTask.writeOffFees;
                }
                if (tasks.billMatterTask.fees) {
                    unBilledFees = unBilledFees + tasks.billMatterTask.fees;
                }
                if (tasks.billMatterTask.writeOffdisbursements) {
                    unBilledDisbs = unBilledDisbs - tasks.billMatterTask.writeOffdisbursements;
                }
                if (tasks.billMatterTask.disbursements) {
                    unBilledDisbs = unBilledDisbs + tasks.billMatterTask.disbursements;
                }
                if (tasks.billMatterTask.applyTrust) {
                    trustBalance = trustBalance - tasks.billMatterTask.applyTrust;
                }
            }

            if (tasks.writeOffDebtorsTask && tasks.writeOffDebtorsTask.debtors) {
                debtors = debtors - tasks.writeOffDebtorsTask.debtors; 
            }

            if (tasks.transferTrustToPayInvoiceTask && tasks.transferTrustToPayInvoiceTask.transfer) {
                trustBalance = trustBalance - tasks.transferTrustToPayInvoiceTask.transfer;
            }

            if ( (unBilledFees + unBilledDisbs + debtors + trustBalance) === 0) {
                handleDisableContextMenu(tasks, false);
                return false;                
            } else {
                // clearCloseTasks(matterId);
                handleDisableContextMenu(tasks, true);
            }
        } else if (selectedMatter) {
            let selectedMatterAmounts = getCurrentMatterAmounts(selectedMatter);

            let unBilledFees = selectedMatterAmounts.unBilledFees;
            let unBilledDisbs = selectedMatterAmounts.unBilledDisbs;
            let debtors = selectedMatterAmounts.debtors;
            let trustBalance = selectedMatterAmounts.trustBalance;

            if ( (unBilledFees + unBilledDisbs + debtors + trustBalance) === 0) {
                handleDisableContextMenu(tasks, false);
                return false;                
            } else {
                // clearCloseTasks(matterId);
                handleDisableContextMenu(tasks, true);
            }            
        }

        return true;
    };

    // Check values against Tasks and current dialog values to see whether the close should be enabled or not.
    const getIsCloseDisabledForFeeWriteOffDialog = (matterId?: number, writeOffFee?: number, writeOffDisb?: number): boolean => {
        // tslint:disable-next-line: no-console
        // console.log('getIsCloseDisabledForFeeWriteOffDialog');

        const tasks = _.find(matterTasks, (matterTask: MatterTask) => {
            return matterTask.matterId === matterId;
        });

        if (tasks && tasks.matterData) {

            let selectedMatterAmounts = getCurrentMatterAmounts(tasks.matterData);

            let unBilledFees = selectedMatterAmounts.unBilledFees;
            let unBilledDisbs = selectedMatterAmounts.unBilledDisbs;
            let debtors = selectedMatterAmounts.debtors;
            let trustBalance = selectedMatterAmounts.trustBalance;

            if (writeOffFee && !isNaN(writeOffFee)) {
                unBilledFees = unBilledFees - Number(writeOffFee);
            }

            if (writeOffDisb && !isNaN(writeOffDisb)) {
                unBilledDisbs = unBilledDisbs - Number(writeOffDisb);
            }

            if (tasks.billMatterTask) {
                if (tasks.billMatterTask.writeOffFees) {
                    unBilledFees = unBilledFees - tasks.billMatterTask.writeOffFees;
                }
                if (tasks.billMatterTask.fees) {
                    unBilledFees = unBilledFees + tasks.billMatterTask.fees;
                }
                if (tasks.billMatterTask.writeOffdisbursements) {
                    unBilledDisbs = unBilledDisbs - tasks.billMatterTask.writeOffdisbursements;
                }
                if (tasks.billMatterTask.disbursements) {
                    unBilledDisbs = unBilledDisbs + tasks.billMatterTask.disbursements;
                }
                if (tasks.billMatterTask.applyTrust) {
                    trustBalance = trustBalance - tasks.billMatterTask.applyTrust;
                }
            }

            if (tasks.writeOffDebtorsTask && tasks.writeOffDebtorsTask.debtors) {
                debtors = debtors - tasks.writeOffDebtorsTask.debtors; 
            }

            if (tasks.transferTrustToPayInvoiceTask && tasks.transferTrustToPayInvoiceTask.transfer) {
                trustBalance = trustBalance - tasks.transferTrustToPayInvoiceTask.transfer;
            }

            if ( (unBilledFees + unBilledDisbs + debtors + trustBalance) === 0) {
                return false;
            }
        }

        // clearCloseTasks(matterId);
        handleDisableContextMenu(tasks, true);
        return true;
    };

    const getIsCloseDisabledForFeeWriteOffDebtorDialog = (matterId?: number, writeOffDebtors?: number): boolean => {
        // tslint:disable-next-line: no-console
        // console.log('getIsCloseDisabledForFeeWriteOffDebtorDialog');

        const tasks = _.find(matterTasks, (matterTask: MatterTask) => {
            return matterTask.matterId === matterId;
        });

        if (tasks && tasks.matterData) {

            let selectedMatterAmounts = getCurrentMatterAmounts(tasks.matterData);

            let unBilledFees = selectedMatterAmounts.unBilledFees;
            let unBilledDisbs = selectedMatterAmounts.unBilledDisbs;
            let debtors = selectedMatterAmounts.debtors;
            let trustBalance = selectedMatterAmounts.trustBalance;

            if (tasks.writeOffWipTask) {
                if (tasks.writeOffWipTask.fees) {
                    unBilledFees = unBilledFees - tasks.writeOffWipTask.fees;
                }
                if (tasks.writeOffWipTask.disbursements) {
                    unBilledDisbs = unBilledDisbs - tasks.writeOffWipTask.disbursements;
                }
            }

            if (tasks.billMatterTask) {
                if (tasks.billMatterTask.writeOffFees) {
                    unBilledFees = unBilledFees - tasks.billMatterTask.writeOffFees;
                }
                if (tasks.billMatterTask.fees) {
                    unBilledFees = unBilledFees + tasks.billMatterTask.fees;
                }
                if (tasks.billMatterTask.writeOffdisbursements) {
                    unBilledDisbs = unBilledDisbs - tasks.billMatterTask.writeOffdisbursements;
                }
                if (tasks.billMatterTask.disbursements) {
                    unBilledDisbs = unBilledDisbs + tasks.billMatterTask.disbursements;
                }
                if (tasks.billMatterTask.applyTrust) {
                    trustBalance = trustBalance - tasks.billMatterTask.applyTrust;
                }
            }

            if (writeOffDebtors && !isNaN(writeOffDebtors)) {
                debtors = debtors - Number(writeOffDebtors);
            }

            if (tasks.transferTrustToPayInvoiceTask && tasks.transferTrustToPayInvoiceTask.transfer) {
                trustBalance = trustBalance - tasks.transferTrustToPayInvoiceTask.transfer;
            }

            if ( (unBilledFees + unBilledDisbs + debtors + trustBalance) === 0) {
                return false;
            } else {
                // clearCloseTasks(matterId);
                handleDisableContextMenu(tasks, true);
            }
        }
        
        return true;
    };

    // If we are going to disable Close, then we also should clear the close value from tasks.
    // const clearCloseTasks = (matterId: number) => {
    //     // tslint:disable-next-line: no-console
    //     console.log('clearCloseTasks MatterId : ', matterId );

    //     const tasks = _.find(matterTasks, (matterTask: MatterTask) => {
    //         return matterTask.matterId === matterId;
    //     });

    //     if (tasks) {
    //         let isTaskUpdated = false;

    //         if (tasks.closeMatterTask) {
    //             tasks.closeMatterTask = undefined;
    //             isTaskUpdated = true;
    //         }
    //         if (tasks.writeOffWipTask) {
    //             tasks.writeOffWipTask.isCloseMatterAfterWriteOff = undefined;
    //             isTaskUpdated = true;
    //         }
    //         if (tasks.writeOffDebtorsTask) {
    //             tasks.writeOffDebtorsTask.isCloseMatterAfterWriteOff = undefined;
    //             isTaskUpdated = true;
    //         }

    //         // tslint:disable-next-line: no-console
    //         console.log('isTaskUpdated ', isTaskUpdated);
    //     }
    // };

    const getCurrentMatterAmounts = (matterData: MatterManagementSummary): SelectedMatterAmounts => {

        let unBilledFees = Number(matterData.unbilledFees.toFixed(2));

        let unBilledDisbs = Number(matterData.unbilledDisbursements.toFixed(2));
        let debtors = Number(matterData.debtors.toFixed(2));
        let trustBalance = Number(matterData.trustBalance.toFixed(2));  

        let selectedMatterAmounts: SelectedMatterAmounts = {
            unBilledFees: unBilledFees,
            unBilledDisbs: unBilledDisbs,
            debtors: debtors,
            trustBalance: trustBalance
        };

        return selectedMatterAmounts;
    };

    const getWriteOffWipFee = (matterId: number): number | undefined | string => {
        if (matterTasks) {
            let matterTask = matterTasks.find((item: MatterTask) => {
                return item.matterId === matterId;
            });

            if (matterTask) {
                if (matterTask.writeOffWipTask && matterTask.writeOffWipTask.fees) {
                    return matterTask.writeOffWipTask.fees;
                } else if (matterTask.billMatterTask && matterTask.billMatterTask.writeOffFees) {
                    return matterTask.billMatterTask.writeOffFees;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        }

        return '';
    };

    const getWriteOffWipDisb = (matterId: number): number | undefined | string => {
        if (matterTasks) {
            let matterTask = matterTasks.find((item: MatterTask) => {
                return item.matterId === matterId;
            });

            if (matterTask) {
                if (matterTask.writeOffWipTask && matterTask.writeOffWipTask.disbursements) {
                    return matterTask.writeOffWipTask.disbursements;
                } else if (matterTask.billMatterTask && matterTask.billMatterTask.writeOffdisbursements) {
                    return matterTask.billMatterTask.writeOffdisbursements;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        }

        return '';
    };

    const getWriteOffWipDebtors = (matterId: number): number | undefined | string => {
        if (matterTasks) {
            let matterTask = matterTasks.find((item: MatterTask) => {
                return item.matterId === matterId;
            });

            if (matterTask) {
                if (matterTask.writeOffDebtorsTask && matterTask.writeOffDebtorsTask.debtors) {
                    return matterTask.writeOffDebtorsTask.debtors;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        }

        return '';
    };

    const getBillFees = (matterId: number): number | undefined | string => {
        if (matterTasks) {
            let matterTask = matterTasks.find((item: MatterTask) => {
                return item.matterId === matterId;
            });

            if (matterTask) {
                if (matterTask.billMatterTask && matterTask.billMatterTask.fees) {
                    return matterTask.billMatterTask.fees;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        }

        return '';
    };

    const getBillDisb = (matterId: number): number | undefined | string => {
        if (matterTasks) {
            let matterTask = matterTasks.find((item: MatterTask) => {
                return item.matterId === matterId;
            });

            if (matterTask) {
                if (matterTask.billMatterTask && matterTask.billMatterTask.disbursements) {
                    return matterTask.billMatterTask.disbursements;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        }

        return '';
    };

    const getApplyTrust = (matterId: number): number | undefined | string => {
        if (matterTasks) {
            let matterTask = matterTasks.find((item: MatterTask) => {
                return item.matterId === matterId;
            });

            if (matterTask) {
                if (matterTask.billMatterTask && matterTask.billMatterTask.applyTrust) {
                    return matterTask.billMatterTask.applyTrust;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        }

        return '';
    };

    const getTransferTrust = (matterId: number): number | undefined | string => {
        if (matterTasks) {
            let matterTask = matterTasks.find((item: MatterTask) => {
                return item.matterId === matterId;
            });

            if (matterTask && matterTask.transferTrustToPayInvoiceTask && matterTask.transferTrustToPayInvoiceTask.transfer) {
                return matterTask.transferTrustToPayInvoiceTask.transfer;
            } else {
                return '';
            }
        }

        return '';
    };

    const getTransferInvoice = (matterId: number): string | undefined => {
        if (matterTasks) {
            let matterTask = matterTasks.find((item: MatterTask) => {
                return item.matterId === matterId;
            });

            if (matterTask && matterTask.transferTrustToPayInvoiceTask && matterTask.transferTrustToPayInvoiceTask.invoiceNumber) {
                return matterTask.transferTrustToPayInvoiceTask.invoiceNumber;
            } else {
                return '';
            }
        }

        return '';
    };

    const getIsClosed = (matterId: number): boolean | undefined => {
        if (matterTasks) {
            let matterTask = matterTasks.find((item: MatterTask) => {
                return item.matterId === matterId;
            });

            if (matterTask) {
                if (matterTask.closeMatterTask && matterTask.closeMatterTask.isCloseMatter) {
                    return matterTask.closeMatterTask.isCloseMatter;
                } else if (matterTask.writeOffWipTask && matterTask.writeOffWipTask.isCloseMatterAfterWriteOff) {
                    return matterTask.writeOffWipTask.isCloseMatterAfterWriteOff;
                } else if (matterTask.writeOffDebtorsTask && matterTask.writeOffDebtorsTask.isCloseMatterAfterWriteOff) {
                    return matterTask.writeOffDebtorsTask.isCloseMatterAfterWriteOff;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        return false;
    };

    const getIsArchived = (matterId: number): boolean | undefined => {
        if (matterTasks) {
            let matterTask = matterTasks.find((item: MatterTask) => {
                return item.matterId === matterId;
            });

            if (matterTask) {
                if (matterTask.archiveMatterTask && matterTask.archiveMatterTask.isArchived) {
                    return matterTask.archiveMatterTask.isArchived;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        return false;
    };

    const getTrustMaxValue = (matterId: number, trustBalance: number, field: string): number => {
        if (matterTasks) {
            let matterTask = matterTasks.find((item: MatterTask) => {
                return item.matterId === matterId;
            });

            let maxTrustAmount = Number(trustBalance);
            let usedTrustAmount = 0;

            if (matterTask) {
                if (matterTask.transferTrustToPayInvoiceTask && matterTask.transferTrustToPayInvoiceTask.transfer && field === 'applyTrust') {
                    usedTrustAmount = usedTrustAmount + Number(matterTask.transferTrustToPayInvoiceTask.transfer);
                }
                if (matterTask.billMatterTask && matterTask.billMatterTask.applyTrust && field === 'transferTrust') {
                    usedTrustAmount = usedTrustAmount + Number(matterTask.billMatterTask.applyTrust);
                }
            }

            if (maxTrustAmount > 0) {
                maxTrustAmount = maxTrustAmount - usedTrustAmount;
                return maxTrustAmount;
            }
        }

        return 0;
    };

    return (
        <MatterManagementContext.Provider
            value={{
                ...contextState,
                batchGuidId,
                matterSummaryHeader,
                selectedMatter,
                currentMatterTask,
                matterTasks,
                menuDisable,
                // processedTaskCount,
                currencyData,
                currentSummaryViewSelectionId,

                setBatchGuidId: setBatchGuidId,
                // setProcessedTaskCount: setProcessedTaskCount,
                setMatterSummaryHeader: setMatterSummaryHeader,
                setSelectedMatter: setSelectedMatter,
                setCurrentMatterTask: setCurrentMatterTask,
                setMatterTasks: setMatterTasks,
                setCurrentSummaryViewSelectionId: setCurrentSummaryViewSelectionId,

                onMatterSelected: onMatterSelected,
                onWriteOffWipSave: onWriteOffWipSave,
                onWriteOffDebtorsSave: onWriteOffDebtorsSave,
                onBillTheMatterSave: onBillTheMatterSave,
                onTransferTustToPlayInvoiceSave: onTransferTustToPlayInvoiceSave,
                onArchiveMatterSave: onArchiveMatterSave,
                onCloseMatterSave: onCloseMatterSave,

                onWriteOffWipOpen: onWriteOffWipOpen,
                onWriteOffWipClosed: onWriteOffWipClosed,
                onWriteOffDebtorsOpen: onWriteOffDebtorsOpen,
                onWriteOffDebtorsClosed: onWriteOffDebtorsClosed,
                onBillTheMatterOpen: onBillTheMatterOpen,
                onBillTheMatterClosed: onBillTheMatterClosed,
                onTransferTrustToPayInvoiceOpen: onTransferTrustToPayInvoiceOpen,
                onTransferTrustToPayInvoiceClosed: onTransferTrustToPayInvoiceClosed,
                onArchiveMatterOpen: onArchiveMatterOpen,
                onArchiveMatterClosed: onArchiveMatterClosed,
                onCloseMatterOpen: onCloseMatterOpen,
                onCloseMatterClosed: onCloseMatterClosed,

                onUnBilledFeesExists: onUnBilledFeesExists,
                onUnBilledDisbursementsExists: onUnBilledDisbursementsExists,
                onDebtorsExists: onDebtorsExists,
                onTrustExists: onTrustExists,

                onHandleTextChange: onHandleTextChange,
                onHandleTextChangeNumeric: onHandleTextChangeNumeric,
                onHandleCheckBoxChange: onHandleCheckBoxChange,
                getTextBoxDisabled: getTextBoxDisabled,
                getFieldDisabled: getFieldDisabled,
                calculateMatterSummaryHeader: calculateMatterSummaryHeader,
                hasTasks: hasTasks,

                getIsCloseDisabled: getIsCloseDisabled,
                getIsCloseDisabledForFeeWriteOffDialog: getIsCloseDisabledForFeeWriteOffDialog,
                getIsCloseDisabledForFeeWriteOffDebtorDialog: getIsCloseDisabledForFeeWriteOffDebtorDialog,

                getWriteOffWipFee: getWriteOffWipFee,
                getWriteOffWipDisb: getWriteOffWipDisb,
                getWriteOffWipDebtors: getWriteOffWipDebtors,
                getBillFees: getBillFees,
                getBillDisb: getBillDisb,
                getApplyTrust: getApplyTrust,
                getTransferTrust: getTransferTrust,
                getTransferInvoice: getTransferInvoice,
                getIsClosed: getIsClosed,
                getIsArchived: getIsArchived,
                getTrustMaxValue: getTrustMaxValue,
                fetchCurrencyDetails: fetchCurrencyDetails,
            }}
        >
            {props.children}
        </MatterManagementContext.Provider>
    );    
};