import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const useOtherPartyQuery = (fetchPolicy?: FetchPolicy) => {
    const { data, error, loading } = useQuery<OtherPartyTypeData>(OtherPartyTypeQuery, {
        fetchPolicy: fetchPolicy
    });

    return {
        data,
        error,
        loading,
    };
};

export interface OtherPartyTypeData {
    lookup: Lookup;
}

export interface Lookup {
    otherPartyType: OtherPartyType[];
}

export interface OtherPartyType {
    description: string;
    sequence:    number;
    guidId:      string;
}

export const OtherPartyTypeQuery = gql`
    query otherPartyType{
        lookup {
            otherPartyType {
                description
                sequence
                guidId
            }
        }
    }
`;