import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Theme,
    createStyles,
    FormControlLabel,
    Button,
    Checkbox,
    FormGroup,
    DialogActions,
    CircularProgress,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { mainTheme } from '../../Theme';
import { useQuery } from 'react-apollo';
import { ActionMessage, LookupActionMessageData, LookupActionMessageQuery } from '../../lookupConfiguration/tabs/LookupMatterFollowUpActionMaintenance';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { client } from '../..';
import { showNotification } from '../../App';
import { MatterDetails } from '../models/Matter';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';
import { LocalStorageEnum } from '../../enums/LocalStorageEnum';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        dialogTitle: {
            width: '100%',
        },
        title: {
            padding: '0 10px 10px 10px',
            fontSize: 16,
            color: mainTheme.BrandColors.TemplatePrimary,
            textAlign: 'center',
        },
        dialogPaper: {
            minWidth: 500,
        },
        formControlWrapper: {
            width: '100%',
        },
        actionMessageWrapper: {
            display: 'flex',
            flexFlow: 'column',
            position: 'relative'
        },
        button: {
            marginBottom: 10,
            textTransform: 'none',
        },
        formGroupWrapper: {
            justifyContent: 'center'
        },
        noteWrapper: {
            textAlign: 'center',
            paddingBottom: 10,
            color: theme.palette.primary.main,
            fontSize: 18
        },
        dialogContentWrapper: {
            position: 'relative'
        },
        loadingWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        floatLoadingWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 1,
        }
    })
);

interface MatterFollowUpDialogProps {
    onClose?: () => void;
    matterData: MatterDetails;
}

interface MatterFollowUpDialogState {
    personActing: boolean;
    personResponsible: boolean;
    data: ActionMessage[];
    isLoading: boolean;
}

// tslint:disable-next-line: no-anyW
export const MatterFollowUpDialog: React.FC<MatterFollowUpDialogProps> = ( props ) => {
    const classes = useStyles();
    
    const lookupActionMessageQuery = useQuery<LookupActionMessageData>(LookupActionMessageQuery);

    const [state, setState] = useState<MatterFollowUpDialogState>({
        personActing: true,
        personResponsible: false,
        data: [],
        isLoading: false
    });

    useEffect(() => {
        if (!lookupActionMessageQuery.error && lookupActionMessageQuery.data && lookupActionMessageQuery.data.lookup ) {
            setState((prevState) => {
                return {
                    ...prevState,
                    // tslint:disable-next-line: max-line-length
                    data: lookupActionMessageQuery.data!.lookup.actionMessage.sort((a, b) => a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0)
                };
            });
        }
    // tslint:disable-next-line: align
    }, [lookupActionMessageQuery.data, lookupActionMessageQuery.error, lookupActionMessageQuery.loading]);

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    // tslint:disable-next-line: no-any
    const onClickMessage = (item: ActionMessage | null) => (event: any) => {
        
        setState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            };
        });

        const hasMatterEmailPersonActing = UserFeature.HasAccess(UserFeatureEnum.hasMatterEmailPersonActing);
        const hasMatterEmailPersonResponsible = UserFeature.HasAccess(UserFeatureEnum.hasMatterEmailPersonResponsible);

        const followUpActionEmailBodyParams: FollowUpActionEmailBodyParams = {
            matterGuidId: props.matterData.matterGuid,
            actionMessageGuidId: item ? item.guidId : null,
            includePersonActing: hasMatterEmailPersonActing ? state.personActing : null,
            includePersonResponsible: hasMatterEmailPersonResponsible ? state.personResponsible : null
        };

        let mailto = '';

        if (state.personResponsible && state.personActing) {
            mailto = `${props.matterData?.responsibleEmail};${props.matterData?.actingEmail}`;
        } else if (state.personActing) {
            mailto = props.matterData?.actingEmail;
        } else if (state.personResponsible) {
            mailto = props.matterData?.responsibleEmail;
        }

        retrieveFollowUpActionEmailBodyData(
            followUpActionEmailBodyParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (bodyData) => onRetrieveData(bodyData, item, mailto),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );

    };

    const onRetrieveData = (bodyData: FollowUpActionEmailBodyData, item: ActionMessage | null, mailto: string | undefined) => {

        setState((prevState) => {

            var mail = document.createElement('a');
            // tslint:disable-next-line: max-line-length
            mail.href = `mailto:${mailto ? mailto : ''}?subject=RE ${encodeURIComponent(props.matterData?.client)} - ${encodeURIComponent(props.matterData?.description)} (${props.matterData?.fileNumber})&body=${bodyData.matterFollowUpActionEmailBody}`;
            mail.click();
            onClose();
            
            return {
                ...prevState,
                isLoading: false
            };
        });
        
    };

    const onChangePerson = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name; 
        const checked = event.target.checked;
        if (name) {
            setState((prevState) => {
                if (name === 'personActing') {
                    if (!checked && !state.personResponsible) {
                        return {
                            ...prevState,
                            personActing: checked,
                            personResponsible: true
                        };
                    }
                } else if (name === 'personResponsible') {
                    if (!checked && !state.personActing) {
                        return {
                            ...prevState,
                            personActing: true,
                            personResponsible: checked
                        };
                    }
                }
                return {
                    ...prevState,
                    [name]: checked
                };
            });
        }
    };

    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            className={classes.root}
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <DialogTitle
                id="form-dialog-title"
                className={classes.dialogTitle}
            >
                <div className={classes.title}>Follow Up Email</div>
                <FormGroup row={true} className={classes.formGroupWrapper}>
                    {UserFeature.HasAccess(UserFeatureEnum.hasMatterEmailPersonActing) && (
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    color="primary" 
                                    name="personActing"
                                    checked={state.personActing}
                                    onChange={onChangePerson}
                                />
                            } 
                            label={localStorage.getItem(LocalStorageEnum.PersonActing || 'Person Acting')} 
                        />
                    )}
                    {UserFeature.HasAccess(UserFeatureEnum.hasMatterEmailPersonResponsible) && (
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    color="primary" 
                                    name="personResponsible"
                                    checked={state.personResponsible}
                                    onChange={onChangePerson}
                                />
                            } 
                            label={localStorage.getItem(LocalStorageEnum.PersonResponsible || 'Person Responsible')} 
                        />
                    )}
                </FormGroup >
            </DialogTitle>
            <DialogContent dividers={true} className={classes.dialogContentWrapper}>
                {!lookupActionMessageQuery.loading ? (
                    <>
                        <div className={classes.noteWrapper}>
                            Please select the required message.
                        </div>
                        <div className={classes.actionMessageWrapper}>
                            {!!state.isLoading && (
                                <div className={classes.floatLoadingWrapper}>
                                    <CircularProgress />
                                </div>
                            )}
                            {state.data.map((item: ActionMessage) => (
                                <Button 
                                    variant="outlined" 
                                    color="primary"
                                    className={classes.button}
                                    onClick={onClickMessage(item)}
                                >
                                    {item.description}
                                </Button>
                            ))}
                            <Button 
                                variant="outlined" 
                                color="primary"
                                className={classes.button}
                                onClick={onClickMessage(null)}
                            >
                                Other
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className={classes.loadingWrapper}>
                        <CircularProgress />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onClose} 
                    color="primary"
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export interface FollowUpActionEmailBodyParams {
    matterGuidId: string | null;
    actionMessageGuidId: string | null;
    includePersonActing: boolean | null;
    includePersonResponsible: boolean | null;
}

export interface FollowUpActionEmailBodyData {
    matterFollowUpActionEmailBody: string;
}

export function retrieveFollowUpActionEmailBodyData(
    query: FollowUpActionEmailBodyParams,
    refreshData: boolean,
    onSuccess: (data: FollowUpActionEmailBodyData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: MatterFollowUpActionEmailBodyQuery,
            variables: {
                matterGuidId: query.matterGuidId,
                actionMessageGuidId: query.actionMessageGuidId,
                includePersonActing: query.includePersonActing,
                includePersonResponsible: query.includePersonResponsible
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const MatterFollowUpActionEmailBodyQuery = gql`
    query MatterFollowUpActionEmailBody($matterGuidId: String, $actionMessageGuidId: String, $includePersonActing: Boolean, $includePersonResponsible: Boolean) {
        matterFollowUpActionEmailBody(matterGuidId: $matterGuidId, actionMessageGuidId: $actionMessageGuidId, includePersonActing: $includePersonActing, includePersonResponsible: $includePersonResponsible)
    }
`;