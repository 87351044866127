import React from 'react';
import {
    makeStyles,
    Theme,
    createStyles,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { mainTheme } from '../../Theme';
import { Form, Field, AnyObject, FieldInputProps, FormSpy } from 'react-final-form';
// import moment from 'moment';
import { CurrencyDisplayStyle } from '../../components/DataDisplay/CurrencyDisplayStyle';
import { FormattedCurrency } from '../../components/DataDisplay/FormattedCurrency';
import { CurrencyDetails } from '../cellRenderer/MatterTaskAction';
import { client } from '../..';
import { CloseMatterRequestMutation, UpdateCloseMatterRequestMutation } from '../MatterSummaryRepository';
import { showNotification } from '../../App';
import { MatterGroupField, MatterGroupFields } from '../fields/MatterGroupField';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { DialogTitleExtension } from '../common/DialogTitleExtension';
import moment from 'moment';
import { TaskAssignedByUserTeamSelector } from '../../tasksSummary/selectors/TaskAssignedByUserTeamSelector';
import { AllocatedToEntityType } from '../../tasksSummary/AllocatedToEntityType';
import { FormApi } from 'final-form';
import { TaskAssignedBySelector } from '../../tasksSummary/selectors/TaskAssignedBySelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        textField: {
            // width: '100%',
        },
        commendField: {
            width: '100%',
        },
        dialogTitle: {
            color: mainTheme.TemplateColor.Primary,
        },
        customGrid: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        generatedContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 -10px',
            flex: 1,
            '& .col': {
                padding: '0 10px',
            },
            '& .value': {
                fontWeight: 600,
            },
        },
        gridCenter: {
            display: 'flex',
            alignItems: 'center'
        }
    })
);

export interface CloseMatter extends MatterGroupFields {
    isCloseMatter?: boolean;
    comments?: string;

    assignedTo: IAutoCompleteItem | null;
    allocatedToEntityType: number | null;
    owner: IAutoCompleteItem | null;
}

interface CloseMatterDialogProps {
    currencyDetails: CurrencyDetails;
    matterId: number;
    matterGuid: string;
    matterTitle: string;
    client: string;
    onDialogClose?: () => void;
    loggedInUser: IAutoCompleteItem | null;
    defaultPriority: IAutoCompleteItem | null;

    billedFees?: number;

    // Saved values
    taskGuid?: string;
    formAction: 'create' | 'edit';
    closeMatter?: boolean | null;
    comments?: string | null;
    priority?: IAutoCompleteItem | null;
    taskCategory?: IAutoCompleteItem | null;
    dueDate?: Date | null;
    estimatedEffort?: string | null;
    startDate?: Date | null;
    completionDate?: Date | null;
    duration?: string | null;
    assignedBy?: IAutoCompleteItem | null;
    completionNotes?: string | null;

    assignedTo?: IAutoCompleteItem | null;
    owner?: IAutoCompleteItem | null;
    allocatedToEntityType?: number | null;
}

// tslint:disable-next-line: no-anyW
export const CloseMatterDialog: React.FC<CloseMatterDialogProps> = ( props ) => {
    const classes = useStyles();

    const onSubmit = (values: CloseMatter) => {
        if (props.formAction === 'create') {
            closeMatterRequest(values);
        } else {
            updateCloseMatterRequest(values);
        }

        onCloseMatterClosed();
    };

    const closeMatterRequest = (closeMatterTask: CloseMatter) => {
        client
            .mutate({
                mutation: CloseMatterRequestMutation,
                variables: {
                    matterGuid: props.matterGuid,
                    close: closeMatterTask.isCloseMatter,
                    comments: closeMatterTask.comments,
                    taskInput: getTaskInput(closeMatterTask)
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error !== undefined) {
                        showNotification('Failed to Request Close Matter', results.data.error, 'error');
                    } else {
                        showNotification(null, 'Successfully submitted', 'info'); 
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Request Close Matter', reason, 'error');
            });
    };

    const updateCloseMatterRequest = (closeMatterTask: CloseMatter) => {
        client
            .mutate({
                mutation: UpdateCloseMatterRequestMutation,
                variables: {
                    taskRequestGuid: props.taskGuid,
                    matterGuid: props.matterGuid,
                    close: closeMatterTask.isCloseMatter,
                    comments: closeMatterTask.comments,
                    taskInput: getTaskInput(closeMatterTask)
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error !== undefined) {
                        showNotification('Failed to Update Close Matter Request', results.data.error, 'error');
                    } else {
                        showNotification(null, 'Successfully submitted', 'info'); 
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Update Close Matter Request', reason, 'error');
            });
    };

    const getTaskInput = (task: CloseMatter) => {
        let ownerGuid = task.owner 
            ? task.owner.value 
            : task.allocatedToEntityType === AllocatedToEntityType.user 
                ? (task.assignedTo ? task.assignedTo.value : null)
                : (task.assignedBy ? task.assignedBy.value : null);

        return {
            description: '',
            priority: task.priority?.label ? task.priority?.label : null,
            category: task.taskCategory?.label ? task.taskCategory?.label : null,
            dueDate: task.dueDate ? task.dueDate : null,
            estimatedEffort: task.estimatedEffort ? task.estimatedEffort : null,
            startDate: task.startDate ? task.startDate : null,
            completionDate: task.completionDate ? task.completionDate : null,
            duration: task.duration ? task.duration : null,
            assignedBy: task.assignedBy?.value,
            completionNotes: task.completionDate ? task.completionNotes : null,
            assignedTo: task.assignedTo?.value ? task.assignedTo?.value : null,
            owner: ownerGuid,
            allocatedToEntityType: task.allocatedToEntityType,
        };
    };

    const onCloseMatterClosed = () => {
        if (props.onDialogClose) {
            props.onDialogClose();
        }
    };

    const getInitialValue = () => {
        if (props.formAction === 'create') {
            return {
                isCloseMatter: false,
                comments: '',
                priority: props.defaultPriority,
                assignedBy: props.loggedInUser,

                assignedTo: null,
                owner: null,
                allocatedToEntityType: null,
            };
        } else {
            return {
                isCloseMatter: props.closeMatter,
                comments: props.comments,
                priority: props.priority ? props.priority : props.defaultPriority,
                taskCategory: props.taskCategory,
                dueDate: props.dueDate ? moment(props.dueDate).toDate() : null,
                estimatedEffort: props.estimatedEffort,
                startDate: props.startDate ? moment(props.startDate).toDate() : null,
                completionDate: props.completionDate ? moment(props.completionDate).toDate() : null,
                completionNotes: props.completionNotes,
                duration: props.duration,
                assignedBy: props.assignedBy ? props.assignedBy : props.loggedInUser,

                assignedTo: props.assignedTo,
                owner: props.owner,
                allocatedToEntityType: props.allocatedToEntityType,
            };
        }        
    };

    // tslint:disable-next-line: no-any
    const onDropDownSelect = (form: FormApi<CloseMatter>, values: AnyObject, input: FieldInputProps<any, HTMLElement>, 
                              // tslint:disable-next-line: no-any
                              selection: IAutoCompleteItem | any, name: string, isUser: boolean) => {
        if (name === 'assignedTo') {
            let assignedTo = selection && selection.value && selection.label 
                                ? { value : selection.value, label: selection.label }
                                : null;
            form.batch(() => {
                form.change('assignedTo', assignedTo);
                form.change('allocatedToEntityType', isUser 
                    ? AllocatedToEntityType.user 
                    : AllocatedToEntityType.team
                );                
            });
        }
    };

    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={getInitialValue()}
                subscription={{submitting: true, pristine: true}}
                render={({handleSubmit, form, submitting, pristine, values}) => (
                   <form onSubmit={event => handleSubmit(event)} id="closeMatterDialog">
                        <Dialog
                            open={true}
                            onClose={onCloseMatterClosed}
                            aria-labelledby="form-dialog-title"
                            className={classes.root}
                        >
                            <DialogTitle
                                id="form-dialog-title"
                                className={classes.dialogTitle}
                            >
                                <DialogTitleExtension 
                                    title="Close Matter"
                                    subTitle={props.client}
                                    description={props.matterTitle}
                                />
                            </DialogTitle>
                            <DialogContent dividers={true}>
                                <div>
                                    <Grid container={true} spacing={1}>
                                        <Grid item={true} xs={6}>
                                            <Grid container={true} spacing={1}>
                                                <Grid item={true} xs={12}>
                                                    <Field
                                                        name="isCloseMatter"
                                                        subscription={{ touched: true, error: true, value: true}}
                                                    >
                                                        {({ input, meta }) => (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox 
                                                                        name="isCloseMatter" 
                                                                        onChange={(event) => input.onChange(event.target.checked)}
                                                                        checked={input.value}
                                                                        // disabled={props.formAction === 'edit'}
                                                                    />
                                                                }
                                                                label="Close Matter"
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item={true} xs={6} className={classes.gridCenter}>
                                            <Grid container={true} spacing={1}>
                                                <Grid item={true} xs={12}>
                                                    <div className={classes.generatedContainer} >
                                                        <div className="col label">
                                                            Billed Fees:
                                                        </div>
                                                        <div className="col value">
                                                            <FormattedCurrency
                                                                value={props.billedFees ? props.billedFees : 0}
                                                                displayStyle={CurrencyDisplayStyle.Decimal} 
                                                                format={props.currencyDetails.currency}
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <Field
                                                name="comments"
                                                subscription={{ touched: true, error: true, value: true}}
                                            >
                                                {({ input, meta }) => (
                                                    <TextField
                                                        {...input}
                                                        label="Comments"
                                                        multiline={true}
                                                        className={classes.commendField}
                                                        // disabled={props.formAction === 'edit'}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <Grid container={true} spacing={1}>
                                                <Grid item={true} xs={6}>
                                                    <Field
                                                        name="assignedTo"
                                                        // validate={form.getFieldState('assignTo')?.value !== null ? required : undefined}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TaskAssignedByUserTeamSelector
                                                                {...input}
                                                                label="Assigned To"
                                                                value={input.value ? input.value : null}
                                                                disablePortal={false}
                                                                // required={true}
                                                                className={`${classes.textField}`}
                                                                error={meta.error && meta.touched}
                                                                // tslint:disable-next-line: jsx-alignment
                                                                onSelection={(selection: IAutoCompleteItem, name: string, isUser: boolean) => 
                                                                    onDropDownSelect(form, values, input, selection, name, isUser)
                                                                }
                                                                // required={true}
                                                                helperText={
                                                                    meta.error &&
                                                                    meta.touched
                                                                        ? 'Assigned to is required'
                                                                        : ''
                                                                }
                                                                visible={{
                                                                    role: true
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item={true} xs={6}>
                                                    <Field
                                                        name="owner"
                                                    >
                                                        {({ input, meta }) => (
                                                            <TaskAssignedBySelector
                                                                {...input}
                                                                label="Owner"
                                                                value={input.value ? input.value : null}
                                                                disablePortal={false}
                                                                // required={true}
                                                                className={`${classes.textField} `}
                                                                error={meta.error && meta.touched}
                                                                // tslint:disable-next-line: jsx-alignment
                                                                onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                                    input.onChange(selection)
                                                                }
                                                                helperText={
                                                                    meta.error &&
                                                                    meta.touched
                                                                        ? 'Owner to is required'
                                                                        : ''
                                                                }
                                                                visible={{
                                                                    role: true
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <FormSpy subscription={{values: true, validating: true}} >
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values }) => ( 
                                                    <MatterGroupField 
                                                        values={values} 
                                                        formAction={props.formAction}
                                                        allocatedToEntityType={values.allocatedToEntityType}
                                                    />   
                                                )}
                                            </FormSpy> 
                                        </Grid>
                                    </Grid>
                                </div>
                                {/* {printJson(values)} */}
                            </DialogContent>
                            <DialogActions>
                                <Button 
                                    disabled={submitting || pristine} 
                                    color="primary"
                                    type="submit"
                                    onClick={() => form.submit()} 
                                >
                                    {props.formAction === 'create' ? 'Save' : 'Update'}
                                </Button>
                                <Button 
                                    onClick={() => form.reset()} 
                                    disabled={submitting || pristine} 
                                    color="primary"
                                >
                                    Reset
                                </Button>
                                <Button 
                                    onClick={onCloseMatterClosed} 
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

// tslint:disable-next-line: no-any
// function printJson(values: any) {
//     if (values) {
//         return (
//             <>
//                 <pre>
//                     {JSON.stringify(values, undefined, 2)}
//                 </pre>
//             </>
//         );
//     } else {
//         return (
//             <>
//                 <FormSpy subscription={{ values: true }}>
//                     {/* tslint:disable-next-line: no-shadowed-variable */}
//                     {({ values }) => (
//                         <pre>
//                             {JSON.stringify(values, undefined, 2)}
//                             {/* <RenderCount /> */}
//                         </pre>
//                     )}
//                 </FormSpy>
//             </>
//         );
//     }
// }