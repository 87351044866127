import * as React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import glamorous from 'glamorous';
import { RvLoader } from '../../components/Loader';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Scale, Tick, Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { convertStringNumberToNumber, formatNumber, getLabels, getMaxTickLimit, getSuggestedMaxTick } from '../../helpers/ChartHelper';
import { MatterTerminology } from '../../MatterTerminology';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, zoomPlugin);

const BarWrapper = glamorous.div<{ theme?: object }>((props) => ({
    position: 'relative',
    paddingBottom: '10px',
    flex: 1,
    display: 'flex',
    flexFlow: 'column',
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100%',
    textAlign: 'center',
}));

const BarContainer = glamorous.div<{ theme?: object }>((props) => ({
    padding: '5px 15px 15px 15px',
    // height: '100vh',
    minHeight: '300px',
    flex: 1,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '400px',
    maxWidth: 'auto',
    position: 'relative',
    [`& .${`titleWrapper`}`]: {
        textAlign: 'center',
        display: 'inline-block',
        width: '100%',
        padding: '15px',
        [`& .${`title`}`]: {
            fontSize: '18px',
            fontWeight: 600,
            display: 'block',
            marginBottom: '15px',
        },
        [`& .${`value`}`]: {
            fontSize: '24px',
            fontWeight: 600,
            display: 'block',
            marginBottom: '15px',
        },
        [`& .${`secondary-title`}`]: {
            fontSize: '16px',
            fontWeight: 400,
            display: 'block',
            marginBottom: '15px',
        },
    },
}));

const LoadWrapper = glamorous.div<{ theme?: object }>((props) => ({
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
}));

interface ChartProps {
    getSelectedItem?: (selected: string) => void;
    // tslint:disable-next-line: no-any
    labels: any;
    // tslint:disable-next-line: no-any
    data: any;
}

interface ChartState {
    //
}

export default class FinancialByDayChart extends React.Component<ChartProps, ChartState> {
    // tslint:disable-next-line: no-any
    public chartRef: React.RefObject<any>;

    constructor(props: ChartProps, state: ChartState) {
        super(props, state);
        this.formatNumber = this.formatNumber.bind(this);
        this.onPan = this.onPan.bind(this);

        this.chartRef = React.createRef();
    }

    public componentDidMount() {
        //
    }

    public componentWillUnmount() {
        //
    }

    public render() {
        return (
            <React.Fragment>
                <BarWrapper>
                    <BarContainer>
                        {/* tslint:disable-next-line: no-any */}
                        <Query<any> query={CurrencySymbol}>
                            {({
                                // tslint:disable-next-line: no-shadowed-variable
                                loading,
                                error,
                                data,
                            }) => {
                                if (loading) {
                                    return (
                                        <LoadWrapper className="load-wrapper">
                                            <RvLoader size="small" />
                                        </LoadWrapper>
                                    );
                                }

                                const currencySymbol = loading || error ? null : data.options.currencySymbol;

                                const symbol = currencySymbol ? currencySymbol.symbol : null;
                                return (
                                    <Bar
                                        ref={this.chartRef}
                                        // tslint:disable-next-line: no-any
                                        onClick={(event: React.MouseEvent<any>) => {
                                            const _data = getElementAtEvent(this.chartRef.current, event);
                                            if (_data.length > 0 && this.props.getSelectedItem) {
                                                const _index = getElementAtEvent(this.chartRef.current, event)[0].index;

                                                const item = this.props.labels[_index];

                                                if (this.props.getSelectedItem) {
                                                    return this.props.getSelectedItem(item);
                                                }
                                            }
                                        }}
                                        data={{
                                            labels: getLabels(this.props.labels),
                                            datasets: [
                                                {
                                                    label: 'label here',
                                                    backgroundColor: ['#0078D4', '#28d60d', '#fac43c', '#FF6384'],
                                                    borderColor: ['#0078D4', '#28d60d', '#fac43c', '#FF6384'],
                                                    borderWidth: 1,
                                                    hoverBackgroundColor: ['#0078D4', '#28d60d', '#fac43c', '#FF6384'],
                                                    hoverBorderColor: ['#0078D4', '#28d60d', '#fac43c', '#FF6384'],
                                                    data: this.props.data,
                                                },
                                            ],
                                        }}
                                        options={{
                                            indexAxis: 'y' as const,
                                            elements: {
                                                bar: {
                                                    borderWidth: 2,
                                                },
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            scales: {
                                                x: {
                                                    // beginAtZero: true,
                                                    suggestedMax: getSuggestedMaxTick(this.props.data),
                                                    beginAtZero: true,
                                                    ticks: {
                                                        maxTicksLimit: getMaxTickLimit(this.props.data),
                                                        callback: function (this: Scale, tickValue: number, index: number, ticks: Tick[]) {
                                                            return `${symbol}${formatNumber(tickValue)}`;
                                                        },
                                                    },
                                                },
                                                //   y: {
                                                //     min: 0,
                                                //     max: 9 // minimum display
                                                //   }
                                            },
                                            plugins: {
                                                legend: {
                                                    position: 'bottom' as const,
                                                    display: false,
                                                },
                                                title: {
                                                    display: true,
                                                    text: MatterTerminology.TotalOwing,
                                                },
                                                zoom: {
                                                    pan: {
                                                        enabled: true,
                                                        onPan: this.onPan,
                                                    },
                                                    zoom: {
                                                        wheel: {
                                                            enabled: true,
                                                        },
                                                    },
                                                    // limits: {
                                                    //   y: { min: 0, max: 9 },
                                                    // },
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        // tslint:disable-next-line: no-any
                                                        label: function (context: any) {
                                                            const {
                                                                //   dataIndex,
                                                                //   label,
                                                                formattedValue,
                                                                // dataset,
                                                            } = context;

                                                            var currentValue = convertStringNumberToNumber(formattedValue);

                                                            return `${symbol}${formatNumber(currentValue)}`;
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                );
                            }}
                        </Query>
                    </BarContainer>
                </BarWrapper>
            </React.Fragment>
        );
    }

    private formatNumber(value: number) {
        return value
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    private onPan = (context: { chart: Chart }) => {
        this.chartRef.current.options.scales.x.min = 0;
        this.chartRef.current.update();
    }
}

const CurrencySymbol = gql`
    query CurrencySymbol {
        options {
            currencySymbol {
                symbol
            }
        }
    }
`;
