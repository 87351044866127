import React, { useEffect }  from 'react';
import {
    makeStyles,
    Theme,
    createStyles,
    Button,
    Drawer,
    Typography,
    Paper,
} from '@material-ui/core';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, GridOptions, GridReadyEvent } from '@ag-grid-enterprise/all-modules';
import { defaultExcelStyles } from '../MatterSummaryColumns';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { RvLoader } from '../../components/Loader';
import moment from 'moment';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { formatNumber } from '../../helpers/ChartHelper';
import { showNotification } from '../../App';
import { useCurrencySymbol } from '../../hooks/useCurrencySymbol';
import { MatterForecastSubscription, MatterForecastSubscriptionData } from '../MatterSummaryRepository';
import { client } from '../..';
import { mainTheme } from '../../Theme';
import { useMatterSummaryContext } from '../MatterSummaryContextProvider';
import InfoIcon from '@material-ui/icons/Info';
import { LightTooltip } from '../../helpers/commonHelper';
import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined';
import { useHistory } from 'react-router-dom';
import { LearnMoreEnum } from '../../learnMore/LearnMorePage';
import { FetchResult } from 'apollo-link';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '1000px',
            padding: '15px',
            minWidth: '900px',
        },
        buttonWrapper: {
            paddingTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '10px',
        },
        loaderWrapper: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        agWrapper: {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            gap: '10px',
            '& .ag-theme-material': {
                flex: 1
            },
            '& .ag-status-bar': {
                border: 'none',
            }
        },
        infoBody: {
            display: 'flex',
            justifyContent: 'space-between',
            gap: '10px',
            alignItems: 'flex-start',
        },
        learnMoreContainer: {
            padding: '10px',
        },
        infoContainer: {
            display: 'flex',
            flexFlow: 'column',
            gap: '5px',
            paddingBottom: '10px',
        },
        infoWrapper: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            fontSize: '14px',
        },
        infoTitle: {
            fontWeight: 'bold',
            // color: mainTheme.TemplateColor.Primary,
        },
        infoValue: {
            // color: mainTheme.TemplateColor.Primary,
        },
        cardContainer: {
            display: 'flex',
            flexFlow: 'column', 
            gap: '10px',
        },
        cardWrapper: {
            display: 'flex', 
            flexFlow: 'row wrap',
            alignItems: 'center', 
            gap: '10px',
            flex: 1,
        },
        card: {
            flex: 1,
            padding: '25px 15px',
            display: 'flex',
            flexFlow: 'column',
            gap: '2px',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '230px',
            height: '100%',
            textAlign: 'center',
            position: 'relative',
        },
        cardTitle: {
            color: mainTheme.TemplateColor.Primary,
        },
        cardValue: {
            fontSize: '16px',
            // fontWeight: 600,
        },
        cardInfo: {
            top: '5px',
            right: '5px',
            position: 'absolute',
            color: 'orange'
        },
        tooltip: {
            fontSize: '14px',
            padding: '10px',
            fontFamily: 'Nunito, Arial, Helvetica, sans-serif'
        }
    })
);

interface MatterForecastsDrawerProps {
    matterId: number;
    matterGuid: string;
    open?: boolean;
    onClose?: () => void;
}

interface CellChange extends MatterForecast {

}

// tslint:disable-next-line: no-anyW
export const MatterForecastsDrawer: React.FC<MatterForecastsDrawerProps> = ( props ) => {
    const classes = useStyles();

    const history = useHistory();

    const { pageKey, refreshPage } = useMatterSummaryContext();
    
    const [changes, setChanges] = React.useState<CellChange[]>([]);

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const [showConfirm, setShowConfirm] = React.useState<boolean>(false);

    const currencyQuery = useCurrencySymbol();

    const forecastQuery = useQuery<MatterForecastData, MatterForecastParams>(MatterForecastQuery, {
        variables: {
            matterId: props.matterId
        }
    });

    const [forecastMutate, forecastResult] = useMutation<MutationMatterData, MutationMatterParams>(MatterForecastMutation);

    useEffect(() => {
        // console.log('hook key', pageKey);
    // tslint:disable-next-line: align
    }, [pageKey]);

    useEffect(() => {
        if (forecastResult.data?.matter.matterForecast.update.status) {
            showNotification(null, 'Successfully updated', 'info'); 
            setIsSubmitting(false);
            setChanges([]);
        }
        if (forecastResult.error) {
            showNotification(null, forecastResult.error.message, 'error');
            setIsSubmitting(false);
        }
    // tslint:disable-next-line: align
    }, [forecastResult.data, forecastResult.loading, forecastResult.error]);

     // Create Subscription - Ran only once
     useEffect(() => {

        const subcribeForecastObj = subscribeToForecast();

        // Cleanup
        return () => {
            subcribeForecastObj.unsubscribe();
        };
    // tslint:disable-next-line: align
    }, []);

    const subscribeToForecast = () => {
        return (
            client
                .subscribe<MatterForecastSubscriptionData>({
                    fetchPolicy: 'network-only',
                    query: MatterForecastSubscription,
                    variables: {},
                })
                .subscribe({
                    // tslint:disable-next-line:no-any
                    next(results: FetchResult<MatterForecastSubscriptionData>) {
                        if (results.data?.updateMatterForecastNotification.status) {
                            showNotification('Success', results.data.updateMatterForecastNotification.message, 'info');
                            forecastQuery.refetch({
                                matterId: props.matterId
                            });

                            // refresh page
                            refreshPage();
                        } else {

                            if (!results.data) {
                                return;
                            }
                            
                            showNotification('Failed', results.data.updateMatterForecastNotification.message, 'error');
                        }
                    },
                    // tslint:disable-next-line:no-any
                    error(err: any) {
                        // tslint:disable-next-line:no-console
                        console.error(err);
                        showNotification(null, err, 'error');
                    },
                })
        );
    };

    const onSave = () => {

        setIsSubmitting(true);

        const tempChanges: MatterForecastDetailInput[] = changes.map(change => {
            return {
                guid: change.guid,
                startDate: change.startDate,
                periodType: change.periodType,
                feesBilled: change.feesBilled,
                feesCollected: change.feesCollected,
                hours: change.hours,
                // matterId: props.matterId
            };
        });

        forecastMutate({
            variables: {
                input: {
                    matterGuid: props.matterGuid,
                    matterForecasts: tempChanges
                }
            }
        });     
    };

    const onCloseConfirm = (agree: boolean) => {
        if (agree && props.onClose) {
            props.onClose();
        }
        setShowConfirm(false);
    };

    const onClose = () => {
        if (changes.length > 0) {
            setShowConfirm(true);
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const gridOptions: GridOptions = {
        // rowModelType: this.state.isInMemory ? 'clientSide' : 'serverSide',
        rowBuffer: 0,
        cacheOverflowSize: 5,
        maxConcurrentDatasourceRequests: 1,
        maxBlocksInCache: 100, // How many blocks to cache in the client.
        suppressPropertyNamesCheck: true,
        // defaultColDef: {
        //     sortable: this.state.isInMemory,
        //     resizable: true,
        //     filter: this.state.isInMemory,
        // },
        excelStyles: defaultExcelStyles,
        // getContextMenuItems: this.getContextMenuItems,
        suppressAggFuncInHeader: true,
        groupIncludeTotalFooter: false,
        // groupSuppressBlankHeader: true,
        rowClass: 'custom-ag-selected',
        suppressAutoSize: true,
    };

    // const generateRowData = () => {
    //     const rowData = [];
    //     const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
    //     for (let year = 2023; year <= 2025; year++) {
    //         for (let month of months) {
    //             rowData.push({
    //                 month: `${month} ${year}`,
    //                 hours: Math.floor(Math.random() * 100),
    //                 feesBilled: Math.floor(Math.random() * 200),
    //                 feesCollected: Math.floor(Math.random() * 150),
    //             });
    //         }
    //     }
    
    //     return rowData;
    // };

    const onGridReady = (params: GridReadyEvent) => {

        const rowData = forecastQuery.data?.matter.forecasts.matterForecast || [];
        
        params.api.setRowData(forecastQuery.data?.matter.forecasts.matterForecast || []);
        
        const currentMonthYear = moment().format('YYYY-MM');

        const rowIndex = rowData.findIndex(row => {
            const rowYearMonth = moment(row.startDate).format('YYYY-MM');
            return rowYearMonth === currentMonthYear;
        });

        if (rowIndex !== -1) {
            params.api.ensureIndexVisible(rowIndex, 'top');
            params.api.setFocusedCell(rowIndex, 'month');
        }
    };

    // tslint:disable-next-line: no-any
    const onCellValueChanged = (params: any) => {

        const existingChangeIndex = changes.findIndex(change => change.startDate === params.data.startDate);

        if (existingChangeIndex !== -1) {
            // If a change for the same startDate exists, update it
            setChanges(prevChanges => prevChanges.map((change, index) => 
                index === existingChangeIndex ? params.data : change
            ));
        } else {
            // If no change for the same startDate exists, add a new one
            setChanges(prevChanges => [...prevChanges, params.data]);
        }

    };

    const onLearnMore = () => {
        history.push({
            pathname: `learnMore/${LearnMoreEnum[LearnMoreEnum.MatterForecast]}`, 
        });
    };

    const initializeComponent = () => {
        if (forecastQuery.loading || currencyQuery.loading) {
            return (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            );
        } else {

            // tslint:disable-next-line: no-shadowed-variable
            const { client, fileNumber, title, financials, forecasts } = forecastQuery.data?.matter || {};

            const symbol = currencyQuery.data?.options.currencySymbol.symbol;

            const feeEstimate = financials ? financials.feeEstimate : 0;
            const billedTimeAndFees = financials ? financials.billedTimeAndFees : 0;
            const unbilledTimeAndFees = financials ? financials.unbilledTimeAndFees : 0;
            const unbilledDisbursements = financials ? financials.unbilledDisbursements : 0;
            const { totalFutureFeesBilled, totalFutureFeesCollected, totalFutureHours } = forecasts || {};
            
            const remianingEstimate = feeEstimate - billedTimeAndFees;

            const isEstimatedFeesRed = billedTimeAndFees > feeEstimate;

            let isRemaningEstimateRed = false;

            let isFeesCollectedRed = false;
            
            if (totalFutureFeesBilled) {
                isRemaningEstimateRed = totalFutureFeesBilled > remianingEstimate;
            }

            if (totalFutureFeesCollected && totalFutureFeesBilled) {
                isFeesCollectedRed = totalFutureFeesBilled !== totalFutureFeesCollected;
            }

            return (
                <>
                    <div className={classes.agWrapper}>
                        <div className={classes.infoBody}>
                            <div className={classes.infoContainer}>
                                <Typography
                                    style={{ fontSize: '24px', fontWeight: 'bold' }}
                                >
                                    {title}
                                </Typography>
                                <div className={classes.infoWrapper}>
                                    <div className={classes.infoTitle}>{fileNumber}</div>
                                    <div className={classes.infoValue}>{client}</div>
                                </div>
                            </div>
                            <div className={classes.learnMoreContainer}>
                                <Button 
                                    variant="text" 
                                    color="primary" 
                                    size="small" 
                                    onClick={onLearnMore} 
                                    startIcon={<VideoLibraryOutlinedIcon />}
                                >
                                    Learn More
                                </Button>
                            </div>
                        </div>
                        <div className={classes.cardContainer}>
                            <div className={classes.cardWrapper}>
                                <Paper
                                    elevation={3}
                                    className={classes.card}
                                >
                                    {isEstimatedFeesRed ? (
                                        <LightTooltip 
                                            classes={{
                                                tooltip: classes.tooltip
                                            }}
                                            title="Billed time & fees exceeds estimate"
                                        >
                                            <InfoIcon 
                                                className={classes.cardInfo}
                                            />
                                        </LightTooltip>
                                    ) : null}
                                    <Typography className={classes.cardTitle}>Estimated Fees</Typography>
                                    <Typography 
                                        className={classes.cardValue}
                                        style={{
                                            color: isEstimatedFeesRed ? 'red' : 'inherit'
                                        }}
                                    >
                                        {`${symbol}${formatNumber(feeEstimate, 0)}`}
                                    </Typography>
                                </Paper>
                                <Paper
                                    elevation={3}
                                    className={classes.card}
                                >
                                    <Typography className={classes.cardTitle}>Billed Time & Fees</Typography>
                                    <Typography className={classes.cardValue}>{`${symbol}${formatNumber(billedTimeAndFees, 0)}`}</Typography>
                                </Paper>
                                <Paper
                                    elevation={3}
                                    className={classes.card}
                                >
                                    {isRemaningEstimateRed ? (
                                        <LightTooltip 
                                            classes={{
                                                tooltip: classes.tooltip
                                            }}
                                            title="Total forecast billed fees exceeds remaining fee estimate"
                                        >
                                            <InfoIcon 
                                                className={classes.cardInfo}
                                            />
                                        </LightTooltip>
                                    ) : null}
                                    <Typography className={classes.cardTitle}>Remaining Estimate</Typography>
                                    <Typography 
                                        className={classes.cardValue}
                                        style={{
                                            color: isRemaningEstimateRed ? 'red' : 'inherit'
                                        }}
                                    >
                                        {`${symbol}${formatNumber(remianingEstimate >= 0 ? remianingEstimate : 0, 0)}`}
                                    </Typography>
                                </Paper>
                                <Paper
                                    elevation={3}
                                    className={classes.card}
                                >
                                    <Typography className={classes.cardTitle}>Total Fees Billed Forecast</Typography>
                                    <Typography className={classes.cardValue}>{`${symbol}${formatNumber(totalFutureFeesBilled || 0, 0)}`}</Typography>
                                </Paper>
                            </div>
                            <div className={classes.cardWrapper}>
                                <Paper
                                    elevation={3}
                                    className={classes.card}
                                >
                                    <Typography className={classes.cardTitle}>Unbilled Time & Fees</Typography>
                                    <Typography className={classes.cardValue}>{`${symbol}${formatNumber(unbilledTimeAndFees, 0)}`}</Typography>
                                </Paper>
                                <Paper
                                    elevation={3}
                                    className={classes.card}
                                >
                                    <Typography className={classes.cardTitle}>Unbilled Disbursements</Typography>
                                    <Typography className={classes.cardValue}>{`${symbol}${formatNumber(unbilledDisbursements, 0)}`}</Typography>
                                </Paper>
                                <Paper
                                    elevation={3}
                                    className={classes.card}
                                >
                                    <Typography className={classes.cardTitle}>Total Hours Forecast</Typography>
                                    <Typography className={classes.cardValue}>{`${formatNumber(totalFutureHours || 0, 2)}`}</Typography>
                                </Paper>
                                <Paper
                                    elevation={3}
                                    className={classes.card}
                                >
                                    {isFeesCollectedRed ? (
                                        <LightTooltip 
                                            classes={{
                                                tooltip: classes.tooltip
                                            }}
                                            title="Total Fees Collected Forecast is different from the Total Fees Billed Forecast"
                                        >
                                            <InfoIcon 
                                                className={classes.cardInfo}
                                            />
                                        </LightTooltip>
                                    ) : null}
                                    <Typography className={classes.cardTitle}>Total Fees Collected F'cast</Typography>
                                    <Typography 
                                        className={classes.cardValue}
                                        style={{
                                            color: isFeesCollectedRed ? 'red' : 'inherit'
                                        }}
                                    >
                                        {`${symbol}${formatNumber(totalFutureFeesCollected || 0, 0)}`}
                                    </Typography>
                                </Paper>
                            </div>
                        </div>
                        <div className="ag-theme-material">
                            <AgGridReact
                                modules={AllModules} // This is required for Infinite scrolling
                                gridOptions={gridOptions}
                                onGridReady={onGridReady}
                                // onRowValueChanged={onRowValueChanged}
                                onCellValueChanged={onCellValueChanged}
                                columnDefs={[
                                    {
                                        headerName: 'Month',
                                        field: 'startDate',
                                        valueFormatter: params => {
                                            const date = new Date(params.value);
                                            return `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
                                        }
                                    },
                                    {
                                        headerName: 'Hours',
                                        field: 'hours',
                                        editable: true,
                                        filter: 'agNumberColumnFilter',
                                        valueParser: params => {
                                            const newValue = Number(params.newValue);
                                            if (isNaN(newValue)) {
                                                return params.oldValue;
                                            } else if (params.newValue >= 744) {
                                                return 744;
                                            } else {
                                                return params.newValue;
                                            }
                                        },
                                        valueFormatter: params => {
                                            if (isNaN(params.value) || params.value === 0) {
                                                return '';
                                            } else {
                                                return `${formatNumber(Number(params.value), 2)}`;
                                            }
                                        }
                                    },
                                    {
                                        headerName: 'Fees Billed',
                                        field: 'feesBilled',
                                        editable: true,
                                        filter: 'agNumberColumnFilter',
                                        valueParser: params => {
                                            const newValue = Number(params.newValue);
                                            if (isNaN(newValue)) {
                                                return params.oldValue;
                                            } else if (params.newValue.length > 9) {
                                                return 999_999_999.99;
                                            } else {
                                                return params.newValue;
                                            }
                                        },
                                        valueFormatter: params => {
                                            if (isNaN(params.value) || params.value === 0) {
                                                return '';
                                            } else {
                                                return `${symbol ?? '$'}${formatNumber(Number(params.value))}`;
                                            }
                                        }
                                    },
                                    {
                                        headerName: 'Fees Collected',
                                        field: 'feesCollected',
                                        editable: true,
                                        filter: 'agNumberColumnFilter',
                                        valueParser: params => {
                                            const newValue = Number(params.newValue);
                                            if (isNaN(newValue)) {
                                                return params.oldValue;
                                            } else if (params.newValue.length > 9) {
                                                return 999_999_999.99;
                                            } else {
                                                return params.newValue;
                                            }
                                        },
                                        valueFormatter: params => {
                                            if (isNaN(params.value) || params.value === 0) {
                                                return '';
                                            } else {
                                                return `${symbol ?? '$'}${formatNumber(Number(params.value))}`;
                                            }
                                        }
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </>
            );
        }
    };
    return (
        <>
            {showConfirm && (
                <DialogBox
                    title="Forecasts"
                    // tslint:disable-next-line:max-line-length
                    content={'Are you sure you want to close?'}
                    show={showConfirm}
                    isAgree={onCloseConfirm}
                    disAgreeLabel={'No'}
                    agreeLabel={'Yes'}
                />
            )}
            <Drawer
                open={props.open || false}
                onClose={onClose}
                classes={{
                    paper: classes.root,
                }}
                anchor="right"
            >                
                {initializeComponent()}
                <div className={classes.buttonWrapper}>
                    <Button 
                        onClick={onSave} 
                        variant="contained"
                        disabled={isSubmitting || changes.length === 0}
                    >
                        Save
                    </Button>
                    <Button 
                        onClick={onClose} 
                        variant="contained"
                    >
                        Close
                    </Button>
                </div>
            </Drawer>
        </>
    );
};

type MatterForecastParams = {
    matterId: number;
};

type MatterForecastData = {
    matter: Matter;
};

type Matter = {
    id:               number;
    matterGuid:       string;
    fileNumber:       string;
    acting:           string;
    actingEmail:      string;
    responsible:      string;
    responsibleEmail: string;
    client:           string;
    description:      string;
    title:            string;
    completionDate:   Date;
    financials:       Financials;
    forecasts:        Forecasts;
};

type Financials = {
    unbilledTimeAndFees:   number;
    unbilledDisbursements: number;
    debtors:               number;
    trustBalance:          number;
    billedTimeAndFees:     number;
    feeEstimate:           number;
    percentageEstimate:    number;
};

type Forecasts = {
    totalFutureHours: number;
    totalFutureFeesBilled: number;
    totalFutureFeesCollected: number;
    matterForecast: MatterForecast[];
};

type MatterForecast = {
    guid:          string;
    matterId:      number;
    periodType:    number;
    feesBilled:    number;
    feesCollected: number;
    hours:         number;
    startDate:     Date;
};

const MatterForecastQuery = gql`
    query matterDetails($matterId: Int!) {
        matter(id: $matterId) {
            id
            matterGuid
            fileNumber
            acting
            actingEmail
            responsible
            responsibleEmail
            client
            description
            title
            completionDate
            financials {
                unbilledTimeAndFees
                unbilledDisbursements
                debtors: balanceDue
                trustBalance: trustGeneralBalance
                billedTimeAndFees
                feeEstimate: estimatedCosts
                percentageEstimate
            }
            forecasts{
                totalFutureHours
                totalFutureFeesBilled
                totalFutureFeesCollected
                matterForecast{
                    guid
                    matterId
                    periodType
                    feesBilled
                    feesCollected
                    hours
                    startDate
                }
            }
        }
    }
`;

type MatterForecastDetailInput = {
    guid: string;
    startDate: Date;
    periodType: number;
    feesBilled: number;
    feesCollected: number;
    hours: number;
    // matterId: number;
};

type MutationMatterInput = {
    matterGuid: string;
    matterForecasts: MatterForecastDetailInput[];
};

type MutationMatterParams = {
    input: MutationMatterInput;
};

type MutationMatterData = {
    matter:  MutationMatter;
};

type  MutationMatter = {
    matterForecast:  MutationMatterForecast;
};

type  MutationMatterForecast = {
    update: Update;
};

type Update = {
    error:  null;
    status: boolean;
};

const MatterForecastMutation = gql`
    mutation updateMatterForecast($input: MatterForecastInput) {
        matter {
            matterForecast {
                update(input: $input) {
                    error
                    status
                }
            }
        }
    }
`;
