import React from 'react';
import {
    makeStyles,
    Theme,
    createStyles,
    Grid,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    TextField,
} from '@material-ui/core';
import { PrioritySelector } from '../../tasksSummary/selectors/PrioritySelector';
// import { IAutoCompleteItem } from '../../typings/autoComplete';
// import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { Field, FormSpy } from 'react-final-form';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { TaskCategorySelector } from '../../tasksSummary/selectors/TaskCategorySelector';
import { TaskAssignedBySelector } from '../../tasksSummary/selectors/TaskAssignedBySelector';
// import { KeyboardTimePicker } from '@material-ui/pickers/TimePicker/TimePicker';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { mainTheme } from '../../Theme';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { KeyboardDateTimePicker } from '@material-ui/pickers/DateTimePicker/DateTimePicker';
import { TimeNumberFormatCustom } from '../../components/TimeNumberFormat';
import { AllocatedToEntityType } from '../../tasksSummary/AllocatedToEntityType';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            width: '100%'
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            // fontWeight: theme.typography.fontWeightRegular,
            color: mainTheme.BrandColors.TemplatePrimary
        },
        headerIcon: {
            marginRight: 10,
            color: mainTheme.BrandColors.TemplatePrimary
        },        
        commentField: {
            width: '100%',
        },
    })
);

export interface MatterGroupFields {
    priority: IAutoCompleteItem | null;
    taskCategory: IAutoCompleteItem | null;
    assignedBy: IAutoCompleteItem | null;
    dueDate: ParsableDate;
    estimatedEffort: ParsableDate;
    startDate: ParsableDate;
    completionDate: ParsableDate;
    duration: ParsableDate;
    completionNotes: string | null;
}

interface MatterGroupFieldProps {
    // tslint:disable-next-line: no-any
    values?: any;
    name?: string;
    formAction: 'create' | 'edit';
    allocatedToEntityType: number | null;
}

// tslint:disable-next-line: no-any
function TimePickerComp(compProps: any) {
    const { input, meta } = compProps;
    const classes = useStyles();
    return (
        <TextField
            {...input}
            label={compProps.label}
            InputProps={{
                // tslint:disable-next-line: no-any
                inputComponent: TimeNumberFormatCustom   as any
            }}
            className={classes.textField}
            error={meta.error && meta.touched}
            helperText={meta.error ? compProps.helperText : undefined}
            placeholder="00:00"
            inputProps={{autoComplete: 'off'}}
            required={compProps.required}
        />
    );
}

// tslint:disable-next-line: no-any
function TimePickerCompDisabled(compProps: any) {
    const { input, meta } = compProps;
    const classes = useStyles();
    return (
        <TextField
            {...input}
            label={compProps.label}
            InputProps={{
                // tslint:disable-next-line: no-any
                inputComponent: TimeNumberFormatCustom   as any
            }}
            disabled={true}
            className={classes.textField}
            error={meta.error && meta.touched}
            helperText={meta.error ? compProps.helperText : undefined}
            placeholder="00:00"
            inputProps={{autoComplete: 'off'}}
            required={compProps.required}
        />
    );
}

// tslint:disable-next-line: no-anyW
export const MatterGroupField: React.FC<MatterGroupFieldProps> = ( props ) => { 
    const classes = useStyles();

    // const defaultPriority = {
    //     label: 'No Priority',
    //     value: '8e0220a6-46ff-4af8-b19c-24f36a9b12b0'
    // };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };
    
    // tslint:disable-next-line: no-any
    const PrioritySelectorComp = (compProps: any) => {
        const { input, meta } = compProps;

        return (
            <PrioritySelector
                {...input}
                value={input.value ? input.value : null}
                disabled={compProps.disabled}
                helperText={meta.error ? compProps.helperText : undefined}
                label="Priority"
                className={classes.textField}
                onSelection={(value: IAutoCompleteItem, name: string) => {
                    input.onChange(value);
                }}
                error={meta.error}
                required={compProps.required}
                disablePortal={false}
            />
        );
    };

    // tslint:disable-next-line: no-any
    const TaskCategorySelectorComp = (compProps: any) => {
        const { input, meta } = compProps;
        return (
            <TaskCategorySelector
                {...input}
                value={input.value ? input.value : null}
                disabled={compProps.disabled}
                helperText={meta.error ? compProps.helperText : undefined}
                label="Category"
                className={classes.textField}
                onSelection={(value: IAutoCompleteItem, name: string) => {
                    input.onChange(value);
                }}
                error={meta.error}
                required={compProps.required}
                // helperText={'Priority is required'}
                disablePortal={false}
            />
        );
    };

    // tslint:disable-next-line: no-any
    const TaskAssignedBySelectorComp = (compProps: any) => {
        const { input, meta } = compProps;
        return (
            <TaskAssignedBySelector
                {...input}
                value={input.value ? input.value : null}
                disabled={compProps.disabled}
                helperText={meta.error ? compProps.helperText : undefined}
                label="Assigned By"
                className={classes.textField}
                onSelection={(value: IAutoCompleteItem, name: string) => {
                    input.onChange(value);
                }}
                error={meta.error}
                required={compProps.required}
                // helperText={'Priority is required'}
                disablePortal={false}
            />
        );
    };

    // tslint:disable-next-line: no-any
    const DateTimePickerComp = (compProps: any) => {
        const { input, meta } = compProps;
        return (
            <KeyboardDateTimePicker 
                {...input}
                className={classes.textField}
                format="DD/MM/YYYY hh:mm a"
                placeholder="dd/mm/yyyy hh:mm a"
                animateYearScrolling={true}
                // tslint:disable-next-line: max-line-length
                autoOk={true}
                allowKeyboardControl={true}
                variant={'inline'}
                // error={meta.error && meta.touched}
                inputProps={{autoComplete: 'off'}}
                InputLabelProps={{ shrink: true }}
                error={meta.error}
                label={compProps.label}
                helperText={meta.error ? compProps.helperText : undefined}
                required={compProps.required}
                initialFocusedDate={input.name === 'dueDate' ? new Date().setHours(17, 0, 0, 0) : new Date()}
            />
        );
    };

    // tslint:disable-next-line: no-any
    const DateTimePickerCompDisabled = (compProps: any) => {
        const { input, meta } = compProps;
        return (
            <KeyboardDateTimePicker 
                {...input}
                className={classes.textField}
                format="DD/MM/YYYY hh:mm a"
                placeholder="dd/mm/yyyy hh:mm a"
                animateYearScrolling={true}
                // tslint:disable-next-line: max-line-length
                autoOk={true}
                allowKeyboardControl={true}
                variant={'inline'}
                // error={meta.error && meta.touched}
                inputProps={{autoComplete: 'off'}}
                InputLabelProps={{ shrink: true }}
                disabled={true}
                error={meta.error}
                label={compProps.label}
                helperText={meta.error ? compProps.helperText : undefined}
                required={compProps.required}
                initialFocusedDate={input.name === 'dueDate' ? new Date().setHours(17, 0, 0, 0) : new Date()}
            />
        );
    };

    return (
        <>
            <Accordion
                defaultExpanded={props.formAction === 'create'}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <AssignmentIcon className={classes.headerIcon} />
                    <Typography className={classes.heading}>Task Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12}>
                            <Grid container={true} spacing={3}>
                                <Grid item={true} xs={12} md={6}>
                                    <Field
                                        name={props.name ? `${props.name}.dueDate` : 'dueDate'}
                                        label="Due"
                                        component={DateTimePickerComp}
                                        // validate={required}
                                        // required={true}
                                        // helperText={'Due is required'}
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Field
                                        name={props.name ? `${props.name}.estimatedEffort` : 'estimatedEffort'}
                                        label="Estimated Effort"
                                        component={TimePickerComp}
                                        // validate={required}
                                        // required={true}
                                        // helperText={'EStiamted Effort is required'}
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Field
                                        name={props.name ? `${props.name}.priority` : 'priority'}
                                        component={PrioritySelectorComp}
                                        validate={required}
                                        required={true}
                                        helperText={'Priority is required'}
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Field
                                        name={props.name ? `${props.name}.taskCategory` : 'taskCategory'}
                                        component={TaskCategorySelectorComp}
                                        // validate={required}
                                        // required={false}
                                        // helperText={'Category is required'}
                                    />
                                </Grid>                                                                
                                <Grid item={true} xs={12} md={6}>
                                    <Field
                                        name={props.name ? `${props.name}.startDate` : 'startDate'}
                                        label="Start"
                                        component={props.allocatedToEntityType !== AllocatedToEntityType.user 
                                            ? DateTimePickerCompDisabled
                                            : DateTimePickerComp}
                                        // validate={required}
                                        // required={true}
                                        // helperText={'Start is required'}
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Field
                                        name={props.name ? `${props.name}.completionDate` : 'completionDate'}
                                        label="Completion"
                                        component={props.allocatedToEntityType !== AllocatedToEntityType.user 
                                            ? DateTimePickerCompDisabled
                                            : DateTimePickerComp}
                                        // validate={required}
                                        // required={true}
                                        // helperText={'Completion is required'}
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Field
                                        name={props.name ? `${props.name}.duration` : 'duration'}
                                        label="Actual Effort"
                                        component={props.allocatedToEntityType !== AllocatedToEntityType.user 
                                            ? TimePickerCompDisabled
                                            : TimePickerComp}                                       
                                        // validate={required}
                                        // required={true}
                                        // helperText={'Duration is required'}
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Field
                                        name={props.name ? `${props.name}.assignedBy` : 'assignedBy'}
                                        component={TaskAssignedBySelectorComp}
                                        validate={required}
                                        required={true}
                                        helperText={'Assigned by is required'}
                                    />
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <FormSpy 
                                        subscription={{values: true, validating: true}}
                                    >
                                        {/* tslint:disable-next-line: no-shadowed-variable */}
                                        {({ values }) => ( 
                                            values.completionDate ? (
                                                <Field
                                                    name={props.name ? `${props.name}.completionNotes` : 'completionNotes'}
                                                >
                                                    {({ input, meta }) => (
                                                        <TextField
                                                            {...input}
                                                            label="Completion Notes"
                                                            multiline={true}
                                                            className={classes.commentField}
                                                            // disabled={props.formAction === 'edit'}
                                                        />
                                                    )}
                                                </Field>
                                                )
                                                : null
                                        )} 
                                    </FormSpy>                                  
                                </Grid>  
                                </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

// tslint:disable-next-line: no-any
// function printJson(values: any) {
//     if (values) {
//         return (
//             <>
//                 <pre>
//                     {JSON.stringify(values, undefined, 2)}
//                 </pre>
//             </>
//         );
//     } else {
//         return (
//             <>
//                 <FormSpy subscription={{ values: true }}>
//                     {/* tslint:disable-next-line: no-shadowed-variable */}
//                     {({ values }) => (
//                         <pre>
//                             {JSON.stringify(values, undefined, 2)}
//                             {/* <RenderCount /> */}
//                         </pre>
//                     )}
//                 </FormSpy>
//             </>
//         );
//     }
// }