import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { client } from '..';

export interface MatterManagementListData {
    loading?: boolean;
    networkStatus?: number;
    matterSummaryListX: MatterManagementSummaryList;
}

export interface MatterManagementSummaryList {
    recordCount: number;
    rowCountWithoutFilters: number;
    matters: MatterManagementSummary[];    
}

export interface MatterManagementSummary {
    id: number;
    matterGuidId: string;
    fileNumber: string;
    status: string;
    title: string;
    longTitle: string;
    countryID: number;
    country: string;
    state: string;
    categoryID: number;
    category: string;
    typeID: number;
    type: string;
    typeClassification: string;
    typeState: string;
    subTypeID: number;
    subType: string;
    stage: string;
    fIRB: Boolean;
    family: string;
    property: string;
    propertyLabel: string;
    client: string;
    clientReference: string;
    otherSide: string;
    owner: string;
    billed: number;
    unbilled: number; // Including Tax
    unbilledFees: number; // Excluding Tax
    unbilledDisbursements: number;
    debtors: number;
    trustBalance: number;
    estimatedCosts: number;
    completionOrSettlmentDate: Date;
    instructionDate: Date;
    nextAction: string;
    nextActionDate: Date;
    nextDueDescription: string;
    fileLocation: string;
    imageLocation: string;
    latestComment: string;
    inProgress: Boolean;
    publishedDate: Date;
    lastUpdate: Date;
    nextRenewalDate: Date;
    isVIP: Boolean;
    syncSystemID: number;
    responsibleID: number;
    responsible: string;
    responsibleEmail: string;
    responsiblePhone: string;
    actingID: number;
    acting: string;
    actingEmail: string;
    actingPhone: string;
    assistingID: number;
    assisting: string;
    assistingEmail: string;
    assistingPhone: string;
    price: number;
    deposit: number;
    exchangeDate: Date;
    coolingOffDate: Date;
    depositDueDate: Date;
    depositPaidDate: Date;
    earliestPriorityDate: Date;
    applicationNumber: number;
    publicationDate: Date;
    publicationNumber: string;
    grantNumber: string;
    class: string;
    goodsAndServices: string;  
    referrer: string;
}

export interface MatterManagementOptionsData {
    loading?: boolean;
    networkStatus?: number;
    appUser: AppUser;
}

export interface AppUser {
    isAdministrator: boolean;
}

export interface CurrencyOptionData {
    loading?: boolean;
    networkStatus?: number;
    options: Options;
}

export interface Options {
    localCurrency: LocalCurrency;
    currencySymbol: CurrencySymbol;
}

export interface LocalCurrency {
    currency: string;
}

export interface CurrencySymbol {
    symbol: string;
}

export function fetchAppUserData(refreshData: boolean,
                                 onSuccess: (data?: MatterManagementOptionsData) => void,
                                 // tslint:disable-next-line:no-any
                                 onError: (reason: any) => void)
: void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client.query({
        query: UserData,
        variables: {},
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); });
}

export function fetchCurrencyData(refreshData: boolean,
                                  onSuccess: (data?: CurrencyOptionData) => void,
                                  // tslint:disable-next-line:no-any
                                  onError: (reason: any) => void)
: void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client.query({
        query: CurrencyData,
        variables: {},
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); });
}

export const UserData = gql`
query AppUser {
    appUser {
        isAdministrator
        name      
        userId
    }
}`;

export const CurrencyData = gql`
query CurrencySymbol {
	options {
    localCurrency {
      currency
    }
    currencySymbol {
      symbol
    }
  }
}`;

export const ArchiveMatterRequestMutation = gql`
mutation archiveMatterRequest(
    $batchGuidID: String, 
    $matterGuid: String, 
    $archive: Boolean,
    $comments: String,
    $taskInput: TaskInput) {
        archiveMatterRequest(
            batchGuidID: $batchGuidID, 
            matterGuid: $matterGuid, 
            archive: $archive,
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const BillMatterRequestMutation = gql`
mutation billMatterRequest(
    $batchGuidID: String, 
    $matterGuid: String,
    $billFeesAmount: Decimal,
    $billDisbursementsAmount: Decimal,
    $applyTrustAmount: Decimal,
    $writeOffFeesAmount: Decimal,
    $writeOffDisbursementsAmount: Decimal,
    $comments: String,
    $taskInput: TaskInput) {
        billMatterRequest(
            batchGuidID: $batchGuidID, 
            matterGuid: $matterGuid,
            billFeesAmount: $billFeesAmount,
            billDisbursementsAmount: $billDisbursementsAmount,
            applyTrustAmount: $applyTrustAmount,
            writeOffFeesAmount: $writeOffFeesAmount,
            writeOffDisbursementsAmount: $writeOffDisbursementsAmount,    
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const CloseMatterRequestMutation = gql`
mutation closeMatterRequest(
    $batchGuidID: String, 
    $matterGuid: String, 
    $close: Boolean,
    $comments: String,
    $taskInput: TaskInput) {
        closeMatterRequest(
            batchGuidID: $batchGuidID, 
            matterGuid: $matterGuid, 
            close: $close,
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const TransferMatterTrustToInvoiceRequestMutation = gql`
mutation transferMatterTrustToInvoiceRequest(
    $batchGuidID: String, 
    $matterGuid: String,
    $trustTransferAmount: Decimal,
    $invoiceToApply: String,
    $comments: String,
    $taskInput: TaskInput) {
        transferMatterTrustToInvoiceRequest(
            batchGuidID: $batchGuidID, 
            matterGuid: $matterGuid,
            trustTransferAmount: $trustTransferAmount,
            invoiceToApply: $invoiceToApply,  
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const WriteOffMatterDebtorsRequestMutation = gql`
mutation writeOffMatterDebtorsRequest(
    $batchGuidID: String, 
    $matterGuid: String, 
    $writeOffDebtorsAmount: Decimal,
    $closeMatterAfterWriteOff: Boolean, 
    $comments: String,
    $taskInput: TaskInput) {
        writeOffMatterDebtorsRequest(
            batchGuidID: $batchGuidID, 
            matterGuid: $matterGuid,
            writeOffDebtorsAmount: $writeOffDebtorsAmount,
            closeMatterAfterWriteOff: $closeMatterAfterWriteOff,
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const WriteOffMatterWIPRequestMutation = gql`
mutation writeOffMatterWIPRequest(
    $batchGuidID: String, 
    $matterGuid: String, 
    $writeOffFeesAmount: Decimal,
    $writeOffDisbursementsAmount: Decimal, 
    $closeMatterAfterWriteOff: Boolean, 
    $comments: String,
    $taskInput: TaskInput) {
        writeOffMatterWIPRequest(
            batchGuidID: $batchGuidID, 
            matterGuid: $matterGuid, 
            writeOffFeesAmount: $writeOffFeesAmount,
            writeOffDisbursementsAmount: $writeOffDisbursementsAmount, 
            closeMatterAfterWriteOff: $closeMatterAfterWriteOff,
            comments: $comments,
            taskInput: $taskInput
        )
}`;

export const FinaliseTaskBatchRequestMutation = gql`
mutation finaliseTaskBatchRequest($batchGuidID: String){
    finaliseTaskBatchRequest(batchGuidID: $batchGuidID)
}`;

export const MatterManagementTaskNotification = gql`
subscription matterManagementTaskNotification {
    matterManagementTaskNotification{
        id,
        status,
        message,
        description
    }
}`;