import * as React from 'react';
import gql from 'graphql-tag';
import { FirmData, FeeEarner } from '../models/Firm';
import { Query } from 'react-apollo';
import { RvLoader } from '../../components/Loader';
import MultiSelect from '../../components/MultiSelect';
import { StandardTextFieldProps } from '@material-ui/core/TextField';

export interface FeeEarnerQueryParams {
    offset?: number; 
    first?: number;
    filter?: string;
    isNotExistsInDepartment?: boolean;
}

interface AvailableDepartmentMemberProps extends StandardTextFieldProps {
    intitialSelectedLabel?: string;
    // tslint:disable-next-line: no-any
    onSelectedItem?: (item: any , name: string) => void;
    onRemoveItem?: (item: string, name: string) => void;
    value?: string[];
    disabled?: boolean;
    name: string;
    required?: boolean;
    groupId: string;
}

interface AvailableDepartmentMemberState {
    selectedCode?: string;
    selectedLabel?: string;
}

const loadBlockLimit: number = 1000;

export class AvailableDepartmentMemberSelector extends React.Component<AvailableDepartmentMemberProps, AvailableDepartmentMemberState> {
    public rowCount: number = 0;
    public offset: number = 0;
    public filter: string = '';
    // tslint:disable-next-line: no-any
    private list: any = [];

    constructor(props: AvailableDepartmentMemberProps, state?: AvailableDepartmentMemberState) {
        super(props, state);
        this.onSelectedItem = this.onSelectedItem.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);

        this.state = {
            selectedCode: undefined,
            selectedLabel: this.props.intitialSelectedLabel
        };
    }

    public render() {
        let vm = this;
        return (
            // tslint:disable-next-line: no-any
            <Query<any> 
                query={FeeEarnerData} 
                fetchPolicy={'network-only'} // Highly transactional query. Always get the latest data set
                variables={{
                    filter: '',
                    first: loadBlockLimit,
                    offset: this.offset,
                    isNotExistsInDepartment: true,
                    groupGuid: this.props.groupId
                }}
            >
                {({ loading, error, data }) => {
                    if (data === undefined || data?.loading) {
                        return  <RvLoader />;
                    }

                    // map the data to a format for MuiDownshift  
                    const firmData: FirmData = data;
                    vm.list = (loading || error) 
                        ? null
                        : firmData.firm.departments.availableMembers.availableMember.map( (feeEarner: FeeEarner) => (
                            {
                                id: feeEarner.staffId, 
                                description: feeEarner.staffName,
                                isActive: feeEarner.isActive
                            }));  

                    return (  
                        <MultiSelect
                            disabled={vm.props.disabled}
                            label={vm.props.label}
                            name={vm.props.name}
                            multiple={true}
                            onSelectedItem={vm.onSelectedItem}
                            onRemoveItem={vm.onRemoveItem}
                            items={vm.list}  
                            selectedItems={vm.props.value}    
                            required={vm.props.required}
                            helperText={vm.props.helperText}
                            error={vm.props.error}
                            itemsToShow={12}
                            isShowUserActiveStatusIcons={true}
                        />
                    );
                }}
            </Query>
        );
    }

    private onRemoveItem(itemId: string, name: string) {
        if (this.props.onRemoveItem) {
            this.props.onRemoveItem(itemId, name);
        }
    }

    private onSelectedItem(itemList: string[], name: string) {
        // Send the complete collection of value and label
        if (this.list) {
            // tslint:disable-next-line: no-any
            const items = this.list.filter((source: any) => itemList.includes(source.id) );

            // tslint:disable-next-line: no-any
            const selectedItems = items.map((source: any) => ({value: source.id, label: source.description}));

            if (this.props.onSelectedItem) {
                this.props.onSelectedItem(selectedItems, name);
            }
        }
    }
}

const FeeEarnerData = gql`
query departmentAvailableMembers($offset: Int, $first: Int, $filter: String, 
                                 $isNotExistsInDepartment: Boolean, $groupGuid: String) {
    firm{
        departments{
            availableMembers(offset: $offset, first: $first, filter: $filter, 
                            isNotExistsInDepartment: $isNotExistsInDepartment, groupGuid: $groupGuid){
                recordCount,
                availableMember{
                    staffId,
                    staffName,
                    emailAddress,
                    isRedViewUser,
                    isActive
                }
            }
        }
    }
}`;