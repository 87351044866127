/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { CorrespondenceActivity, useCorrespondenceActivity } from '../../hooks/useCorrespondenceActivity';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        width: '100%'
    },
  })
);

interface CorrespondenceActivitySelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    disablePortal?: boolean;
    disableCloseOnSelect?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    style?: React.CSSProperties;
    isSystem?: boolean;
}

interface CorrespondenceActivitySelectorState {
    options: IAutoCompleteItem[];
}

export const CorrespondenceActivitySelector: React.FC<CorrespondenceActivitySelectorProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<CorrespondenceActivitySelectorState>({
        options: [],
    });

    const correspondenceActivityQuery = useCorrespondenceActivity({ isSystem: props.isSystem ?? null }, 'cache-first');

    useEffect(() => {   
        
        if (!correspondenceActivityQuery.loading && !correspondenceActivityQuery.error && correspondenceActivityQuery.data) {

            const { data } = correspondenceActivityQuery;
            setState((prevState) => {
                return {
                    ...prevState,
                    options: data.lookup.correspondenceActivity.map( (source: CorrespondenceActivity) => ({...source, value: source.guid, label: source.description }))
                                                        .sort((a, b) => a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0),
                };
            });
        }

    },        [correspondenceActivityQuery.data, correspondenceActivityQuery.error, correspondenceActivityQuery.loading]);

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        // tslint:disable-next-line: no-console
        console.log('onChange', value);
        if (props.onSelection) {
            props.onSelection(value, props.name ? props.name : 'options');
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <>
            { props.multiple ? 
                (
                    <Autocomplete
                        className={classes.root}
                        style={props.style}
                        value={props.value}
                        multiple={true}
                        loading={correspondenceActivityQuery.loading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.options}
                        disableCloseOnSelect={props.disableCloseOnSelect}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}       
                        getOptionSelected={(option, value) => value.value === option.value}     
                        onBlur={props.onBlur}        
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, correspondenceActivityQuery.loading)}
                                error={props.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        className={classes.root}
                        style={props.style}
                        value={props.value}
                        loading={correspondenceActivityQuery.loading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.options}
                        disableCloseOnSelect={props.disableCloseOnSelect}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}    
                        getOptionSelected={(option, value) => value.value === option.value}  
                        onBlur={props.onBlur}   
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, correspondenceActivityQuery.loading)}
                                error={props.error}    
                                helperText={props.helperText}                        
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </>
    );
};