import React from 'react';
import {
    makeStyles,
    Theme,
    createStyles,
} from '@material-ui/core';
import { mainTheme } from '../../Theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 -10px',
        },
        title: {
            padding: '0 10px',
            fontSize: 16,
            color: mainTheme.BrandColors.TemplatePrimary,
        },
        secondaryDescription: {
            flex: 1,
            padding: '0 10px',
            maxWidth: '100%',
            overflow: 'hidden',
        },
        subTitle: {
            fontSize: 14,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '97%',
            color: '#000000DE'
        },
        description: {
            fontSize: 12,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '97%',
            color: '#000000DE'
        }
    })
);

interface DialogTitleExtensionProps {
    title: string;
    subTitle?: string;
    description?: string;
}

// interface DialogTitleExtensionState extends FieldForms {}

// tslint:disable-next-line: no-anyW
export const DialogTitleExtension: React.FC<DialogTitleExtensionProps> = ( props ) => { 

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.title}>{props.title}</div>
            <div className={classes.secondaryDescription}>
                <div className={classes.subTitle}>{props.subTitle}</div>
                <div className={classes.description}>{props.description}</div>
            </div>
        </div>
    );
};