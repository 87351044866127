import React, { createContext, useState, useContext, useCallback, ReactNode } from 'react';

// Define the shape of the context
interface MatterSummaryContextProps {
  refreshPage: () => void;
  pageKey: number;
  isMatterForcastFormOpen: boolean;
  matterId: number | null;
  matterGuid: string | null;
  onMaterForcastFormOpen: (matterId: number, matterGuid: string) => void;
  onMatterForcastFormClose: () => void;
}

// Step 1: Create the context
export const MatterSummaryContext = createContext<MatterSummaryContextProps | undefined>(undefined);

// Define the props for the provider
interface MatterSummaryContextProviderProps {
  children: ReactNode;
}

// Step 2: Create the provider component
export const MatterSummaryContextProvider: React.FC<MatterSummaryContextProviderProps> = ({ children }) => {

  const [pageKey, setPageKey] = useState(0);

  const [isMatterForcastFormOpen, setIsMatterForcastFormOpen] = useState(false);

  const [matterId, setMatterId] = useState<number | null>(null);

  const [matterGuid, setMatterGuid] = useState<string | null>(null);

  const onMatterForcastFormClose = useCallback(() => {
    setIsMatterForcastFormOpen(false);
    // tslint:disable-next-line: align
  }, []);

  // tslint:disable-next-line: no-shadowed-variable
  const onMaterForcastFormOpen = useCallback((matterId: number, matterGuid: string) => {
    setMatterId(matterId);
    setMatterGuid(matterGuid);
    setIsMatterForcastFormOpen(true);
    // tslint:disable-next-line: align
  }, []);

  const refreshPage = useCallback(() => {
    setPageKey(prevKey => prevKey + 1);
    // tslint:disable-next-line: align
  }, []);

  return (
    <MatterSummaryContext.Provider 
      value={{ 
        matterId,
        matterGuid,
        pageKey, 
        refreshPage, 
        isMatterForcastFormOpen,
        onMaterForcastFormOpen, 
        onMatterForcastFormClose 
      }}
    >
      {children}
    </MatterSummaryContext.Provider>
  );
};

// Step 3: Create the custom hook
export const useMatterSummaryContext = () => {
  const context = useContext(MatterSummaryContext);

  if (!context) {
    throw new Error('useMatterSummaryContext must be used within a MatterSummaryContextProvider');
  }

  return {
    matterId: context.matterId,
    matterGuid: context.matterGuid,
    pageKey: context.pageKey,
    refreshPage: context.refreshPage,
    isMatterForcastFormOpen: context.isMatterForcastFormOpen,
    onMaterForcastFormOpen: context.onMaterForcastFormOpen,
    onMatterForcastFormClose: context.onMatterForcastFormClose
  };
};