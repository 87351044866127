import React from 'react';
import {
    makeStyles,
    Theme,
    createStyles,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { mainTheme } from '../../Theme';
import { Form, Field, FormSpy, AnyObject, FieldInputProps } from 'react-final-form';
import { CurrencyDisplayStyle } from '../../components/DataDisplay/CurrencyDisplayStyle';
import { FormattedCurrency } from '../../components/DataDisplay/FormattedCurrency';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';
import { CurrencyInput } from '../../components/CurrencyInput';
import { CurrencyDetails } from '../cellRenderer/MatterTaskAction';
import { client } from '../..';
import { UpdateWriteOffMatterDebtorsRequestMutation, WriteOffMatterDebtorsRequestMutation } from '../MatterSummaryRepository';
import { showNotification } from '../../App';
import { MatterGroupField, MatterGroupFields } from '../fields/MatterGroupField';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { DialogTitleExtension } from '../common/DialogTitleExtension';
import moment from 'moment';
import { AllocatedToEntityType } from '../../tasksSummary/AllocatedToEntityType';
import { FormApi } from 'final-form';
import { TaskAssignedBySelector } from '../../tasksSummary/selectors/TaskAssignedBySelector';
import { TaskAssignedByUserTeamSelector } from '../../tasksSummary/selectors/TaskAssignedByUserTeamSelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        textField: {
            // width: '100%',
        },
        commendField: {
            width: '100%',
        },
        dialogTitle: {
            color: mainTheme.TemplateColor.Primary,
        },
        customGrid: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        generatedContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 -10px',
            flex: 1,
            '& .col': {
                padding: '0 10px',
            },
            '& .value': {
                fontWeight: 600,
            },
        },
        gridCenter: {
            display: 'flex',
            alignItems: 'center',
        },
    })
);

export interface WriteOffDebtor extends MatterGroupFields {
    debtors?: number;
    isCloseMatterAfterWriteOff?: boolean;
    comments?: string;

    assignedTo: IAutoCompleteItem | null;
    allocatedToEntityType: number | null;
    owner: IAutoCompleteItem | null;
}

interface WriteOffDebtorsDialogProps {
    currencyDetails: CurrencyDetails;
    matterId: number;
    matterGuid: string;
    matterTitle: string;
    client: string;
    onDialogClose?: () => void;
    loggedInUser: IAutoCompleteItem | null;
    defaultPriority: IAutoCompleteItem | null;

    unBilledFees?: number;
    unbilledDisbursements?: number;
    outstandingDebtors?: number;
    billedFees?: number;

    canClose: boolean;

    // Saved values
    taskGuid?: string;
    formAction: 'create' | 'edit';
    debtorsAmount?: number | null;
    closeMatterAfterWriteOff?: boolean | null;
    comments?: string | null;
    priority?: IAutoCompleteItem | null;
    taskCategory?: IAutoCompleteItem | null;
    dueDate?: Date | null;
    estimatedEffort?: string | null;
    startDate?: Date | null;
    completionDate?: Date | null;
    duration?: string | null;
    assignedBy?: IAutoCompleteItem | null;
    completionNotes?: string | null;

    assignedTo?: IAutoCompleteItem | null;
    owner?: IAutoCompleteItem | null;
    allocatedToEntityType?: number | null;
}
// tslint:disable-next-line: no-anyW
export const WriteOffDebtorsDialog: React.FC<WriteOffDebtorsDialogProps> = ( props ) => {
    const classes = useStyles();

    const onSubmit = (values: WriteOffDebtor) => {
        if (props.formAction === 'create') {
            writeOffDebtorsRequest(values);
        } else {
            updateWriteOffDebtorsRequest(values);
        }

        onWriteOffDebtorsClosed();
    };

    const writeOffDebtorsRequest = (writeOffDebtorsTask: WriteOffDebtor) => {
        client
            .mutate({
                mutation: WriteOffMatterDebtorsRequestMutation,
                variables: {
                    matterGuid: props.matterGuid,
                    writeOffDebtorsAmount: writeOffDebtorsTask.debtors,
                    closeMatterAfterWriteOff: writeOffDebtorsTask.isCloseMatterAfterWriteOff,
                    comments: writeOffDebtorsTask.comments,
                    taskInput: getTaskInput(writeOffDebtorsTask)
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error !== undefined) {
                        showNotification('Failed to Request Debtor Write Off Matter', results.data.error, 'error');
                    } else {
                        showNotification(null, 'Successfully submitted', 'info'); 
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Request Debtor Write Off Matter', reason, 'error');
            });
    };

    const updateWriteOffDebtorsRequest = (writeOffDebtorsTask: WriteOffDebtor) => {
        client
            .mutate({
                mutation: UpdateWriteOffMatterDebtorsRequestMutation,
                variables: {
                    taskRequestGuid: props.taskGuid,
                    matterGuid: props.matterGuid,
                    writeOffDebtorsAmount: writeOffDebtorsTask.debtors,
                    closeMatterAfterWriteOff: writeOffDebtorsTask.isCloseMatterAfterWriteOff,
                    comments: writeOffDebtorsTask.comments,
                    taskInput: getTaskInput(writeOffDebtorsTask)
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error !== undefined) {
                        showNotification('Failed to Update Debtor Write Off Matter Request', results.data.error, 'error');
                    } else {
                        showNotification(null, 'Successfully submitted', 'info'); 
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Update Debtor Write Off Matter Request', reason, 'error');
            });
    };

    const getTaskInput = (task: WriteOffDebtor) => {
        let ownerGuid = task.owner 
            ? task.owner.value 
            : task.allocatedToEntityType === AllocatedToEntityType.user 
                ? (task.assignedTo ? task.assignedTo.value : null)
                : (task.assignedBy ? task.assignedBy.value : null);
                
        return {
            description: '',
            priority: task.priority?.label ? task.priority?.label : null,
            category: task.taskCategory?.label ? task.taskCategory?.label : null,
            dueDate: task.dueDate ? task.dueDate : null,
            estimatedEffort: task.estimatedEffort ? task.estimatedEffort : null,
            startDate: task.startDate ? task.startDate : null,
            completionDate: task.completionDate ? task.completionDate : null,
            duration: task.duration ? task.duration : null,
            assignedBy: task.assignedBy?.value,
            completionNotes: task.completionDate ? task.completionNotes : null,
            assignedTo: task.assignedTo?.value ? task.assignedTo?.value : null,
            owner: ownerGuid,
            allocatedToEntityType: task.allocatedToEntityType,
        };
    };

    const onWriteOffDebtorsClosed = () => {
        if (props.onDialogClose) {
            props.onDialogClose();
        }
    };
    
    const getInitialValue = () => {
        if (props.formAction === 'create') {
            return {
                debtors: undefined,
                isCloseMatterAfterWriteOff: false,
                comments: '',
                priority: props.defaultPriority,
                assignedBy: props.loggedInUser,

                assignedTo: null,
                owner: null,
                allocatedToEntityType: null,
            };
        } else {
            return {
                debtors: props.debtorsAmount,
                isCloseMatterAfterWriteOff: props.closeMatterAfterWriteOff,
                comments: props.comments,
                priority: props.priority ? props.priority : props.defaultPriority,
                taskCategory: props.taskCategory,
                dueDate: props.dueDate ? moment(props.dueDate).toDate() : null,
                estimatedEffort: props.estimatedEffort,
                startDate: props.startDate ? moment(props.startDate).toDate() : null,
                completionDate: props.completionDate ? moment(props.completionDate).toDate() : null,
                completionNotes: props.completionNotes,
                duration: props.duration,
                assignedBy: props.assignedBy ? props.assignedBy : props.loggedInUser,

                assignedTo: props.assignedTo,
                owner: props.owner,
                allocatedToEntityType: props.allocatedToEntityType,
            };
        }        
    };

    // tslint:disable-next-line: no-any
    const onDropDownSelect = (form: FormApi<WriteOffDebtor>, values: AnyObject, input: FieldInputProps<any, HTMLElement>, 
                              // tslint:disable-next-line: no-any
                              selection: IAutoCompleteItem | any, name: string, isUser: boolean) => {
        if (name === 'assignedTo') {
            let assignedTo = selection && selection.value && selection.label 
                                ? { value : selection.value, label: selection.label }
                                : null;
            form.batch(() => {
                form.change('assignedTo', assignedTo);
                form.change('allocatedToEntityType', isUser 
                    ? AllocatedToEntityType.user 
                    : AllocatedToEntityType.team
                );                
            });
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={getInitialValue()}
            subscription={{submitting: true, pristine: true}}
            render={({handleSubmit, form, submitting, pristine, values}) => (
                <form onSubmit={handleSubmit}>
                    <Dialog
                        open={true}
                        onClose={onWriteOffDebtorsClosed}
                        aria-labelledby="form-dialog-title"
                        className={classes.root}
                    >
                        <DialogTitle
                            id="form-dialog-title"
                            className={classes.dialogTitle}
                        >
                            <DialogTitleExtension 
                                title="Write Off Debtors"
                                subTitle={props.client}
                                description={props.matterTitle}
                            />
                        </DialogTitle>
                        <DialogContent dividers={true}>
                            <div>
                                <Grid container={true} spacing={1}>
                                    <Grid item={true} xs={6}>
                                        <Grid container={true} spacing={1}>
                                            <Grid item={true} xs={12}>
                                                <Field 
                                                    name="debtors" 
                                                    label={'Debtors'} 
                                                    helperText={'Debtors amount to write off'}
                                                    subscription={{ touched: true, error: true, value: true}}
                                                    prefix={props.currencyDetails.currencySymbol}
                                                    className={classes.textField}
                                                    maxValue={props.outstandingDebtors ? props.outstandingDebtors : 0}
                                                    component={CurrencyInput}
                                                    // disabled={props.formAction === 'edit'}
                                                />
                                            </Grid>
                                            {UserFeature.HasAccess(UserFeatureEnum.hasCloseMatter)
                                                ?
                                                    <Grid item={true} xs={12}>
                                                        <FormSpy subscription={{ values: true }}>
                                                            {/* tslint:disable-next-line: no-shadowed-variable */}
                                                            {({ values }) => (
                                                                <Field
                                                                    name="isCloseMatterAfterWriteOff"
                                                                    // validate={required}
                                                                    // subscription={{ touched: true, error: true, value: true}}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox 
                                                                                    name="isCloseMatterAfterWriteOff" 
                                                                                    onChange={(event) => input.onChange(event.target.checked)}
                                                                                    checked={input.value}
                                                                                    disabled={!props.canClose}
                                                                                />
                                                                            }
                                                                            label="Close Matter After Write Off"
                                                                        />
                                                                    )}
                                                                </Field>
                                                            )}
                                                        </FormSpy>
                                                    </Grid>
                                                : null
                                            }                                            
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <Field
                                                name="assignedTo"
                                                // validate={form.getFieldState('assignTo')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    <TaskAssignedByUserTeamSelector
                                                        {...input}
                                                        label="Assigned To"
                                                        value={input.value ? input.value : null}
                                                        disablePortal={false}
                                                        // required={true}
                                                        className={`${classes.textField}`}
                                                        error={meta.error && meta.touched}
                                                        // tslint:disable-next-line: jsx-alignment
                                                        onSelection={(selection: IAutoCompleteItem, name: string, isUser: boolean) => 
                                                            onDropDownSelect(form, values, input, selection, name, isUser)
                                                        }
                                                        // required={true}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Assigned to is required'
                                                                : ''
                                                        }
                                                        visible={{
                                                            role: true
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <Field
                                                name="owner"
                                            >
                                                {({ input, meta }) => (
                                                    <TaskAssignedBySelector
                                                        {...input}
                                                        label="Owner"
                                                        value={input.value ? input.value : null}
                                                        disablePortal={false}
                                                        // required={true}
                                                        className={`${classes.textField} `}
                                                        error={meta.error && meta.touched}
                                                        // tslint:disable-next-line: jsx-alignment
                                                        onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                            input.onChange(selection)
                                                        }
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Owner to is required'
                                                                : ''
                                                        }
                                                        visible={{
                                                            role: true
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item={true}
                                        xs={6}
                                        className={classes.gridCenter}
                                    >
                                        <Grid container={true} spacing={1}>
                                            <Grid item={true} xs={12}>
                                                <div className={classes.generatedContainer} >
                                                    <div className="col label">
                                                        Unbilled Fees:
                                                    </div>
                                                    <div className="col value">
                                                        <FormattedCurrency
                                                            value={props.unBilledFees ? props.unBilledFees : 0}
                                                            displayStyle={CurrencyDisplayStyle.Decimal} 
                                                            format={props.currencyDetails.currency}
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item={true} xs={12}>
                                                <div className={classes.generatedContainer} >
                                                    <div className="col label">
                                                        Unbilled Disbursements:
                                                    </div>
                                                    <div className="col value">
                                                        <FormattedCurrency
                                                            value={props.unBilledFees ? props.unBilledFees : 0}
                                                            displayStyle={CurrencyDisplayStyle.Decimal} 
                                                            format={props.currencyDetails.currency}
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item={true} xs={12}>
                                                <div className={classes.generatedContainer} >
                                                    <div className="col label">
                                                        Outstanding Debtors:
                                                    </div>
                                                    <div className="col value">
                                                        <FormattedCurrency
                                                            value={props.outstandingDebtors ? props.outstandingDebtors : 0}
                                                            displayStyle={CurrencyDisplayStyle.Decimal} 
                                                            format={props.currencyDetails.currency}
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item={true} xs={12}>
                                                <div className={classes.generatedContainer} >
                                                    <div className="col label">
                                                        Billed Fees:
                                                    </div>
                                                    <div className="col value">
                                                        <FormattedCurrency
                                                            value={props.billedFees ? props.billedFees : 0}
                                                            displayStyle={CurrencyDisplayStyle.Decimal} 
                                                            format={props.currencyDetails.currency}
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item={true} xs={12}>
                                        <Field
                                            name="comments"
                                            // validate={required}
                                            subscription={{ touched: true, error: true, value: true}}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Comments"
                                                    multiline={true}
                                                    className={classes.commendField}
                                                    // disabled={props.formAction === 'edit'}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12}>   
                                        <FormSpy subscription={{values: true, validating: true}} >
                                            {/* tslint:disable-next-line: no-shadowed-variable */}
                                            {({ values }) => ( 
                                                <MatterGroupField 
                                                    values={values} 
                                                    formAction={props.formAction}
                                                    allocatedToEntityType={values.allocatedToEntityType}
                                                />   
                                            )}
                                        </FormSpy>
                                    </Grid>
                                </Grid>
                            </div>
                            {/* {printJson(values)} */}
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                disabled={submitting || pristine} 
                                color="primary"
                                type="submit"
                                onClick={() => form.submit()} 
                            >
                                {props.formAction === 'create' ? 'Save' : 'Update'}
                            </Button>
                            <Button 
                                onClick={() => form.reset()} 
                                disabled={submitting || pristine} 
                                color="primary"
                            >
                                Reset
                            </Button>
                            <Button 
                                onClick={onWriteOffDebtorsClosed} 
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        />
    );
};

// tslint:disable-next-line: no-any
// function printJson(values: any) {
//     if (values) {
//         return (
//             <>
//                 <pre>
//                     {JSON.stringify(values, undefined, 2)}
//                 </pre>
//             </>
//         );
//     } else {
//         return (
//             <>
//                 <FormSpy subscription={{ values: true }}>
//                     {/* tslint:disable-next-line: no-shadowed-variable */}
//                     {({ values }) => (
//                         <pre>
//                             {JSON.stringify(values, undefined, 2)}
//                             {/* <RenderCount /> */}
//                         </pre>
//                     )}
//                 </FormSpy>
//             </>
//         );
//     }
// }