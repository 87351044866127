/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { client } from '../..';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { FirmData, Department } from '../models/Firm';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//         minWidth: '150px',
//     },
//   })
// );

export interface DepartmentQueryParams {
    isMyDepartmentOnly: boolean | null;
}

interface FirmDepartmentSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value?: IAutoCompleteItem | IAutoCompleteItem[], name?: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    style?: React.CSSProperties; 
}

interface FirmDepartmentSelectorState {
    isLoading: boolean;
    error?: boolean;
    data: IAutoCompleteItem[];
}

export const FirmDepartmentSelector: React.FC<FirmDepartmentSelectorProps> = props => {

    // const classes = useStyles();

    const [state, setState] = useState<FirmDepartmentSelectorState>({
        isLoading: false,
        error: props.error,
        data: [],
    });

    useEffect(() => {   
        fetchData();
    // tslint:disable-next-line: align
    }, []);

    const fetchData = () => {

        setState({
            ...state,
            isLoading: true
        });

        let query: DepartmentQueryParams = {
            isMyDepartmentOnly: UserFeature.HasAccess(UserFeatureEnum.hasFirmDashboardDepartmentView) 
                                ? null
                                : UserFeature.HasAccess(UserFeatureEnum.hasFirmDashboardMyDepartmentOnly)
        };

        retrieveDepartmentData(
            query,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveData(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveData = (data: FirmData) => {
        setState({
            ...state,
            data: data.firm.departments.department.map((source: Department) => ({value: source.guidID, label: source.description })),
            isLoading: false
        });
    };

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (props.onSelection) {
            props.onSelection(value, props.name);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <>
            { props.multiple ? 
                (
                    <Autocomplete
                        style={props.style}
                        className={props.className}
                        value={props.value}
                        multiple={true}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.data}
                        disablePortal={true} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}                    
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={state.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        style={props.style}
                        className={props.className}
                        value={props.value}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.data}
                        disablePortal={true} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}         
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={state.error}                            
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </>
    );
};

export function retrieveDepartmentData(
    query: DepartmentQueryParams | undefined,
    refreshData: boolean,
    onSuccess: (data: FirmData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: FirmDepartmentList,
            variables: {
                isMyDepartmentOnly: query ? query.isMyDepartmentOnly : null
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const FirmDepartmentList = gql`
query firmDepartmentList($isMyDepartmentOnly: Boolean) {
    firm{
        departments(isMyDepartmentOnly: $isMyDepartmentOnly){
            recordCount,
            department{
                guidID,
                description,
                staffCount
            }
        }
    }
}`;