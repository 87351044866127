import { ColDef, Column, ExcelStyle, IAggFunc, ValueGetterParams } from '@ag-grid-enterprise/all-modules';
import { MatterTerminology } from '../MatterTerminology';
import { DataType } from '../components/DataDisplay/ValueFormatter';
import { DateRenderer, 
    BoolRenderer, DateTimeRenderer, ImageRenderer, PercentageRenderer, NumberRenderer, CurrencyRendererWithoutDecimal, CurrencyRenderer } from './MatterSummaryCellRenderer';
import { MatterTaskAction } from './cellRenderer/MatterTaskAction';
// import moment from 'moment';
import { getValueGetter } from '../components/Grid/ColumnConfig';
import moment from 'moment';
import { DueDateRenderer } from '../enquirySummary/cellRenderer/DueDateRenderer';
// import _ from 'lodash';
// import _ from 'lodash';

export interface ColumnProperty extends ColDef {
    canSearch?: boolean;
    cardDisplay?: boolean; // Do not display on summary card or is already displayed
    format: number;
    isHideCustom?: boolean; // To display / hide column if it is not an admin user
}

export interface SummaryViewColumnConfiguration extends ColDef {
    field: string;
    headerName: string;
    width: number;
    dataType: number;
    show: boolean;
    isLocked: boolean;
    sort: string;
    aggFunc?: string | IAggFunc;
    enableValue?: boolean;
    valueGetter?: string;
    // tslint:disable-next-line: no-any
    cellClass?: any;
    // tslint:disable-next-line: no-any
    checkboxSelection?: any;
    // tslint:disable-next-line: no-any
    headerCheckboxSelection?: any;
}

var menuTabs = ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'];
var nonFilterMenuTabs = ['generalMenuTab', 'columnsMenuTab'];

export enum cellClassEnum {
    numberType = 'numberType',
    currencyType = 'currencyType',
    stringType = 'stringType',
    dateTimeType = 'dateTimeType',
    dateType = 'dateType',
    monthYearType = 'monthYearType',
    booleanType = 'booleanType',
    actionType = 'actionType',
    imageType = 'imageType',
    currencyWithoutDecimalsType = 'currencyWithoutDecimalsType',
    percentageType = 'percentageType',
    linkType = 'linkType',
    checkboxType = 'checkboxType',
    editorViewType = 'editorViewType',
    durationType = 'durationType',
    chipsType = 'chipsType',
    contextMenuStaffType = 'contextMenuStaffType',
    dueDateType = 'dueDateType',
    timeType = 'timeType',
    ratingType = 'ratingType',
    dateMonthYear = 'dateMonthYear'
}

// tslint:disable-next-line: no-any
export const defaultExcelStyles: ExcelStyle[] | any[] = [
    {
        id: cellClassEnum.stringType,
        dataType: 'string',
        alignment: {
            horizontal: 'Left', vertical: 'Center'
        }
    },
    {
        id: cellClassEnum.dateType,
        dataType: 'dateTime',
        numberFormat: { format: 'yyyy-mm-dd;;;' },
        alignment: {
            horizontal: 'Center', vertical: 'Center'
        }
    },
    {
        id: cellClassEnum.dateTimeType,
        dataType: 'dateTime',
        numberFormat: { format: 'yyyy-mm-dd hh:mm:ss;;;' },
        alignment: {
            horizontal: 'Center', vertical: 'Center'
        }
    },
    {
        id: cellClassEnum.currencyType,
        dataType: 'currency',
        alignment: {
            horizontal: 'Right', vertical: 'Center'
        }
    },
    {
        id: cellClassEnum.currencyWithoutDecimalsType,
        dataType: 'currency',
        alignment: {
            horizontal: 'Right', vertical: 'Center'
        }
    },
    {
        id: cellClassEnum.percentageType,
        dataType: 'percentage',
        alignment: {
            horizontal: 'Right', vertical: 'Center'
        }
    },
    {
        id: cellClassEnum.booleanType,
        dataType: 'string',
        alignment: {
            horizontal: 'Center', vertical: 'Center'
        }
    },
    {
        id: cellClassEnum.numberType,
        dataType: 'number',
        alignment: {
            horizontal: 'Right', vertical: 'Center'
        }
    },
    {
        id: cellClassEnum.durationType,
        dataType: 'string',
        alignment: {
            horizontal: 'Left', vertical: 'Center'
        }
    },
    {
        id: cellClassEnum.dueDateType,
        dataType: 'string',
        alignment: {
            horizontal: 'Center', vertical: 'Center'
        }
    },
    {
        id: cellClassEnum.checkboxType,
        dataType: 'string',
        alignment: {
            horizontal: 'Center', vertical: 'Center'
        }
    },
];

export function filterParamsOptions() {
    return {
        filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
        // use inRangeInclusive: true for the range filter to include the selected 
        // from and to dates. Setting it false would fetch only the inbetween dates
        inRangeInclusive: true,  
        // tslint:disable-next-line: no-any
        comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            var dateAsString = moment(cellValue).format('DD/MM/YYYY');
            var dateParts = dateAsString.split('/');
            var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            } else if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return -1;
            }            
        }
    };
}

// converts our json view configuration to the coulumn layout used by ag-grid
export function ConvertViewConfigToLayout(layoutConfiguration: string, isInMemory: boolean) {
    return( ConvertColumnFromConfig(JSON.parse(layoutConfiguration), isInMemory));
}

// converts our the coulumn layout used by ag-grid to our view configuration
export function ConvertViewLayoutToConfig(viewLayout: Column[]): string {
    var columnConfig = ConvertColumnsToConfig(viewLayout);
    return JSON.stringify(columnConfig);
}

function ConvertColumnsToConfig(columns: Column[]): SummaryViewColumnConfiguration[] {
    var result = new Array<SummaryViewColumnConfiguration>();
    columns.forEach(column => {
        var colDef = column.getColDef();
        // tslint:disable-next-line: no-any
        let columnCfg: any;

        if (colDef.checkboxSelection === true) {
            columnCfg = {
                // colId: colDef.field,
                field: colDef.field ? colDef.field : '',
                headerName: colDef.headerName ? colDef.headerName : '',
                width: column.getActualWidth(),
                dataType: getDataTypeForColumn(colDef),
                show: column.isVisible() || column.isRowGroupActive(),
                isLocked: colDef.field === 'FileNumber',
                sort: column.getSort(),
                aggFunc: colDef.aggFunc,
                enableValue: colDef.enableValue,
                valueGetter: colDef.field,
                cellClass: colDef.cellClass,
                checkboxSelection: colDef.checkboxSelection ? colDef.checkboxSelection : undefined,
                headerCheckboxSelection: colDef.headerCheckboxSelection ? colDef.headerCheckboxSelection : undefined,
            };
        } else {
            columnCfg = {
                // colId: colDef.field,
                field: colDef.field ? colDef.field : '',
                headerName: colDef.headerName ? colDef.headerName : '',
                width: column.getActualWidth(),
                dataType: getDataTypeForColumn(colDef),
                show: column.isVisible() || column.isRowGroupActive(),
                isLocked: colDef.field === 'FileNumber',
                sort: column.getSort(),
                aggFunc: colDef.aggFunc,
                enableValue: colDef.enableValue,
                valueGetter: colDef.field,
                cellClass: colDef.cellClass
            };
        }
        
        // if (colDef.field !== 'isSelect') {
        result.push(columnCfg);
        // }
    });
    return result;
}

function ConvertColumnFromConfig(columncfgs: Array<SummaryViewColumnConfiguration>, isInMemory: boolean): Array<ColDef> {
    var result = new Array<ColDef>();

    // if (_.findIndex(result, ['field', 'isSelect']) === -1) {
    //     result.push(
    //         {            
    //             checkboxSelection: true,
    //             headerCheckboxSelection: true,
    //             // headerCheckboxSelectionFilteredOnly: true,             
    //             headerName: '',
    //             field: 'isSelect',
    //             width: 50,
    //             cellClass: cellClassEnum.checkboxType,
    //             sortable: false
    //         }
    //     );
    // }

    columncfgs.forEach(columncfg => {
        var filter = getFilterForDataType(columncfg.dataType);
        
        let showMenuTab = (columncfg.dataType !== DataType.ContextMenu && columncfg.dataType !== DataType.Checkbox) && isInMemory;
        let isDateFilter = columncfg.dataType === DataType.Date || columncfg.dataType === DataType.DateTime;
        // let isSortable = (columncfg.dataType !== DataType.ContextMenu && columncfg.dataType !== DataType.Checkbox);

        // tslint:disable-next-line: no-any
        let config: any;

        if (columncfg.checkboxSelection === true) {
            config = {
                // colId: columncfg.field,
                field: columncfg.field,
                headerName: columncfg.headerName,
                width: columncfg.width,
                format: columncfg.dataType,
                filter: filter ? filter : '',
                filterParams: isDateFilter ? filterParamsOptions() : null,
                hide: !columncfg.show,
                pinned: columncfg.isLocked ? 'left' : 'none',
                menuTabs: showMenuTab ? menuTabs : nonFilterMenuTabs,
                enableRowGroup: false,
                sort: '',
                sortable: false,
                tooltipField: columncfg.dataType === DataType.String ? columncfg.field : undefined,
                aggFunc: undefined,
                enableValue: columncfg.enableValue,
                valueGetter: getValueGetter(columncfg.valueGetter),
                cellClass: columncfg.cellClass,
                checkboxSelection: columncfg.checkboxSelection ? columncfg.checkboxSelection : undefined,
                headerCheckboxSelection: columncfg.headerCheckboxSelection ? columncfg.headerCheckboxSelection : undefined
            };
        } else {
            config = {
                // colId: columncfg.field,
                field: columncfg.field,
                headerName: columncfg.headerName,
                width: columncfg.width,
                format: columncfg.dataType,
                filter: filter ? filter : '',
                filterParams: isDateFilter ? filterParamsOptions() : null,
                hide: !columncfg.show,
                pinned: columncfg.isLocked ? 'left' : 'none',
                menuTabs: showMenuTab ? menuTabs : nonFilterMenuTabs,
                enableRowGroup: true,
                sort: columncfg.sort,
                tooltipField: columncfg.dataType === DataType.String ? columncfg.field : undefined,
                aggFunc: columncfg.aggFunc,
                enableValue: columncfg.enableValue,
                valueGetter: getValueGetter(columncfg.valueGetter),
                cellClass: columncfg.cellClass
            };
        }      
        
        // if (columncfg.field !== 'isSelect') {
        applyCellRendererToColumn(config, columncfg.dataType);
        // }

        // result.push(config);
        // if (config.field !== 'isSelect') {
        result.push(config);
        // }
    });
    return result;
}

function getDataTypeForColumn(column: ColDef): number {
    if (column.cellRendererFramework === DateTimeRenderer) {
        return DataType.DateTime;
    }
    if (column.cellRendererFramework === DateRenderer) {
        return DataType.Date;
    }
    if (column.cellRendererFramework === NumberRenderer) { // filter === 'number') {
        return DataType.Number;
    }
    if (column.cellRendererFramework === CurrencyRenderer) {
        return DataType.Currency;
    }
    if (column.cellRendererFramework === CurrencyRendererWithoutDecimal) {
        return DataType.CurrencyWithoutDecimals;
    }
    if (column.cellRendererFramework === BoolRenderer) {
        return DataType.Boolean;
    }
    if (column.cellRendererFramework === ImageRenderer) {
        return DataType.Image;
    }
    if (column.cellRendererFramework === PercentageRenderer) {
        return DataType.Percentage;
    }
    if (column.cellRendererFramework === MatterTaskAction) {
        return DataType.ContextMenu;
    }
    if (column.cellRendererFramework === DueDateRenderer) {
        return DataType.DueDate;
    }
    // if (column.filter === 'currency') {
    //     return DataType.Currency;
    // }
    if (column.filter === 'date') {
        return DataType.Date;
    }
    if (column.filter === 'dateTime') {
        return DataType.DateTime;
    }
    if (column.filter === 'image') {
        return DataType.Image;
    }
    // if (column.filter === 'boolean') {
    //     return DataType.Boolean;
    // }
    if (column.filter === 'menu') {
        return DataType.ContextMenu;
    }
    if (column.filter === 'percentage') {
        return DataType.Percentage;
    }
    if (column.filter === 'longTextRenderer') {
        return DataType.LongText;
    }
    if (column.filter === 'dueDateRenderer') {
        return DataType.DueDate;
    }
    if (column.filter === 'checkBox') {
        return DataType.Checkbox;
    }
    if (!column.cellRenderer) {
        return DataType.String;
    }
    return DataType.Boolean;
}

function getFilterForDataType(dataType: number): string | void {
    switch (dataType) {
        case DataType.Date:
            return 'agDateColumnFilter';
        case DataType.DateTime:
            return 'agDateColumnFilter';
        case DataType.Percentage:
            return 'percentage';
        case DataType.Number:
            return 'number';
        case DataType.Currency:
            return 'agNumberColumnFilter';
        case DataType.CurrencyWithoutDecimals:
            return 'agNumberColumnFilter';
        case DataType.Image:
            return 'image';   
        case DataType.Boolean:
            return 'boolean';
        case DataType.LongText:
            return 'text';
        case DataType.ContextMenu:
            return 'menu';
        case DataType.DueDate:
            return 'dueDateRenderer';
        case DataType.Checkbox:
            return 'checkBox';
        default:
            return 'text';
    }
}

// var filterParams = {
//     // tslint:disable-next-line: no-any
//     comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
//         var dateAsString = cellValue;
//         if (dateAsString == null) {
//             return -1;
//         }
//         const diff = moment(cellValue).diff(moment(filterLocalDateAtMidnight), 'days');
//         if (diff === 0) {
//             return 0;
//         }
//         if (diff < 0) {
//             return -1;
//         }
//         if (diff > 0) {
//             return 1;
//         } else {
//             return -1;
//         }
//         // if (moment(filterLocalDateAtMidnight).toDate().getDate() === moment(cellValue).format('DD-MMM-YYYY')) {
//         //     return 0;
//         // }
//         // if (moment(cellValue).format('DD-MMM-YYYY') < moment(filterLocalDateAtMidnight).format('DD-MMM-YYYY')) {
//         //     return -1;
//         // }
//         // if (moment(cellValue).format('DD-MMM-YYYY') > moment(filterLocalDateAtMidnight).format('DD-MMM-YYYY')) {
//         //     return 1;
//         // } else {
//         //     return -1;
//         // }
//     },
//     browserDatePicker: true,
// };

export function applyCellRendererToColumn(column: ColDef, dataType: number) {
    switch (dataType) {
        case DataType.DateTime:
            column.cellRendererFramework = DateTimeRenderer;
            break;
        case DataType.Date:
            column.cellRendererFramework = DateRenderer;
            break;
        case DataType.Number:
            column.cellRendererFramework = NumberRenderer;
            break;
        case DataType.Currency:
            column.cellRendererFramework = CurrencyRenderer;
            break;
        case DataType.CurrencyWithoutDecimals:
            column.cellRendererFramework = CurrencyRendererWithoutDecimal;
            break;
        case DataType.Boolean:
            column.cellRendererFramework = BoolRenderer;
            break;
        case DataType.Image:
            column.cellRendererFramework = ImageRenderer;
            break;
        case DataType.Percentage:
            column.cellRendererFramework = PercentageRenderer;
            break;
        case DataType.DueDate:
            column.cellRendererFramework = DueDateRenderer;
            break;
        // case DataType.Link:
        //     column.cellRendererFramework = ReferrerCellRenderer;
        //     break;
        case DataType.ContextMenu:
            column.cellRendererFramework =  MatterTaskAction;
            break;
        case DataType.Checkbox: 
            column.cellRendererFramework = undefined;
            break;
        case DataType.String:
        default:
            return;
    }
}

export function DefaultColumnConfig(): Array<ColDef> {
    var result = new Array<ColDef>();
    MatterSummaryColumns.forEach(column => {
        var filter = getFilterForDataType(column.format);

        var isLocked = column.field === 'FileNumber';
        let showMenuTab = column.format !== DataType.ContextMenu && column.format !== DataType.Checkbox;

        var colDef = {
            // colId: column.field,
            field: column.field ? column.field : '',
            headerName: column.headerName ? column.headerName : '',
            dataType: column.format,
            width: column.width ? column.width : 50,
            format: column.format,
            filter: filter ? filter : '',
            hide: column.hide,
            show: !column.hide,
            pinned: isLocked ? 'left' : 'none',
            isLocked: isLocked,
            menuTabs: showMenuTab ? menuTabs : nonFilterMenuTabs,
            enableRowGroup: true,
            sort: column.sort ? column.sort : '',
            tooltipField: column.format === DataType.String ? column.field : undefined,
            aggFunc: column.aggFunc,
            enableValue: column.enableValue,
            valueGetter: getValueGetter(column.field),
            cellClass: column.cellClass,
            checkboxSelection: column.checkboxSelection ? column.checkboxSelection : undefined,
            headerCheckboxSelection: column.headerCheckboxSelection ? column.headerCheckboxSelection : undefined
            // rowHeight: column.format === DataType.Image ? 50 : 40 ,
        };

        // if (column.field !== 'isSelect') {
        applyCellRendererToColumn(colDef, column.format);
        // }
        
        result.push(colDef);
    });
    return result;   
}

export function DefaultColumnConfigNew(): Array<ColDef> {
    var result = new Array<ColDef>();
    MatterSummaryColumnsNew.forEach(column => {
        var filter = getFilterForDataType(column.format);

        var isLocked = column.field === 'FileNumber';
        let showMenuTab = column.format !== DataType.ContextMenu && column.format !== DataType.Checkbox;

        var colDef = {
            // colId: column.field,
            field: column.field ? column.field : '',
            headerName: column.headerName ? column.headerName : '',
            dataType: column.format,
            width: column.width ? column.width : 50,
            format: column.format,
            filter: filter ? filter : '',
            hide: column.hide,
            show: !column.hide,
            pinned: isLocked ? 'left' : 'none',
            isLocked: isLocked,
            menuTabs: showMenuTab ? menuTabs : nonFilterMenuTabs,
            enableRowGroup: true,
            sort: column.sort ? column.sort : '',
            tooltipField: column.format === DataType.String ? column.field : undefined,
            aggFunc: column.aggFunc,
            enableValue: column.enableValue,
            valueGetter: getValueGetter(column.field),
            cellClass: column.cellClass,
            checkboxSelection: column.checkboxSelection ? column.checkboxSelection : undefined,
            headerCheckboxSelection: column.headerCheckboxSelection ? column.headerCheckboxSelection : undefined
            // rowHeight: column.format === DataType.Image ? 50 : 40 ,
        };

        // if (column.field !== 'isSelect') {
        applyCellRendererToColumn(colDef, column.format);
        // }

        result.push(colDef);
    });
    return result;   
}

export const MatterSummaryColumns: Array<ColumnProperty> = [
    {            
        field: 'isSelect',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,             
        headerName: '',
        width: 50,
        cellClass: cellClassEnum.checkboxType,
        format: DataType.Checkbox,
        sortable: false
    },
    {
        field: 'id',
        headerName: MatterTerminology.MatterId,
        format: DataType.Default,
        hide: true,
        filter: 'number',
        menuTabs: menuTabs,
        canSearch: false,
        cardDisplay: false,
        cellClass: cellClassEnum.numberType,
    },
    {
        field: 'task',
        headerName: 'Task',
        format: DataType.ContextMenu,
        width: 80,
        hide: false,
        menuTabs: menuTabs,
        canSearch: false,
        cardDisplay: false,
        cellRendererFramework: MatterTaskAction,
        cellClass: cellClassEnum.actionType,
    },
    {
        field: 'imageLocation',
        headerName: MatterTerminology.Logo,
        format: DataType.Image,
        cardDisplay: false,
        menuTabs: menuTabs,
        enableRowGroup: false,
        width: 110,
        hide: false,
        pinned: 'left',
        cellClass: cellClassEnum.imageType,
    },
    {
        field: 'fileNumber',
        headerName: MatterTerminology.FileNumber,
        format: DataType.String,
        cardDisplay: false,
        menuTabs: menuTabs,
        enableRowGroup: false,
        pinned: 'left',
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'matterCategory',
        headerName: MatterTerminology.MatterCategory,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'matterType',
        headerName: MatterTerminology.Type,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'stage',
        headerName: MatterTerminology.Stage,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'client',
        headerName: MatterTerminology.Client,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'title',
        headerName: MatterTerminology.Title,
        minWidth: 290,
        cardDisplay: false,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'otherSide',
        headerName: MatterTerminology.OtherSide,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'latestComment',
        headerName: MatterTerminology.LastMessage,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        hide: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'last3Comments',
        headerName: MatterTerminology.Last3Messages,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        hide: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'status',
        headerName: MatterTerminology.Status,
        format: DataType.String,
        menuTabs: menuTabs,
        cardDisplay: false,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'completionOrSettlmentDate',
        headerName: MatterTerminology.SettlementDate,
        format: DataType.Date,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'instructionDate',
        headerName: MatterTerminology.InstructionDate,
        format: DataType.Date,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'nextAction',
        headerName: MatterTerminology.NextAction,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'nextActionDate',
        headerName: MatterTerminology.NextActionDate,
        format: DataType.DueDate,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellRendererFramework: DueDateRenderer,
        cellClass: cellClassEnum.dueDateType,
    },
    {
        field: 'nextDueDescription',
        headerName: MatterTerminology.NextActionItem,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'lastUpdate',
        headerName: MatterTerminology.LastUpdate,
        sort: 'desc',
        format: DataType.DateTime,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'dateTime',
        cellClass: cellClassEnum.dateTimeType,
    },
    {
        field: 'nextRenewalDate',
        headerName: MatterTerminology.NextRenewalDate,
        format: DataType.Date,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'estimatedCosts',
        headerName: MatterTerminology.EstimatedCosts,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'unbilled',
        headerName: MatterTerminology.UnbilledShort,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        'filter': 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'billed',
        headerName: MatterTerminology.BilledInxTax,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        'filter': 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'debtors',
        headerName: MatterTerminology.Debtors,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        'filter': 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'state',
        headerName: MatterTerminology.State,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'country',
        headerName: MatterTerminology.Country,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'family',
        headerName: MatterTerminology.BrandFamily,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'propertyLabel',
        headerName: MatterTerminology.Property,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'owner',
        headerName: MatterTerminology.Owner,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'property',
        headerName: MatterTerminology.PropertyType,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'clientReference',
        headerName: MatterTerminology.ClientReference,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'fIRBStatus',
        headerName: MatterTerminology.FIRB,
        format: DataType.Boolean,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.booleanType,
    },
    {
        field: 'inProgress',
        headerName: MatterTerminology.InProgress,
        format: DataType.Boolean,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.booleanType,
    },
    {
        field: 'isComplete',
        headerName: MatterTerminology.Completed,
        format: DataType.Boolean,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.booleanType,
        // cellClass: 'sampleSample'
    },
    {
        field: 'isAwaitingInstructions',
        headerName: MatterTerminology.IsAwaitingInstructions,
        format: DataType.Boolean,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.booleanType,
    },
];

// Material View's Matter Summary Default columns.
export const MatterSummaryColumnsNew: Array<ColumnProperty> = [
    {            
        field: 'isSelect',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,             
        headerName: '',
        width: 50,
        cellClass: cellClassEnum.checkboxType,
        format: DataType.Checkbox,
        sortable: false
    },
    {
        field: 'id',
        headerName: MatterTerminology.MatterId,
        format: DataType.Number,
        hide: true,
        filter: 'number',
        menuTabs: menuTabs,
        canSearch: false,
        cardDisplay: false,
        cellClass: cellClassEnum.numberType,
    },    
    {
        field: 'task',
        headerName: 'Task',
        format: DataType.ContextMenu,
        hide: false,
        menuTabs: menuTabs,
        canSearch: false,
        cardDisplay: false,
        cellRendererFramework: MatterTaskAction,
        cellClass: cellClassEnum.actionType,
    },
    {
        field: 'guidId',
        headerName: MatterTerminology.GuidId,
        format: DataType.String,
        hide: true,
        menuTabs: menuTabs,
        canSearch: false,
        cardDisplay: false,
        cellClass: cellClassEnum.stringType,
    },    
    {
        field: 'imageLocation',
        headerName: MatterTerminology.Logo,
        format: DataType.Image,
        cardDisplay: false,
        menuTabs: menuTabs,
        enableRowGroup: false,
        width: 110,
        hide: false,
        pinned: 'left',
        cellClass: cellClassEnum.imageType,
    },
    {
        field: 'fileNumber',
        headerName: MatterTerminology.FileNumber,
        format: DataType.String,
        cardDisplay: false,
        menuTabs: menuTabs,
        enableRowGroup: false,
        pinned: 'left',
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'status',
        headerName: MatterTerminology.Status,
        format: DataType.String,
        menuTabs: menuTabs,
        cardDisplay: false,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'title',
        headerName: MatterTerminology.Title,
        minWidth: 290,
        cardDisplay: false,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'longTitle',
        headerName: MatterTerminology.LongTitle,
        minWidth: 290,
        cardDisplay: false,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'countryId',        
        headerName: MatterTerminology.CountryId,
        hide: true,
        format: DataType.Number,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.numberType,
    },
    {
        field: 'state',
        headerName: MatterTerminology.State,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'categoryId',
        headerName: MatterTerminology.CategoryId,
        hide: true,
        format: DataType.Number,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.numberType,
    },
    {
        field: 'category',
        headerName: MatterTerminology.Category,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'typeId',
        headerName: MatterTerminology.TypeId,
        hide: true,
        format: DataType.Number,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.numberType,
    },
    {
        field: 'type',
        headerName: MatterTerminology.Type,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'typeClassification',
        headerName: MatterTerminology.TypeClassification,
        hide: true,
        format: DataType.Number,        
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.numberType,
    },
    {
        field: 'typeState',
        headerName: MatterTerminology.TypeState,
        format: DataType.String,        
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'subTypeID',
        headerName: MatterTerminology.SubTypeID,
        hide: true,
        format: DataType.Number,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.numberType,
    },
    {
        field: 'subType',
        headerName: MatterTerminology.SubType,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'stage',
        headerName: MatterTerminology.Stage,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'fIRB',
        headerName: MatterTerminology.FIRB,
        format: DataType.Boolean,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.booleanType,
    },
    {
        field: 'family',
        headerName: MatterTerminology.BrandFamily,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'property',
        headerName: MatterTerminology.Property,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'propertyLabel',
        headerName: MatterTerminology.PropertyLabel,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'client',
        headerName: MatterTerminology.Client,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'clientReference',
        headerName: MatterTerminology.ClientReference,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'otherSide',
        headerName: MatterTerminology.OtherSide,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'owner',
        headerName: MatterTerminology.Owner,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },    
    {
        field: 'billed',
        headerName: MatterTerminology.BilledInxTax,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        'filter': 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'unbilled',
        headerName: MatterTerminology.UnbilledShort,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        'filter': 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'debtors',
        headerName: MatterTerminology.Debtors,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        'filter': 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'estimatedCosts',
        headerName: MatterTerminology.EstimatedCosts,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'completionDate',
        headerName: MatterTerminology.CompletionDate,
        format: DataType.Date,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'instructionDate',
        headerName: MatterTerminology.InstructionDate,
        format: DataType.Date,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'nextAction',
        headerName: MatterTerminology.NextAction,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'nextActionDate',
        headerName: MatterTerminology.NextActionDate,
        format: DataType.DueDate,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellRendererFramework: DueDateRenderer,
        cellClass: cellClassEnum.dueDateType,
    },
    {
        field: 'nextDueDescription',
        headerName: MatterTerminology.NextActionItem,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'fileLocation',
        headerName: MatterTerminology.FileLocation,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'latestComment',
        headerName: MatterTerminology.LastMessage,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        hide: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'inProgress',
        headerName: MatterTerminology.InProgress,
        format: DataType.Boolean,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.booleanType,
    },
    {
        field: 'publishedDate',
        headerName: MatterTerminology.PublishedDate,
        format: DataType.DateTime,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'dateTime',
        cellClass: cellClassEnum.dateTimeType,
    },
    {
        field: 'lastUpdate',
        headerName: MatterTerminology.LastUpdate,
        sort: 'desc',
        format: DataType.DateTime,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'dateTime',
        cellClass: cellClassEnum.dateTimeType,
    },
    {
        field: 'nextRenewal',
        headerName: MatterTerminology.NextRenewalDate,
        format: DataType.Date,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'isVIP',
        headerName: MatterTerminology.IsVip,
        format: DataType.Boolean,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.booleanType,
    },
    {
        field: 'syncSystemID',
        headerName: MatterTerminology.SyncSystemID,
        hide: true,
        format: DataType.Number,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.numberType,
    },
    {
        field: 'responsibleID',
        headerName: MatterTerminology.ResponsibleID,
        hide: true,
        width: 150,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'responsible',
        headerName: MatterTerminology.ResponsibleShort,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'responsibleEmail',
        headerName: MatterTerminology.ResponsibleEmail,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'responsiblePhone',
        headerName: MatterTerminology.ResponsiblePhone,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'actingID',
        headerName: MatterTerminology.ActingID,
        format: DataType.Number,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        hide: true,
        cellClass: cellClassEnum.numberType,
    },
    {
        field: 'acting',
        headerName: MatterTerminology.Acting,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'actingEmail',
        headerName: MatterTerminology.ActingEmail,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'actingPhone',
        headerName: MatterTerminology.ActingPhone,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'assistingID',
        headerName: MatterTerminology.AssistingID,
        format: DataType.Number,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        hide: true,
        cellClass: cellClassEnum.numberType,
    },
    {
        field: 'assisting',
        headerName: MatterTerminology.Assisting,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'assistingEmail',
        headerName: MatterTerminology.AssistingEmail,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'assistingPhone',
        headerName: MatterTerminology.AssistingPhone,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'price',
        headerName: MatterTerminology.Price,
        format: DataType.CurrencyWithoutDecimals,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: false,
        'filter': 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyWithoutDecimalsType,
    },
    {
        field: 'deposit',
        headerName: MatterTerminology.Deposit,
        format: DataType.CurrencyWithoutDecimals,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: false,
        'filter': 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyWithoutDecimalsType,
    },
    {
        field: 'billedFees',
        headerName: MatterTerminology.BilledFees,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 130,
        'filter': 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'unbilledFees',
        headerName: MatterTerminology.UnbilledFees,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        'filter': 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'unbilledDisbursements',
        headerName: MatterTerminology.UnbilledDisbursementsShort,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: true,
        'filter': 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'percentageEstimate',
        headerName: MatterTerminology.PercentageEstimateShort,
        format: DataType.Percentage,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 120,
        aggFunc: 'percentageEstimate',
        enableValue: true,
        valueGetter: 'percentageEstimate',
        cellClass: cellClassEnum.percentageType,
    },    
    {
        field: 'exposure',
        headerName: MatterTerminology.PercentageExposure,
        format: DataType.Percentage,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        aggFunc: 'exposure',
        enableValue: true,
        valueGetter: 'exposure',
        cellClass: cellClassEnum.percentageType,
    },
    {
        field: 'trustBalance',
        headerName: MatterTerminology.TrustBalance,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'exchangeDate',
        headerName: MatterTerminology.ExchangeDate,
        format: DataType.Date,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'coolingOffDate',
        headerName: MatterTerminology.CoolingOffDate,
        format: DataType.Date,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'depositDueDate',
        headerName: MatterTerminology.DepositDueDate,
        format: DataType.Date,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'depositPaidDate',
        headerName: MatterTerminology.DepositPaidDate,
        format: DataType.Date,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'earliestPriorityDate',
        headerName: MatterTerminology.EarliestPriorityDate,
        format: DataType.Date,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'applicationNumber',
        headerName: MatterTerminology.ApplicationNumber,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'publicationDate',
        headerName: MatterTerminology.PublicationDate,
        format: DataType.Date,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'publicationNumber',
        headerName: MatterTerminology.PublicationNumber,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'grantNumber',
        headerName: MatterTerminology.GrantNumber,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'class',
        headerName: MatterTerminology.Class,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'goodsAndServices',
        headerName: MatterTerminology.GoodsAndServices,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'costAgreementReceivedDate',
        headerName: MatterTerminology.CostAgreementReceivedDate,
        format: DataType.Date,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'costAgreementRequired',
        headerName: MatterTerminology.CostAgreementRequired,
        format: DataType.Boolean,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.booleanType,
    },
    {
        field: 'referrer',
        headerName: MatterTerminology.Referrer,
        format: DataType.String,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'trustAccountBalance',
        headerName: MatterTerminology.PotentialTransfer,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: true,
        hide: false,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'priorityCountry',
        headerName: MatterTerminology.PriorityCountry,
        format: DataType.String,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'reviewDate',
        headerName: MatterTerminology.ReviewDate,
        format: DataType.Date,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'injuryDate',
        headerName: MatterTerminology.InjuryDate,
        format: DataType.Date,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'proceedingsIssuedDate',
        headerName: MatterTerminology.ProceedingsIssuedDate,
        format: DataType.Date,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'sunsetOrLimitationDate',
        headerName: MatterTerminology.SunsetOrLimitationDate,
        format: DataType.Date,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'hearingDate',
        headerName: MatterTerminology.HearingDate,
        format: DataType.Date,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'trustee',
        headerName: MatterTerminology.Trustee,
        format: DataType.String,
        menuTabs: menuTabs,
        width: 250,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'trusteeEmail',
        headerName: MatterTerminology.TrusteeEmail,
        format: DataType.String,
        menuTabs: menuTabs,
        width: 250,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'isAwaitingInstructions',
        headerName: MatterTerminology.IsAwaitingInstructions,
        format: DataType.Boolean,
        menuTabs: menuTabs,
        enableRowGroup: true,
        cellClass: cellClassEnum.booleanType,
    },
    {
        field: 'clientType',
        headerName: MatterTerminology.ClientType,
        format: DataType.String,
        menuTabs: menuTabs,
        width: 250,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'lastTrustTransactionDate',
        headerName: MatterTerminology.LastTrustTransactionDate,
        format: DataType.Date,
        menuTabs: menuTabs,
        width: 250,
        enableRowGroup: true,
        cellClass: cellClassEnum.dateType,
    },
    {
        field: 'lastTrustTransactionType',
        headerName: MatterTerminology.LastTrustTransactionType,
        format: DataType.String,
        menuTabs: menuTabs,
        width: 250,
        enableRowGroup: true,
        cellClass: cellClassEnum.stringType,
    },
    {
        field: 'fundsRecovered',
        headerName: MatterTerminology.FundsRecovered,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'forecastFeesBilledThisMonth',
        headerName: MatterTerminology.ForecastFeesBilledThisMonth,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'forecastFeesBilledNextThreeMonths',
        headerName: MatterTerminology.ForecastFeesBilledNextThreeMonths,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'forecastFeesBilledNextSixMonths',
        headerName: MatterTerminology.ForecastFeesBilledNextSixMonths,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'forecastFeesCollectedThisMonth',
        headerName: MatterTerminology.ForecastFeesCollectedThisMonth,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'forecastFeesCollectedNextThreeMonths',
        headerName: MatterTerminology.ForecastFeesCollectedNextThreeMonths,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'forecastFeesCollectedNextSixMonths',
        headerName: MatterTerminology.ForecastFeesCollectedNextSixMonths,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'forecastHoursThisMonth',
        headerName: MatterTerminology.ForecastHoursThisMonth,
        format: DataType.Number,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.numberType,
    },
    {
        field: 'forecastHoursNextThreeMonths',
        headerName: MatterTerminology.ForecastHoursNextThreeMonths,
        format: DataType.Number,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.numberType,
    },
    {
        field: 'forecastHoursNextSixMonths',
        headerName: MatterTerminology.ForecastHoursNextSixMonths,
        format: DataType.Number,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.numberType,
    },
    {
        field: 'expectedFutureFees',
        headerName: MatterTerminology.ExpectedFutureFees,
        format: DataType.Currency,
        menuTabs: menuTabs,
        enableRowGroup: false,
        hide: false,
        width: 140,
        filter: 'number',
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType,
    },
    {
        field: 'archivedDate',
        headerName: MatterTerminology.ArchivedDate,
        format: DataType.Date,
        width: 150,
        menuTabs: menuTabs,
        enableRowGroup: true,
        filter: 'date',
        cellClass: cellClassEnum.dateType,
    }
];

// tslint:disable-next-line: no-any
export const MatterSummaryAggFuncs: any = {
    // tslint:disable-next-line: no-any
    'percentageEstimate': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'exposure': (params: any) => {
        return getCustomAggFuncValue(params);
    }
};

// tslint:disable-next-line: no-any
export const getCustomAggFuncValue = (params: any) => {
    let percentageTotal = 0;
    if (params && params.length > 0) {
        let valueOne = 0;
        let valueTwo = 0;           
            // tslint:disable-next-line: no-any
        params.forEach((value: any) => {
            valueOne += value.valueOne;
            valueTwo += value.valueTwo;
        });

        if (valueOne > 0) {
            percentageTotal = (valueOne / valueTwo) * 100;
        }
        
        return createValueObject(valueOne, valueTwo);            
    }        
    return percentageTotal;
};

export const createValueObject = (valueOne: number, valueTwo: number) => {
    return {
        valueOne: valueOne,
        valueTwo: valueTwo,
        toString: function () {
            return (valueOne && valueTwo ? (valueOne / valueTwo) * 100 : 0).toString();
        },
    };
};

export const percentageEstimate = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject((params.data.billedFees ? params.data.billedFees : 0), 
                                 (params.data.estimatedCosts ? params.data.estimatedCosts : 0));
    } else {
        return undefined;
    }
};

export const exposure = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        let fees = (params.data.billedFees ? params.data.billedFees : 0) + ( params.data.unbilled ? params.data.unbilled : 0);

        return createValueObject(fees, (params.data.estimatedCosts ? params.data.estimatedCosts : 0) );
    } else {
        return undefined;
    }
};