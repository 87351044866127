import * as React from 'react';
import { graphql } from 'react-apollo';
import { Subscription } from 'apollo-client/util/Observable';
import glamorous from 'glamorous';
// import { throttle } from 'lodash';
import { MatterSummaryHeader } from './MatterSummaryHeader';
import { RvContainer } from '../components/Container';
import { MatterSummaryControlPanel, ViewDisplayStyle, ExportType } from './MatterSummaryControlPanel';
// import { MatterSummaryControlPanel, ViewDisplayStyle, ExportType } from './MatterSummaryControlPanel_Obsolete';
import { RvDrawer } from '../components/Drawer';
import { SummaryViewConfig } from '../viewConfig/SummaryViewConfig';
import {
    MatterOptionsData,
    MatterSummaryOptions,
    fetchColumnConfigData,
    fetchMatterSummaryNewEnabled,
    MatterSummaryNewEnabledOption,
    MatterTaskNotification,
    // MatterForecastSubscription,
} from './MatterSummaryRepository';
import { SummaryViewConfigObj, ColumnConfig } from '../viewConfig/SummaryViewConfigurations';
import { SaveSummaryViewConfiguration } from '../viewConfig/SummaryViewConfigRepository';
import { showNotification } from '../App';
import { MatterSummaryView } from '../components/LoadComponentAsync';
import EventSystem from '../EventSystem';
import _, { throttle } from 'lodash';
import { client } from '..';
import gql from 'graphql-tag';
import ReactDOM from 'react-dom';
import { ViewConfigType } from '../viewConfig/ViewConfigType';
import { SummaryViewConfigNotification } from '../viewConfig/SummaryViewConfigRepository';
import { TaskSubscription } from '../tasksSummary/TasksSummaryRepository';
import { CellClickedEvent } from '@ag-grid-community/core';
import { browserHistory } from '../components/AppContent';
import { AdvancedFilterDialog, AdvancedFilterForm, CaseType, MatterCategory, MatterClient, MatterCountry, MatterOwner, MatterProperty, 
    MatterStatus, PersonActing, PersonResponsible, PriorityCountry } from './dialogs/AdvancedFilterDialog';
import { SearchCriteria } from '../home/SearchCriteria';
import { CountryAutoCompleteItem } from './selectors/MatterCountrySelector';
import { MatterTypeTypeAutoCompleteItem } from './selectors/MatterTypeSelector';
import { MatterPropertyAutoCompleteItem } from './selectors/MatterPropertySelector';
import { MatterCategoryAutoCompleteItem } from './selectors/MatterCategorySelector';
import { NameSummaryAutoCompleteItem } from './selectors/MatterClientSelector';
import { MatterStatusAutoCompleteItem } from './selectors/MatterStatusSelector';
import moment from 'moment';
import { PriorityCountryAutoCompleteItem } from './selectors/PriorityCountrySelector';
import { StaffSummaryAutoCompleteItem } from './selectors/StaffSelector';
import { MatterTerminology } from '../MatterTerminology';
import { MatterSummaryContext } from './MatterSummaryContextProvider';
import { MatterForecastsDrawer } from './drawers/MatterForecastsDrawer';

const GridWrapper = glamorous.div<{ theme?: object }>((props) => ({
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    '& .banner-container': {
        width: '100%',
    },
    '& .content-container': {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexFlow: 'column',
    },
    [`& .${`pushable`}`]: {
        overflow: 'visible',
        [`& .${`sidebar`}`]: {
            top: '0px !important',
            width: '500px !important',
        },
    },
    [`& .${`segment`}`]: {
        // overflow: 'visible',
        // top: '10px !important',
        padding: 0,
        // background: props.theme.NeutralColors.BackgroundGrey5
    },
}));

const ContentWrapper = glamorous.div<{ theme?: object }>((props) => ({
    padding: '0 34px',
    flex: 1,
    display: 'flex',
    flexFlow: 'column',
    '@media (max-width: 1024px)': {
        padding: 0,
    },
}));

// tslint:disable-next-line:no-any
const StyledSummaryContent = glamorous.div<{ theme?: any }>((props) => ({
    borderRadius: '8px',
    backgroundColor: props.theme.NeutralColors.BackgroundWhite,
    boxShadow: '0 0 14px 0 ' + props.theme.NeutralColors.BackgroundGrey4,
    // height: window.innerHeight - 300,
    // height: '100%',
    flex: 1,
    display: 'flex',
    flexFlow: 'column',
}));

const SummaryViewWrapper = glamorous.div<{ theme?: object }>((props) => ({
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingBottom: '20px',
    flex: 1,
    display: 'flex',
    flexFlow: 'column',
    '& .drawergrid': {
        flex: 1,
    },
    '& .sidebarpusher': {
        display: 'flex',
        flexFlow: 'column',
    },
    '& .segment-container': {
        flex: 1,
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
    },
    '& .matter-view-wrapper': {
        flex: 1,
        display: 'flex',
        flexFlow: 'column',
    },
}));

interface MatterSummaryPageDataProps {
    // tslint:disable-next-line: no-any
    location: any;
    data: MatterOptionsData;
    nameId?: number;
    isReferredMatters?: boolean;
    // tslint:disable-next-line: no-any
    entityType?: any;
    // tslint:disable-next-line: no-any
    nameSummaryState?: any;
}

interface MatterSummaryPageState {
    viewDisplayStyle: ViewDisplayStyle;
    showConfigPanel: boolean;
    showToolPanel: boolean;
    showGroupPanel: boolean;
    search: {
        filter: string;
        includeCompleted: boolean;
    };
    summarySectionHeight: string;
    isLoading: boolean;
    inRefreshData: boolean;
    totalMatterCount: number;
    matterLoadedCount: number;

    // Column configuration & Access
    summaryViewSelections: Array<SummaryViewConfigObj>;
    currentSummaryViewSelectionId: number;
    currentSummaryViewConfig: string;
    isDisableSave: boolean;
    isInMemory: boolean;
    isDownloadMatter: boolean;
    isForceDownload: boolean;
    isNewMatterSummaryEnabled: boolean;
    // tslint:disable-next-line: no-any
    columnFilterModel?: any;
    showAdvancedFilter: boolean;
    hasFilter: boolean;
    filterValues?: AdvancedFilterForm;
    searchCriterias?: string;
    isExportLoading: boolean;
}

class MatterSummaryPage extends React.Component<MatterSummaryPageDataProps, MatterSummaryPageState> {

    static contextType = MatterSummaryContext;

    private summaryViewConfigSubscription: Subscription;
    private matterTaskSubscription: Subscription;
    private taskSubscription: Subscription;
    private forecastSubscription: Subscription;
    private isSubscribed: boolean;
    // tslint:disable-next-line: no-any
    private summarySectionNode?: any;
    private isLoading: boolean;

    private pageKey: number = 0;

    constructor(props: MatterSummaryPageDataProps, state: MatterSummaryPageState) {
        super(props, state);
        this.isSubscribed = false;

        this.handleSummarySectionRef = this.handleSummarySectionRef.bind(this);
        this.onIncludeCompleted = this.onIncludeCompleted.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onRefreshData = this.onRefreshData.bind(this);
        this.onViewStyle = this.onViewStyle.bind(this);
        this.onViewSelected = this.onViewSelected.bind(this);
        this.onSaveCurrentView = this.onSaveCurrentView.bind(this);
        this.onViewConfigChanged = this.onViewConfigChanged.bind(this);
        this.onToggleGrouping = this.onToggleGrouping.bind(this);
        this.onToggleTools = this.onToggleTools.bind(this);
        this.onToggleConfigs = this.onToggleConfigs.bind(this);
        this.onMatterSelected = this.onMatterSelected.bind(this);
        this.onCellClicked = this.onCellClicked.bind(this);
        this.onMatterCounts = this.onMatterCounts.bind(this);
        this.initSummaryViewConfigs = this.initSummaryViewConfigs.bind(this);
        this.onExport = this.onExport.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.handleLoading = this.handleLoading.bind(this);
        this.fetchColumnConfigData = this.fetchColumnConfigData.bind(this);
        this.fetchIsMatterSummaryNewEnabled = this.fetchIsMatterSummaryNewEnabled.bind(this);
        this.onInMemoryChanged = this.onInMemoryChanged.bind(this);
        this.onMatterDownloadChanged = this.onMatterDownloadChanged.bind(this);
        this.onColumnFilterChanged = this.onColumnFilterChanged.bind(this);

        this.subscribeToSummaryViewConfig = this.subscribeToSummaryViewConfig.bind(this);
        this.onAdvancedFilter = this.onAdvancedFilter.bind(this);
        this.onAdvancedSearch = this.onAdvancedSearch.bind(this);
        this.onFormatFilterValuesForSearchCriteria = this.onFormatFilterValuesForSearchCriteria.bind(this);
        this.onExportLoading = this.onExportLoading.bind(this);

        this.isLoading = false;

        this.state = {
            viewDisplayStyle: ViewDisplayStyle.List,
            showGroupPanel: false,
            showToolPanel: false,
            showConfigPanel: false,
            search: {
                filter: '',
                includeCompleted: false,
            },
            summarySectionHeight: '',
            isLoading: true,
            inRefreshData: false,
            totalMatterCount: 0,
            matterLoadedCount: 0,
            summaryViewSelections: new Array<SummaryViewConfigObj>(),
            currentSummaryViewSelectionId: 0,
            currentSummaryViewConfig: '',
            isDisableSave: true,
            isInMemory: false,
            isDownloadMatter: false,
            isNewMatterSummaryEnabled: false,
            isForceDownload: false,
            showAdvancedFilter: false,
            hasFilter: false,
            searchCriterias: undefined,
            isExportLoading: false
        };
    }

    public componentDidUpdate() {

        const { pageKey } = this.context;

        // console.log('MatterSummaryPageXContext', pageKey, matterId, matterGuid, isMatterForcastFormOpen);

        if (pageKey !== this.pageKey) {
            this.pageKey = pageKey;
            this.onRefreshData();
        }
    }

    public UNSAFE_componentWillMount() {
        this.isLoading = true;
        this.fetchIsMatterSummaryNewEnabled();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: MatterSummaryPageDataProps) {
        if (this.props.location && this.props.location.state !== undefined && this.props.location.state !== null && this.state !== this.props.location.state) {
            // Why set them seperately? - SummaryViewConfig does not include cell renderer components here. So it is not rendering properly.
            // tslint:disable-next-line: no-console
            console.log('matterSummaryPageXState', this.props.location.state.isInMemory);

            let columnFilterLength = 0;
            if (this.props.location.state.columnFilterModel) {
                columnFilterLength = Object.keys(this.props.location.state.columnFilterModel).length;
            }

            const defaultMatterCountry: MatterCountry = {
                matterCountry: [],
                exclude: false,
            };
        
            const defaultCaseType: CaseType = {
                caseType: [],
                exclude: false,
            };
        
            const defaultMatterProperty: MatterProperty = {
                matterProperty: [],
                exclude: false,
            };
        
            const defaultMatterCategory: MatterCategory = {
                matterCategory: [],
                exclude: false,
            };
        
            const defaultMatterClient: MatterClient = {
                matterClient: [],
                exclude: false,
            };
        
            const defaultMatterOwner: MatterOwner = {
                matterOwner: [],
                exclude: false,
            };
        
            const defaultMatterStatus: MatterStatus = {
                matterStatus: [],
                exclude: false,
            };
        
            const defaultPriorityCountry: PriorityCountry = {
                priorityCountry: [],
                exclude: false
            };

            const defaultPersonActings: PersonActing = {
                personActing: [],
                exclude: false,
            };
        
            const defaultPersonResponsibles: PersonResponsible = {
                personResponsible: [],
                exclude: false,
            };
            
            const defaultValues: AdvancedFilterForm = {
                ourReference: undefined,
                yourReference: undefined,
                matterCountries: defaultMatterCountry,
                caseTypes: defaultCaseType,
                matterProperties: defaultMatterProperty,
                matterCategories: defaultMatterCategory,
                matterClients: defaultMatterClient,
                matterOwners: defaultMatterOwner,
                matterStatuses: defaultMatterStatus,
                filingDateFrom: undefined,
                filingDateTo: undefined,
                applicationNumber: undefined,
                grantAndRegistrationDateFrom: undefined,
                grantAndRegistrationDateTo: undefined,
                registrationNumber: undefined,
                priorityDateFrom: undefined,
                priorityDateTo: undefined,
                title: undefined,
                class: undefined,
                goodsAndServices: undefined,
                priorityCountries: defaultPriorityCountry,
                completionDateFrom: undefined,
                completionDateTo: undefined,
                instructionDateFrom: undefined,
                instructionDateTo: undefined,
                personActings: defaultPersonActings,
                personResponsibles: defaultPersonResponsibles,
                feesBilledVsEstimatePercentage: 0,
                isMatterEstimateZero: false
            };

            let hasFilter = false;

            if (!_.isMatch(defaultValues, this.props.location.state.filterValues)) {
                hasFilter = true;
            }

            this.setState({
                summaryViewSelections: new Array<SummaryViewConfigObj>(),
                isNewMatterSummaryEnabled: this.props.location.state.isNewMatterSummaryEnabled,
                currentSummaryViewConfig: this.props.location.state.currentSummaryViewConfig,
                currentSummaryViewSelectionId: this.props.location.state.currentSummaryViewSelectionId,
                search: {
                    filter: this.props.location.state.search.filter,
                    includeCompleted: this.props.location.state.search.includeCompleted,
                },
                columnFilterModel: this.props.location.state.columnFilterModel,
                isForceDownload: this.props.location.state.isInMemory === true && columnFilterLength > 0,
                filterValues: this.props.location.state.filterValues,
                hasFilter: hasFilter,
                searchCriterias: this.onFormatFilterValuesForSearchCriteria(this.props.location.state.filterValues),
            });
        }
    }

    public componentDidMount() {
        // this.hasMounted = true;
        this.resize();
        window.addEventListener('resize', this.resize);

        // this.fetchColumnConfigData();

        if (!this.isSubscribed) {
            this.isSubscribed = true;
            this.subscribeToSummaryViewConfig();
            this.subscribeToMatterTasks();
            this.subscribeToTasks(); // For Create Task Dialog/ Matter Task specific dialog
            // this.subscribeToForecast();
        }
    }

    public componentWillUnmount() {
        // this.hasMounted = false;
        window.removeEventListener('resize', this.resize);

        if (this.summaryViewConfigSubscription) {
            this.summaryViewConfigSubscription.unsubscribe();
        }

        if (this.matterTaskSubscription) {
            this.matterTaskSubscription.unsubscribe();
        }

        if (this.taskSubscription) {
            this.taskSubscription.unsubscribe();
        }

        if (this.forecastSubscription) {
            this.forecastSubscription.unsubscribe();
        }
    }

    public render() {

        const { matterId, matterGuid, isMatterForcastFormOpen, onMatterForcastFormClose } = this.context;

        if (this.props.data && this.props.data.loading) {
            return null;
        }
        if (this.isLoading) {
            return null;
        }

        return (
            <>
                {isMatterForcastFormOpen && matterGuid && matterId && (
                    <MatterForecastsDrawer 
                        open={isMatterForcastFormOpen}
                        onClose={onMatterForcastFormClose}
                        matterId={matterId}
                        matterGuid={matterGuid}
                    />
                )}
                <GridWrapper className="gridwrapper mattersummry-wrapper">
                    {!!this.state.showAdvancedFilter && (
                        <AdvancedFilterDialog
                            open={this.state.showAdvancedFilter}
                            onClose={this.onAdvancedFilter(false)}
                            onSearch={this.onAdvancedSearch}
                            filterValues={this.state.filterValues}
                        />
                    )}
                    <RvContainer rvClassName="banner-container">
                        {/* <MatterSummaryHeader key="msh" /> */}
                        <MatterSummaryHeader 
                            title={MatterTerminology.Matters}
                        />
                    </RvContainer>
                    <RvContainer rvClassName="content-container">
                        <ContentWrapper className="content-wrapper">
                            <StyledSummaryContent className="summarytablediv">
                                <MatterSummaryControlPanel
                                    key="mscp"
                                    showViewLayouts={false}
                                    views={this.state.summaryViewSelections}
                                    isLoading={this.state.isLoading}
                                    initialIncludeCompleted={this.state.search.includeCompleted}
                                    loadedMatterCount={this.state.matterLoadedCount}
                                    totalMatterCount={this.state.totalMatterCount}
                                    viewDisplayStyle={this.state.viewDisplayStyle}
                                    onIncludeCompleted={this.onIncludeCompleted}
                                    onSearch={this.onSearch}
                                    searchFilter={this.state.search.filter}
                                    onViewStyle={this.onViewStyle}
                                    onViewSelected={this.onViewSelected}
                                    onSaveCurrentView={this.onSaveCurrentView}
                                    onToggleGrouping={this.onToggleGrouping}
                                    onToggleTools={this.onToggleTools}
                                    onToggleConfigs={this.onToggleConfigs}
                                    onRefreshData={this.onRefreshData}
                                    onExport={this.onExport}
                                    onPrint={this.onPrint}
                                    isShowViewPanel={this.state.showConfigPanel}
                                    isDisableSave={this.state.isDisableSave}
                                    isInMemory={this.state.isInMemory}
                                    onMatterDownloadChanged={this.onMatterDownloadChanged}
                                    entityType={this.props.entityType}
                                    className="control-panel"
                                    isForceDownload={this.state.isForceDownload}
                                    onAdvancedFilter={this.onAdvancedFilter(true)}
                                    hasFilter={this.state.hasFilter}
                                    isExportLoading={this.state.isExportLoading}
                                />
                                <SummaryViewWrapper className="summaryview-wrapper">
                                    <RvDrawer
                                        key="msvw"
                                        visible={this.state.showConfigPanel}
                                        orientation="right"
                                        animation="scale down"
                                        drawerContent={
                                            <SummaryViewConfig
                                                configType={ViewConfigType.matterSummary} // 1 = Matter Summary
                                                isAdministrator={this.props.data.appUser.isAdministrator}
                                                onClose={this.onToggleConfigs}
                                                onRowSelected={this.onViewSelected}
                                                views={this.state.summaryViewSelections}
                                                viewConfig={this.state.currentSummaryViewConfig}
                                                viewConfigId={this.state.currentSummaryViewSelectionId}
                                                onViewUpdated={this.fetchColumnConfigData}
                                                isNewMatterSummary={this.state.isNewMatterSummaryEnabled}
                                            />
                                        }
                                        width="thin"
                                    >
                                        <MatterSummaryView
                                            key="msv"
                                            isAdministrator={this.props.data.appUser.isAdministrator}
                                            viewConfigId={this.state.currentSummaryViewSelectionId}
                                            viewConfig={this.state.currentSummaryViewConfig}
                                            refreshSummaryData={this.state.inRefreshData}
                                            viewDisplayStyle={this.state.viewDisplayStyle}
                                            showTools={this.state.showToolPanel}
                                            showGrouping={this.state.showGroupPanel}
                                            filter={this.state.search.filter}
                                            showCompleted={this.state.search.includeCompleted}
                                            onMatterSelected={this.onMatterSelected}
                                            onMatterCounts={this.onMatterCounts}
                                            onViewConfigChanged={this.onViewConfigChanged}
                                            onLoading={this.handleLoading}
                                            onInMemoryChanged={this.onInMemoryChanged}
                                            isDownloadMatter={this.state.isDownloadMatter}
                                            isNewMatterSummaryEnabled={this.state.isNewMatterSummaryEnabled}
                                            nameId={this.props.nameId}
                                            isReferredMatters={this.props.isReferredMatters}
                                            onCellClicked={this.onCellClicked}
                                            onColumnFilterChanged={this.onColumnFilterChanged}
                                            columnFilterModel={this.state.columnFilterModel}
                                            searchCriteriasJson={this.state.searchCriterias}
                                            onExportLoading={this.onExportLoading}
                                        />
                                    </RvDrawer>
                                </SummaryViewWrapper>
                            </StyledSummaryContent>
                        </ContentWrapper>
                    </RvContainer>
                </GridWrapper>
            </>
        );
    }

    // tslint:disable-next-line:member-ordering
    resize = throttle(() => {
        if (this.summarySectionNode) {
            //
            var node = ReactDOM.findDOMNode(this.summarySectionNode);

            if (node instanceof Element) {
                var summaryGridRect = node.getBoundingClientRect();

                this.setState({
                    // should calc the footer but hardcode for now
                    summarySectionHeight: window.innerHeight - summaryGridRect.top - 100 + 'px',
                });
            }
        }
        // tslint:disable-next-line:align
    }, 50);

    private handleSummarySectionRef(node: HTMLElement) {
        this.summarySectionNode = node;
    }

    private onIncludeCompleted(include: boolean) {
        this.setState({
            search: {
                includeCompleted: include,
                filter: this.state.search.filter,
            },
        });
    }

    private onSearch(filter: string) {
        this.setState({
            search: {
                includeCompleted: this.state.search.includeCompleted,
                filter: filter,
            },
        });
    }

    private onViewStyle(style: ViewDisplayStyle) {
        this.setState({ viewDisplayStyle: style });
    }

    private fetchColumnConfigData() {
        fetchColumnConfigData(
            true,
            ViewConfigType.matterSummary,
            (data: ColumnConfig) => {
                this.handleColumnConfigFetch(data);
            },
            (reason) => {
                showNotification('Failed to Fetch Templates', reason, 'error');
            },
        );
    }

    private fetchIsMatterSummaryNewEnabled() {
        fetchMatterSummaryNewEnabled(
            false,
            (data: MatterSummaryNewEnabledOption) => {
                this.handleMatterSummaryNewEnabledFetch(data);
            },
            (reason) => {
                this.fetchColumnConfigData();
                showNotification('Failed to Matter Summary New Enabled Lookup value', reason, 'error');
            },
        );
    }

    private handleMatterSummaryNewEnabledFetch(data?: MatterSummaryNewEnabledOption) {
        if (data != null && data.options !== undefined && data.options.matterSummaryNewEnabled.boolValue !== undefined) {
            this.setState({
                isNewMatterSummaryEnabled: data.options.matterSummaryNewEnabled.boolValue,
            });
        } else {
            this.setState({
                isNewMatterSummaryEnabled: false,
            });
        }

        this.isLoading = false;

        // If a ViewConfig is already selected, then no need to fetch this
        this.fetchColumnConfigData();
    }

    private handleColumnConfigFetch(data?: ColumnConfig) {
        // data will be null for the initial load
        if (data != null && data.columnConfigList !== undefined) {
            this.initSummaryViewConfigs(data.columnConfigList.summaryViewConfig);
        } else {
            this.initSummaryViewConfigs(new Array<SummaryViewConfigObj>());
        }
    }

    private initSummaryViewConfigs(summaryViewConfig: Array<SummaryViewConfigObj>) {
        // Set the default layouts. If no defaults in the database, then set the default of layout exists in UI
        let defaultConfigId = 0;
        let currentConfigLayout = '';

        let summaryViewDefault: SummaryViewConfigObj | undefined;

        // 1) Check for User's Default
        summaryViewDefault = _.find(summaryViewConfig, (item: SummaryViewConfigObj) => {
            return item.isDefault === true;
        });

        // 2) If no user default available, then Role's Default
        if (summaryViewDefault === undefined) {
            summaryViewDefault = _.find(summaryViewConfig, (item: SummaryViewConfigObj) => {
                return item.isRoleDefault === true;
            });
        }

        // 3) If no role's default available, then Tenant's default
        if (summaryViewDefault === undefined) {
            summaryViewDefault = _.find(summaryViewConfig, (item: SummaryViewConfigObj) => {
                return item.isTenantDefault === true;
            });
        }

        if (summaryViewDefault === undefined) {
            defaultConfigId = 0;
        } else {
            defaultConfigId = summaryViewDefault.configId;
            currentConfigLayout = summaryViewDefault.layoutConfiguration;
        }

        let isDisableSave = summaryViewDefault ? !this.props.data.appUser.isAdministrator && !summaryViewDefault.isPrivate : true;

        if (this.props.data.appUser.isAdministrator) {
            isDisableSave = false;
        }

        if (this.state.currentSummaryViewConfig === '' && this.state.currentSummaryViewSelectionId === 0) {
            this.setState({
                summaryViewSelections: summaryViewConfig,
                currentSummaryViewSelectionId: defaultConfigId,
                currentSummaryViewConfig: currentConfigLayout,
                isDisableSave: isDisableSave,
            });
        } else {
            this.setState({
                summaryViewSelections: summaryViewConfig,
                isDisableSave: isDisableSave,
            });
        }
    }

    private onRefreshData() {
        // there is probably a better way but we only want to set refresh temprorarily
        this.setState({
            inRefreshData: true,
            columnFilterModel: undefined,
        });

        // To remove any states in this.props.location.state
        window.history.replaceState(null, '');

        // When refresh is clicked, set the state of Loading to true. Which will disable to refresh button.
        // When refresh is done. Change the state of loading back to false.
        this.handleLoading(true);
    }

    private onToggleGrouping(show: boolean) {
        this.setState({ showGroupPanel: show });
    }

    private onToggleTools(show: boolean) {
        this.setState({ showToolPanel: show });
        // remove select/unselect all in filter
        window.jQuery('.ag-primary-cols-header-panel').find('a[ref="eSelect"]').remove();
    }

    private onToggleConfigs(show: boolean) {
        this.setState({
            showConfigPanel: show,
        });
    }

    // This Update only changes the Layout. Save through Control Panel
    private onSaveCurrentView() {
        let currentSummaryView = _.find(this.state.summaryViewSelections, (item: SummaryViewConfigObj) => {
            return item.configId === this.state.currentSummaryViewSelectionId;
        });

        if (currentSummaryView !== undefined) {
            SaveSummaryViewConfiguration(
                this.state.currentSummaryViewSelectionId,
                currentSummaryView.name,
                false,
                currentSummaryView.isPrivate,
                currentSummaryView.isDefault,
                currentSummaryView.isTenantDefault,
                ViewConfigType.matterSummary, // 1 = Matter Summary
                this.state.currentSummaryViewConfig,
                false,
                this.state.isNewMatterSummaryEnabled,
                () => {
                    // Success
                    showNotification(null, 'Successfully submitted', 'info');
                },
                (reason) => {
                    showNotification('Failed to Save Template', reason, 'error');
                },
            );
        }
    }

    private onViewSelected(configId: number) {
        let currentConfigLayout = '';
        let isDisableSave = false;

        if (this.state.summaryViewSelections) {
            // search the views for the
            var selection = this.state.summaryViewSelections.find((view) => view.configId === configId);
            currentConfigLayout = selection ? selection.layoutConfiguration : '';
            isDisableSave = selection ? !this.props.data.appUser.isAdministrator && !selection.isPrivate : true;
        }

        this.setState({
            currentSummaryViewSelectionId: configId,
            currentSummaryViewConfig: currentConfigLayout,
            isDisableSave: isDisableSave,
        });
    }

    private onViewConfigChanged(config: string) {
        // Update the Config
        let columnConfigs = _.cloneDeepWith(this.state.summaryViewSelections);

        let matterColumnConfig = columnConfigs.find((item: SummaryViewConfigObj) => {
            return item.configId === this.state.currentSummaryViewSelectionId;
        });

        if (matterColumnConfig !== undefined) {
            matterColumnConfig.layoutConfiguration = config.toString();

            _.forEach(columnConfigs, (item: SummaryViewConfigObj) => {
                if (item.configId === this.state.currentSummaryViewSelectionId) {
                    if (matterColumnConfig !== undefined) {
                        item = matterColumnConfig;
                    }
                }
            });
        }

        // Update the collection.
        this.setState({
            summaryViewSelections: columnConfigs,
            currentSummaryViewConfig: config,
        });
    }

    private onMatterSelected(matterId: number) {
        // TODO: onMatterSelected to Open in double click.
        // client.mutate({
        //     mutation: AddUserMatterAccessActivity,
        //     variables: {
        //         matterId: matterId
        //     }
        // }).then((results: {
        //     data: {
        //         addUserMatterAccessActivity: boolean
        //     }
        // }) => {
        //     if (results.data.addUserMatterAccessActivity === false) {
        //         // tslint:disable-next-line:no-console
        //         console.log('AddUserMatterAccessActivity mutation failed');
        //     }
        // });
        // // browserHistory.push('/matterDetail/' + matterId);
        // browserHistory.push({
        //     pathname: '/matterDetail/' + matterId ,
        //     // search: '?the=search', // The URL query string
        //     state: { // Some extra state for this location that does not reside in the URL
        //         matterSummaryPageXState: this.state,
        //         nameId: this.props.nameId,
        //         entityType: this.props.entityType,
        //         nameSummaryState: this.props.nameSummaryState,
        //     }
        // });
    }

    private onCellClicked(event: CellClickedEvent) {
        if (event.data !== null && event.data !== undefined && event.data.id !== undefined && event.data.id !== null) {
            client
                .mutate({
                    mutation: AddUserMatterAccessActivity,
                    variables: {
                        matterId: event.data.id,
                    },
                })
                .then(
                    (results: {
                        data: {
                            addUserMatterAccessActivity: boolean;
                        };
                    }) => {
                        if (results.data.addUserMatterAccessActivity === false) {
                            // tslint:disable-next-line:no-console
                            console.log('AddUserMatterAccessActivity mutation failed');
                        }
                    },
                );

            // browserHistory.push('/matterDetail/' + matterId);

            sessionStorage.setItem('typeOfView', '');

            browserHistory.push({
                pathname: '/matterDetail/' + event.data.id,
                // search: '?the=search', // The URL query string
                state: {
                    // Some extra state for this location that does not reside in the URL
                    matterSummaryPageXState: this.state,
                    nameId: this.props.nameId,
                    entityType: this.props.entityType,
                    nameSummaryState: this.props.nameSummaryState,
                    isSearchCriteriaFromAdvancedFilter: true,
                },
            });
        }
    }

    // tslint:disable-next-line: no-any
    private onColumnFilterChanged(filterModel: any) {
        this.setState({
            columnFilterModel: filterModel,
            isForceDownload: false,
        });
    }

    private onMatterCounts(matterLoadedCount: number, totalMatters: number, isLoading: boolean) {
        this.setState({
            matterLoadedCount: matterLoadedCount,
            totalMatterCount: totalMatters,
            isLoading: isLoading,
            inRefreshData: false, // toggle off so it can be selected again
        });
    }

    private handleLoading(isLoading: boolean) {
        this.setState({
            isLoading: isLoading,
        });
    }

    private onExport(type: ExportType) {
        if (type === ExportType.CSV) {
            EventSystem.emit('export:matterCSV');
        } else {
            EventSystem.emit('export:matterExcel');
        }
    }

    private onPrint() {
        EventSystem.emit('print:matter');
    }

    private subscribeToSummaryViewConfig() {
        const vm = this;
        this.summaryViewConfigSubscription = client
            .subscribe({
                query: SummaryViewConfigNotification,
            })
            .subscribe({
                // tslint:disable-next-line:no-any
                next(results: any) {
                    // tslint:disable-next-line:no-console
                    console.log(results);
                    if (results.data.summaryViewConfigNotification.status) {
                        showNotification('Success', results.data.summaryViewConfigNotification.message, 'info');
                        vm.fetchColumnConfigData();
                    } else {
                        showNotification('Failed', results.data.summaryViewConfigNotification.message, 'error');
                    }
                },
                // tslint:disable-next-line:no-any
                error(err: any) {
                    // tslint:disable-next-line:no-console
                    console.error(err);
                    showNotification(null, err, 'error');
                },
            });
    }

    private subscribeToMatterTasks() {
        this.matterTaskSubscription = client
            .subscribe({
                query: MatterTaskNotification,
            })
            .subscribe({
                // tslint:disable-next-line:no-any
                next(results: any) {
                    // tslint:disable-next-line:no-console
                    console.log(results);
                    if (results.data.matterManagementTaskNotification.status) {
                        showNotification('Success', results.data.matterManagementTaskNotification.message, 'info');
                    } else {
                        showNotification('Failed', results.data.matterManagementTaskNotification.message, 'error');
                    }
                },
                // tslint:disable-next-line:no-any
                error(err: any) {
                    // tslint:disable-next-line:no-console
                    console.error(err);
                    showNotification(null, err, 'error');
                },
            });
    }

    // private subscribeToForecast() {
    //     this.forecastSubscription = client
    //         .subscribe({
    //             query: MatterForecastSubscription,
    //         })
    //         .subscribe({
    //             // tslint:disable-next-line:no-any
    //             next(results: any) {
    //                 // tslint:disable-next-line:no-console
    //                 console.log(results);
    //                 if (results.data.updateMatterForecastNotification.status) {
    //                     showNotification('Success', results.data.updateMatterForecastNotification.message, 'info');
    //                 } else {
    //                     showNotification('Failed', results.data.updateMatterForecastNotification.message, 'error');
    //                 }
    //             },
    //             // tslint:disable-next-line:no-any
    //             error(err: any) {
    //                 // tslint:disable-next-line:no-console
    //                 console.error(err);
    //                 showNotification(null, err, 'error');
    //             },
    //         });
    // }

    private subscribeToTasks() {
        this.taskSubscription = client
            .subscribe({
                query: TaskSubscription,
            })
            .subscribe({
                // tslint:disable-next-line:no-any
                next(results: any) {
                    // tslint:disable-next-line:no-console
                    console.log(results);
                    if (results.data.taskNotification.status) {
                        showNotification('Success', results.data.taskNotification.message, 'info');
                    } else {
                        showNotification('Failed', results.data.taskNotification.message, 'error');
                    }
                },
                // tslint:disable-next-line:no-any
                error(err: any) {
                    // tslint:disable-next-line:no-console
                    console.error(err);
                    showNotification(null, err, 'error');
                },
            });
    }

    private onInMemoryChanged(isInMemory: boolean) {
        this.setState({
            isInMemory: isInMemory,
            isDownloadMatter: isInMemory ? false : this.state.isDownloadMatter, // If in memory changed, then set it to false
        });

        // browserHistory.replace(this.props.location.pathname, null);
        window.history.replaceState(null, '');
    }

    private onMatterDownloadChanged(isDownloadMatter: boolean) {
        this.setState({
            isDownloadMatter: isDownloadMatter,
            isForceDownload: false,
        });
    }

    private onAdvancedFilter = (show: boolean) => () => {
        this.setState({
            showAdvancedFilter: show,
        });
    }

    private onAdvancedSearch(filter: AdvancedFilterForm, hasFilter: boolean) {
        this.setState({
            hasFilter: hasFilter,
            filterValues: filter,
            searchCriterias: this.onFormatFilterValuesForSearchCriteria(filter),
        });
    }

    private onFormatFilterValuesForSearchCriteria(filterValues: AdvancedFilterForm) {
        const searchCriterias = new Array<SearchCriteria>();

        if (filterValues) {
        
            if (filterValues.hasOwnProperty('ourReference') && filterValues.ourReference) {
                searchCriterias.push({ property: 'OurReference', operator: 'like', comparison: filterValues.ourReference });
            }

            if (filterValues.hasOwnProperty('yourReference') && filterValues.yourReference) {
                searchCriterias.push({ property: 'YourReference', operator: 'like', comparison: filterValues.yourReference });
            }

            if (filterValues.hasOwnProperty('matterCountries') && filterValues.matterCountries.matterCountry.length > 0) {
                const _operator = filterValues.matterCountries.exclude ? 'notin' : 'in';
                const _comparison = filterValues.matterCountries.matterCountry.map((option: CountryAutoCompleteItem) => option.value).join(',');

                searchCriterias.push({ property: 'Country', operator: _operator, comparison: _comparison });
            }

            if (filterValues.hasOwnProperty('caseTypes') && filterValues.caseTypes.caseType.length > 0) {
                const _operator = filterValues.caseTypes.exclude ? 'notin' : 'in';
                const _comparison = filterValues.caseTypes.caseType.map((option: MatterTypeTypeAutoCompleteItem) => option.value).join(',');

                searchCriterias.push({ property: 'CaseType', operator: _operator, comparison: _comparison });
            }

            if (filterValues.hasOwnProperty('matterProperties') && filterValues.matterProperties.matterProperty.length > 0) {
                const _operator = filterValues.matterProperties.exclude ? 'notin' : 'in';
                const _comparison = filterValues.matterProperties.matterProperty.map((option: MatterPropertyAutoCompleteItem) => option.value).join(',');

                searchCriterias.push({ property: 'Property', operator: _operator, comparison: _comparison });
            }

            if (filterValues.hasOwnProperty('matterCategories') && filterValues.matterCategories.matterCategory.length > 0) {
                const _operator = filterValues.matterCategories.exclude ? 'notin' : 'in';
                const _comparison = filterValues.matterCategories.matterCategory.map((option: MatterCategoryAutoCompleteItem) => option.value).join(',');

                searchCriterias.push({ property: 'CaseCategory', operator: _operator, comparison: _comparison });
            }

            if (filterValues.hasOwnProperty('matterClients') && filterValues.matterClients.matterClient.length > 0) {
                const _operator = filterValues.matterClients.exclude ? 'notin' : 'in';
                const _comparison = filterValues.matterClients.matterClient.map((option: NameSummaryAutoCompleteItem) => option.value).join(',');

                searchCriterias.push({ property: 'Client', operator: _operator, comparison: _comparison });
            }

            if (filterValues.hasOwnProperty('matterOwners') && filterValues.matterOwners.matterOwner.length > 0) {
                const _operator = filterValues.matterOwners.exclude ? 'notin' : 'in';
                const _comparison = filterValues.matterOwners.matterOwner.map((option: NameSummaryAutoCompleteItem) => option.value).join(',');

                searchCriterias.push({ property: 'Owner', operator: _operator, comparison: _comparison });
            }

            if (filterValues.hasOwnProperty('matterStatuses') && filterValues.matterStatuses.matterStatus.length > 0) {
                const _operator = filterValues.matterStatuses.exclude ? 'notin' : 'in';
                const _comparison = filterValues.matterStatuses.matterStatus.map((option: MatterStatusAutoCompleteItem) => option.value).join(',');

                searchCriterias.push({ property: 'Status', operator: _operator, comparison: _comparison });
            }

            if (filterValues.hasOwnProperty('filingDateFrom') && filterValues.filingDateFrom) {
                const dateFrom = moment(filterValues.filingDateFrom).format('YYYY-MM-DD');

                searchCriterias.push({ property: 'FilingDate', operator: 'gteq', comparison: dateFrom });
            }
            if (filterValues.hasOwnProperty('filingDateTo') && filterValues.filingDateTo) {
                const dateTo = moment(filterValues.filingDateTo).format('YYYY-MM-DD');

                searchCriterias.push({ property: 'FilingDate', operator: 'lteq', comparison: dateTo });
            }

            if (filterValues.hasOwnProperty('applicationNumber') && filterValues.applicationNumber) {
                searchCriterias.push({ property: 'ApplicationNumber', operator: 'like', comparison: filterValues.applicationNumber });
            }

            if (filterValues.hasOwnProperty('grantAndRegistrationDateFrom') && filterValues.grantAndRegistrationDateFrom) {
                const dateFrom = moment(filterValues.grantAndRegistrationDateFrom).format('YYYY-MM-DD');

                searchCriterias.push({ property: 'RegistrationDate', operator: 'gteq', comparison: dateFrom });
            }

            if (filterValues.hasOwnProperty('grantAndRegistrationDateTo') && filterValues.grantAndRegistrationDateTo) {
                const dateTo = moment(filterValues.grantAndRegistrationDateTo).format('YYYY-MM-DD');

                searchCriterias.push({ property: 'RegistrationDate', operator: 'lteq', comparison: dateTo });
            }

            if (filterValues.hasOwnProperty('registrationNumber') && filterValues.registrationNumber) {
                searchCriterias.push({ property: 'RegistrationNumber', operator: 'like', comparison: filterValues.registrationNumber });
            }

            if (filterValues.hasOwnProperty('priorityDateFrom') && filterValues.priorityDateFrom) {
                const dateFrom = moment(filterValues.priorityDateFrom).format('YYYY-MM-DD');

                searchCriterias.push({ property: 'PriorityDate', operator: 'gteq', comparison: dateFrom });
            }
            if (filterValues.hasOwnProperty('priorityDateTo') && filterValues.priorityDateTo) {
                const dateTo = moment(filterValues.priorityDateTo).format('YYYY-MM-DD');

                searchCriterias.push({ property: 'PriorityDate', operator: 'lteq', comparison: dateTo });
            }

            if (filterValues.hasOwnProperty('priorityCountries') && filterValues.priorityCountries.priorityCountry.length > 0) {
                const _operator = filterValues.priorityCountries.exclude ? 'notin' : 'in';
                const _comparison = filterValues.priorityCountries.priorityCountry.map((option: PriorityCountryAutoCompleteItem) => option.value).join(',');

                searchCriterias.push({ property: 'PriorityCountry', operator: _operator, comparison: _comparison });
            }

            if (filterValues.hasOwnProperty('title') && filterValues.title) {
                searchCriterias.push({ property: 'Title', operator: 'like', comparison: filterValues.title });
            }

            if (filterValues.hasOwnProperty('class') && filterValues.class) {
                searchCriterias.push({ property: 'Class', operator: 'like', comparison: filterValues.class });
            }

            if (filterValues.hasOwnProperty('goodsAndServices') && filterValues.goodsAndServices) {
                searchCriterias.push({ property: 'GoodsAndService', operator: 'like', comparison: filterValues.goodsAndServices });
            }

            // CompletionDate
            if (filterValues.hasOwnProperty('completionDateFrom') && filterValues.completionDateFrom) {
                const dateFrom = moment(filterValues.completionDateFrom).format('YYYY-MM-DD');

                searchCriterias.push({ property: 'CompletionDate', operator: 'gteq', comparison: dateFrom });
            }
            if (filterValues.hasOwnProperty('completionDateTo') && filterValues.completionDateTo) {
                const dateTo = moment(filterValues.completionDateTo).format('YYYY-MM-DD');

                searchCriterias.push({ property: 'CompletionDate', operator: 'lteq', comparison: dateTo });
            }

            // InstructionDate
            if (filterValues.hasOwnProperty('instructionDateFrom') && filterValues.instructionDateFrom) {
                const dateFrom = moment(filterValues.instructionDateFrom).format('YYYY-MM-DD');

                searchCriterias.push({ property: 'InstructionDate', operator: 'gteq', comparison: dateFrom });
            }
            if (filterValues.hasOwnProperty('instructionDateTo') && filterValues.instructionDateTo) {
                const dateTo = moment(filterValues.instructionDateTo).format('YYYY-MM-DD');

                searchCriterias.push({ property: 'InstructionDate', operator: 'lteq', comparison: dateTo });
            }

            // Acting
            if (filterValues.hasOwnProperty('personActings') && filterValues.personActings.personActing.length > 0) {
                const _operator = filterValues.personActings.exclude ? 'notin' : 'in';
                const _comparison = filterValues.personActings.personActing.map((option: StaffSummaryAutoCompleteItem) => option.staffId).join(',');

                searchCriterias.push({ property: 'Acting', operator: _operator, comparison: _comparison });
            }

            // Responsible
            if (filterValues.hasOwnProperty('personResponsibles') && filterValues.personResponsibles.personResponsible.length > 0) {
                const _operator = filterValues.personResponsibles.exclude ? 'notin' : 'in';
                const _comparison = filterValues.personResponsibles.personResponsible.map((option: StaffSummaryAutoCompleteItem) => option.staffId).join(',');

                searchCriterias.push({ property: 'Responsible', operator: _operator, comparison: _comparison });
            }

            if (filterValues.hasOwnProperty('isMatterEstimateZero') && filterValues.isMatterEstimateZero) {
                searchCriterias.push({ property: 'EstimatedCosts', operator: 'eq', comparison: '0' });
            }

            if (filterValues.hasOwnProperty('feesBilledVsEstimatePercentage') && filterValues.feesBilledVsEstimatePercentage) {
                searchCriterias.push({ property: 'FeesBilledGreaterThanAmount', operator: 'gt', comparison: filterValues.feesBilledVsEstimatePercentage.toString() });
            }

            const convertToJson = JSON.stringify(searchCriterias);

            if (searchCriterias.length > 0) {
                return convertToJson;
            }
        }
        
        return undefined;
    }

    private onExportLoading(isLoading: boolean) {
        this.setState({
            isExportLoading: isLoading
        });
    }
}

// tslint:disable-next-line:no-any
const MatterSummary = graphql<any, MatterSummaryPageDataProps, any>(MatterSummaryOptions)(MatterSummaryPage);

export default MatterSummary;

export const AddUserMatterAccessActivity = gql`
    mutation AddUserMatterAccessActivity($matterId: Int) {
        addUserMatterAccessActivity(matterId: $matterId)
    }
`;
