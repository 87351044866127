import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { client } from '..';
import { AppUser } from '../matterSummary/MatterSummaryRepository';
import { ColumnConfigList } from '../viewConfig/SummaryViewConfigurations';

export interface NameSummaryQueryParams {
    offset: number; 
    first: number;
    filter?: string; 
    isClientsOnly: boolean;
    includeStaff: boolean | null;
    includeIndividual: boolean | null;
    includeOrganisation: boolean | null;
    tagsList: string | null;   
    stateList: string | null;  
    countryList: string | null;   
    businessTypeList: string | null;
    relationshipManagerList: string | null;
    includePmsContacts: boolean | null;
    includeNonPmsContacts: boolean | null;
}

export interface NameSummaryData {
    loading?: boolean;
    networkStatus?: number;
    party: Party;
}

export interface Party {
    nameSummaries: NameSummaries;
}

export interface NameSummaries {
    recordCount: number;
    nameSummaryActualCount: NameSummaryActualCount;
    nameSummary: NameSummary[];
}

export interface NameSummaryActualCount {
    actualRowCountForSearch:   number;
}

export interface NameSummary {
    image: string | null;
    guidID: string;
    nameId: number;
    name: string | null;
    title: string | null;
    relatedName: string | null;
    emailAddress: string | null;
    mobile: string | null;
    phone: string | null;
    position: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    address: string | null;
    entityType: number;
    relationshipManager: string | null;
    relationshipQualityDescription: string | null;
    relationshipQualityLevel: string | null;
    referrerType: string | null;
    referrerSource: string | null;
    lastContactDate: Date | null;
    lastReferralDate: Date | null;
    notes: string | null;
    isClient: boolean | null;
    isOrganisation: boolean | null;
    isIndividual: boolean | null;
    isStaff: boolean | null;
    isReferrer: boolean | null;
    isUser: boolean | null;
    currentMatters: number | null;
    lastMatterDate: Date | null;
    estimatedFees: number | null;
    billedFees: number | null;
    trustBalance: number | null;
    billedDisbursements: number | null;
    unbilledFees: number | null;
    unbilledDisbursements: number | null;
    completedMatters: number | null;
    newMattersInTheLast12Months: number | null;
    firstMatterDate: Date | null;
    totalBilledFees: number | null;
    percentageEstimate: number | null;
    exposure: number | null;
    hasMarketingConsent: boolean;
    tags: string | null;
    enquiriesReferredInTheLast12Months: number | null;
    enquiriesReferredInTotal: number | null;
    lastEnquiryDate: Date | null;
    businessType: string | null;
    isFromDataSource: boolean | null;
    isEntered: boolean | null;
    partyRelationshipManager: string | null;
    partyRelationshipQualityDescription: string | null;
    partyRelationshipQualityLevel: string | null;
}

export interface NameOptionsData {
    loading?: boolean;
    networkStatus?: number;
    appUser: AppUser;
    columnConfigList: ColumnConfigList;
}

export enum NameEntityName {
    IndividualContactForOrganisation = 1,
    OrganisationClient = 2,
    OrganisationContact = 3,
    IndividualContact = 4,
    IndividualClient = 5
}

export function retrieveNameSummaryData(query: NameSummaryQueryParams, 
                                        refreshData: boolean,
                                        // isSearch: boolean,
                                        onSuccess: (data: NameSummaryData) => void,
                                        // tslint:disable-next-line:no-any
                                        onError: (reason: any) => void)
                                        : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    // tslint:disable-next-line: no-console
    // console.log('isSearch', isSearch);

    // if (isSearch) {
    client.query({
        query: NameData,
        variables: {
            offset: query.offset,
            first: query.first,
            filter: query.filter,
            isClientsOnly: query.isClientsOnly,
            includeStaff: query.includeStaff,
            includeIndividual: query.includeIndividual,
            includeOrganisation: query.includeOrganisation, 
            tagsList: query.tagsList, 
            stateList: query.stateList, 
            countryList: query.countryList,      
            businessTypeList: query.businessTypeList,
            relationshipManagerList: query.relationshipManagerList,
            includePmsContacts: query.includePmsContacts,
            includeNonPmsContacts: query.includeNonPmsContacts
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
            onSuccess(results.data);
            })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); }
    );
    // } else {
    //     onSuccess({
    //         party: {
    //             nameSummaries: {
    //                 recordCount: 0,
    //                 nameSummaryActualCount: {
    //                      actualRowCountForSearch: 0
    //                 },
    //                 nameSummary: [] 
    //             }
    //         }
    //     });
    // }
}

const NameData = gql`
    query NameSummaries(
        $offset: Int, $first: Int,
        $filter: String, 
        $isClientsOnly: Boolean,
        $includeStaff: Boolean, 
        $includeIndividual: Boolean, 
        $includeOrganisation: Boolean,
        $tagsList: String,
        $stateList: String,
        $countryList: String,
        $businessTypeList: String,
        $relationshipManagerList: String,
        $includePmsContacts: Boolean,
        $includeNonPmsContacts: Boolean
    ) {
    party{
        nameSummaries(
            offset: $offset, 
            first: $first, 
            filter: $filter, 
            isClientsOnly: $isClientsOnly, 
            includeStaff: $includeStaff, 
            includeIndividual: $includeIndividual, 
            includeOrganisation: $includeOrganisation,
            tagsList: $tagsList,
            stateList: $stateList,
            countryList: $countryList,
            relationshipManagerList: $relationshipManagerList,
            businessTypeList: $businessTypeList,
          	includePmsContacts: $includePmsContacts,
            includeNonPmsContacts: $includeNonPmsContacts,

        ){
            recordCount,
            nameSummaryActualCount{
                actualRowCountForSearch
            }, 
            nameSummary{
                    image,
                    guidID,
                    nameId,
                    name,
                    title,
                    relatedName,
                    emailAddress,
                    mobile,
                    phone,
                    position,
                    city,
                    state,
                    country,
                    address,
                    entityType,
                    relationshipManager,
                    relationshipQualityDescription,
                    relationshipQualityLevel,
                    referrerType,
                    referrerSource,
                    lastContactDate,
                    lastReferralDate,
                    notes,
                    isClient,
                    isOrganisation,
                    isIndividual,
                    isStaff,
                    isReferrer,
                    isUser,
                    currentMatters,
                    lastMatterDate,
                    estimatedFees,
                    billedFees,
                    trustBalance,
                    billedDisbursements,
                    unbilledFees,
                    unbilledDisbursements,
                    completedMatters,
                    newMattersInTheLast12Months,
                    firstMatterDate,
                    totalBilledFees,
                    percentageEstimate,
                    exposure,
                    hasMarketingConsent,
                    tags,
                    enquiriesReferredInTheLast12Months,
                    enquiriesReferredInTotal,
                    lastEnquiryDate,
                    businessType,
                    isFromDataSource
                    isEntered,
                    partyRelationshipManager,
                    partyRelationshipQualityDescription,
                    partyRelationshipQualityLevel,
                }
            }
        }
    }
`;

export const NameSummaryOptions = gql`query NameSummaryOptions{
    appUser {
        isAdministrator
    }  
}`;

export const UpdateNameSubscription = gql`
subscription UpdateNameSubscription {
    updateNameNotification{
        id,
        description,
        status,
        message
    }
}`;

export function retrieveNameTagsData(
    query: NameTagsParams, 
    refreshData: boolean,
    onSuccess: (data: NameTagsData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
    : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client.query({
        query: NameTagsQuery,
        variables: {
            nameId: query.nameId, 
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
        // tslint:disable-next-line:no-any
        .catch((reason: any ) => { onError(reason); }
    );
}

export interface NameTagsParams {
    nameId: number;
}
export interface NameTagsData {
    partydetails: Partydetails;
}

export interface Partydetails {
    tags: Tag[];
}

export interface Tag {
    guidId:    string;
    tag:       string;
    tagGuidId: string;
    tagTypeGuidId: string;
    tagType: string;
}

export const NameTagsQuery = gql`
    query nameTags($nameId: Int!) {
        partydetails(nameId: $nameId) {
            ... on Individual {
                tags {
                    guidId
                    tag
                    tagGuidId
                    tagTypeGuidId
                    tagType
                }
            }
            ... on Organisation {
                tags {
                    guidId
                    tag
                    tagGuidId
                    tagTypeGuidId
                    tagType
                }
            }
        }
    }
`;

export type NameCrmAddressInput = {
    addressName: string | null;
    floor: string | null;
    number: string | null;
    street: string | null;
    suburb: string | null;
    state: string | null;
    postcode: string | null;
    country: string | null;
    pOBoxType: string | null;
};

export type NameCrmContactInput = {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    gender: string | null;
    title: string | null;
    phone: string | null;
    mobile: string | null;
    isMarketingConsent: boolean | null;
    postalAddress: NameCrmAddressInput | null;
    mainAddress: NameCrmAddressInput | null;
    tags: string[] | null;
    position: string | null;
    qualityOfRelationshipGuid: string | null;
    relationshipManagerGuid: string | null;
};

export type CreateNameCrmInput = {
    guid: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    position: string | null;
    gender: string | null;
    title: string | null;
    phone: string | null;
    mobile: string | null;
    isOrganisation: boolean | null;
    organisation: string | null;
    createdDate: Date | null;
    isMarketingConsent: boolean | null;
    dOB: Date | null;
    postalAddress: NameCrmAddressInput | null;
    mainAddress: NameCrmAddressInput | null;
    contacts: NameCrmContactInput[] | null;
    tags: string[] | null;
    url: string | null;
    qualityOfRelationshipGuid: string | null;
    relationshipManagerGuid: string | null;
    backgroundNotes: string | null;
};

export type CreateCrmPartyMutationParams = {
    input: CreateNameCrmInput | null;
};

export type UpdateCrmPartyMutationParams = CreateCrmPartyMutationParams;

export type CreateCrmPartyMutationData = {
    crm: CRM;
};

export type CRM = {
    party: CrmParty;
};

export type CrmParty = {
    create: Create;
    delete: Create;
    update: Create;
};

export type Create = {
    error:  null;
    status: boolean;
};

export type DeleteCrmPartyMutationData = CreateCrmPartyMutationData;

export type UpdateCrmPartyMutationData = CreateCrmPartyMutationData;

export type DeleteCrmPartyMutationParams = {
    partyGuid: string | null;
};

export const CreateCrmPartyMutation = gql`
    mutation CreateCrmPartyMutation($input: CreateNameCrmInput) {
        crm {
            party {
                create(input: $input) {
                    error
                    status
                }
            }
        }
    }
`;

export const UpdateCrmPartyMutation = gql`
    mutation UpdateCrmPartyMutation($input: UpdateNameCrmInput) {
        crm {
            party {
                update(input: $input) {
                    error
                    status
                }
            }
        }
    }
`;

export const DeleteCrmPartyMutation = gql`
    mutation DeleteCrmPartyMuation($partyGuid: String) {
        crm {
            party {
                delete(nameGuid: $partyGuid) {
                    error
                    status
                }
            }
        }
    }
`;

export const CreateNameCrmNotificationSubscription = gql`
    subscription CreateNameCrmNotification {
        createNameCrmNotification {
            description
            id
            message
            guid
            status
        }
    }
`;

export const UpdateNameCrmNotificationSubscription = gql`
    subscription UpdateNameCrmNotification {
        updateNameCrmNotification {
            description
            id
            message
            status
        }
    }
`;

export const DeleteNameCrmNotificationSubscription = gql`
    subscription DeleteNameCrmNotification {
        deleteNameCrmNotification {
            description
            id
            message
            status
        }
    }
`;

export type NameCrmQueryParams = {
    nameGuid: string | null;
};

export type NameCrmQueryData = {
    party: NameCrmParty;
};

export type NameCrmParty = {
    nameCrm: NameCrm;
};

export type NameCrm = {
    backgroundNotes: null | string;
    position: null | string;
    nameId: null | number;
    nameGuid: null | string;
    website: null | string;
    isOrganisation?: boolean;
    title: null | string;
    firstName: null | string;
    lastName: null | string;
    organisationName?: null | string;
    emailAddress: null | string;
    phone: null | string;
    mobile: null | string;
    buildingName: null | string;
    level: null | string;
    number: null | string;
    street: null | string;
    suburb: null | string;
    state: null | string;
    postCode: null | string;
    country: null | string;
    postalPoBoxType: null | string;
    postalPoBoxTypeGuid: null | string;
    postalNumber: null | string;
    postalSuburb: null | string;
    postalState: null | string;
    postalPostCode: null | string;
    postalCountry: null | string;
    tags: null | string;
    hasMarketingConsent: boolean;
    relationshipManager: string | null;
    relationshipManagerGuid: string | null;
    relationshipQualityGuid: string | null;
    relationshipQualityLevel: number | null;
    relationshipQualityDescription: string | null;
    contactCrm?: NameCrm[];
};

export type NameCrmTag = {
    id:                 number;
    value:              string;
    TagDescription:     string;
    label:              string;
    TagTypeDescription: string;
    TagTypeGuid:        string;
};

export const NameCrmQuery = gql`
    query partyCrm($nameGuid: String) {
        party {
            nameCrm(nameGuid: $nameGuid) {
                backgroundNotes
                position
                nameId
                nameGuid
                website
                isOrganisation
                title
                firstName
                lastName
                organisationName
                emailAddress
                phone
                mobile
                buildingName
                level
                number
                street
                suburb
                state
                postCode
                country
                postalPoBoxType
                postalPoBoxTypeGuid
                postalNumber
                postalSuburb
                postalState
                postalPostCode
                postalCountry
                tags
                hasMarketingConsent
                relationshipManager
                relationshipManagerGuid
                relationshipQualityGuid
                relationshipQualityLevel
                relationshipQualityDescription
                contactCrm {
                    position
                    nameId
                    nameGuid
                    title
                    firstName
                    lastName
                    emailAddress
                    phone
                    mobile
                    buildingName
                    level
                    number
                    street
                    suburb
                    state
                    postCode
                    country
                    backgroundNotes
                    postalPoBoxType
                    postalPoBoxTypeGuid
                    postalNumber
                    postalSuburb
                    postalState
                    postalPostCode
                    postalCountry
                    tags
                    hasMarketingConsent
                  	relationshipManager
                    relationshipManagerGuid
                    relationshipQualityGuid
                    relationshipQualityLevel
                    relationshipQualityDescription
                }
            }
        }
    }
`;

export function retrieveNameCrmData(
    query: NameCrmQueryParams,
    refreshData: boolean,
    onSuccess: (data: NameCrmQueryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: NameCrmQuery,
            variables: {
                nameGuid: query.nameGuid,
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

// party address
export type PartyAddressesParams = {
    nameId: number | null;
    isFromDataSource: boolean | null;
};

export type PartyAddressesData = {
    party: PartyAddress;
};

export type PartyAddress = {
    addresses: Addresses;
};

export type Addresses = {
    address:     Address[];
    recordCount: number;
};

export type Address = {
    id:          number;
    nameId:      number;
    name:        string;
    addressType: string;
    floor:       string;
    number:      string;
    street:      string;
    state:       string;
    suburb:      string;
    postcode:    string;
    country:     string;
};

export const PartyAddressesQuery = gql`
    query Addresses($nameId: Int, $isFromDataSource: Boolean) {
        party {
            addresses(nameId: $nameId, isFromDataSource: $isFromDataSource) {
                address {
                    id
                    nameId
                    name
                    addressType
                    floor
                    number
                    street
                    state
                    suburb
                    postcode
                    country
                }
                recordCount
            }
        }
    }
`;

export const PartyTagsProcessCompletesSubscription = gql`
    subscription PartyTagsProcessCompletesNotification{
        publishPartyTagsProcessCompleteNotification {
            id
            status
            message
            description
        }
    }
`;

export interface PublishPartyTagsProcessCompleteNotificationData {
    publishPartyTagsProcessCompleteNotification: PublishPartyTagsProcessCompleteNotification;
}

export interface PublishPartyTagsProcessCompleteNotification {
    id:          number;
    status:      boolean;
    message:     string;
    description: null;
}
