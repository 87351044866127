import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import { TextField, CircularProgress } from '@material-ui/core';
import { RenderInputParams } from '@material-ui/lab';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { InfiniteAutocomplete } from '../../components/InfiniteAutocomplete';
import { showNotification } from '../../App';
import { useDebouncedCallback } from 'use-debounce/lib';
import { EventAndCampaignSummary, EventsAndCampaignQueryData, EventsAndCampaignQueryParams, retrieveEventsAndCampaignData } from '../../eventsAndCampaigns/EventsAndCampaignsRepositories';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        width: '100%',
    },
    textField: {
        width: '100%',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
      },
      color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
      },
      text: {
        flexGrow: 1,
      },
      close: {
        opacity: 0.6,
        width: 18,
        height: 18,
      },
      renderWrapper: {
          padding: '15px 0'
      },
      customRenderWrapper: {
        display: 'flex',
        margin: '0 -10px',
        width: '100%',
        '& > div': {
            padding: '0 10px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
        },
        '& .icon-wrapper': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .other': {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            '& .primary': {
                fontSize: '1rem',
                lineHeight: 1.5,
                letterSpacing: '0.00938em',
            },
            '& .secondary': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.875rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
            }
        }
      }
  })
);

export type EventsAndCampaignIAutoCompleteItem = IAutoCompleteItem & EventAndCampaignSummary;

interface EventsAndCampaignSelectorProps {
    multiple?: boolean;
    label?: string;
    name: string;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    onSelection?: (data: EventsAndCampaignIAutoCompleteItem | EventsAndCampaignIAutoCompleteItem[], name: string) => void;
    required?: boolean;
    id?: string;
    style?: React.CSSProperties;
    className?: string;
    isCompleted?: boolean;
    error?: boolean;
    helperText?: string;
}

interface EventsAndCampaignSelectorState {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    offset: number;
    filter: string;
    // tslint:disable-next-line: no-any
    items: any;
}

export const EventsAndCampaignSelector: React.FC<EventsAndCampaignSelectorProps> = props => {

    const classes = useStyles();
    const loadBlockLimit = 20;
    
    const [state, setState] = useState<EventsAndCampaignSelectorState>({
        hasNextPage: true,
        isNextPageLoading: false,
        offset: 0,
        filter: '',
        items: []
    });

    const [delayedonInputChange] = useDebouncedCallback(
        // function
        (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {

            let isClear = false;
            let offset = 0;
            let filter = '';

            if (reason === 'input') {
                filter = value;
                isClear = true;

                fetchData(filter, offset, isClear);
            } 
        },
        // delay in ms
        500
    );

    const fetchData = (filter: string, offset: number, isClear: boolean) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: true,
            };
        });

        const eventAndCapaignQueryParams: EventsAndCampaignQueryParams = {
            filter: filter,
            first: loadBlockLimit,
            offset: offset,
            isIncludeCompleted: props.isCompleted
        };

        retrieveEventsAndCampaignData(
            eventAndCapaignQueryParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onDataRetrieved(data, filter, offset, isClear),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isNextPageLoading: false,
                    };
                });
            }
        );
    };

    const onDataRetrieved = (data: EventsAndCampaignQueryData, filter: string, offset: number, isClear: boolean) => {

        const eventAndCampaignData = data.eventAndCampaign.summaryList.eventAndCampaignSummaries;
        const recordCount = data.eventAndCampaign.summaryList.recordCount; 

        // tslint:disable-next-line: no-any
        let itemsList: any = [];

        let items = state.items;

        if (!isClear) {
            itemsList = [...state.items];
        } else {
            items = [];
        }

        if (items && items.length === 0) {
            itemsList = eventAndCampaignData.map( (source: EventAndCampaignSummary) => ({
                ...source,
                value: source.guid,
                label: source.name,
                guid: source.guid,
                name: source.name,
            }));
        } else {
            // tslint:disable-next-line: no-shadowed-variable
            for (const eventAndCampaign of eventAndCampaignData) {
                // tslint:disable-next-line: no-any
                const isExists = items.filter((item: EventAndCampaignSummary | any) => item.guid === eventAndCampaign.guid).length;

                if (isExists === 0) {
                    itemsList.push({
                        ...eventAndCampaign,
                        value: eventAndCampaign.guid,
                        label: eventAndCampaign.name,
                        guid: eventAndCampaign.guid,
                        name: eventAndCampaign.name,
                    });
                }
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                filter: filter,
                isNextPageLoading: false,
                items: itemsList,
                offset: offset + loadBlockLimit,
                hasNextPage: eventAndCampaignData.length !== 0 && (offset + loadBlockLimit) < recordCount,
            };
        });
    };

    // tslint:disable-next-line: no-any
    const loadNextPage = (...args: any) => {
        if (state.hasNextPage && !state.isNextPageLoading) {
            fetchData(state.filter, state.offset, false);
        }
    };

    const onClose = (event: React.ChangeEvent<{}>) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: false,
                hasNextPage: true,
                filter: '',
                offset: 0,
                items: []
            };
        });
    };

    const onOpen = (event: React.ChangeEvent<{}>) => {
        
        fetchData(state.filter, 0, true);
    };

    // tslint:disable-next-line: no-any
    const onSelection = (selection: IAutoCompleteItem | any, name: string) => {

        if (props.onSelection) {
            props.onSelection(selection, name);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={clsx(classes.root, props.className)}>
            <InfiniteAutocomplete
                // onOpen={onOpen}
                id={props.id}
                itemSize={45}
                loading={state.isNextPageLoading}
                disablePortal={true}
                name={props.name}
                isMultiple={props.multiple}
                value={props.value}
                hasNextPage={state.hasNextPage}
                isNextPageLoading={state.isNextPageLoading}
                items={state.items}
                loadNextPage={loadNextPage}
                // disableCloseOnSelect={true}
                className={classes.textField}
                disableListWrap={true}
                onSelection={onSelection}
                onClose={onClose}
                onOpen={onOpen}
                getOptionLabel={(option: IAutoCompleteItem) => option.label}
                getOptionSelected={(option, value) => value.value === option.value}
                onInputChange={delayedonInputChange}
                style={props.style}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={props.label}
                        required={props.required}
                        fullWidth={true}
                        InputProps={getInputProps(params, state.isNextPageLoading)}
                        error={props.error}
                        helperText={props.helperText}
                    />
                )}
            />
        </div>
    );
};
