import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import { TextField, CircularProgress, Tooltip, Chip } from '@material-ui/core';
import { GetTagProps, RenderInputParams } from '@material-ui/lab';
import { useDebouncedCallback } from 'use-debounce/lib';
import { InfiniteAutocomplete } from '../../components/InfiniteAutocomplete';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { client } from '../..';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: '200px',
        },
        textField: {
            width: '100%',
        },
        iconSelected: {
            width: 17,
            height: 17,
            marginRight: 5,
            marginLeft: -2,
        },
        color: {
            width: 14,
            height: 14,
            flexShrink: 0,
            borderRadius: 3,
            marginRight: 8,
            marginTop: 2,
        },
        text: {
            flexGrow: 1,
        },
        close: {
            opacity: 0.6,
            width: 18,
            height: 18,
        },
        renderWrapper: {
            padding: '15px 0',
        },
        customRenderWrapper: {
            display: 'flex',
            margin: '0 -10px',
            width: '100%',
            '& > div': {
                padding: '0 10px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontWeight: 400,
            },
            '& .icon-wrapper': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .other': {
                flex: 1,
                display: 'flex',
                flexFlow: 'column',
                '& .primary': {
                    fontSize: '1rem',
                    lineHeight: 1.5,
                    letterSpacing: '0.00938em',
                },
                '& .secondary': {
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                },
            },
        },
    }),
);

interface StaffSelectorProps {
    multiple?: boolean;
    label?: string;
    name: string;
    // tslint:disable-next-line: no-any
    value?: StaffSummaryAutoCompleteItem | StaffSummaryAutoCompleteItem[] | any; // value only available in single select
    onSelection?: (value: StaffSummaryAutoCompleteItem | StaffSummaryAutoCompleteItem[], name: string) => void;
    required?: boolean;
    id?: string;
    textLimit?: {
        label?: number;
        email?: number;
    };
    error?: boolean;
    helperText?: string;
    disablePortal?: boolean;
    variant?: 'standard' | 'filled' | 'outlined';
    size?: 'small' | 'medium';
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    className?: string;
}

export interface StaffSummaryAutoCompleteItem extends IAutoCompleteItem, StaffSummary {}

interface StaffSelectorState {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    offset: number;
    filter: string;
    // tslint:disable-next-line: no-any
    items: StaffSummaryAutoCompleteItem[];
}

export const StaffSelector: React.FC<StaffSelectorProps> = (props) => {
    const classes = useStyles();
    const loadBlockLimit = 20;

    const [delayedonInputChange] = useDebouncedCallback(
        // function
        (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {
            let isClear = false;
            let offset = 0;
            let filter = '';

            if (reason === 'input') {
                filter = value;
                isClear = true;

                fetchData(filter, offset, isClear);
            }
        },
        // delay in ms
        500,
    );

    const [state, setState] = useState<StaffSelectorState>({
        hasNextPage: true,
        isNextPageLoading: false,
        offset: 0,
        filter: '',
        items: [],
    });

    // useEffect(() => {
    //     fetchData(state.filter, 0, state.isNextPageLoading, state.hasNextPage, false);
    // // tslint:disable-next-line: align
    // }, []);

    // tslint:disable-next-line: no-any
    const loadNextPage = (...args: any) => {
        if (state.hasNextPage && !state.isNextPageLoading) {
            fetchData(state.filter, state.offset, false);
        }
    };

    const fetchData = (filter: string, offset: number, isClear: boolean) => {
        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: true,
            };
        });

        const userQueryParams: StaffQueryParams = {
            filter: filter,
            first: loadBlockLimit,
            offset: offset,
        };

        retrieveStaffListData(
            userQueryParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveClientList(data, filter, offset, isClear),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isNextPageLoading: false,
                        isLoading: false,
                    };
                });
            },
        );
    };

    const onRetrieveClientList = (data: StaffListData, filter: string, offset: number, isClear: boolean) => {
        const staffSummaryData = data.party.staffSummaries.staffSummary;
        const recordCount = data.party.staffSummaries.recordCount;

        // tslint:disable-next-line: no-any
        let itemsList: StaffSummaryAutoCompleteItem[] = [];

        let items = state.items;

        if (!isClear) {
            itemsList = [...state.items];
        } else {
            items = [];
        }

        if (items.length === 0) {
            itemsList = staffSummaryData.map((source: StaffSummary) => ({
                ...source,
                value: source.staffGuid,
                label: source.name,
            }));
        } else {
            // tslint:disable-next-line: no-shadowed-variable
            for (const staffSummary of staffSummaryData) {
                // tslint:disable-next-line: no-any
                const isExists = items.filter((item: StaffSummaryAutoCompleteItem) => item.value.toLowerCase().includes(staffSummary.staffGuid)).length;

                if (isExists === 0) {
                    itemsList.push({
                        ...staffSummary,
                        value: staffSummary.staffGuid,
                        label: staffSummary.name,
                    });
                }
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                filter: filter,
                isNextPageLoading: false,
                items: itemsList,
                offset: offset + loadBlockLimit,
                hasNextPage: staffSummaryData.length !== 0 && offset + loadBlockLimit < recordCount,
                isLoading: false,
            };
        });
    };

    const onSelection = (selection: StaffSummaryAutoCompleteItem | StaffSummaryAutoCompleteItem[], name: string) => {
        // setState((prevState) => {
        //     return {
        //         ...prevState,
        //         isNextPageLoading: false,
        //         filter: '',
        //         offset: 0
        //     };
        // });

        if (props.onSelection) {
            props.onSelection(selection, name);
        }
    };

    const onClose = (event: React.ChangeEvent<{}>) => {
        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: false,
                hasNextPage: true,
                filter: '',
                offset: 0,
                items: [],
            };
        });
    };

    const onOpen = (event: React.ChangeEvent<{}>) => {
        // tslint:disable-next-line: no-console
        fetchData(state.filter, 0, true);
    };

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
        arrow: {
            '&:before': {
                border: '1px solid #E6E8ED',
            },
            color: theme.palette.common.white,
        },
    }))(Tooltip);

    const shortingTitle = (title: string, limit?: number) => {
        if (limit === undefined || limit === null) {
            return title;
        } else if (title === undefined || title === null) {
            return title;
        } else if (title.length < limit) {
            return title;
        } else {
            return (
                <LightTooltip title={title} aria-label="client" arrow={true} placement="right">
                    <span>{`${title.substring(0, limit)}...`}</span>
                </LightTooltip>
            );
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return {
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                    {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>
            ),
        };
    };

    const onRenderTags = (value: StaffSummaryAutoCompleteItem[], getTagProps: GetTagProps): React.ReactNode => {
        let renderTagsValue = value.map((option: StaffSummaryAutoCompleteItem, index: number) => {
            let label = option.label;
            if (option.label.length > 20) {
                label = `${option.label.substring(0, 20)}...`;
            }

            return (
                <Chip
                    {...getTagProps({ index })}
                    key={index}
                    label={label}
                    // variant="outlined"
                    size="small"
                />
            );
        });

        return renderTagsValue;
    };

    return (
        <InfiniteAutocomplete
            id={props.id}
            itemSize={70}
            loading={state.isNextPageLoading}
            disablePortal={props.disablePortal}
            name={props.name}
            isMultiple={props.multiple}
            value={props.value}
            hasNextPage={state.hasNextPage}
            isNextPageLoading={state.isNextPageLoading}
            items={state.items}
            loadNextPage={loadNextPage}
            // disableCloseOnSelect={true}
            className={`${classes.textField} ${props.className}`}
            disableListWrap={true}
            onSelection={onSelection}
            onClose={onClose}
            onOpen={onOpen}
            getOptionLabel={(option: StaffSummaryAutoCompleteItem) => option.label}
            getOptionSelected={(option, value) => value.value === option.value}
            onInputChange={delayedonInputChange}
            onBlur={props.onBlur}
            renderTags={onRenderTags}
            renderOption={(option, { selected }) => {
                return (
                    <div className={classes.customRenderWrapper}>
                        <div className="icon-wrapper">
                            {option.isStaff ? <PersonIcon fontSize="large"/> : <PersonOutlinedIcon fontSize="large"/>}
                        </div>
                        <div className="other">
                            <div className="primary">{shortingTitle(option.label)}</div>
                            <div className="secondary">{shortingTitle(option.email)}</div>
                        </div>
                    </div>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={props.variant}
                    label={props.label}
                    required={props.required}
                    fullWidth={true}
                    InputProps={getInputProps(params, state.isNextPageLoading)}
                    error={props.error}
                    helperText={props.helperText}
                    size={props.size}
                />
            )}
        />
    );
};
export function retrieveStaffListData(
    query: StaffQueryParams,
    refreshData: boolean,
    onSuccess: (data: StaffListData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: StaffQuery,
            variables: {
                filter: query.filter,
                first: query.first,
                offset: query.offset,
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface StaffQueryParams {
    offset?: number;
    first?: number;
    filter?: string;
}

export interface StaffListData {
    loading?: boolean;
    networkStatus?: number;
    party: Party;
}

export interface Party {
    staffSummaries: StaffSummaries;
}

export interface StaffSummaries {
    recordCount: number;
    staffSummary: StaffSummary[];
}

export interface StaffSummary {
    staffGuid: string;
    staffId: number;
    name: string;
    emailAddress: string;
}

const StaffQuery = gql`
    query staffQuery($offset: Int, $first: Int, $filter: String) {
        party {
            staffSummaries(offset: $offset, first: $first, filter: $filter) {
                recordCount
                staffSummary {
                    staffGuid
                    staffId
                    name
                    emailAddress
                }
            }
        }
    }
`;
