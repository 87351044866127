/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress, makeStyles, Theme, createStyles } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { client } from '../..';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        listPaper: {
            '& > ul': {
                maxHeight: '52vh !important'
            }
        },
    })
);

export enum PanelSelectorUseByEnum {
    FirmDashboard = 0,
    LeadDashboard,
    FeeEarnerDashboard,
    TaskDashboard,
    ClientDashboard
} 
interface PanelSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value?: IAutoCompleteItem | IAutoCompleteItem[], name?: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    style?: React.CSSProperties; 
    disablePortal?: boolean;
    dashboardPanelUsedBy: PanelSelectorUseByEnum;
}

interface PanelSelectorState {
    isLoading: boolean;
    error?: boolean;
    // tslint:disable-next-line: no-any
    data: IAutoCompleteItem[] | any[];
}

export const PanelSelector: React.FC<PanelSelectorProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<PanelSelectorState>({
        isLoading: false,
        error: props.error,
        data: [],
    });

    useEffect(() => {   
        fetchData();
    // tslint:disable-next-line: align
    }, []);

    const fetchData = () => {

        setState({
            ...state,
            isLoading: true
        });

        const dashboardParams: DahboardPanelParams = {
            type: PanelSelectorUseByEnum[props.dashboardPanelUsedBy]
        };

        retrieveDashboardPaneleData(
            dashboardParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveData(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveData = (data: DahboardPanelData) => {
        let resData: IAutoCompleteItem[] = [];

        resData.push({
            label: 'Home',
            value: 'all'
        });

        data.lookup.dashboardPanel.forEach((source: DashboardPanel) => {
            resData.push({
                label: source.description,
                value: source.guidId,
            });
        });

        setState((prevState) => {
            return {
                ...prevState,
                data: resData,
                isLoading: false
            };
        });
    };

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (props.onSelection) {
            props.onSelection(value, props.name);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <>
            { props.multiple ? 
                (
                    <Autocomplete
                        style={props.style}
                        className={`${props.className} ${classes.root}`}
                        value={props.value}
                        multiple={true}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.data}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}       
                        classes={{
                            paper: classes.listPaper
                        }}             
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={state.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        style={props.style}
                        className={`${props.className} ${classes.root}`}
                        value={props.value}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.data}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}      
                        classes={{
                            paper: classes.listPaper
                        }}     
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={state.error}                            
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </>
    );
};

export interface DahboardPanelParams {
    type: string | null;
}
export interface DahboardPanelData {
    lookup: Lookup;
}

export interface Lookup {
    dashboardPanel: DashboardPanel[];
}

export interface DashboardPanel {
    guidId:      string;
    description: string;
    hasLayouts: boolean;
    usedByEnquiryDashboard: boolean;
    usedByFeeEarnerDashboard: boolean;
    usedByFirmDashboard: boolean;
    usedByTaskDashboard: boolean;
    usedByClientDashboard: boolean;
}

export function retrieveDashboardPaneleData(
    params: DahboardPanelParams,
    refreshData: boolean,
    onSuccess: (data: DahboardPanelData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: DashboardPanelQuery,
            variables: {
                type: params.type
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const DashboardPanelQuery = gql`
    query LookupDashboardPanel($type: DashboardPanelUsedBy) {
        lookup {
            dashboardPanel(type: $type) {
                guidId      
                description
                hasLayouts
                usedByEnquiryDashboard
                usedByFeeEarnerDashboard
                usedByFirmDashboard
                usedByTaskDashboard
                usedByClientDashboard
            }
        }
    }
`;