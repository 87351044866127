import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { RvButtonFabric, RvButtonType } from '../components/Button';
import { RvLabel, LabelStyle } from '../components/Label/Label';
import { mainTheme } from '../Theme';
import { Button } from '@material-ui/core';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { RvLoader } from '../components/Loader';
import { useHistory } from 'react-router-dom';
import { LearnMoreEnum } from '../learnMore/LearnMorePage';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            padding: '0 1rem',
        },
        container: {},
        buttonContainer: {
            position: 'relative',
            padding: '15px 80px',
            display: 'flex',
            // justifyContent: 'space-between',
            alignItems: 'center',
            color: mainTheme.BrandColors.TextPrimary,
            '@media (max-width: 1024px)': {
                padding: '15px 30px',
            },
            '& .rvlabel': {
                flex: 1,
                // paddingLeft: '82px',
                fontFamily: mainTheme.Font.Main,
                fontSize: mainTheme.FontSize.PX.size8,
                letterSpacing: '0.86px',
                lineHeight: mainTheme.LineHeight.ExtraLarge,
            },
            '& .back-button': {
                paddingRight: '32px',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                '& .ms-Button': {
                    width: '50px',
                    height: '50px',
                },
                '& .ms-Button-icon': {
                    fontSize: mainTheme.FontSize.PX.size10,
                    color: mainTheme.BrandColors.TemplatePrimary,
                },
            },
        },
        buttonGroupWrapper: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 -10px',
            '& > button': {
                margin: '0 10px',
            },
        },
        button: {
            margin: '0 10px',
            textTransform: 'none',
            fontSize: '14px',
        },
    }),
);

interface BannerProps {
    title: string;
}

export const MatterSummaryHeader: React.FC<BannerProps> = ({ title }) => {
    const classes = useStyles();

    const currentUser = useCurrentUser();

    const history = useHistory();

    if (currentUser.loading) {
        return (
            <div className={classes.root}>
                <RvLoader />
            </div>
        );
    }

    const isInternal = currentUser.data?.appUser.isInternal;
    
    const onLearnMore = () => {
        if (!isInternal) {
            history.push({
                pathname: `/learnMore/${LearnMoreEnum[LearnMoreEnum.Matter_Searching_Client]}`, 
            });
        } else {

            history.push({
                pathname: `/learnMore/${LearnMoreEnum[LearnMoreEnum.Matter_Searching_Client_Internal_User]}`, 
            });
        }

    };

    const onResources = () => {
        history.push({
            pathname: `/mattersResourceCenter`,
        });
    };
    
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.buttonContainer}>
                    <RvLabel label={title} style={LabelStyle.Heading4} />
                    <div className={classes.buttonGroupWrapper}>
                        {UserFeature.HasAccess(UserFeatureEnum.hasMatterLearnMore) && (
                            <Button variant="text" color="primary" size="small" className={classes.button} onClick={onLearnMore} startIcon={<VideoLibraryOutlinedIcon />}>
                                Learn More
                            </Button>
                        )}
                        {UserFeature.HasAccess(UserFeatureEnum.hasMatterLearnMore) && isInternal && (
                            <Button variant="text" color="primary" size="small" className={classes.button} onClick={onResources} startIcon={<ErrorOutlineIcon />}>
                                Resources
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
