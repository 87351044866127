/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress, PopperProps } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { client } from '../..';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { FirmData, Office } from '../models/Firm';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';

export interface OfficeQueryParams {
    isMyOfficeOnly: boolean | null;
}

interface FirmOfficeSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value?: IAutoCompleteItem | IAutoCompleteItem[], name?: string) => void;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    style?: React.CSSProperties; 
    disablePortal?: boolean;
    showAllOffice?: boolean;
    PopperComponent?: React.ComponentType<PopperProps>;
}

interface FirmOfficeSelectorState {
    isLoading: boolean;
    data: IAutoCompleteItem[];
}

export const FirmOfficeSelector: React.FC<FirmOfficeSelectorProps> = props => {

    // const classes = useStyles();

    const [state, setState] = useState<FirmOfficeSelectorState>({
        isLoading: false,
        data: [],
    });

    useEffect(() => {   
        fetchData();
    // tslint:disable-next-line: align
    }, []);

    const fetchData = () => {

        setState({
            ...state,
            isLoading: true
        });

        let query: OfficeQueryParams = {
            isMyOfficeOnly : props.showAllOffice && props.showAllOffice === true // When not used with Dashboard controls
                            ? null
                            : (
                                UserFeature.HasAccess(UserFeatureEnum.hasFirmDashboardOfficeView) 
                                ? null
                                : UserFeature.HasAccess(UserFeatureEnum.hasFirmDashboardMyOfficeOnly)
                            )
                            
        };

        retrieveOfficeData(
            query,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveData(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveData = (data: FirmData) => {
        setState({
            ...state,
            data: data.firm.firmOffices.firmOffice.map((source: Office) => ({ ...source, value: source.guidID, label: source.description })),
            isLoading: false
        });
    };

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (props.onSelection) {
            props.onSelection(value, props.name);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <>
            { props.multiple ? 
                (
                    <Autocomplete
                        style={props.style}
                        className={props.className}
                        value={props.value}
                        multiple={true}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        onBlur={props.onBlur}
                        options={state.data}
                        // tslint:disable-next-line: max-line-length
                        disablePortal={props.disablePortal !== undefined ? props.disablePortal : true} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}   
                        PopperComponent={props.PopperComponent}           
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        style={props.style}
                        className={props.className}
                        value={props.value}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        onBlur={props.onBlur}
                        options={state.data}
                        // tslint:disable-next-line: max-line-length
                        disablePortal={props.disablePortal !== undefined ? props.disablePortal : true} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}         
                        // tslint:disable-next-line: jsx-no-lambda
                        PopperComponent={props.PopperComponent}           
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </>
    );
};

export function retrieveOfficeData(
    query: OfficeQueryParams | undefined,
    refreshData: boolean,
    onSuccess: (data: FirmData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: FirmOfficeList,
            variables: {
                isMyOfficeOnly: query ? query.isMyOfficeOnly : null
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const FirmOfficeList = gql`
query firmOfficeList($isMyOfficeOnly: Boolean) {
    firm {
        firmOffices(isMyOfficeOnly: $isMyOfficeOnly) {
            recordCount
            firmOffice {
                guidID,
                description,
                staffCount,
                timezone,
                timezoneDescription
            }
        }
    }
}`;