/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Grid, TextField, FormControlLabel, Switch, Checkbox } from '@material-ui/core';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { FormApi } from 'final-form';
import DialogBox from '../../Dashboard/Component/DialogBox';
import arrayMutators from 'final-form-arrays';
// import { printJson } from '../../firmSettingConfiguration/tabs/RegionalTab';
import { CountryAutoCompleteItem, MatterCountrySelector } from '../selectors/MatterCountrySelector';
import { MatterTypeSelector, MatterTypeTypeAutoCompleteItem } from '../selectors/MatterTypeSelector';
import { MatterPropertyAutoCompleteItem, MatterPropertySelector } from '../selectors/MatterPropertySelector';
import { MatterCategoryAutoCompleteItem, MatterCategorySelector } from '../selectors/MatterCategorySelector';
import { MatterClientSelector, NameSummaryAutoCompleteItem } from '../selectors/MatterClientSelector';
import { MatterStatusAutoCompleteItem, MatterStatusSelector } from '../selectors/MatterStatusSelector';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
// import moment from 'moment';
import _ from 'lodash';
import { useTenant } from '../../hooks/useTenant';
import { RvLoader } from '../../components/Loader';
import { PriorityCountryAutoCompleteItem, PriorityCountrySelector } from '../selectors/PriorityCountrySelector';
import { StaffSelector, StaffSummaryAutoCompleteItem } from '../selectors/StaffSelector';
import { CurrencyInput2 } from '../../components/CurrencyInput';
import { LocalStorageEnum } from '../../enums/LocalStorageEnum';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogRoot: {
            '& .downshift-wrapper': {
                flex: 1,
                padding: 0,
            },
        },
        header: {
            textTransform: 'capitalize',
        },
        textField: {
            width: '100%',
        },
        textFieldRight: {
            '& .MuiInputBase-input': {
                textAlign: 'right',
            }
        },
        dateField: {
            width: '100%',
        },
        content: {
            flex: 1,
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            // paddingBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
        addButton: {
            textTransform: 'none',
        },
        clientSelector: {
            flex: 1,
        },
        removedAvatar: {
            maxWidth: '15px',
            maxHeight: '15px',
        },
        addProperyWrapperGrid: {
            display: 'flex',
            alignItems: 'flex-end',
            padding: '4px',
        },
        divider: {
            width: '100%',
        },
        switchLabel: {
            margin: 0,
        },
        textFieldWithToggleWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        loadingWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            minHeight: '200px',
        },
    }),
);

interface AdvancedFilterDialogProps {
    open: boolean;
    onClose?: () => void;
    onSearch?: (filter: AdvancedFilterForm, hasFilter: boolean) => void;
    filterValues?: AdvancedFilterForm;
}

interface AdvancedFilterDialogState {
    onSubmitLoading: boolean;
    showDiscard: boolean;
    isChanged: boolean;
}

export interface MatterCountry {
    matterCountry: CountryAutoCompleteItem[];
    exclude: boolean;
}

export interface CaseType {
    caseType: MatterTypeTypeAutoCompleteItem[];
    exclude: boolean;
}

export interface MatterProperty {
    matterProperty: MatterPropertyAutoCompleteItem[];
    exclude: boolean;
}

export interface MatterCategory {
    matterCategory: MatterCategoryAutoCompleteItem[];
    exclude: boolean;
}

export interface MatterClient {
    matterClient: NameSummaryAutoCompleteItem[];
    exclude: boolean;
}

export interface MatterOwner {
    matterOwner: NameSummaryAutoCompleteItem[];
    exclude: boolean;
}

export interface MatterStatus {
    matterStatus: MatterStatusAutoCompleteItem[];
    exclude: boolean;
}

export interface PriorityCountry {
    priorityCountry: PriorityCountryAutoCompleteItem[];
    exclude: boolean;
}

export interface PersonActing {
    personActing: StaffSummaryAutoCompleteItem[];
    exclude: boolean;
}

export interface PersonResponsible {
    personResponsible: StaffSummaryAutoCompleteItem[];
    exclude: boolean;
}

export interface AdvancedFilterForm {
    ourReference?: string;
    yourReference?: string;
    matterCountries: MatterCountry;
    caseTypes: CaseType;
    matterProperties: MatterProperty;
    matterCategories: MatterCategory;
    matterClients: MatterClient;
    matterOwners: MatterOwner;
    matterStatuses: MatterStatus;
    filingDateFrom?: Date;
    filingDateTo?: Date;
    applicationNumber?: string;
    grantAndRegistrationDateFrom?: Date;
    grantAndRegistrationDateTo?: Date;
    registrationNumber?: string;
    priorityDateFrom?: Date;
    priorityDateTo?: Date;
    title?: string;
    class?: string;
    goodsAndServices?: string;
    priorityCountries: PriorityCountry;
    instructionDateFrom?: Date;
    instructionDateTo?: Date;
    completionDateFrom?: Date;
    completionDateTo?: Date;
    personActings: PersonActing;
    personResponsibles: PersonResponsible;
    isMatterEstimateZero: boolean;
    feesBilledVsEstimatePercentage: number;
}

export const AdvancedFilterDialog: React.FC<AdvancedFilterDialogProps> = (props) => {
    const classes = useStyles();

    const tenant = useTenant();

    const [state, setState] = useState<AdvancedFilterDialogState>({
        onSubmitLoading: false,
        showDiscard: false,
        isChanged: false,
    });

    const defaultMatterCountry: MatterCountry = {
        matterCountry: [],
        exclude: false,
    };

    const defaultCaseType: CaseType = {
        caseType: [],
        exclude: false,
    };

    const defaultMatterProperty: MatterProperty = {
        matterProperty: [],
        exclude: false,
    };

    const defaultMatterCategory: MatterCategory = {
        matterCategory: [],
        exclude: false,
    };

    const defaultMatterClient: MatterClient = {
        matterClient: [],
        exclude: false,
    };

    const defaultMatterOwner: MatterOwner = {
        matterOwner: [],
        exclude: false,
    };

    const defaultMatterStatus: MatterStatus = {
        matterStatus: [],
        exclude: false,
    };

    const defaultPriorityCountry: PriorityCountry = {
        priorityCountry: [],
        exclude: false,
    };

    const defaultPersonActings: PersonActing = {
        personActing: [],
        exclude: false,
    };

    const defaultPersonResponsibles: PersonResponsible = {
        personResponsible: [],
        exclude: false,
    };

    const defaultValues: AdvancedFilterForm = {
        ourReference: undefined,
        yourReference: undefined,
        matterCountries: defaultMatterCountry,
        caseTypes: defaultCaseType,
        matterProperties: defaultMatterProperty,
        matterCategories: defaultMatterCategory,
        matterClients: defaultMatterClient,
        matterOwners: defaultMatterOwner,
        matterStatuses: defaultMatterStatus,
        filingDateFrom: undefined,
        filingDateTo: undefined,
        applicationNumber: undefined,
        grantAndRegistrationDateFrom: undefined,
        grantAndRegistrationDateTo: undefined,
        registrationNumber: undefined,
        priorityDateFrom: undefined,
        priorityDateTo: undefined,
        title: undefined,
        class: undefined,
        goodsAndServices: undefined,
        priorityCountries: defaultPriorityCountry,
        completionDateFrom: undefined,
        completionDateTo: undefined,
        instructionDateFrom: undefined,
        instructionDateTo: undefined,
        personActings: defaultPersonActings,
        personResponsibles: defaultPersonResponsibles,
        feesBilledVsEstimatePercentage: 0,
        isMatterEstimateZero: false
    };

    let initialValues: AdvancedFilterForm = {
        ...defaultValues,
    };

    if (props.filterValues) {
        initialValues = props.filterValues;
    }
    // if (props.filterValues && !_.isMatch(props.filterValues, defaultValues)) {
    //     initialValues = props.filterValues;
    // }

    const onSubmit = (form: FormApi<AdvancedFilterForm>, values: AdvancedFilterForm) => {
        // code here..

        let hasFilter = false;

        if (!_.isMatch(defaultValues, values)) {
            hasFilter = true;
        }
        if (props.onSearch) {
            props.onSearch(values, hasFilter);
        }
        if (props.onClose) {
            props.onClose();
        }
    };

    const closePanel = () => {
        // codepanel
        if (props.onClose) {
            return props.onClose();
        }
    };

    const onClose = () => {
        if (state.isChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true,
                };
            });
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                };
            });

            if (props.onClose) {
                props.onClose();
            }
        } else {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    // const requiredDateValidator = (value: any) => {
    //     if (value) {
    //         // check for a valid date and check for sql's min date
    //         var date = moment(value);

    //         if (date.isValid() && date.year() >= 1753) {
    //             return undefined;
    //         } else {
    //             return 'Incorrect Date';
    //         }
    //     } else {
    //         return 'Required';
    //     }
    // };

    const onReset = (form: FormApi<AdvancedFilterForm>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.change('ourReference', undefined);
            form.change('yourReference', undefined);
            form.change('matterCountries', defaultMatterCountry);
            form.change('caseTypes', defaultCaseType);
            form.change('matterProperties', defaultMatterProperty);
            form.change('matterCategories', defaultMatterCategory);
            form.change('matterClients', defaultMatterClient);
            form.change('matterOwners', defaultMatterOwner);
            form.change('matterStatuses', defaultMatterStatus);
            form.change('filingDateFrom', undefined);
            form.change('filingDateTo', undefined);
            form.change('applicationNumber', undefined);
            form.change('grantAndRegistrationDateFrom', undefined);
            form.change('grantAndRegistrationDateTo', undefined);
            form.change('registrationNumber', undefined);
            form.change('priorityDateFrom', undefined);
            form.change('priorityDateTo', undefined);
            form.change('title', undefined);
            form.change('class', undefined);
            form.change('goodsAndServices', undefined);
            form.change('priorityCountries', defaultPriorityCountry);
            form.change('completionDateFrom', undefined);
            form.change('completionDateTo', undefined);
            form.change('instructionDateFrom', undefined);
            form.change('instructionDateTo', undefined);
            form.change('isMatterEstimateZero', false);
            form.change('feesBilledVsEstimatePercentage', undefined);

            form.change('personActings', defaultPersonActings);
            form.change('personResponsibles', defaultPersonResponsibles);
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<AdvancedFilterForm>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: AnyObject,
    ) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                <div>
                    <Button
                        color="primary"
                        type="submit"
                        className={classes.button}
                        onClick={() => {
                            // code here..
                        }}
                        disabled={submitting || pristine || state.onSubmitLoading}
                    >
                        Search
                    </Button>
                </div>
                <div>
                    <Button
                        color="primary"
                        type="button"
                        className={classes.button}
                        onClick={onReset(form)}
                        // disabled={submitting || pristine}
                    >
                        Clear
                    </Button>
                </div>
                <div>
                    <Button color="primary" type="button" className={classes.button} onClick={closePanel}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };

    // tslint:disable-next-line: no-any
    // const onRequired = (value: any) => {
    //     if (value === undefined || value.length === 0) {
    //         return 'Required';
    //     }
    //     return undefined;
    // };

    const ourReferenceField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <Field name={`ourReference`} subscription={{ touched: true, error: true, value: true }}>
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        label="Attorney Reference"
                        type="text"
                        variant="standard"
                        className={classes.textField}
                        error={meta.error && meta.touched}
                        // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                        size="small"
                    />
                )}
            </Field>
        );
    };

    const yourReferenceField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <Field name={`yourReference`} subscription={{ touched: true, error: true, value: true }}>
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        label="Client Reference"
                        type="text"
                        variant="standard"
                        className={classes.textField}
                        error={meta.error && meta.touched}
                        // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                        size="small"
                    />
                )}
            </Field>
        );
    };

    const matterCountryField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <div className={classes.textFieldWithToggleWrapper}>
                <Field name={`matterCountries.matterCountry`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <MatterCountrySelector
                            {...input}
                            label="Country"
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            error={meta.error && meta.touched}
                            onSelection={(selection: CountryAutoCompleteItem[]) => {
                                input.onChange(selection);
                            }}
                            onBlur={input.onBlur}
                            multiple={true}
                            isApplyMatterAccess={true}
                        />
                    )}
                </Field>
                <Field name={`matterCountries.exclude`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <FormControlLabel
                            className={classes.switchLabel}
                            control={<Switch {...input} checked={input.value} onChange={input.onChange} color="primary" size="small" />}
                            label="Not"
                        />
                    )}
                </Field>
            </div>
        );
    };

    const caseTypeField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        const isInprotech = tenant.data!.tenant.sourceSystem.toLocaleLowerCase() === 'inprotech';

        return (
            <div className={classes.textFieldWithToggleWrapper}>
                <Field name={`caseTypes.caseType`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <MatterTypeSelector
                            {...input}
                            label={isInprotech ? 'Case Type' : 'Matter Type'}
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            error={meta.error && meta.touched}
                            onSelection={(selection: MatterTypeTypeAutoCompleteItem[]) => {
                                input.onChange(selection);
                            }}
                            onBlur={input.onBlur}
                            multiple={true}
                            isApplyMatterAccess={true}
                        />
                    )}
                </Field>
                <Field name={`caseTypes.exclude`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <FormControlLabel
                            className={classes.switchLabel}
                            control={<Switch {...input} checked={input.value} onChange={input.onChange} color="primary" size="small" />}
                            label="Not"
                        />
                    )}
                </Field>
            </div>
        );
    };

    const matterPropertyField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <div className={classes.textFieldWithToggleWrapper}>
                <Field name={`matterProperties.matterProperty`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <MatterPropertySelector
                            {...input}
                            label="Property Type"
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            error={meta.error && meta.touched}
                            onSelection={(selection: MatterPropertyAutoCompleteItem[]) => {
                                input.onChange(selection);
                            }}
                            onBlur={input.onBlur}
                            multiple={true}
                            isApplyMatterAccess={true}
                        />
                    )}
                </Field>
                <Field name={`matterProperties.exclude`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <FormControlLabel
                            className={classes.switchLabel}
                            control={<Switch {...input} checked={input.value} onChange={input.onChange} color="primary" size="small" />}
                            label="Not"
                        />
                    )}
                </Field>
            </div>
        );
    };

    const matterCategoryField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        const isInprotech = tenant.data!.tenant.sourceSystem.toLocaleLowerCase() === 'inprotech';

        return (
            <div className={classes.textFieldWithToggleWrapper}>
                <Field name={`matterCategories.matterCategory`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <MatterCategorySelector
                            {...input}
                            label={isInprotech ? 'Case Category' : 'Matter Category'}
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            error={meta.error && meta.touched}
                            onSelection={(selection: MatterCategoryAutoCompleteItem[]) => {
                                input.onChange(selection);
                            }}
                            onBlur={input.onBlur}
                            multiple={true}
                            isApplyMatterAccess={true}
                        />
                    )}
                </Field>
                <Field name={`matterCategories.exclude`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <FormControlLabel
                            className={classes.switchLabel}
                            control={<Switch {...input} checked={input.value} onChange={input.onChange} color="primary" size="small" />}
                            label="Not"
                        />
                    )}
                </Field>
            </div>
        );
    };

    const matterClientField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <div className={classes.textFieldWithToggleWrapper}>
                <Field name={`matterClients.matterClient`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <MatterClientSelector
                            {...input}
                            value={input.value}
                            label="Client"
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            error={meta.error && meta.touched}
                            onSelection={(selection: NameSummaryAutoCompleteItem[]) => {
                                input.onChange(selection);
                            }}
                            onBlur={input.onBlur}
                            isClientsOnly={true}
                            includeIndividual={true}
                            includeOrganisation={true}
                            multiple={true}
                            isMatterOwnersOnly={false}
                            isApplyMatterAccess={true}
                        />
                    )}
                </Field>
                <Field name={`matterClients.exclude`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <FormControlLabel
                            className={classes.switchLabel}
                            control={<Switch {...input} checked={input.value} onChange={input.onChange} color="primary" size="small" />}
                            label="Not"
                        />
                    )}
                </Field>
            </div>
        );
    };

    const matterOwnerField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <div className={classes.textFieldWithToggleWrapper}>
                <Field name={`matterOwners.matterOwner`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <MatterClientSelector
                            {...input}
                            value={input.value}
                            label="Owner"
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            error={meta.error && meta.touched}
                            onSelection={(selection: NameSummaryAutoCompleteItem[]) => {
                                input.onChange(selection);
                            }}
                            onBlur={input.onBlur}
                            isClientsOnly={false}
                            isMatterOwnersOnly={true}
                            includeIndividual={true}
                            includeOrganisation={true}
                            multiple={true}
                            isApplyMatterAccess={true}
                        />
                    )}
                </Field>

                <Field name={`matterOwners.exclude`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <FormControlLabel
                            className={classes.switchLabel}
                            control={<Switch {...input} checked={input.value} onChange={input.onChange} color="primary" size="small" />}
                            label="Not"
                        />
                    )}
                </Field>
            </div>
        );
    };

    const matterStatusField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <div className={classes.textFieldWithToggleWrapper}>
                <Field name={`matterStatuses.matterStatus`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <MatterStatusSelector
                            {...input}
                            label="Status"
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            error={meta.error && meta.touched}
                            onSelection={(selection: MatterStatusAutoCompleteItem[]) => {
                                input.onChange(selection);
                            }}
                            onBlur={input.onBlur}
                            multiple={true}
                            isApplyMatterAccess={true}
                        />
                    )}
                </Field>

                <Field name={`matterStatuses.exclude`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <FormControlLabel
                            className={classes.switchLabel}
                            control={<Switch {...input} checked={input.value} onChange={input.onChange} color="primary" size="small" />}
                            label="Not"
                        />
                    )}
                </Field>
            </div>
        );
    };

    const matterFilingDateFieldFrom = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <FormSpy subscription={{ values: true, validating: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Field name="filingDateFrom" subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <KeyboardDatePicker
                                {...input}
                                value={input && input.value ? input.value : null}
                                className={classes.dateField}
                                label="Filing Date From"
                                format="DD/MM/YYYY"
                                placeholder="dd/mm/yyyy"
                                // tslint:disable-next-line: max-line-length
                                // required={true}
                                animateYearScrolling={true}
                                // tslint:disable-next-line: max-line-length
                                autoOk={true}
                                allowKeyboardControl={false}
                                variant={'inline'}
                                error={meta.error && meta.touched}
                                // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                inputProps={{ autocomplete: 'off' }}
                                InputLabelProps={{ shrink: true }}
                                // inputvariant="standard"
                                size="small"
                                maxDate={values.filingDateTo ? values.filingDateTo : undefined}
                            />
                        )}
                    </Field>
                )}
            </FormSpy>
        );
    };

    const matterFilingDateFieldTo = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <FormSpy subscription={{ values: true, validating: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Field name="filingDateTo" subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <KeyboardDatePicker
                                {...input}
                                value={input && input.value ? input.value : null}
                                className={classes.dateField}
                                label="Filing Date To"
                                format="DD/MM/YYYY"
                                placeholder="dd/mm/yyyy"
                                // tslint:disable-next-line: max-line-length
                                // required={true}
                                animateYearScrolling={true}
                                // tslint:disable-next-line: max-line-length
                                autoOk={true}
                                allowKeyboardControl={false}
                                variant={'inline'}
                                error={meta.error && meta.touched}
                                // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                inputProps={{ autocomplete: 'off' }}
                                InputLabelProps={{ shrink: true }}
                                // inputvariant="standard"
                                size="small"
                                minDate={values.filingDateFrom ? values.filingDateFrom : undefined}
                            />
                        )}
                    </Field>
                )}
            </FormSpy>
        );
    };

    const applicationNumberField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <Field name="applicationNumber" subscription={{ touched: true, error: true, value: true }}>
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        label="Application Number"
                        type="text"
                        variant="standard"
                        className={classes.textField}
                        error={meta.error && meta.touched}
                        // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                        size="small"
                    />
                )}
            </Field>
        );
    };

    const grantAndRegistrationDateFieldFrom = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <FormSpy subscription={{ values: true, validating: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Field name="grantAndRegistrationDateFrom" subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <KeyboardDatePicker
                                {...input}
                                value={input && input.value ? input.value : null}
                                className={classes.dateField}
                                label="Grant/Registration Date From"
                                format="DD/MM/YYYY"
                                placeholder="dd/mm/yyyy"
                                // tslint:disable-next-line: max-line-length
                                // required={true}
                                animateYearScrolling={true}
                                // tslint:disable-next-line: max-line-length
                                autoOk={true}
                                allowKeyboardControl={false}
                                variant={'inline'}
                                error={meta.error && meta.touched}
                                // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                inputProps={{ autocomplete: 'off' }}
                                InputLabelProps={{ shrink: true }}
                                // inputvariant="standard"
                                size="small"
                                maxDate={values.grantAndRegistrationDateTo ? values.grantAndRegistrationDateTo : undefined}
                            />
                        )}
                    </Field>
                )}
            </FormSpy>
        );
    };

    const grantAndRegistrationDateFieldTo = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <FormSpy subscription={{ values: true, validating: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Field name="grantAndRegistrationDateTo" subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <KeyboardDatePicker
                                {...input}
                                value={input && input.value ? input.value : null}
                                className={classes.dateField}
                                label="Grant/Registration Date To"
                                format="DD/MM/YYYY"
                                placeholder="dd/mm/yyyy"
                                // tslint:disable-next-line: max-line-length
                                // required={true}
                                animateYearScrolling={true}
                                // tslint:disable-next-line: max-line-length
                                autoOk={true}
                                allowKeyboardControl={false}
                                variant={'inline'}
                                error={meta.error && meta.touched}
                                // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                inputProps={{ autocomplete: 'off' }}
                                InputLabelProps={{ shrink: true }}
                                // inputvariant="standard"
                                size="small"
                                minDate={values.grantAndRegistrationDateFrom ? values.grantAndRegistrationDateFrom : undefined}
                            />
                        )}
                    </Field>
                )}
            </FormSpy>
        );
    };

    const registrationNumberField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <Field name="registrationNumber" subscription={{ touched: true, error: true, value: true }}>
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        label="Registration/Grant Number"
                        type="text"
                        variant="standard"
                        className={classes.textField}
                        error={meta.error && meta.touched}
                        // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                        size="small"
                    />
                )}
            </Field>
        );
    };

    const priorityDateFieldFrom = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <FormSpy subscription={{ values: true, validating: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Field name="priorityDateFrom" subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <KeyboardDatePicker
                                {...input}
                                value={input && input.value ? input.value : null}
                                className={classes.dateField}
                                label="Priority Date From"
                                format="DD/MM/YYYY"
                                placeholder="dd/mm/yyyy"
                                // tslint:disable-next-line: max-line-length
                                // required={true}
                                animateYearScrolling={true}
                                // tslint:disable-next-line: max-line-length
                                autoOk={true}
                                allowKeyboardControl={false}
                                variant={'inline'}
                                error={meta.error && meta.touched}
                                // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                inputProps={{ autocomplete: 'off' }}
                                InputLabelProps={{ shrink: true }}
                                // inputvariant="standard"
                                size="small"
                                maxDate={values.priorityDateTo ? values.priorityDateTo : undefined}
                            />
                        )}
                    </Field>
                )}
            </FormSpy>
        );
    };

    const priorityDateFieldTo = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <FormSpy subscription={{ values: true, validating: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Field name="priorityDateTo" subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <KeyboardDatePicker
                                {...input}
                                value={input && input.value ? input.value : null}
                                className={classes.dateField}
                                label="Priority Date To"
                                format="DD/MM/YYYY"
                                placeholder="dd/mm/yyyy"
                                // tslint:disable-next-line: max-line-length
                                // required={true}
                                animateYearScrolling={true}
                                // tslint:disable-next-line: max-line-length
                                autoOk={true}
                                allowKeyboardControl={false}
                                variant={'inline'}
                                error={meta.error && meta.touched}
                                // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                inputProps={{ autocomplete: 'off' }}
                                InputLabelProps={{ shrink: true }}
                                // inputvariant="standard"
                                size="small"
                                minDate={values.priorityDateFrom ? values.priorityDateFrom : undefined}
                            />
                        )}
                    </Field>
                )}
            </FormSpy>
        );
    };

    const priorityCountryField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <div className={classes.textFieldWithToggleWrapper}>
                <Field name={`priorityCountries.priorityCountry`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <PriorityCountrySelector
                            {...input}
                            label="Priority Country"
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            error={meta.error && meta.touched}
                            onSelection={(selection: PriorityCountryAutoCompleteItem[]) => {
                                input.onChange(selection);
                            }}
                            onBlur={input.onBlur}
                            multiple={true}
                            isApplyMatterAccess={true}
                        />
                    )}
                </Field>
                <Field name={`priorityCountries.exclude`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <FormControlLabel
                            className={classes.switchLabel}
                            control={<Switch {...input} checked={input.value} onChange={input.onChange} color="primary" size="small" />}
                            label="Not"
                        />
                    )}
                </Field>
            </div>
        );
    };

    const titleField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <Field name="title" subscription={{ touched: true, error: true, value: true }}>
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        label="Title/Mark"
                        type="text"
                        variant="standard"
                        className={classes.textField}
                        error={meta.error && meta.touched}
                        // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                        size="small"
                    />
                )}
            </Field>
        );
    };

    const classField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <Field name="class" subscription={{ touched: true, error: true, value: true }}>
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        label="Class"
                        type="text"
                        variant="standard"
                        className={classes.textField}
                        error={meta.error && meta.touched}
                        // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                        size="small"
                    />
                )}
            </Field>
        );
    };

    const goodsAndServicesField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <Field name="goodsAndServices" subscription={{ touched: true, error: true, value: true }}>
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        label="Goods And Services"
                        type="text"
                        variant="standard"
                        className={classes.textField}
                        error={meta.error && meta.touched}
                        // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                        size="small"
                    />
                )}
            </Field>
        );
    };

    const instructionDateFieldFrom = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <FormSpy subscription={{ values: true, validating: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Field name="instructionDateFrom" subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <KeyboardDatePicker
                                {...input}
                                value={input && input.value ? input.value : null}
                                className={classes.dateField}
                                label="Instruction Date From"
                                format="DD/MM/YYYY"
                                placeholder="dd/mm/yyyy"
                                // tslint:disable-next-line: max-line-length
                                // required={true}
                                animateYearScrolling={true}
                                // tslint:disable-next-line: max-line-length
                                autoOk={true}
                                allowKeyboardControl={false}
                                variant={'inline'}
                                error={meta.error && meta.touched}
                                // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                inputProps={{ autocomplete: 'off' }}
                                InputLabelProps={{ shrink: true }}
                                // inputvariant="standard"
                                size="small"
                                maxDate={values.instructionDateTo ? values.instructionDateTo : undefined}
                            />
                        )}
                    </Field>
                )}
            </FormSpy>
        );
    };

    const instructionDateFieldTo = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <FormSpy subscription={{ values: true, validating: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Field name="instructionDateTo" subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <KeyboardDatePicker
                                {...input}
                                value={input && input.value ? input.value : null}
                                className={classes.dateField}
                                label="Instruction Date To"
                                format="DD/MM/YYYY"
                                placeholder="dd/mm/yyyy"
                                // tslint:disable-next-line: max-line-length
                                // required={true}
                                animateYearScrolling={true}
                                // tslint:disable-next-line: max-line-length
                                autoOk={true}
                                allowKeyboardControl={false}
                                variant={'inline'}
                                error={meta.error && meta.touched}
                                // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                inputProps={{ autocomplete: 'off' }}
                                InputLabelProps={{ shrink: true }}
                                // inputvariant="standard"
                                size="small"
                                minDate={values.instructionDateFrom ? values.instructionDateFrom : undefined}
                            />
                        )}
                    </Field>
                )}
            </FormSpy>
        );
    };

    const completionDateFieldFrom = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <FormSpy subscription={{ values: true, validating: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Field name="completionDateFrom" subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <KeyboardDatePicker
                                {...input}
                                value={input && input.value ? input.value : null}
                                className={classes.dateField}
                                label="Completion Date From"
                                format="DD/MM/YYYY"
                                placeholder="dd/mm/yyyy"
                                // tslint:disable-next-line: max-line-length
                                // required={true}
                                animateYearScrolling={true}
                                // tslint:disable-next-line: max-line-length
                                autoOk={true}
                                allowKeyboardControl={false}
                                variant={'inline'}
                                error={meta.error && meta.touched}
                                // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                inputProps={{ autocomplete: 'off' }}
                                InputLabelProps={{ shrink: true }}
                                // inputvariant="standard"
                                size="small"
                                maxDate={values.completionDateTo ? values.completionDateTo : undefined}
                            />
                        )}
                    </Field>
                )}
            </FormSpy>
        );
    };

    const completionDateFieldTo = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <FormSpy subscription={{ values: true, validating: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Field name="completionDateTo" subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <KeyboardDatePicker
                                {...input}
                                value={input && input.value ? input.value : null}
                                className={classes.dateField}
                                label="Completion Date To"
                                format="DD/MM/YYYY"
                                placeholder="dd/mm/yyyy"
                                // tslint:disable-next-line: max-line-length
                                // required={true}
                                animateYearScrolling={true}
                                // tslint:disable-next-line: max-line-length
                                autoOk={true}
                                allowKeyboardControl={false}
                                variant={'inline'}
                                error={meta.error && meta.touched}
                                // helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                inputProps={{ autocomplete: 'off' }}
                                InputLabelProps={{ shrink: true }}
                                // inputvariant="standard"
                                size="small"
                                minDate={values.completionDateFrom ? values.completionDateFrom : undefined}
                            />
                        )}
                    </Field>
                )}
            </FormSpy>
        );
    };

    const personActingField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <div className={classes.textFieldWithToggleWrapper}>
                <Field name={`personActings.personActing`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <StaffSelector
                            {...input}
                            value={input.value}
                            label={localStorage.getItem(LocalStorageEnum.PersonActing) || 'Person Acting'}
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            error={meta.error && meta.touched}
                            onSelection={(selection: StaffSummaryAutoCompleteItem[]) => {
                                input.onChange(selection);
                            }}
                            onBlur={input.onBlur}
                            multiple={true}
                        />
                    )}
                </Field>

                <Field name={`personActings.exclude`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <FormControlLabel
                            className={classes.switchLabel}
                            control={<Switch {...input} checked={input.value} onChange={input.onChange} color="primary" size="small" />}
                            label="Not"
                        />
                    )}
                </Field>
            </div>
        );
    };

    const personResponsibleField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <div className={classes.textFieldWithToggleWrapper}>
                <Field name={`personResponsibles.personResponsible`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <StaffSelector
                            {...input}
                            value={input.value}
                            label={localStorage.getItem(LocalStorageEnum.PersonResponsible) || 'Person Responsible'}
                            variant="standard"
                            size="small"
                            className={classes.textField}
                            error={meta.error && meta.touched}
                            onSelection={(selection: StaffSummaryAutoCompleteItem[]) => {
                                input.onChange(selection);
                            }}
                            onBlur={input.onBlur}
                            multiple={true}
                        />
                    )}
                </Field>

                <Field name={`personResponsibles.exclude`} subscription={{ touched: true, error: true, value: true }}>
                    {({ input, meta }) => (
                        <FormControlLabel
                            className={classes.switchLabel}
                            control={<Switch {...input} checked={input.value} onChange={input.onChange} color="primary" size="small" />}
                            label="Not"
                        />
                    )}
                </Field>
            </div>
        );
    };

    const matterEstimateCheckbox = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <Field name={`isMatterEstimateZero`} subscription={{ touched: true, error: true, value: true }}>
                {({ input, meta }) => (
                    <FormControlLabel
                        className={classes.switchLabel}
                        control={<Checkbox {...input} checked={input.value} onChange={input.onChange} color="primary" size="small" />}
                        label="Matter estimate is zero"
                    />
                )}
            </Field>
        );
    };

    const feesBilledVsEstimateField = (form: FormApi<AdvancedFilterForm>, submitting: boolean, pristine: boolean, values: AdvancedFilterForm) => {
        return (
            <Field 
                name="feesBilledVsEstimatePercentage" 
                label={'Fees Billed vs Estimated greater than'} 
                // helperText={'Fee amount to bill'} 
                suffix="%"
                className={`${classes.textField} ${classes.textFieldRight}`}
                component={CurrencyInput2}
                size="small"
                // disabled={props.formAction === 'edit'}
            />
        );
    };

    const dialogForm = () => {
        if (tenant.data!.tenant.sourceSystem.toLocaleLowerCase() === 'inprotech') {
            return (
                <Form
                    onSubmit={(values, form: FormApi<AdvancedFilterForm>) => onSubmit(form, values)}
                    mutators={{ ...arrayMutators }}
                    initialValues={initialValues}
                    subscription={{ submitting: true, pristine: true }}
                    keepDirtyOnReinitialize={true}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers={true}>
                                <Grid container={true} spacing={2}>
                                    <Grid item={true} xs={12} md={12}>
                                        <Grid container={true} spacing={2}>
                                            <Grid item={true} xs={12} md={4}>
                                                {ourReferenceField(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {yourReferenceField(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {matterCountryField(form, submitting, pristine, values)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12}>
                                        <Grid container={true} spacing={2}>
                                            <Grid item={true} xs={12} md={4}>
                                                {caseTypeField(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {matterPropertyField(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {matterCategoryField(form, submitting, pristine, values)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12}>
                                        <Grid container={true} spacing={2}>
                                            <Grid item={true} xs={12} md={4}>
                                                {matterClientField(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {matterOwnerField(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {matterStatusField(form, submitting, pristine, values)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12}>
                                        <Grid container={true} spacing={2}>
                                            <Grid item={true} xs={12} md={4}>
                                                {applicationNumberField(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {matterFilingDateFieldFrom(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {matterFilingDateFieldTo(form, submitting, pristine, values)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12}>
                                        <Grid container={true} spacing={2}>
                                            <Grid item={true} xs={12} md={4}>
                                                {registrationNumberField(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {grantAndRegistrationDateFieldFrom(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {grantAndRegistrationDateFieldTo(form, submitting, pristine, values)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12}>
                                        <Grid container={true} spacing={2}>
                                            <Grid item={true} xs={12} md={4}>
                                                {priorityCountryField(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {priorityDateFieldFrom(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {priorityDateFieldTo(form, submitting, pristine, values)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12}>
                                        <Grid container={true} spacing={2}>
                                            <Grid item={true} xs={12} md={4}>
                                                {titleField(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {classField(form, submitting, pristine, values)}
                                            </Grid>
                                            <Grid item={true} xs={12} md={4}>
                                                {goodsAndServicesField(form, submitting, pristine, values)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>{actionButton(form, submitting, pristine, values)}</DialogActions>
                            {/* {printJson(values)} */}
                        </form>
                    )}
                />
            );
        }
        return (
            <Form
                onSubmit={(values, form: FormApi<AdvancedFilterForm>) => onSubmit(form, values)}
                mutators={{ ...arrayMutators }}
                initialValues={initialValues}
                subscription={{ submitting: true, pristine: true }}
                keepDirtyOnReinitialize={true}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent dividers={true}>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} xs={12} md={12}>
                                    <Grid container={true} spacing={2}>
                                        <Grid item={true} xs={12} md={4}>
                                            {matterClientField(form, submitting, pristine, values)}
                                        </Grid>
                                        <Grid item={true} xs={12} md={4}>
                                            {matterStatusField(form, submitting, pristine, values)}
                                        </Grid>
                                        <Grid item={true} xs={12} md={4}>
                                            {caseTypeField(form, submitting, pristine, values)}
                                        </Grid>
                                    </Grid>
                                    <Grid container={true} spacing={2}>
                                        <Grid item={true} xs={12} md={4}>
                                            {personActingField(form, submitting, pristine, values)}
                                        </Grid>
                                        <Grid item={true} xs={12} md={4}>
                                            {personResponsibleField(form, submitting, pristine, values)}
                                        </Grid>
                                        <Grid item={true} xs={12} md={4}>
                                            {matterCategoryField(form, submitting, pristine, values)}
                                        </Grid>
                                    </Grid>
                                    <Grid container={true} spacing={2}>
                                        <Grid item={true} xs={12} md={4}>
                                            {instructionDateFieldFrom(form, submitting, pristine, values)}
                                        </Grid>
                                        <Grid item={true} xs={12} md={4}>
                                            {instructionDateFieldTo(form, submitting, pristine, values)}
                                        </Grid>
                                        <Grid item={true} xs={12} md={4}>
                                            {matterEstimateCheckbox(form, submitting, pristine, values)}
                                        </Grid>
                                    </Grid>
                                    <Grid container={true} spacing={2}>
                                        <Grid item={true} xs={12} md={4}>
                                            {completionDateFieldFrom(form, submitting, pristine, values)}
                                        </Grid>
                                        <Grid item={true} xs={12} md={4}>
                                            {completionDateFieldTo(form, submitting, pristine, values)}
                                        </Grid>
                                        <Grid item={true} xs={12} md={4}>
                                            {feesBilledVsEstimateField(form, submitting, pristine, values)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>{actionButton(form, submitting, pristine, values)}</DialogActions>
                        {/* {printJson(values)} */}
                    </form>
                )}
            />
        );
    };

    return (
        <>
            <DialogBox
                title="Priority"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={state.showDiscard}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />

            <Dialog open={props.open} onClose={onClose} maxWidth="md" fullWidth={true} className={classes.dialogRoot} scroll={'paper'}>
                <DialogTitle className={classes.header}>Advanced Filter</DialogTitle>
                {tenant.loading ? (
                    <DialogContent>
                        <div className={classes.loadingWrapper}>
                            <RvLoader />
                        </div>
                    </DialogContent>
                ) : (
                    dialogForm()
                )}
            </Dialog>
        </>
    );
};
