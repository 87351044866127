/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, FormEvent, useMemo } from 'react';
import { makeStyles, Theme, createStyles, Grid, IconButton, Badge } from '@material-ui/core';
import { MatterTerminology } from '../MatterTerminology';
import { RvSearch } from '../components/Search';
import { mainTheme } from '../Theme';
import { RvLabel } from '../components/Label/Label';
import { RvTooltipFabric } from '../components/Tooltip';
import { RvButtonFabric, RvButtonType } from '../components/Button';
import { SummaryViewConfigObj } from '../viewConfig/SummaryViewConfigurations';
import { RvCheckbox, CheckboxState } from '../components/Checkbox';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';
import { CommandButton } from 'office-ui-fabric-react';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useTenant } from '../hooks/useTenant';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            padding: '30px',
        },
        searchContentWrapper: {
            display: 'flex',
            margin: '0 -10px',
        },
        searchWrapper: {
            padding: '0 10px',
            flex: 1,
            '& > div': {
                [theme.breakpoints.down('sm')]: {
                    textAlign: 'center',
                },
            },
            '& .input': {
                height: '48px',
                width: '100%', // '336px',
                border: '1px solid ' + mainTheme.NeutralColors.BackgroundGrey1,
                borderRadius: '4px',
                boxShadow:
                    '0 0 4px 0 ' + mainTheme.NeutralColors.BackgroundGrey2,
                margin: 0,
            },
        },
        checkboxWrapper: {
            padding: '0 10px',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-around',
        },
        showingWrapper: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
            },
            [theme.breakpoints.up('md')]: {
                justifyContent: 'flex-start',
            },
        },
        menuWrapper: {
            display: 'flex',
            margin: '0 -10px',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
            },
            [theme.breakpoints.up('md')]: {
                justifyContent: 'flex-end',
            },
            '& > div': {
                padding: '0 10px',
            },
            '& .crud-container': {
                display: 'flex',
                margin: '0 -10px',
                '& > div': {
                    padding: '0 10px',
                },
                [`& .${`button-fabric`}`]: {
                    [`& .${`ms-Button-icon`}`]: {
                        color: '#70D389',
                        fontSize: '20px',
                    },
                    [`& .${`is-disabled`}`]: {
                        [`& .${`ms-Button-icon`}`]: {
                            color: '#ECF9EF',
                        },
                    },
                },
            },
            [`& .${`ms-Button`}`]: {
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
            [`& .${`is-disabled`}`]: {
                backgroundColor: 'transparent',
                [`& g`]: {
                    stroke: 'none',
                    [`& path`]: {
                        stroke: 'none',
                    },
                },
            },
            [`& .${`custom-split-2`}`]: {
                '& .share i': {
                    fontSize: '29px',
                    display: 'none',
                },
                '& .share i:first-child': {
                    display: 'block',
                },
                [`& .${`ms-Button`}`]: {
                    minWidth: 0,
                    padding: 0,
                    backgroundColor: 'transparent',
                    border: 0,
                    // [`& .${`ms-Button-flexContainer`}`]: {
                    //     [`& ${`i`}`]: {
                    //         color: 'green',
                    //     },
                    // },
                },
                '& [data-icon-name="ChevronDown"]': {
                    color: '#70D389',
                }
            },
            [`& .${`custom-split`}`]: {
                [`& .${`ms-Button`}`]: {
                    minWidth: 0,
                    padding: 0,
                    backgroundColor: 'transparent',
                    border: 0,
                },
                [`& .${`ms-Button-menuIcon`}`]: {
                    display: 'none',
                },
            },
        },
        notes: {
            backgroundColor: 'rgba(243, 243, 158, 0.29)',
            padding: '10px',
        },
        downlaod: {
            cursor: 'pointer'
        },
        customButton: {
            padding: 0,
            '& i.ms-Icon': {
                fontSize: 20,
                margin: 0,
                color: 'rgb(112, 211, 137)',
            }
        },
        advancedFilter: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 -10px',
            '& > div': {
                padding: '0 10px',
            }
        },
        advancedFilterButton: {
            color: '#70D389',
        },
        disabledShare: {
            '& [data-icon-name="share"]': {
                color: '#EAF8ED',
            }
        },
        enabledShare: {
            '& [data-icon-name="share"]': {
                color: '#70D389',
            }
        }
    })
);

export enum ExportType {
    CSV = 0,
    Excel,
}

export enum ViewDisplayStyle {
    List = 0,
    Card,
}

interface ControlPanelProps {
    showViewLayouts: boolean;
    isLoading: boolean;
    initialIncludeCompleted: boolean;
    loadedMatterCount: number;
    totalMatterCount: number;
    viewDisplayStyle?: ViewDisplayStyle;
    views?: Array<SummaryViewConfigObj>;
    isDisableSave?: boolean;
    onIncludeCompleted?: (includeCompleted: boolean) => void;
    onSearch?: (searchString: string) => void;
    searchFilter?: string;
    onViewSelected?: (configId: number) => void;
    onSaveCurrentView?: () => void;
    onToggleGrouping?: (show: boolean) => void;
    onToggleConfigs?: (show: boolean) => void;
    onToggleTools?: (show: boolean) => void;
    onRefreshData?: () => void;
    onExport?: (type: ExportType) => void;
    onPrint?: (style: ViewDisplayStyle) => void;
    onViewStyle?: (style: ViewDisplayStyle) => void;
    isShowViewPanel?: boolean;
    isInMemory: boolean /* If the grid data is in memory */;
    onMatterDownloadChanged?: (isDownload: boolean) => void /* Trigger to start matter download */;
    // tslint:disable-next-line: no-any
    entityType?: any;
    className?: string;
    isForceDownload: boolean;
    onAdvancedFilter?: () => void;
    hasFilter: boolean;
    isExportLoading: boolean;
}

interface ControlPanelStates {
    search: {
        query?: string;
        includeCompleted?: boolean;
    };
    isActive: {
        group?: boolean;
        refresh?: boolean;
        share?: boolean;
        views?: boolean;
        filter?: boolean;
        tools?: boolean;
    };
    viewStyle: ViewDisplayStyle;
    isInMemory: boolean;
    isDownloading: boolean;
    isPaused: boolean;
    hasFilter: boolean;
    isInprotech: boolean;
}

// tslint:disable-next-line: no-anyW
export const MatterSummaryControlPanel: React.FunctionComponent<ControlPanelProps> = (
    props
) => {

    const classes = useStyles();

    const tenant = useTenant();

    const [value, setValue] = useState<ControlPanelStates>({
        search: {
            query: props.searchFilter !== undefined ? props.searchFilter : '',
            includeCompleted:
                props.initialIncludeCompleted !== undefined
                    ? props.initialIncludeCompleted
                    : false,
        },
        viewStyle: props.viewDisplayStyle
            ? props.viewDisplayStyle
            : ViewDisplayStyle.List,
        isActive: {
            group: false,
            refresh: false,
            share: false,
            views: false,
            filter: false,
            tools: false,
        },
        isInMemory: false,
        isDownloading: false,
        isPaused: false,
        hasFilter: props.hasFilter,
        isInprotech: false,
    });

    const [delayedSearch] = useDebouncedCallback((filter: string) => onSearch(filter), 500);

    // tslint:disable-next-line: no-any
    let downloadMatterLink: any;
    var viewCount = 0;
    // tslint:disable-next-line: no-any
    var viewMenuItems: any = [];

    useMemo(() => {
        setValue((prevValue) => {
            return {
                ...prevValue,
                hasFilter: props.hasFilter
            };
        });
    // tslint:disable-next-line: align
    }, [props.hasFilter]);
    
    // useEffect(() => {
    //     setValue((prevState) => {
    //         return {
    //             ...prevState,
    //             isExportLoading: props.isExportLoading
    //         }
    //     })
    // }, [props.isExportLoading]);

    // tslint:disable-next-line: no-console
    useEffect(() => {
        setValue({
            ...value,
            isActive: { views: props.isShowViewPanel },
            isInMemory: props.isInMemory,
            isDownloading: props.isInMemory ? false : value.isDownloading,
            isPaused: props.isInMemory ? false : value.isPaused,
        });
    // tslint:disable-next-line: align
    }, [
        props.isLoading,
        props.isInMemory,
        props.isShowViewPanel,
        downloadMatterLink,
        // viewCount,
        // viewMenuItems
    ]);

    useEffect(() => {
        if (props.isForceDownload && !props.isLoading) {
            handleMatterDownload();        
        }
    // tslint:disable-next-line: align
    }, [props.isForceDownload, props.isLoading]);

    const handleMatterDownload = () => {
        if (props.isLoading) {
            return;
        }

        if (value.isDownloading === false) {
            setValue({
                ...value,
                isPaused: false,
                isDownloading: true,
            });

            if (props.onMatterDownloadChanged) {
                props.onMatterDownloadChanged(true);
            }
        } else {
            setValue({
                ...value,
                isPaused: true,
                isDownloading: false,
            });

            if (props.onMatterDownloadChanged) {
                props.onMatterDownloadChanged(false);
            }
        }
    };

    const onSearch = (filter: string) => {
        setValue({
            ...value,
            search: {
                includeCompleted: value.search.includeCompleted,
                query: filter,
            },
        });

        if (props.onSearch) {
            props.onSearch(filter);
        }
    };

    const onIncludeCompleted = (
        event: FormEvent<HTMLInputElement>,
        state: CheckboxState
    ) => {
        setValue({
            ...value,
            search: {
                includeCompleted: state.checked,
                query: value.search.query,
            },
            isDownloading: false,
            isPaused: false,
        });

        if (props.onIncludeCompleted) {
            props.onIncludeCompleted(state.checked);
        }
    };

    const onRefreshData = () => {
        setValue({
            ...value,
            isDownloading: false,
            isPaused: false,
        });

        if (props.onRefreshData) {
            props.onRefreshData();
        }
    };

    // const onPrint = () => {
    //     if (props.onPrint) {
    //         props.onPrint(value.viewStyle);
    //     }
    // };

    const onExport = (type: ExportType) => {
        if (props.onExport) {
            props.onExport(type);
        }
    };

    const onToggleConfigs = () => {
        if (props.onToggleConfigs) {
            props.onToggleConfigs(!value.isActive.views);
        }
        setValue({ ...value, isActive: { views: !value.isActive.views } });
    };

    const onSaveView = () => {
        if (props.onSaveCurrentView) {
            props.onSaveCurrentView();
        }
    };

    const onViewSelected = (viewKey: string, configId: number) => {
        if (props.onViewSelected) {
            props.onViewSelected(configId);
        }
    };

    const onToggleToolPanel = () => {
        if (props.onToggleTools) {
            props.onToggleTools(!value.isActive.tools);
        }
        setValue({ ...value, isActive: { tools: !value.isActive.tools } });
    };

    const onToggleRowGrouping = () => {
        // If all the records are not downloaded, we do not toggle. But we don't want the control to look disabled.
        // So we are not setting the state.

        if (value.isInMemory) {
            if (props.onToggleGrouping) {
                props.onToggleGrouping(!value.isActive.group);
            }
            setValue({ ...value, isActive: { group: !value.isActive.group } });
        }
    };

    const onViewStyle = (style: ViewDisplayStyle) => {
        setValue({ ...value, viewStyle: style });
        if (props.onViewStyle) {
            props.onViewStyle(style);
        }
    };

    if (props.views) {
        viewCount = props.views.length;
    }

    if (props.views) {
        props.views.forEach((config: SummaryViewConfigObj) => {
            viewMenuItems.push({
                key: config.configId,
                name: config.name,
                onClick: () => {
                    onViewSelected(config.name, config.configId);
                },
            });
        });
    }
    
    viewMenuItems.push({
        key: 'saveLayout',
        name: 'Save Current Layout',
        onClick: () => {
            onSaveView();
        },
        disabled: viewCount === 0 ? true : props.isDisableSave,
    });

    if (
        value.isInMemory === false &&
        value.isDownloading === false &&
        value.isPaused === false
    ) {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        downloadMatterLink = <a onClick={handleMatterDownload} className={classes.downlaod} >Click Here</a>;
    } else if (
        value.isInMemory === false &&
        value.isDownloading &&
        value.isPaused === false
    ) {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        downloadMatterLink = (<a onClick={handleMatterDownload} className={classes.downlaod}>Pause Download</a>);
    } else if (
        value.isInMemory === false &&
        value.isDownloading === false &&
        value.isPaused === true
    ) {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        downloadMatterLink = (<a onClick={handleMatterDownload} className={classes.downlaod}>Resume Download</a>);
    } else if (value.isInMemory) {
        // Setting it back to be used again. When Include complete is clicked
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        downloadMatterLink = <a onClick={handleMatterDownload} className={classes.downlaod}>Click Here</a>;
    }

    if (!tenant.loading && !tenant.error && tenant.data) {
        value.isInprotech  = tenant.data!.tenant.sourceSystem.toLocaleLowerCase() === 'inprotech';
    }

    return (
        <div className={`${classes.root} ${props.className}`}>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} md={6}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={6}>
                            <div className={classes.searchContentWrapper}>
                                <div className={classes.searchWrapper}>
                                    <RvSearch
                                        hint={MatterTerminology.SearchMatters}
                                        search={
                                            value.search.query
                                                ? value.search.query
                                                : ''
                                        }
                                        searchAction={delayedSearch}
                                        // disabled={props.isLoading}
                                        showClear={true}
                                    />
                                </div>
                                <div className={classes.checkboxWrapper}>
                                    <RvCheckbox
                                        label={
                                            value.isInprotech ? MatterTerminology.IncludeDead : MatterTerminology.IncludeCompleted 
                                        }
                                        isChecked={
                                            value.search.includeCompleted
                                        }
                                        disabled={props.isLoading}
                                        onChange={onIncludeCompleted}
                                    />
                                </div>
                                <div className={classes.advancedFilter}>
                                    <div>
                                        <IconButton aria-label="Advanced Filter" className={classes.advancedFilterButton} onClick={props.onAdvancedFilter}>
                                            <Badge color="secondary" variant="dot" invisible={!value.hasFilter}>
                                                <FilterListIcon
                                                    fontSize="default"
                                                    style={{
                                                        color: '#70D389',
                                                    }}
                                                />
                                            </Badge>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <div className={classes.showingWrapper}>
                                {props.entityType 
                                    ? (
                                            <RvLabel
                                                label={`Showing ${props.loadedMatterCount} of 
                                                            ${props.loadedMatterCount} 
                                                            ${MatterTerminology.Matters}`}
                                            />
                                    )
                                    :  (
                                            <RvLabel
                                                label={`Showing ${props.loadedMatterCount} of 
                                                            ${props.totalMatterCount} 
                                                            ${MatterTerminology.Matters}`}
                                            />
                                        )
                                }
                               
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                    <div className={classes.menuWrapper}>
                        {UserFeature.HasAccess(UserFeatureEnum.hasMatterExport) ? (
                            <RvTooltipFabric hint="Export">
                                <RvButtonFabric
                                    rvKey="views"
                                    rvbuttonType={RvButtonType.split}
                                    split={true}
                                    primary={false}
                                    // onClick={onExport}
                                    disabled={props.isLoading || props.isExportLoading}
                                    rvIcon={'share'}
                                    rvClassName={`button-fabric custom-split-2 ${props.isLoading || props.isExportLoading ? classes.disabledShare : classes.enabledShare}`}
                                    rvMenuProps={{
                                        items: [
                                            {
                                                key: 'csv',
                                                name: 'CSV Export',
                                                onClick: () => {
                                                    // tslint:disable-next-line: no-console
                                                    onExport(ExportType.CSV);
                                                },
                                            },
                                            {
                                                key: 'excel',
                                                name: 'Excel Export',
                                                onClick: () => {
                                                    // tslint:disable-next-line: no-console
                                                    onExport(ExportType.Excel);
                                                },
                                            },
                                        ],
                                    }}
                                />
                            </RvTooltipFabric>
                        ) : null}
                        <RvTooltipFabric
                            hint={
                                value.isInMemory
                                    ? 'Row Grouping'
                                    : 'Row Grouping - Available when all matters have been loaded'
                            }
                        >
                            <RvButtonFabric
                                rvKey="group"
                                rvbuttonType={RvButtonType.icon}
                                rvClassName="button-fabric"
                                rvIcon={
                                    value.isActive.group
                                        ? 'row-groups-hover-svg'
                                        : 'row-groups-svg'
                                }
                                onClick={onToggleRowGrouping}
                                disabled={props.isLoading}
                            />
                        </RvTooltipFabric>
                        <RvTooltipFabric hint="Refresh">
                            <RvButtonFabric
                                rvKey="refresh"
                                rvbuttonType={RvButtonType.icon}
                                onClick={onRefreshData}
                                disabled={props.isLoading}
                                rvIcon="refresh-svg"
                                rvClassName="button-fabric"
                            />
                        </RvTooltipFabric>

                        {props.showViewLayouts ? (
                            <>
                                <RvTooltipFabric hint="List View">
                                    <RvButtonFabric
                                        rvKey="list"
                                        rvbuttonType={RvButtonType.icon}
                                        disabled={props.isLoading}
                                        checked={
                                            value.viewStyle ===
                                            ViewDisplayStyle.List
                                                ? true
                                                : false
                                        }
                                        // title="list"
                                        // tslint:disable-next-line:max-line-length
                                        rvIcon={
                                            value.viewStyle ===
                                            ViewDisplayStyle.List
                                                ? 'list-on-svg'
                                                : 'list-off-svg'
                                        }
                                        rvClassName="display-type list"
                                        onClick={() =>
                                            onViewStyle(ViewDisplayStyle.List)
                                        }
                                    />
                                </RvTooltipFabric>
                                <RvTooltipFabric hint="Grid View">
                                    <RvButtonFabric
                                        rvKey="grid"
                                        rvbuttonType={RvButtonType.icon}
                                        disabled={props.isLoading}
                                        checked={
                                            value.viewStyle ===
                                            ViewDisplayStyle.Card
                                                ? true
                                                : false
                                        }
                                        // title="grid"
                                        // tslint:disable-next-line:max-line-length
                                        rvIcon={
                                            value.viewStyle ===
                                            ViewDisplayStyle.Card
                                                ? 'grid-on-svg'
                                                : 'grid-off-svg'
                                        }
                                        rvClassName="display-type grid"
                                        onClick={() =>
                                            onViewStyle(ViewDisplayStyle.Card)
                                        }
                                    />
                                </RvTooltipFabric>
                            </>
                        ) : undefined}

                        <RvTooltipFabric hint="Columns">
                            <RvButtonFabric
                                rvKey="filter"
                                rvbuttonType={RvButtonType.icon}
                                disabled={
                                    props.isLoading ||
                                    value.viewStyle !== ViewDisplayStyle.List
                                }
                                onClick={onToggleToolPanel}
                                rvClassName="button-fabric"
                                // tslint:disable-next-line:max-line-length
                                rvIcon={
                                    value.isActive.tools
                                        ? 'columns-hover-svg'
                                        : 'columns-svg'
                                }
                            />
                        </RvTooltipFabric>
                        {UserFeature.HasAccess(UserFeatureEnum.hasMatterSummaryViewManagement) 
                            ?
                                <RvTooltipFabric hint="Saved Views">
                                    <RvButtonFabric
                                        rvKey="views"
                                        rvbuttonType={RvButtonType.split}
                                        split={true}
                                        primary={false}
                                        onClick={onToggleConfigs}
                                        disabled={props.isLoading}
                                        rvIcon={
                                            value.isActive.views
                                                ? 'views-hover-svg'
                                                : 'views-svg'
                                        }
                                        rvClassName="button-fabric custom-split-2"
                                        rvMenuProps={{
                                            items: viewMenuItems,
                                        }}
                                    />
                                </RvTooltipFabric>
                            :
                                <RvTooltipFabric hint="Saved Views">
                                    <CommandButton
                                        key="views"
                                        primary={false}
                                        disabled={props.isLoading}
                                        className={`${classes.customButton} button-fabric custom-split-2`}
                                        menuProps={{
                                            items: viewMenuItems,
                                        }}
                                    />
                                </RvTooltipFabric>
                        }
                    </div>
                </Grid>
                {props.isLoading === false && props.loadedMatterCount > 0 ? (
                    value.isInMemory === false &&
                    (value.search.query === '' ||
                        value.search.query === undefined) ? (
                        <Grid item={true} xs={12} md={12}>
                            <p className={classes.notes}>
                                The summary returned a large number of matters.
                                Narrow the filter or {downloadMatterLink} to
                                download remaining matter. Note that grouping,
                                sorting and filtering won’t be activated until
                                all matter are downloaded
                            </p>
                        </Grid>
                    ) : null
                ) : null}
            </Grid>
        </div>
    );
};
