import React, { useState } from 'react';
import {
    makeStyles,
    Theme,
    createStyles,
    Grid,
    TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { mainTheme } from '../../Theme';
import { Field, Form, FormSpy } from 'react-final-form';
import { KeyboardDateTimePicker } from '@material-ui/pickers/DateTimePicker/DateTimePicker';
import { DropzoneArea } from 'material-ui-dropzone';
import { MatterAutoCompleteSelector } from '../../components/MatterAutoCompleteSelector';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { showNotification } from '../../App';
import { client } from '../..';
import { UploadMatterDocumentMutation } from '../MatterSummaryRepository';
import { DocumentFile } from '../../types/DocumentFile';
import { FormApi } from 'final-form';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { RoleSelector } from '../../lookupConfiguration/selectors/RoleSelector';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { RvLoader } from '../../components/Loader';
import { Role, useRoleListQuery } from '../../lookupConfiguration/hooks/useRoleListQuery';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        textField: {
            width: '100%',
        },
        commendField: {
            width: '100%',
        },
        dialogTitle: {
            color: mainTheme.TemplateColor.Primary,
        },
        dropzone: {
            paddingTop: 10,
            paddingBottom: 10,
        },
        matterLabel: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
        },
        loaderWrapper: {
            minWidth: '560px',
            minHeight: '394.625px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    })
);

interface UploadMatterDocumentState {
    date: ParsableDate;
    files: DocumentFile[];
    notes: string | null;
    matter: IAutoCompleteItem | null;
    roles: IAutoCompleteItem[];
    isChanged: boolean;
    confirmSubmit: boolean;
    isDisableSave: boolean;
}

export interface UploadDocumentDialogProps {
    onClose: () => void;
    matter?: IAutoCompleteItem;
    roles?: IAutoCompleteItem[];
}

// tslint:disable-next-line: no-anyW
export const UploadDocumentDialog: React.FC<UploadDocumentDialogProps> = ( props ) => {
    const classes = useStyles();

    const currentUser = useCurrentUser();

    const [filesKey] = useState<string>(Math.random().toString());

    const roleListQuery = useRoleListQuery({ isExternal: true }, 'cache-first');

    const defaultRole: IAutoCompleteItem[] = roleListQuery.data ? roleListQuery.data?.roleList.role
                    .map((source: Role) => ({value: source.roleIdString, label: source.rolename }))
                    .filter((item) => item.label === 'Client') as IAutoCompleteItem[] : [];

    const initialValue: UploadMatterDocumentState = {
        date: new Date(),
        files: [],
        notes: null,
        matter: props.matter ? props.matter : null,
        roles: defaultRole,
        isChanged: false,
        confirmSubmit: false,
        isDisableSave: false
    };
    
    const onSubmit = (form: FormApi<UploadMatterDocumentState>, values: UploadMatterDocumentState) => {
        // tslint:disable-next-line: no-console
        console.log('OnSubmit'); 
        form.change('confirmSubmit', true);

        return new Promise<void>(resolve => {
            setTimeout(() => {
                resolve();
            // tslint:disable-next-line: align
            }, 1000);
        });
    };

    const onUpload = (form: FormApi<UploadMatterDocumentState>, values: UploadMatterDocumentState) => {
        // Save the File
        client
            .mutate({
                mutation: UploadMatterDocumentMutation,
                variables: { 
                    input: getUploadMatterDocumentInput(values)
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        form.change('isDisableSave', false);
                        if (props.onClose) {
                            props.onClose();
                        } 
                    } else {
                        showNotification('Failed to Upload Documents', results.data.error, 'error');
                    }
                }

            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                form.change('isDisableSave', false);
                showNotification('Failed to Upload Documents', reason, 'error');
            });

        // form.change('isDisableSave', false);
        // onClose(form);
    };

    const getUploadMatterDocumentInput = (values: UploadMatterDocumentState) => {
        return {
            matterGuid: values.matter?.value,
            files: GetFiles(values.files),
            notes: currentUser.data!.appUser.isInternal ? null : values.notes,
            roleGuids: currentUser.data!.appUser.isInternal ? values.roles.map((role: IAutoCompleteItem) => role.value).join(',') : null
        };
    };

    const GetFiles = (files: DocumentFile[]) => {
        return files.map((document: DocumentFile) => {
            return {
                file: document.file,
                name: document.name,
                guidID: document.guidID,
                status: document.status
            };
        });
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (form: FormApi<UploadMatterDocumentState>, changeProps: any) => {
        if (!changeProps.pristine) {
            form.change('isChanged', true);
        }
    };

    // tslint:disable-next-line: no-any
    const onSubmitConfirmationChanges = (agree: boolean, form: FormApi<UploadMatterDocumentState>, values: any) => {
        if (agree) {
            form.batch(() => {
                form.change('confirmSubmit', false); 
                form.change('isDisableSave', true);
            });
            onUpload(form, values); 
        } else {
            form.change('confirmSubmit', false);
        }
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    const initializeForm = () => {

        if (currentUser.loading || roleListQuery.loading) {
            return (
                <DialogContent dividers={true} className={classes.loaderWrapper}>
                    <div>
                        <RvLoader />
                    </div>
                </DialogContent>
            );
        } 

        return (
            <Form
                onSubmit={(values, form: FormApi<UploadMatterDocumentState>) => onSubmit(form, values)}
                initialValues={initialValue}
                initialValuesEqual={() => true}
                keepDirtyOnReinitialize={true}
                subscription={{submitting: true, pristine: true}}
                render={({handleSubmit, form, submitting, pristine, values}) => (
                    <form onSubmit={event => handleSubmit(event)} id="UploadDocumentDialog">
                        <FormSpy 
                            subscription={{ pristine: true, values: true }}
                            // tslint:disable-next-line: no-shadowed-variable
                            onChange={props => {
                                onFormValueChanged(form, props);
                            }}
                        />
                        <FormSpy subscription={{ values: true }}>
                            {/* tslint:disable-next-line: no-shadowed-variable */}
                            {({ values }) => (
                                <DialogBox
                                    title="Upload Document Confirmation"
                                    // tslint:disable-next-line:max-line-length
                                    content={`Are you sure you want to upload the selected ${values.files.count > 1 ? 'documents' : 'document'} ?`}
                                    show={values.confirmSubmit}
                                    isAgree={(agree: boolean) => onSubmitConfirmationChanges(agree, form, values)}
                                    disAgreeLabel={'No'}
                                    agreeLabel={'Yes'}
                                />
                            )}
                        </FormSpy>
                            <DialogContent dividers={true}>
                                <Grid container={true} spacing={1}>
                                    <Grid item={true} xs={12}>
                                        <Grid container={true} spacing={1}>
                                            <Grid item={true} xs={6}>
                                                {props.matter ? (
                                                    <p className={classes.matterLabel}>{props.matter.label}</p>
                                                ) : (
                                                    <Field
                                                        name="matter"
                                                    >
                                                        {({ input, meta }) => (
                                                            <MatterAutoCompleteSelector
                                                                label="Matter"
                                                                name="matter"
                                                                required={false}
                                                                onSelection={(selection: IAutoCompleteItem, name: string) =>
                                                                    input.onChange(selection)
                                                                }
                                                                value={input.value ? input.value : null}
                                                            />
                                                        )}
                                                    </Field>
                                                )}
                                            </Grid>
                                            <Grid item={true} xs={6}>
                                                <Field
                                                    name="date"
                                                    // validate={form.getFieldState('dueDate')?.value !== null ? required : undefined}
                                                >
                                                    {({ input, meta }) => (
                                                        <KeyboardDateTimePicker
                                                            {...input}
                                                            className={classes.textField}
                                                            label="Date"
                                                            format="DD/MM/YYYY"
                                                            placeholder="dd/mm/yyyy"
                                                            animateYearScrolling={true}
                                                            // tslint:disable-next-line: max-line-length
                                                            autoOk={true}
                                                            allowKeyboardControl={true}
                                                            variant={'inline'}
                                                            error={meta.error && meta.touched}
                                                            inputProps={{autoComplete: 'off'}}
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={
                                                                meta.error &&
                                                                meta.touched
                                                                    ? 'Date is required'
                                                                    : ''
                                                            }
                                                            // set new date to current with default hours to 5pm
                                                            initialFocusedDate={new Date().setHours(17, 0, 0, 0)}
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {currentUser.data!.appUser.isInternal && (
                                        <Grid item={true} xs={12}>
                                            <Field
                                                name="roles"
                                                validate={required}
                                            >
                                                {({ input, meta }) => (
                                                    <RoleSelector 
                                                        {...input}
                                                        label="Roles"
                                                        multiple={true}
                                                        className={classes.textField}
                                                        onSelection={(selection: IAutoCompleteItem[]) => {
                                                            input.onChange(selection);
                                                        }}
                                                        error={meta.error}
                                                        helperText={meta.error}
                                                        required={true}
                                                        isExternal={true}
                                                    />
                                                )}
                                            </Field>
                                        </Grid> 
                                    )}
                                    <Grid item={true} xs={12}>
                                        <Field
                                            name="files"
                                            // subscription={{touched: true, error: true, value: true}}
                                        >
                                            {({ input, meta }) => (
                                                <div className={classes.dropzone}>
                                                    <DropzoneArea
                                                        {...input}
                                                        key={filesKey}
                                                        maxFileSize={15000000}
                                                        // acceptedFiles={['.msg', '.jpg', '.jpeg', '.png', '.docx', '.zip']}
                                                        showFileNames={true}
                                                        useChipsForPreview={true}
                                                        filesLimit={10}
                                                        onChange={(files: File[]) => {
                                                            let existingFiles: DocumentFile[] = [...input.value];
                                                            const newFiles = files.filter((file: File) => {
                                                                return !existingFiles.some((item: DocumentFile) => {
                                                                    return item.name === file.name && item.status !== 2;
                                                                });
                                                            });
                                                            if (newFiles && newFiles.length > 0) {
                                                                newFiles.forEach((file: File) => {
                                                                    const documentFile: DocumentFile = {
                                                                        guidID: null,
                                                                        file: file,
                                                                        status: 1,
                                                                        name: file.name,
                                                                        url: ''
                                                                    };
                                                                    existingFiles.push(documentFile);
                                                                }); 
                                                                input.onChange(existingFiles);
                                                            }
                                                        }}
                                                        onDelete={(file: File) => {
                                                            let existingFiles: DocumentFile[] = [...input.value];
                                                            // eslint-disable-next-line array-callback-return
                                                            existingFiles.map((item: DocumentFile) => {
                                                                if (file.name === item.name) {
                                                                    item.file = file;
                                                                    item.status = 2; // deleted
                                                                }
                                                            }); 
                                                            // tslint:disable-next-line: max-line-length
                                                            const filteredFiles = existingFiles.filter((item: DocumentFile) => {
                                                                return item.status !== 2 || item.guidID !== null;
                                                            });
                                                            input.onChange(filteredFiles);
                                                        }} 
                                                        // tslint:disable-next-line:no-any
                                                        onDrop={(files: File[], event: any) => {
                                                            // let existingFiles: DocumentFile[] = [...input.value];
                                                            // tslint:disable-next-line: max-line-length
                                                            // const existingFile = existingFiles.find((item: DocumentFile) => item.name === file)
                                                            // tslint:disable-next-line:no-console
                                                            console.log(event);
                                                        }} 
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </Grid>
                                    {!currentUser.data!.appUser.isInternal && (
                                        <Grid item={true} xs={12}>
                                            <FormSpy subscription={{ values: true }}>
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values }) => (
                                                    <Field
                                                        name="notes"
                                                    >
                                                        {({ input, meta }) => (
                                                            <TextField
                                                                {...input}
                                                                variant="outlined"
                                                                label="Notes"
                                                                multiline={true}
                                                                className={classes.textField}
                                                                rows={3}
                                                                // disabled={values.date && values.matter && values.files.length > 0 ? false : true}
                                                            />
                                                        )}
                                                    </Field>
                                                )}
                                            </FormSpy>
                                        </Grid>
                                    )}
                                </Grid>
                                {/* {printJson(values)} */}
                            </DialogContent>
                            <DialogActions>
                                <FormSpy subscription={{ values: true, pristine: true, submitting: true }}>
                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                    {({ values, pristine }) => (
                                        <>
                                            <Button 
                                                // tslint:disable-next-line: max-line-length
                                                disabled={submitting || pristine || !(values.date && values.matter && values.files.length > 0) || values.isDisableSave} 
                                                color="primary"
                                                type="submit"
                                                onClick={() => form.submit()} 
                                            >
                                                Ok
                                            </Button>
                                            {/* <Button 
                                                onClick={() => {
                                                    setKey(Math.random().toString());
                                                    form.reset();
                                                }} 
                                                disabled={submitting || pristine} 
                                                color="primary"
                                            >
                                                Reset
                                            </Button> */}
                                            <Button 
                                                onClick={props.onClose} 
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                    )}
                                </FormSpy>
                            </DialogActions>
                        {/* {printJson(values)} */}
                    </form>
                )}
            />
        );
    };

    return (
        <React.Fragment>
            <Dialog
                open={true}
                onClose={props.onClose}
                className={classes.root}
                scroll={'paper'}
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.dialogTitle}
                >
                    Upload Document
                </DialogTitle>
                {initializeForm()}
            </Dialog>

        </React.Fragment>
    );
};

// tslint:disable-next-line: no-any
// function printJson(values: any) {
// 	if (values) {
// 		return (
// 			<>
// 				<pre>
// 					{JSON.stringify(values, undefined, 2)}
// 				</pre>
// 			</>
// 		);
// 	} else {
// 		return (
// 			<>
// 				<FormSpy subscription={{ values: true }}>
// 					{/* tslint:disable-next-line: no-shadowed-variable */}
// 					{({ values }) => (
// 						<pre>
// 							{JSON.stringify(values, undefined, 2)}
// 							{/* <RenderCount /> */}
// 						</pre>
// 					)}
// 				</FormSpy>
// 			</>
// 		);
// 	}
// }
