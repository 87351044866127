/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { ICellRendererParams } from '@ag-grid-community/core';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import {
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    IconButton,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
// import { styles } from '../../home/styles';
import { client } from '../..';
import { AddUserMatterAccessActivity } from '../MatterSummaryPageX';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';
import { WriteOffWipDialog } from '../dialogs/WriteOffWipDialog';
import { WriteOffDebtorsDialog } from '../dialogs/WriteOffDebtorsDialog';
import { BillTheMatterDialog } from '../dialogs/BillTheMatterDialog';
import { TransferTustToPayInvoiceDialog } from '../dialogs/TransferTustToPayInvoiceDialog';
import { ArchiveMatterDialog } from '../dialogs/ArchiveMatterDialog';
import { CloseMatterDialog } from '../dialogs/CloseMatterDialog';
import { fetchMatterDetailsData } from '../MatterSummaryRepository';
import DoneIcon from '@material-ui/icons/Done';
import { MatterData, MatterDetails } from '../models/Matter';
import { CurrencyOptionData, fetchAppUserData, fetchCurrencyData } from '../../matterManagement/MatterManagementRepository';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { TasksSummaryFormDialog } from '../../tasksSummary/dialog/TasksSummaryFormDialog';
import { AppUserData } from '../../enquirySummary/EnquirySummaryRepository';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { PriorityData, retrievePriorityData } from '../../tasksSummary/TasksSummaryRepository';
import { UploadDocumentDialog } from '../dialogs/UploadDocumentDialog';
import { MatterFollowUpDialog } from '../dialogs/MatterFollowUpDialog';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import { mainTheme } from '../../Theme';
import CancelPresentationOutlinedIcon from '@material-ui/icons/CancelPresentationOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import PlaylistAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import TollOutlinedIcon from '@material-ui/icons/TollOutlined';
import LocationSearchingOutlinedIcon from '@material-ui/icons/LocationSearchingOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import CastOutlinedIcon from '@material-ui/icons/CastOutlined';
import { useMatterSummaryContext } from '../MatterSummaryContextProvider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        },
        doneIcon: {
            marginRight: '10px',
        },
        loaderWrapper: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        icon: {
            marginRight: '10px',
            color: mainTheme.BrandColors.TemplatePrimary
        },
    })
);

export enum MatterTaskActionEnum {
    BillMatter = 1,
    WriteOffUnbilledFees,
    TransferTrustToPayInvoice,
    WriteOffDebtors,
    CloseMatter,
    ArchiveMatter,
    ViewMatter,
    CreateTask,
    EmailPersonActing,
    EmailPersonResponsible,
    UploadDocument,
    MatterFollowUpAction,
    MatterForecasts
}

export interface CurrencyDetails {
    currencySymbol: string;
    currency: string;
}

interface MatterTaskActionProps /* extends ICellRendererParams,*/ /*WithStyles<typeof styles>*/ {
    // tslint:disable-next-line: no-any
    data?: any;
    matterId: number;
}

type MatterTaskDialogState = {
    isBillMatterOpen: boolean;
    isWriteOffUnbilledFeesOpen: boolean;
    isTransferTrustToPayInvoiceOpen: boolean;
    isWriteOffDebtorsOpen: boolean;
    isCloseMatterOpen: boolean;
    isArchiveMatterOpen: boolean;
    isCreateTaskOpen: boolean;
    isUploadDocumentOpen: boolean;
    isMatterFollowUpOpen: boolean;
};

interface MatterTaskActionState extends MatterTaskDialogState {
    loggedInUser: IAutoCompleteItem | null;
    defaultPriority: IAutoCompleteItem | null;
}

// tslint:disable-next-line: no-any
export const MatterTaskAction: React.FC<MatterTaskActionProps> = ( props ) => {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const { onMaterForcastFormOpen } = useMatterSummaryContext();

    const [currencyData, setCurrencyData] = useState<CurrencyDetails>();
    const [matterData, setMatterData] = useState<MatterDetails>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [state, setState] = useState<MatterTaskActionState>({
        isBillMatterOpen: false,
        isWriteOffUnbilledFeesOpen: false,
        isTransferTrustToPayInvoiceOpen: false,
        isWriteOffDebtorsOpen: false,
        isCloseMatterOpen: false,
        isArchiveMatterOpen: false,
        isCreateTaskOpen: false,
        isUploadDocumentOpen: false,
        isMatterFollowUpOpen: false,
        loggedInUser: null,
        defaultPriority: null,
    });

    const matterId: number = props.matterId 
        ? props.matterId 
        : (props.data && props.data.id) ? props.data.id : 0;

    useEffect(() => {
        fetchCurrencyDetails();
        fetchUser();
        fetchPriority();
    // tslint:disable-next-line: align
    }, []);

    const fetchCurrencyDetails = () => {
        if (currencyData === undefined) {
            fetchCurrencyData(false,
                              (data: CurrencyOptionData) => {
                                handleCurrencyFetch(data);
                               }, 
                              (reason) => {
                                    showNotification('Failed to Fetch App User Settings', reason, 'error'); 
                               });
        }
    };

    const handleCurrencyFetch = (data: CurrencyOptionData) => {
        if (data && !data.loading && data.options) {
            if (data.options.currencySymbol && data.options.localCurrency) {
                setCurrencyData({
                    currency: data.options.localCurrency.currency,
                    currencySymbol: data.options.currencySymbol.symbol
                });
            }
        }
    };

    const fetchMatterDetails = () => {
        fetchMatterDetailsData(true, // This should always be a new request.
                               matterId,
                               (data: MatterData) => {
                                    handleMatterDataFetch(data);
                               }, 
                               (reason) => {
                                    showNotification('Failed to Fetch App User Settings', reason, 'error'); 
                               });
        
    };

    const handleMatterDataFetch = (data: MatterData) => {
        if (data && !data.loading && data.matter) {
            if (data.matter) {
                setMatterData(data.matter);

                setIsLoading(false);
            }
        }
    };

    const fetchUser = () => {
        fetchAppUserData(
            false,
            (data: AppUserData) => handleUserDataFetch(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const handleUserDataFetch = (data: AppUserData) => {
        setState((prevState) => {
            return {
                ...prevState,
                loggedInUser: { label: data.appUser.name, value: data.appUser.userId },
                isLoading: false
            };
        });
    };

    const fetchPriority = () => {
        retrievePriorityData(
            false,
            // tslint:disable-next-line: no-console
            (data) => handlePriorityDataFetch(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const handlePriorityDataFetch = (data: PriorityData) => {
        // set the priority default data
        const priorityData = data.lookup.priority.filter(value => {
            return value.description === 'No Priority';
        });

        if (priorityData && priorityData.length > 0) {
            setState((prevState) => {
                return {
                    ...prevState,
                    defaultPriority: { label: priorityData[0].description, value: priorityData[0].guidId },
                    isLoading: false
                };
            });
        }        
    };

    const handleToggle = () => {
        // tslint:disable-next-line: no-shadowed-variable
        setOpen((prevOpen) => !prevOpen);
        setIsLoading(true);
        fetchMatterDetails();
    };

    const archorEvent = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
    };

    const handleClickMenu = (menu: MatterTaskActionEnum) => (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        switch (menu) {
            case MatterTaskActionEnum.BillMatter : {
                setOpen(false);
                onBillTheMatterOpen(event);
                break;
            }
            case MatterTaskActionEnum.WriteOffUnbilledFees : {
                setOpen(false);
                onWriteOffUnbilledFeesOpen(event);
                break;
            }
            case MatterTaskActionEnum.TransferTrustToPayInvoice : {
                setOpen(false);
                onTransferTrustToPayInvoiceOpen(event);
                break;
            }
            case MatterTaskActionEnum.WriteOffDebtors: {
                setOpen(false);
                onWriteOffDebtorsOpen(event);
                break;
            }
            case MatterTaskActionEnum.CloseMatter: {
                setOpen(false);
                onCloseMatterOpen(event);
                break;
            }
            case MatterTaskActionEnum.ArchiveMatter: {
                setOpen(false);
                onArchiveMatterOpen(event);
                break;
            }
            case MatterTaskActionEnum.CreateTask: {
                setOpen(false);
                onCreateMatterTaskOpen(event);
                break;
            }
            case MatterTaskActionEnum.UploadDocument: {
                setOpen(false);
                onUploadDocumentOpen(event);
                break;
            }
            case MatterTaskActionEnum.MatterFollowUpAction: {
                setOpen(false);
                onMatterFollowUpOpen(event);
                break;
            }
            case MatterTaskActionEnum.MatterForecasts: {
                setOpen(false);
                onMatterForecastsOpen(matterId, matterData!.matterGuid, event);
                break;
            }
            default: {
                setOpen(false);
                break;
            }
        }
    };

    const onBillTheMatterOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isBillMatterOpen: true
        });
    };

    const onWriteOffUnbilledFeesOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isWriteOffUnbilledFeesOpen: true
        });
    };

    const onTransferTrustToPayInvoiceOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isTransferTrustToPayInvoiceOpen: true
        });
    };

    const onWriteOffDebtorsOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isWriteOffDebtorsOpen: true
        });
    };
    
    const onCloseMatterOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isCloseMatterOpen: true
        });
    };    

    const onArchiveMatterOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isArchiveMatterOpen: true
        });
    };    
    
    const onCreateMatterTaskOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isCreateTaskOpen: true
        });
    }; 

    const onUploadDocumentOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isUploadDocumentOpen: true
        });
    };
    
    const onMatterFollowUpOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState((prevState) => {
            return {
                ...prevState,
                isMatterFollowUpOpen: true
            };
        });
    };
    // tslint:disable-next-line: no-shadowed-variable
    const onMatterForecastsOpen = (matterId: number, matterGuid: string, event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        onMaterForcastFormOpen(matterId, matterGuid);

        // setState((prevState) => {
        //     return {
        //         ...prevState,
        //         isMatterForecastsOpen: true
        //     };
        // });
    };

    // const onEmailPersonActing = (event: React.MouseEvent<EventTarget>) => {
    //     archorEvent(event);
    //     setOpen(false);

    //     var mail = document.createElement('a');
    //     // tslint:disable-next-line: max-line-length
    //     mail.href = `mailto:${matterData?.actingEmail}?subject=RE ${matterData?.client} - ${matterData?.description} (${matterData?.fileNumber})`;
    //     mail.click();
    // };

    // const onEmailPersonResponsible = (event: React.MouseEvent<EventTarget>) => {
    //     archorEvent(event);
    //     setOpen(false);

    //     var mail = document.createElement('a');
        // tslint:disable-next-line: max-line-length
    //     mail.href = `mailto:${matterData?.responsibleEmail}?subject=RE ${matterData?.client} - ${matterData?.description} (${matterData?.fileNumber})`;
    //     mail.click();
    // };

    const onDialogClose = () => {
        setOpen(false);

        setState({
            ...state,
            isBillMatterOpen: false,
            isWriteOffUnbilledFeesOpen: false,
            isTransferTrustToPayInvoiceOpen: false,
            isWriteOffDebtorsOpen: false,
            isCloseMatterOpen: false,
            isArchiveMatterOpen: false,
            isCreateTaskOpen: false,
            isUploadDocumentOpen: false,
            isMatterFollowUpOpen: false
        });
    };

    const onMatterDetailOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        client.mutate({
            mutation: AddUserMatterAccessActivity,
            variables: {
                matterId: matterId
            }
        }).then((results: {
            data: {
                addUserMatterAccessActivity: boolean
            }
        }) => {
            if (results.data.addUserMatterAccessActivity === false) {
                // tslint:disable-next-line:no-console
                console.log('AddUserMatterAccessActivity mutation failed');
            } 
        });      

        sessionStorage.setItem('typeOfView', 'noback');

        // TODO: We need state of Matter Summary to be passed
        var href = history.createHref({
            pathname: '/matterDetail/' + matterId,
            key: 'matterManagement',
            state: {
                matterManagementState: 'false',
            }
        });

        window.open(href, '_blank');
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
        // tslint:disable-next-line: align
    }, [open]);

    // tslint:disable-next-line: no-shadowed-variable
    const getMatterTaskActions = (matterId: number) => {
        if (matterData && currencyData) {
            const unBilledFees = matterData.financials ? matterData.financials.unbilledTimeAndFees : 0;
            const unbilledDisbursements =  matterData.financials ? matterData.financials.unbilledDisbursements : 0;
            const outstandingDebtors =  matterData.financials ? matterData.financials.debtors : 0;
            const currentTrustBalance = matterData.financials ? matterData.financials.trustBalance : 0;
            const billedFees = matterData.financials ? matterData.financials.billedTimeAndFees : 0;
            const feeEstimate = matterData.financials ? matterData.financials.feeEstimate : 0;
            const percentageEstimate = matterData.financials ? matterData.financials.percentageEstimate : 0;
            const pendingWriteOffFeesAmountInBillMatterTask = matterData.financials 
                                                                ? matterData.actions.pendingWriteOffFeesAmountInBillMatterTask 
                                                                : 0;
            const pendingWriteOffFeesAmountInWriteOffWipTask = matterData.financials 
                                                                ? matterData.actions.pendingWriteOffFeesAmountInWriteOffWipTask 
                                                                : 0;
            const pendingWriteOffDisbAmountInBillMatterTask = matterData.financials 
                                                                ? matterData.actions.pendingWriteOffDisbAmountInBillMatterTask 
                                                                : 0;
            const pendingWriteOffDisbAmountInWriteOffWipTask = matterData.financials 
                                                                ? matterData.actions.pendingWriteOffDisbAmountInWriteOffWipTask  
                                                                : 0;

            const matterTitle = `${matterData.description}`;

            return (
                <React.Fragment>
                    {state.isBillMatterOpen && (
                        <BillTheMatterDialog 
                            currencyDetails={currencyData}
                            matterId={matterId}
                            matterGuid={matterData.matterGuid}
                            matterTitle={matterTitle}
                            client={matterData.client}
                            onDialogClose={onDialogClose}
                            unBilledFees={unBilledFees}
                            unbilledDisbursements={unbilledDisbursements}
                            outstandingDebtors={outstandingDebtors}
                            currentTrustBalance={currentTrustBalance}
                            billedFees={billedFees}
                            feeEstimate={feeEstimate}
                            percentageEstimate={percentageEstimate}
                            pendingWriteOffFeesAmount={pendingWriteOffFeesAmountInWriteOffWipTask}
                            pendingWriteOffDisbAmount={pendingWriteOffDisbAmountInWriteOffWipTask}
                            loggedInUser={state.loggedInUser}
                            defaultPriority={state.defaultPriority}
                            formAction={'create'}
                        />
                    )}
                    
                    {state.isWriteOffUnbilledFeesOpen && (
                        <WriteOffWipDialog 
                            currencyDetails={currencyData}
                            matterId={matterId}
                            matterGuid={matterData.matterGuid}
                            matterTitle={matterTitle}
                            client={matterData.client}
                            onDialogClose={onDialogClose}
                            unBilledFees={unBilledFees}
                            unbilledDisbursements={unbilledDisbursements}
                            outstandingDebtors={outstandingDebtors}
                            billedFees={billedFees}
                            canClose={matterData.actions.canCloseMatter}
                            pendingWriteOffFeesAmount={pendingWriteOffFeesAmountInBillMatterTask}
                            pendingWriteOffDisbAmount={pendingWriteOffDisbAmountInBillMatterTask}
                            loggedInUser={state.loggedInUser}
                            defaultPriority={state.defaultPriority}
                            formAction={'create'}
                        />
                    )}
                    
                    {state.isWriteOffDebtorsOpen && (
                        <WriteOffDebtorsDialog 
                            currencyDetails={currencyData}
                            matterId={matterId}
                            matterGuid={matterData.matterGuid}
                            matterTitle={matterTitle}
                            client={matterData.client}
                            onDialogClose={onDialogClose}
                            unBilledFees={unBilledFees}
                            unbilledDisbursements={unbilledDisbursements}
                            outstandingDebtors={outstandingDebtors}
                            billedFees={billedFees}
                            canClose={matterData.actions.canCloseMatter}
                            loggedInUser={state.loggedInUser}
                            defaultPriority={state.defaultPriority}
                            formAction={'create'}
                        />
                    )}
                    
                    {state.isTransferTrustToPayInvoiceOpen && (
                        <TransferTustToPayInvoiceDialog 
                            currencyDetails={currencyData}                 
                            matterId={matterId}
                            matterGuid={matterData.matterGuid}
                            matterTitle={matterTitle}
                            client={matterData.client}
                            onDialogClose={onDialogClose}
                            outstandingDebtors={outstandingDebtors}
                            currentTrustBalance={currentTrustBalance}
                            loggedInUser={state.loggedInUser}
                            defaultPriority={state.defaultPriority}
                            formAction={'create'}
                        />
                    )}
                    
                    {state.isArchiveMatterOpen && (
                        <ArchiveMatterDialog 
                            currencyDetails={currencyData}            
                            matterId={matterId}
                            matterGuid={matterData.matterGuid}
                            matterTitle={matterTitle}
                            client={matterData.client}
                            onDialogClose={onDialogClose}
                            billedFees={billedFees}
                            closedDate={matterData.completionDate}
                            loggedInUser={state.loggedInUser}
                            defaultPriority={state.defaultPriority}
                            formAction={'create'}
                        />
                    )}
                    
                    {state.isCloseMatterOpen && (
                        <CloseMatterDialog 
                            currencyDetails={currencyData}
                            matterId={matterId}
                            matterGuid={matterData.matterGuid}
                            matterTitle={matterTitle}
                            client={matterData.client}
                            onDialogClose={onDialogClose}
                            billedFees={billedFees}
                            loggedInUser={state.loggedInUser}
                            defaultPriority={state.defaultPriority}
                            formAction={'create'}
                        />
                    )}   

                    {state.isCreateTaskOpen && (
                        <TasksSummaryFormDialog  
                            isOpen={state.isCreateTaskOpen}
                            onClose={onDialogClose}
                            guid={props.data ? props.data.taskGuidID : null}
                            isCreate={true}
                            matterFileNumber={matterData.fileNumber}
                            matterGuid={matterData.matterGuid}
                            matterTitle={matterData.title}
                            defaultPriority={state.defaultPriority}
                        />
                    )} 
                    {state.isUploadDocumentOpen && (
                        <UploadDocumentDialog 
                            onClose={onDialogClose}
                            matter={{
                                label: `${matterData.fileNumber} (${matterData.title})`,
                                value: matterData.matterGuid
                            }}
                        />
                    )}  
                    {state.isMatterFollowUpOpen && (
                        <MatterFollowUpDialog 
                            onClose={onDialogClose}
                            matterData={matterData}
                        />
                    )} 
                </React.Fragment>
            );
        } else {
            return null;
        }
    };

    const getLoader = () => {
        return (
            <div className={classes.loaderWrapper}>
                <RvLoader />
            </div>
        );
    };

    const getMenuItems = () => {
        if (matterData) {
            return (
                <React.Fragment>  
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition={true}
                        disablePortal={false}
                        style={{
                            marginLeft: '100px'                                                       
                        }}   
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom'
                                            ? 'center top'
                                            : 'center bottom',
                                }}
                            >  
                                <React.Fragment>
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={open}
                                                id="menu-list-grow"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                {
                                                    UserFeature.HasAccess(UserFeatureEnum.hasBillMatter) && 
                                                    <MenuItem
                                                        onClick={handleClickMenu(MatterTaskActionEnum.BillMatter)}
                                                        disabled={!matterData.actions.canBillMatter}
                                                    >
                                                        {matterData.actions.hasBillMatterTask && (
                                                            <DoneIcon
                                                                fontSize="small"
                                                                className={classes.doneIcon}
                                                            />
                                                        )}
                                                        <AssignmentTurnedInOutlinedIcon fontSize="small" className={classes.icon} /> Bill the matter
                                                    </MenuItem>
                                                }

                                                {
                                                    UserFeature.HasAccess(UserFeatureEnum.hasWriteOffWIP) &&
                                                    <MenuItem
                                                        onClick={handleClickMenu(MatterTaskActionEnum.WriteOffUnbilledFees)}
                                                        disabled={!matterData.actions.canWriteOffUnbilledFees}
                                                    >
                                                        {matterData.actions.hasWriteOffUnbilledFeesTask && (
                                                            <DoneIcon
                                                                fontSize="small"
                                                                className={classes.doneIcon}
                                                            />
                                                        )}
                                                        <AssignmentOutlinedIcon fontSize="small" className={classes.icon} /> Write off Wip
                                                    </MenuItem>
                                                }
                                                
                                                {
                                                    UserFeature.HasAccess(UserFeatureEnum.hasTransferMatterTrust) &&
                                                    <MenuItem
                                                        onClick={handleClickMenu(MatterTaskActionEnum.TransferTrustToPayInvoice)}
                                                        disabled={!matterData.actions.canTransferTrustToPayInvoice}
                                                    >
                                                        {matterData.actions.hasTransferTrustToPayInvoiceTask && (
                                                            <DoneIcon
                                                                fontSize="small"
                                                                className={classes.doneIcon}
                                                            />
                                                        )}
                                                        <AccountBalanceWalletOutlinedIcon fontSize="small" className={classes.icon} /> Transfer Trust to Pay invoice
                                                    </MenuItem>  

                                                }

                                                {
                                                    UserFeature.HasAccess(UserFeatureEnum.hasWriteOffDebtors)  &&
                                                    <MenuItem
                                                        onClick={handleClickMenu(MatterTaskActionEnum.WriteOffDebtors)}
                                                        disabled={!matterData.actions.canWriteOffDebtors}
                                                    >
                                                        {matterData.actions.hasWriteOffDebtorsTask && (
                                                            <DoneIcon
                                                                fontSize="small"
                                                                className={classes.doneIcon}
                                                            />
                                                        )}
                                                        <TollOutlinedIcon fontSize="small" className={classes.icon} /> Write off Debtors
                                                    </MenuItem>
                                                }

                                                {
                                                    UserFeature.HasAccess(UserFeatureEnum.hasCloseMatter) &&

                                                    <MenuItem
                                                        onClick={handleClickMenu(MatterTaskActionEnum.CloseMatter)}
                                                        disabled={!matterData.actions.canCloseMatter}
                                                    >
                                                        {matterData.actions.hasCloseMatterTask && (
                                                            <DoneIcon
                                                                fontSize="small"
                                                                className={classes.doneIcon}
                                                            />
                                                        )}
                                                        <CancelPresentationOutlinedIcon fontSize="small" className={classes.icon} /> Close Matter
                                                    </MenuItem>
                                                }                             
                                                
                                                {
                                                    UserFeature.HasAccess(UserFeatureEnum.hasArchiveMatter) &&
                                                    <MenuItem
                                                        onClick={handleClickMenu(MatterTaskActionEnum.ArchiveMatter)}
                                                        disabled={!matterData.actions.canArchiveMatter}
                                                    >
                                                        {matterData.actions.hasArchiveMatterTask && (
                                                            <DoneIcon
                                                                fontSize="small"
                                                                className={classes.doneIcon}
                                                            />
                                                        )}
                                                        <ArchiveOutlinedIcon fontSize="small" className={classes.icon} /> Archive Matter
                                                    </MenuItem>
                                                }                                                                                  
                                                
                                                {/* Display View Matter if it is used in Summary View only */}
                                                {props.data && props.data.id && (
                                                    <MenuItem
                                                        onClick={(event: React.MouseEvent<EventTarget>) => 
                                                            onMatterDetailOpen(event)
                                                        }
                                                    >
                                                        <VisibilityOutlinedIcon fontSize="small" className={classes.icon} /> View Matter
                                                    </MenuItem>
                                                )} 

                                                {
                                                    UserFeature.HasAccess(UserFeatureEnum.hasCreateTask) && UserFeature.HasAccess(UserFeatureEnum.hasAdHocTask) &&
                                                    <MenuItem
                                                        onClick={handleClickMenu(MatterTaskActionEnum.CreateTask)}
                                                        disabled={false}
                                                    >
                                                        {matterData.actions.hasMatterTask && (
                                                            <DoneIcon
                                                                fontSize="small"
                                                                className={classes.doneIcon}
                                                            />
                                                        )}
                                                        <PlaylistAddOutlinedIcon fontSize="small" className={classes.icon} /> Create Adhoc Task
                                                    </MenuItem>
                                                }                                               
                                                
                                                {
                                                    UserFeature.HasAccess(UserFeatureEnum.hasFollowUpMatterTask) && 
                                                    <MenuItem
                                                        onClick={handleClickMenu(MatterTaskActionEnum.MatterFollowUpAction)}
                                                    >
                                                        <LocationSearchingOutlinedIcon fontSize="small" className={classes.icon} /> Follow Up
                                                    </MenuItem>
                                                }

                                                {/* <MenuItem
                                                    onClick={(event) => onEmailPersonActing(event)}
                                                    disabled={false}
                                                    style={!UserFeature.HasAccess(UserFeatureEnum.hasMatterEmailPersonActing) 
                                                        ? {display: 'none'} 
                                                        : {display: 'block'}}
                                                >
                                                    Email Person Acting
                                                </MenuItem>  
                                                <MenuItem
                                                    onClick={(event) => onEmailPersonResponsible(event)}
                                                    disabled={false}
                                                    style={!UserFeature.HasAccess(UserFeatureEnum.hasMatterEmailPersonResponsible) 
                                                        ? {display: 'none'} 
                                                        : {display: 'block'}}
                                                >
                                                    Email Person Responsible
                                                </MenuItem>   */}
                                                {
                                                    UserFeature.HasAccess(UserFeatureEnum.hasUploadMatterDocument) && 
                                                    <MenuItem
                                                        onClick={handleClickMenu(MatterTaskActionEnum.UploadDocument)}
                                                        disabled={false}
                                                    >
                                                        <PublishOutlinedIcon fontSize="small" className={classes.icon} /> Upload Document
                                                    </MenuItem> 
                                                }
                                                {UserFeature.HasAccess(UserFeatureEnum.hasMatterForecast) && (
                                                    <MenuItem
                                                        onClick={handleClickMenu(MatterTaskActionEnum.MatterForecasts)}
                                                        disabled={false}
                                                    >
                                                        <CastOutlinedIcon fontSize="small" className={classes.icon} /> Matter Forecasts
                                                    </MenuItem> 
                                                )}
                                            </MenuList>
                                        </ClickAwayListener>                                                    
                                    </Paper>
                                </React.Fragment>                        
                            </Grow>
                        )}
                    </Popper>
                </React.Fragment> 
            );
        } else {
            return null;
        }
    };

    return (
        <React.Fragment>
            {
                props.data !== undefined && (
                    <div className={classes.root + ' ag-matter-action-column'}>
                        <IconButton
                            color="primary"
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        >
                            <MoreHorizOutlinedIcon />
                        </IconButton>

                        {
                            isLoading
                            ? getLoader()
                            : getMenuItems()                  
                        }

                        {getMatterTaskActions(matterId)} 
                    </div> 
                )
            }
        </React.Fragment>    
    );
};
