import React, { useState, useEffect } from 'react';
// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { Role, useRoleListQuery } from '../hooks/useRoleListQuery';

interface RoleSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | IAutoCompleteItem[] | any; // value only available in single select
    error?: boolean;
    onSelection?: (value?: IAutoCompleteItem | IAutoCompleteItem[], name?: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    style?: React.CSSProperties; 
    disablePortal?: boolean;
    isExternal?: boolean;
}

interface RoleSelectorState {
    isLoading: boolean;
    data: IAutoCompleteItem[];
}

export const RoleSelector: React.FC<RoleSelectorProps> = props => {

    // const classes = useStyles();

    const [state, setState] = useState<RoleSelectorState>({
        isLoading: false,
        data: [],
    });

    const roleListQuery = useRoleListQuery({ isExternal: props.isExternal ?? null }, 'cache-first');
    
    useEffect(() => {
        if (!roleListQuery.loading && !roleListQuery.error && roleListQuery.data) {
            setState((prevState) => {
                return {
                    ...prevState,
                    data: roleListQuery.data!.roleList.role.map((source: Role) => ({value: source.roleIdString, label: source.rolename })),
                };
            });
        }
        if (roleListQuery.error) {
            showNotification(null, roleListQuery.error.message, 'error');
        }
    },        [roleListQuery.data, roleListQuery.loading, roleListQuery.error]);

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {

        let tempValue = value;

        // if (props.multiple && state.data) {
        //     tempValue = Array.from(new Set(value.map((option: any) => option.value))).map((value) =>
        //         state.data.find((option: any) => option.value === value)
        //     );

        //     console.log('tempValue', tempValue);
        // }

        if (props.onSelection) {
            props.onSelection(tempValue, props.name);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };
      
    return (
        <>
            { props.multiple ? 
                (
                    <Autocomplete
                        style={props.style}
                        className={props.className}
                        value={props.value as IAutoCompleteItem[]}
                        multiple={true}
                        loading={roleListQuery.loading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.data}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}     
                        getOptionSelected={(option, value) => option.value === value.value} 
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, roleListQuery.loading)}
                                error={props.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        style={props.style}
                        className={props.className}
                        value={props.value as IAutoCompleteItem}
                        loading={roleListQuery.loading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.data}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}   
                        getOptionSelected={(option, value) => option.value === value.value} 
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, roleListQuery.loading)}
                                error={props.error}                            
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </>
    );
};